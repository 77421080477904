import axios from 'axios'

export const analysesApi = {
  listProblemSet: () => axios.get('/problem-set/').then(res => res.data),

  getProblemSet: problemSetId =>
    axios.get(`/problem-set/${problemSetId}/`).then(res => res.data),

  listSolutionSet: () => axios.get('/solution-set/').then(res => res.data),

  getSolutionSet: solutionSetId =>
    axios.get(`/solution-set/${solutionSetId}/`).then(res => res.data),

  listAnalysisType: () => axios.get('/analysis-type/').then(res => res.data),

  getAnalysisType: analysisTypeId =>
    axios.get(`/analysis-type/${analysisTypeId}/`).then(res => res.data),

  initAnalysis: data => axios.post('/analysis/', data).then(res => res.data),

  listAnalysis: config => axios.get('/analysis/', config).then(res => res.data),

  getAnalysis: analysisId =>
    axios.get(`/analysis/${analysisId}/`).then(res => res.data),

  updateAnalysis: ({ id, data }) =>
    axios.patch(`/analysis/${id}/`, data).then(res => res.data),

  deleteAnalysis: analysisId =>
    axios.delete(`/analysis/${analysisId}/`).then(res => res.data),

  listAnalysisUser: () => axios.get('/analysis/users/').then(res => res.data),

  prepareDownloadResult: analysisId =>
    axios
      .post(`/analysis/${analysisId}/prepare_download/`)
      .then(res => res.data),

  getLaterAnalyses: analysisId =>
    axios.get(`/analysis/${analysisId}/later_analyses/`).then(res => res.data),

  loadOutputFiles: analysisId =>
    axios.get(`/analysis/${analysisId}/output/`).then(res => res.data),

  searchOutputFiles: config =>
    axios.get('/analysis-output/', config).then(res => res.data),

  downloadHTML: ({ id, isGICA }) =>
    axios.get(
      `/analysis/${id}/${
        isGICA ? 'download_gica_network_summary_html' : 'download_html'
      }/`,
      { responseType: 'text' },
    ),

  getAnalysisDataFile: config =>
    axios.get('/run-analysis-data/datafile/', config).then(res => res.data),
}
