import { WarningOutlined } from '@ant-design/icons'
import { Alert, Form, Table, Tooltip } from 'antd'
import {
  drop,
  filter,
  get,
  head,
  isEmpty,
  map,
  noop,
  uniqBy,
  zipObject,
} from 'lodash'
import { PropTypes } from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, MiscFileSelect } from 'components'
import {
  selectAnalysisOptions,
  setAnalysisOption,
} from 'store/modules/analyses'
import {
  getMetadata,
  selectDataFilesStatus,
  selectMetadata,
} from 'store/modules/datafiles'

const MAX_NUM_FILTER_OPTIONS = 10
const HIDDEN_METADTA_COLUMNS = ['_df']

export const MetadataEditor = ({
  initialValue,
  searchDataFile,
  onChange,
  onFileChange,
}) => {
  const analysisOptions = useSelector(selectAnalysisOptions)
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const metadata = useSelector(selectMetadata)

  const dispatch = useDispatch()

  const selectedMetadata =
    initialValue || get(analysisOptions, 'Metadata.value')

  const [tableData, setTableData] = useState({
    metadataValue: [],
    tableColumns: [],
    metaError: null,
  })

  useEffect(() => {
    if (selectedMetadata) {
      handleMiscFileChange(selectedMetadata)
    }
  }, [selectedMetadata]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (dataFilesStatus === getMetadata.fulfilled().type) {
      initMetadataTable()
    } else if (dataFilesStatus === getMetadata.rejected().type) {
      setTableData({
        metadataValue: [],
        tableColumns: [],
        metaError: 'Failed to load CSV file',
      })

      onChange({ result: [], tableColumns: [] })
    }
  }, [dataFilesStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  const warningDFColumn = {
    title: '',
    dataIndex: '_df',
    key: 'warning_df',
    filters: [
      {
        text: 'Found',
        value: 'found',
      },
      {
        text: 'Not Found',
        value: null,
      },
    ],
    filterMultiple: false,
    render: record =>
      !record && (
        <Tooltip placement="rightTop" title="DataFile is not found">
          <WarningOutlined style={{ color: 'red' }} />
        </Tooltip>
      ),
    onFilter: (value, record) =>
      value === null ? isEmpty(get(record, '_df')) : get(record, '_df'),
  }

  const initMetadataTable = () => {
    // Get table column header from first line.
    const headerMetadata = head(metadata)

    // Remove the header row.
    const dataRows = drop(metadata)

    // Generate dataSource with headerMetadata.
    const parsedMetadata = map(dataRows, (row, index) => ({
      ...zipObject(headerMetadata, row),
      index,
    }))

    const dataColumns = map(headerMetadata, key => {
      const uniqValues = uniqBy(parsedMetadata, key)

      // Note: only columns with small number of distinct values will be used as filters.
      const colFilters =
        uniqValues.length < MAX_NUM_FILTER_OPTIONS
          ? map(uniqValues, val => ({
              text: get(val, key),
              value: get(val, key),
            }))
          : null

      return {
        title: key,
        dataIndex: key,
        key,
        filters: colFilters,
        onFilter: (value, record) => get(record, key).indexOf(value) === 0,
      }
    })

    const newTableColumns = searchDataFile
      ? [warningDFColumn, ...dataColumns]
      : dataColumns

    setTableData({
      metadataValue: parsedMetadata,
      tableColumns: newTableColumns,
      metaErrors: null,
    })

    onChange({
      rows: parsedMetadata,
      tableColumns: newTableColumns,
    })
  }

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const handleMiscFileChange = miscFile => {
    handleSetOption('Metadata', 'value', miscFile)

    setTableData({
      metadataValue: [],
      metaError: null,
      tableColumns: [],
    })

    dispatch(getMetadata({ id: miscFile.id, searchDataFile }))
    onFileChange(miscFile)
  }

  const { metadataValue, metaError, tableColumns } = tableData

  const displayTableColumns = filter(
    tableColumns,
    col => !HIDDEN_METADTA_COLUMNS.includes(col.key),
  )

  const loading = dataFilesStatus === getMetadata.pending().type

  return (
    <>
      <Form.Item>
        <MiscFileSelect
          disabled={loading}
          initialValue={get(selectedMetadata, 'id')}
          onChange={handleMiscFileChange}
        />
      </Form.Item>

      {loading ? (
        <Loader />
      ) : (
        <>
          {metaError && (
            <Form.Item>
              <Alert type="error" message={metaError} />
            </Form.Item>
          )}

          {metadataValue.length > 0 && (
            <Form.Item colon={false} label={<span>Metadata Table:</span>}>
              <Table
                rowKey="index"
                scroll={{ x: '100%' }}
                pagination={{ pageSize: 5, showSizeChanger: false }}
                columns={displayTableColumns}
                dataSource={metadataValue}
                size="small"
              />
            </Form.Item>
          )}
        </>
      )}
    </>
  )
}

MetadataEditor.propTypes = {
  initialValue: PropTypes.string,
  searchDataFile: PropTypes.bool,
  onChange: PropTypes.func,
  onFileChange: PropTypes.func,
}

MetadataEditor.defaultProps = {
  initialValue: null,
  searchDataFile: false,
  onChange: noop,
  onFileChange: noop,
}

export default MetadataEditor
