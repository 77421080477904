import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'

import authMiddleware from './middlewares/auth'
import reducer from './reducers'

export const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat([logger, authMiddleware]),
  devTools: process.env.NODE_ENV !== 'production',
})

export const setupStore = preloadedState =>
  configureStore({
    reducer,
    preloadedState,
  })
