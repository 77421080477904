import { Card, Form } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { CustomAsyncSelect } from 'components'

const SubjectSearchForm = ({ study, visible, onSubmit }) => {
  const [subject, setSubject] = useState()

  const handleSubmit = value => {
    setSubject(value)
    onSubmit(value)
  }

  return (
    <Form className={classNames('subject-search-form', { 'd-none': !visible })}>
      <Card bodyStyle={{ padding: '0.5rem' }}>
        <Form.Item className="mb-05">
          <CustomAsyncSelect
            placeholder="Subject"
            searchByDefault
            fetchUrl={{
              base: '/data-directory-filter/subject/',
              queryParams: { study: study.id },
            }}
            value={subject}
            onChange={value => handleSubmit(value)}
          />
        </Form.Item>
      </Card>
    </Form>
  )
}

SubjectSearchForm.propTypes = {
  study: PropTypes.object,
  visible: PropTypes.bool,
  onSubmit: PropTypes.func,
}

export default SubjectSearchForm
