import { Alert } from 'antd'
import PropTypes from 'prop-types'
import ReactJson from 'react-json-view'

import { Loader } from 'components'

const ScanParamsTab = ({ data, error, loading }) => {
  if (loading) {
    return <Loader />
  }

  if (error) {
    return <Alert message={error} type="error" showIcon />
  }

  return (
    <div data-testid="scan-parameters-view">
      <ReactJson
        src={data}
        displayDataTypes={false}
        displayObjectSize={false}
        theme="monokai"
      />
    </div>
  )
}

ScanParamsTab.propTypes = {
  data: PropTypes.object,
  error: PropTypes.string,
  loading: PropTypes.bool,
}

export default ScanParamsTab
