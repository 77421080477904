import { Tabs } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'

import SummaryTable from './SummaryTable'

const RegressionResult = ({ data, dataFile }) => {
  if (!data.summary) {
    return null
  }

  return (
    <div className="analysis-result" data-testid="regression-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'regression',
            label: 'Regression',
            children: (
              <>
                {map(data.summary, (item, ind) => (
                  <div
                    key={ind}
                    style={{ margin: '2rem 0' }}
                    data-testid="regression-summary"
                  >
                    <SummaryTable content={item} />
                  </div>
                ))}
              </>
            ),
          },
        ]}
      />
    </div>
  )
}

RegressionResult.propTypes = {
  data: PropTypes.shape({
    summary: PropTypes.any,
  }),
  dataFile: PropTypes.object,
}

export default RegressionResult
