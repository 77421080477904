import { Col, Divider, Form, Row, Switch } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  DataFileTree,
  Loader,
  MetadataEditor,
  MiscFileSelect,
} from 'components'
import {
  selectAnalysis,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import {
  setAllFiles,
  setCurrentFiles,
  updateCurrentFileFields,
} from 'store/modules/datafiles'
import { listUploadableStudy, selectSitesStatus } from 'store/modules/sites'

export const GroupICAUploadSection = ({ showSelector }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisType = useSelector(selectAnalysisType)
  const sitesStatus = useSelector(selectSitesStatus)

  const dispatch = useDispatch()

  const initialMetadataValue = get(
    analysis,
    'parameters.analysis.options.Metadata.value',
  )
  const [dataOrder, setDataOrder] = useState()
  const [isMetadataSelected, setIsMetadataSelected] = useState(
    Boolean(initialMetadataValue),
  )

  const inputFile = get(analysis, 'parameters.analysis.options.files.value')

  const handleTreeChange = files => {
    dispatch(setAllFiles(files))
    dispatch(setCurrentFiles(files))
  }

  const handleMiscFileChange = file => {
    dispatch(
      setAnalysisOption({
        name: 'TS_SPM_mat_file_path',
        option: { value: file.path },
      }),
    )
  }

  const loading = sitesStatus === listUploadableStudy.pending().type

  if (loading) {
    return <Loader />
  }

  return (
    <Form layout="vertical">
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Switch
            checkedChildren="Select a Study CSV GICA file"
            unCheckedChildren="Select a Study CSV GICA file"
            checked={isMetadataSelected}
            onChange={setIsMetadataSelected}
          />
        </Col>

        {isMetadataSelected && (
          <Col span={24} className="mt-1">
            <MetadataEditor
              searchDataFile
              initialValue={initialMetadataValue}
              allowedHeaders={[
                'study',
                'subject',
                'session',
                'series',
                'datafile',
              ]}
              onChange={metadata => setDataOrder(get(metadata, 'rows'))}
            />
            <Divider />
          </Col>
        )}

        {showSelector && (
          <>
            <Col span={24} className="mt-1">
              <Form.Item label="DataFile">
                <DataFileTree
                  multiple
                  dataOrder={isMetadataSelected ? dataOrder : null}
                  analysisType={analysisType}
                  analysis={analysis}
                  initialValue={inputFile}
                  onChange={handleTreeChange}
                  onUpdateFields={values =>
                    dispatch(updateCurrentFileFields(values))
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Design matrix">
                <MiscFileSelect
                  sortingInfo={{
                    enabled: true,
                    nameKey: 'title',
                    chronoKey: 'elem.id',
                  }}
                  onChange={handleMiscFileChange}
                />
              </Form.Item>
            </Col>
          </>
        )}
      </Row>
    </Form>
  )
}

GroupICAUploadSection.propTypes = {
  showSelector: PropTypes.bool,
}

GroupICAUploadSection.defaultProps = {
  showSelector: true,
}

export default GroupICAUploadSection
