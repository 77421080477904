import { Alert, Button, Empty } from 'antd'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import { NOTIFICATION_TYPES } from 'config/base'
import {
  deleteNotification,
  listNotification,
  selectAuthStatus,
  selectLoggedInUser,
  selectNotifications,
} from 'store/modules/auth'
import { downloadResult } from 'utils/analyses'
import { getNotificationSocket } from 'utils/sockets'

const MyNotification = () => {
  const notifications = useSelector(selectNotifications)
  const status = useSelector(selectAuthStatus)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const webSocket = useRef(null)

  const handleMessageReceived = useCallback(
    message => {
      if (message.data === 'update') {
        dispatch(listNotification())
      }
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(listNotification())

    webSocket.current = getNotificationSocket(user.id)
    webSocket.current.onmessage = handleMessageReceived

    return () => {
      webSocket.current.close()
    }
  }, [user, handleMessageReceived, dispatch])

  const handleDownload = useCallback(
    (id, downloadUrl) => {
      downloadResult(downloadUrl)
      dispatch(deleteNotification(id))
    },
    [dispatch],
  )

  const notificationList = useMemo(() => {
    if (status === listNotification.pending().type) {
      return <Loader />
    }

    if (notifications.length) {
      return notifications.map(
        ({ id, message, date_time, type, download_url }) => {
          switch (NOTIFICATION_TYPES[type]) {
            case 'download':
              return (
                <Alert
                  key={id}
                  type="info"
                  className="mb-1"
                  message={
                    <div className="d-flex justify-content-between px-05">
                      <div>{message}</div>
                      <div>
                        <Button
                          style={{ marginRight: 20 }}
                          onClick={() => handleDownload(id, download_url)}
                        >
                          Download
                        </Button>
                        <span>
                          {moment(date_time).format('YYYY-MM-DD HH:mm:ss')}
                        </span>
                      </div>
                    </div>
                  }
                  showIcon
                  closable
                  banner
                  data-testid="download-notification"
                  onClose={() => dispatch(deleteNotification(id))}
                />
              )
            default:
              return (
                <Alert
                  key={id}
                  className="mb-1"
                  message={
                    <div className="d-flex justify-content-between px-05">
                      <div>{message}</div>
                      <div>
                        {moment(date_time).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                    </div>
                  }
                  showIcon
                  closable
                  banner
                  data-testid="invite-notification"
                  onClose={() => dispatch(deleteNotification(id))}
                />
              )
          }
        },
      )
    }

    return <Empty description="No Notifications" />
  }, [notifications, status, dispatch, handleDownload])

  return (
    <div data-testid="my-notifications">
      <div className="app-page__subheading">Notifications</div>
      {notificationList}
    </div>
  )
}

export default MyNotification
