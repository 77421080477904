import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import {
  getAnalysisType,
  initAnalysisOptions,
  selectAnalysesStatus,
  selectAnalysis,
} from 'store/modules/analyses'
import { setCurrentFiles } from 'store/modules/datafiles'

import ContrastsOptionEditor from './ContrastsOptionEditor'
import RunsOptionEditor from './RunsOptionEditor'

export const SPMGLMUploadSection = ({ readOnly }) => {
  const analysesStatus = useSelector(selectAnalysesStatus)
  const analysis = useSelector(selectAnalysis)

  const dispatch = useDispatch()

  const [numOnsets, setNumOnsets] = useState(0)

  useEffect(() => {
    if (analysis) {
      const analysisOptions = analysis.parameters.analysis.options

      dispatch(initAnalysisOptions(analysisOptions))
      dispatch(setCurrentFiles([analysis.input_file]))
    } else {
      dispatch(
        initAnalysisOptions({
          Runs: { value: [] },
          Contrasts: { value: [] },
        }),
      )
    }
  }, [dispatch, analysis])

  if (analysesStatus === getAnalysisType.pending().type) {
    return <Loader />
  }

  return (
    <Form.Provider>
      <Form layout="vertical">
        <Form.Item label="Runs Configuration">
          <RunsOptionEditor readOnly={readOnly} setNumOnSets={setNumOnsets} />
        </Form.Item>
      </Form>

      <ContrastsOptionEditor readOnly={readOnly} numOnsets={numOnsets} />
    </Form.Provider>
  )
}

SPMGLMUploadSection.propTypes = {
  readOnly: PropTypes.bool,
}

export default SPMGLMUploadSection
