import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal, Table, Tag, Tooltip } from 'antd'
import pluralize from 'pluralize'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Drawer, TagForm } from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  createTag,
  deleteTag,
  listTag,
  selectSitesStatus,
  selectTags,
  updateTag,
} from 'store/modules/sites'

const TagListPage = () => {
  const status = useSelector(selectSitesStatus)
  const tags = useSelector(selectTags)

  const dispatch = useDispatch()

  const [editingRecord, setEditingRecord] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    dispatch(listTag())
  }, [dispatch])

  useEffect(() => {
    if (
      [createTag.fulfilled().type, updateTag.fulfilled().type].includes(status)
    ) {
      setShowDrawer(false)
    }
  }, [status])

  useEffect(() => {
    if (!showDrawer) {
      setEditingRecord(null)
    }
  }, [showDrawer])

  const columns = [
    { title: 'Id', dataIndex: 'id', key: 'id' },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
      render: (text, record) => (
        <Link to={`/tag/${record.id}`}>
          <Tag color={record.color} className="cursor-pointer">
            {text}
          </Tag>
        </Link>
      ),
    },
    {
      title: 'Studies',
      dataIndex: 'studies',
      key: 'studies',
      render: (_, record) => record.studies.length,
    },
    {
      title: 'Subjects',
      dataIndex: 'subjects',
      key: 'subjects',
      render: (_, record) => record.subjects.length,
    },
    {
      title: 'Sessions',
      dataIndex: 'sessions',
      key: 'sessions',
      render: (_, record) => record.sessions.length,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 150,
      render: (_, record) => (
        <div className="d-flex">
          <Tooltip title="Edit">
            <Button
              className="mr-05"
              shape="circle"
              icon={<EditOutlined />}
              size="small"
              data-testid="tag-edit-button"
              onClick={() => handleEdit(record)}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              danger
              size="small"
              data-testid="tag-delete-button"
              onClick={() => handleDelete(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ]

  const handleSubmit = payload => {
    const { id, data } = payload

    if (id) {
      dispatch(updateTag(payload))
    } else {
      dispatch(createTag(data))
    }
  }

  const handleEdit = record => {
    setEditingRecord(record)
    setShowDrawer(true)
  }

  const handleDelete = record => {
    const tagInfo = []

    if (record.studies.length > 0) {
      tagInfo.push(pluralize('study', record.studies.length, true))
    }

    if (record.sessions.length > 0) {
      tagInfo.push(pluralize('session', record.sessions.length, true))
    }

    if (record.subjects.length > 0) {
      tagInfo.push(pluralize('subject', record.studies.length, true))
    }

    const content =
      tagInfo.length > 0 ? `This tag is linked to ${tagInfo.join(', ')}.` : ''

    Modal.confirm({
      title: `Are you sure want to delete the tag labeled "${record.label}"?`,
      content,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteTag(record.id))
      },
    })
  }

  const loading = status === createTag.pending().type

  return (
    <PageLayout heading="Tags">
      <Card>
        <div className="text-right mb-1">
          <Button
            icon={<PlusOutlined />}
            type="primary"
            disabled={loading}
            data-testid="tag-add-button"
            onClick={() => setShowDrawer(true)}
          >
            Add Tag
          </Button>
        </div>
        <Table
          columns={columns}
          dataSource={tags}
          bordered
          size="small"
          rowKey="id"
          loading={loading}
        />
        <Drawer
          title={editingRecord ? 'Edit Tag' : 'Create Tag'}
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
        >
          <TagForm
            tag={editingRecord}
            submitting={loading}
            onSubmit={handleSubmit}
            onCancel={() => setShowDrawer(false)}
          />
        </Drawer>
      </Card>
    </PageLayout>
  )
}

export default TagListPage
