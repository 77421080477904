import { Layout } from 'antd'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { Header, Sidebar } from 'containers'
import { selectLoggedIn } from 'store/modules/auth'

const { Content } = Layout

const ProtectedRoute = () => {
  const isLoggedIn = useSelector(selectLoggedIn)

  if (!isLoggedIn) {
    return <Navigate to="/login" />
  }

  return (
    <Layout className="h-100">
      <Sidebar />
      <Layout className="main-layout">
        <Header />
        <Content style={{ flex: 1 }}>
          <main className="main-content">
            <Outlet />
          </main>
        </Content>
      </Layout>
    </Layout>
  )
}

export default ProtectedRoute
