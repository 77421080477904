/**
 * Module to create and store persistent websockets.
 */
import { SOCKET_PATH } from 'config/base'

let notificationSocket

/**
 * Get or create a peristent notification socket.
 * @param {Integer} userId ID of the logged in user.
 */
export const getNotificationSocket = userId => {
  if (!notificationSocket) {
    const url = `${SOCKET_PATH}/ws/notifications/${userId}/`
    const socket = new WebSocket(url)
    notificationSocket = socket
  }
  return notificationSocket
}
