import { EditOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Checkbox, Form, Input, Modal, Space } from 'antd'
import { find, get, last, map, orderBy } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CovariateConfigurationTable,
  Loader,
  ParameterSetForm,
  Select,
  UnivariateConfigurationTable,
  VariableConfigurationTable,
} from 'components'
import {
  ANALYSIS_COVARIATE_CONFIGURATIONS,
  ANALYSIS_VARIABLE_CONFIGURATIONS,
  SLURM_DEFAULT_PARTITION,
  SLURM_PARTITIONS,
} from 'config/base'
import {
  getAnalysisType,
  initAnalysisOptions,
  selectAnalysesStatus,
  selectAnalysis,
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import { selectLoggedInUser } from 'store/modules/auth'
import {
  createParameterSet,
  selectCurrentFiles,
  selectDataFilesStatus,
  selectParameterSets,
  toggleAllCurrentFilesField,
  updateCurrentFilesField,
  updateParameterSet,
} from 'store/modules/datafiles'

export const AnalysisConfiguration = () => {
  const analysesStatus = useSelector(selectAnalysesStatus)
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)
  const currentFiles = useSelector(selectCurrentFiles)
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const parameterSets = useSelector(selectParameterSets)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [openModal, setOpenModal] = useState(null)

  useEffect(() => {
    if (analysisType) {
      dispatch(
        setAnalysisOption({
          name: 'group_analysis',
          option: { value: analysisType.options.group_analysis },
        }),
      )
    }
  }, [dispatch, analysisType])

  const selectedParameterSetId = get(analysisOptions, 'parameter_set.value')

  const selectedParameterSet = useMemo(() => {
    if (selectedParameterSetId) {
      return find(parameterSets, { id: selectedParameterSetId })
    }

    return {
      id: null,
      analysis_type: analysisType?.id,
      options: analysisType?.options,
      is_custom: true,
      version: null,
    }
  }, [selectedParameterSetId, parameterSets, analysisType])

  useEffect(() => {
    if (analysis) {
      const payload = {
        name: { value: analysis.name },
        description: { value: analysis.description },
        parameter_set: {
          value: get(analysis, 'parameters.analysis.parameter_set'),
        },
        group_analysis: {
          value: get(analysis, 'parameters.analysis.group_analysis', false),
        },
        slurm_partition: {
          value: get(
            analysis,
            'parameters.analysis.options.slurm_partition.value',
          ),
        },
      }

      dispatch(initAnalysisOptions(payload))
    }
  }, [analysis, dispatch])

  const handleAnalysisOptionChange = useCallback(
    (name, value) => {
      dispatch(setAnalysisOption({ name, option: { value } }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (
      [
        createParameterSet.fulfilled().type,
        updateParameterSet.fulfilled().type,
      ].includes(dataFilesStatus)
    ) {
      setOpenModal(false)

      const lastParameterSetId = get(
        last(orderBy(parameterSets, ['id'], ['asc'])),
        'id',
      )

      if (
        dataFilesStatus === createParameterSet.fulfilled().type &&
        lastParameterSetId
      ) {
        handleAnalysisOptionChange('parameter_set', lastParameterSetId)
      }
    }
  }, [dataFilesStatus, parameterSets, handleAnalysisOptionChange])

  const handleSelectSlurm = parameterSetId => {
    const parameterSet = find(parameterSets, { id: parameterSetId })
    const slurmPartition =
      get(parameterSet, 'options.slurm_partition.value') ||
      SLURM_DEFAULT_PARTITION

    handleAnalysisOptionChange('slurm_partition', slurmPartition)
  }

  const handleToggleAllCurrentFilesField = values =>
    dispatch(toggleAllCurrentFilesField(values))

  const handleUpdateCurrentFilesField = values =>
    dispatch(updateCurrentFilesField(values))

  const preparingData = analysesStatus === getAnalysisType.pending().type

  if (preparingData) {
    return <Loader />
  }

  if (!analysisType) {
    return null
  }

  const showConfiguration = ANALYSIS_VARIABLE_CONFIGURATIONS.includes(
    analysisType.label,
  )

  const showCovariateConfiguration = ANALYSIS_COVARIATE_CONFIGURATIONS.includes(
    analysisType.label,
  )

  const isLoading = [
    createParameterSet.pending().type,
    updateParameterSet.pending().type,
  ].includes(dataFilesStatus)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
      xl: { span: 10 },
      xxl: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
      xl: { span: 14 },
      xxl: { span: 14 },
    },
  }

  const analysisParameterSets = parameterSets.filter(
    parameterSet => parameterSet.analysis_type === analysisType.id,
  )

  const selectedSlurmPartition = get(analysisOptions, 'slurm_partition.value')

  return (
    <div data-testid="analysis-configuration">
      {showConfiguration && (
        <div>
          {map(currentFiles, (file, ind) => (
            <VariableConfigurationTable
              key={ind}
              file={file}
              toggleAllCurrentFilesField={handleToggleAllCurrentFilesField}
              updateCurrentFilesField={handleUpdateCurrentFilesField}
            />
          ))}
        </div>
      )}
      {showCovariateConfiguration && (
        <div>
          {map(currentFiles, (file, ind) => (
            <CovariateConfigurationTable
              key={ind}
              file={file}
              toggleAllCurrentFilesField={handleToggleAllCurrentFilesField}
              updateCurrentFilesField={handleUpdateCurrentFilesField}
            />
          ))}
        </div>
      )}
      {showCovariateConfiguration && (
        <div>
          {map(currentFiles, (file, ind) => (
            <UnivariateConfigurationTable
              key={ind}
              file={file}
              toggleAllCurrentFilesField={handleToggleAllCurrentFilesField}
              updateCurrentFilesField={handleUpdateCurrentFilesField}
            />
          ))}
        </div>
      )}
      <Form.Item label="Name" {...formItemLayout}>
        <Input
          value={get(analysisOptions, 'name.value')}
          onChange={evt => handleAnalysisOptionChange('name', evt.target.value)}
        />
      </Form.Item>
      <Form.Item label="Description" {...formItemLayout}>
        <Input
          value={get(analysisOptions, 'description.value')}
          onChange={evt =>
            handleAnalysisOptionChange('description', evt.target.value)
          }
        />
      </Form.Item>
      <Form.Item label="Group Analysis" {...formItemLayout}>
        <Checkbox
          checked={get(analysisOptions, 'group_analysis.value')}
          onChange={evt =>
            handleAnalysisOptionChange('group_analysis', evt.target.checked)
          }
        />
      </Form.Item>
      <Form.Item label="Slurm Partition" {...formItemLayout}>
        <Select
          placeholder="Use value in Parameter Set by default"
          value={selectedSlurmPartition}
          options={map(SLURM_PARTITIONS, partition => ({
            label: partition,
            value: partition,
          }))}
          onChange={value =>
            handleAnalysisOptionChange('slurm_partition', value)
          }
        />
      </Form.Item>
      <Form.Item label="Parameter Set" {...formItemLayout}>
        {analysisParameterSets.length > 0 ? (
          <Select
            value={selectedParameterSetId}
            placeholder="Parameter Set"
            options={analysisParameterSets.map(({ id, name, version }) => ({
              label: `${name} - v${version}`,
              value: id,
            }))}
            onChange={value => {
              handleAnalysisOptionChange('parameter_set', value)
              handleSelectSlurm(value)
            }}
          />
        ) : (
          <span className="mr-1">No Parameter Sets</span>
        )}

        <div className="mt-05">
          <Space wrap>
            {selectedParameterSetId && (
              <Button
                size="small"
                icon={<EditOutlined />}
                type="primary"
                onClick={() => setOpenModal(true)}
              >
                Edit Parameter Set
              </Button>
            )}

            <Button
              size="small"
              icon={<PlusOutlined />}
              type="primary"
              onClick={() => {
                setOpenModal(true)
                handleAnalysisOptionChange('parameter_set', null)
              }}
            >
              New Parameter Set
            </Button>
          </Space>
        </div>
      </Form.Item>

      <Modal
        title={`${analysisType?.label} Parameter Set`}
        open={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
        width={1100}
        destroyOnClose
      >
        <ParameterSetForm
          parameterSet={selectedParameterSet}
          analysisTypes={[analysisType]}
          user={user}
          submitting={isLoading}
          formModal
          onCancel={() => setOpenModal(false)}
        />
      </Modal>
    </div>
  )
}

export default AnalysisConfiguration
