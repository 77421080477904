import { Card, Col, Row } from 'antd'
import { first, get, keys, map, omit, reduce, toUpper } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import Highcharts from 'react-highcharts'

import { getAnalysisLegend } from 'utils/analyses'

const MetricChart = ({ analyses, onOpenAnalysisPage }) => {
  const chartData = useMemo(() => {
    const analysisKeys = keys(
      omit(get(first(analyses), 'fmri_phantom_qa_data.0'), 'slice'),
    )

    const res = reduce(
      analysisKeys,
      (acc, key) => {
        acc[key] = map(analyses, analysis => ({
          name: getAnalysisLegend(analysis),
          data: analysis.fmri_phantom_qa_data.map(elem => ({
            y: elem[key],
            events: { click: () => onOpenAnalysisPage(analysis.id) },
          })),
        }))

        return acc
      },
      {},
    )

    return res
  }, [analyses, onOpenAnalysisPage])

  const getConfig = (data, key) => ({
    chart: {
      type: 'line',
    },
    title: {
      text: toUpper(key),
    },
    xAxis: {
      title: {
        text: 'Slice',
      },
      allowDecimals: false,
    },
    yAxis: {
      title: {
        text: key === 'signal_p2p' || key === 'ghost' ? 'Percent (%)' : null,
      },
    },
    plotOptions: {
      line: {
        tooltip: {
          headerFormat: '<b>Slice: {point.x}</b><br>',
        },
      },
      series: {
        cursor: 'pointer',
      },
    },
    series: data,
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
  })

  return (
    <Row gutter={24} data-testid="scanner-metric-chart">
      {keys(chartData).map(key => (
        <Col key={key} md={12}>
          <Card className="mt-1">
            <Highcharts config={getConfig(chartData[key], key)} />
          </Card>
        </Col>
      ))}
    </Row>
  )
}

MetricChart.propTypes = {
  analyses: PropTypes.arrayOf(
    PropTypes.shape({
      date_time_end: PropTypes.string,
      date_time_start: PropTypes.string,
      fmri_phantom_qa_data: PropTypes.array,
      name: PropTypes.string,
    }),
  ),
  onOpenAnalysisPage: PropTypes.func,
}

export default MetricChart
