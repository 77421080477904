import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'
import classNames from 'classnames'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { authApi } from 'apis'
import { selectLoggedIn, selectLoggedInUser, setUser } from 'store/modules/auth'
import { parseError } from 'utils/error-parser'
import { setAuthData } from 'utils/storage'

export const EmailVerifyPage = () => {
  const loggedIn = useSelector(selectLoggedIn)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const handleVerifyEmail = useCallback(
    async code => {
      setError(null)
      setIsLoading(true)

      try {
        const data = await authApi.verifyEmail({ token: code })
        setAuthData(data)
        dispatch(setUser(data))
      } catch (err) {
        setError(parseError(err).message)
      } finally {
        setIsLoading(false)
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (loggedIn && user.email_verified) {
      navigate('/study')
    }

    const { code } = queryString.parse(search)

    if (code) {
      handleVerifyEmail(code)
    } else {
      navigate('/login')
    }
  }, [search, loggedIn, user, handleVerifyEmail, dispatch, navigate])

  const icon = useMemo(() => {
    const className = classNames('email-verify__icon', {
      loading: isLoading,
      success: !error,
      fail: Boolean(error),
    })

    if (isLoading) {
      return <LoadingOutlined className={className} />
    }

    if (!error) {
      return <CheckCircleOutlined className={className} />
    }

    return <ExclamationCircleOutlined className={className} />
  }, [error, isLoading])

  return (
    <div className="email-verify">
      <div className="email-verify__message">
        {icon}

        <div style={{ marginTop: 40 }}>
          {isLoading && (
            <Typography.Title>Verifying your email...</Typography.Title>
          )}

          {!isLoading && !error && (
            <>
              <Typography.Title>Email is verified now.</Typography.Title>

              <Typography.Title level={5}>
                <Link to="/study">Click here</Link> to go to main page.
              </Typography.Title>
            </>
          )}
          {!isLoading && Boolean(error) && (
            <>
              <Typography.Title>Failed to verify your email.</Typography.Title>

              <Typography.Title level={5}>
                {error}
                <br />
                <Link to="/login">Click here</Link> to go to login page.
              </Typography.Title>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default EmailVerifyPage
