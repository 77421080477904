import { QuestionCircleOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row, Tooltip } from 'antd'
import { omit } from 'lodash'
import PropTypes from 'prop-types'

import { LANGUAGE } from 'config/base'
import { validators } from 'validators'

const ProfileForm = ({ error, loading, user, onCancel, onSubmit }) => {
  const handleSubmit = values => {
    const data = omit(values, !values.password ? ['password', 'confirm'] : [])
    onSubmit(data)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      disabled={loading}
      initialValues={user}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          {error && (
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
              banner
              data-testid="profile-form-error"
            />
          )}
          <Form.Item
            name="first_name"
            label="First name"
            rules={validators.genericRequired}
            hasFeedback
          >
            <Input placeholder="First name" />
          </Form.Item>
          <Form.Item
            name="last_name"
            label="Last name"
            rules={validators.genericRequired}
            hasFeedback
          >
            <Input placeholder="Last name" />
          </Form.Item>
          <Form.Item
            name="username"
            label="Username"
            rules={validators.genericRequired}
            hasFeedback
          >
            <Input placeholder="Username" />
          </Form.Item>
          <Form.Item
            name="email"
            label={
              <span>
                Email{' '}
                <Tooltip title="Verification email will be sent after updating email.">
                  <QuestionCircleOutlined />
                </Tooltip>
              </span>
            }
            rules={validators.email}
            hasFeedback
          >
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            label="Password"
            rules={validators.passwordUpdate}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  const currentPassword = getFieldValue('password')
                  if (currentPassword && value !== currentPassword) {
                    return Promise.reject(new Error(LANGUAGE.password.misMatch))
                  }

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button onClick={onCancel}>Cancel</Button>
          <Button
            className="ml-1"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

ProfileForm.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  user: PropTypes.shape({
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    username: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ProfileForm
