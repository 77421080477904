import { Col, Form, InputNumber, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts()

const ReorientForm = ({ analysisOptions, readOnly, setOption }) => {
  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item label={fieldLabel} {...formLayout}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  return (
    <Row>
      <Col span={13}>
        {renderInputNumber('x translation in mm', 'reorient_params_x_mm', {
          min: -50,
          max: 50,
        })}
        {renderInputNumber('y translation in mm', 'reorient_params_y_mm', {
          min: -50,
          max: 50,
        })}
        {renderInputNumber('z translation in mm', 'reorient_params_z_mm', {
          min: -50,
          max: 50,
        })}
      </Col>
      <Col span={11}>
        {renderInputNumber(
          'x rotation about pitch in degrees[-360,360]',
          'reorient_params_pitch',
          { min: -360, max: 360 },
        )}
        {renderInputNumber(
          'x rotation about roll in degrees[-360,360]',
          'reorient_params_roll',
          { min: -360, max: 360 },
        )}
        {renderInputNumber(
          'x rotation about yaw in degrees[-360,360]',
          'reorient_params_yaw',
          { min: -360, max: 360 },
        )}
      </Col>
    </Row>
  )
}

ReorientForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

ReorientForm.defaultProps = {
  readOnly: false,
}

export default ReorientForm
