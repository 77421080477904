import { Col, Collapse, Form, InputNumber, Row, Typography } from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { Panel } = Collapse
const { Text } = Typography
const { formLayout } = getParameterLayouts(true)

const FMRIPhantomQAOptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderInputNumber = (
    fieldName,
    label,
    inputProps = { min: 0, max: 1, precision: 4 },
  ) => (
    <FormItem {...formLayout} label={label} disabled={readOnly}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  return (
    <Row data-testid="fmri-phantom-qa-option-editor">
      <Col md={24}>
        {renderInputNumber('slice', 'Slice of Interest', {
          min: 0,
          precision: 0,
        })}
      </Col>
      <Col md={24}>
        <Text>
          The factors below are relative to total number of columns or rows in
          image.
        </Text>
        <Collapse>
          <Panel header="Signal ROI">
            {renderInputNumber('si_row_start_factor', 'Row Start Factor')}
            {renderInputNumber('si_row_width_factor', 'Row Width Factor')}
            {renderInputNumber('si_col_start_factor', 'Column Start Factor')}
            {renderInputNumber('si_col_width_factor', 'Column Width Factor')}
          </Panel>
          <Panel header="SNR Image ROI">
            {renderInputNumber('snr_im_row_start_factor', 'Row Start Factor')}
            {renderInputNumber('snr_im_row_width_factor', 'Row Width Factor')}
            {renderInputNumber(
              'snr_im_col_start_factor',
              'Column Start Factor',
            )}
            {renderInputNumber(
              'snr_im_col_width_factor',
              'Column Width Factor',
            )}
          </Panel>
          <Panel header="SNR Background ROI">
            {renderInputNumber('snr_bg_row_start_factor', 'Row Start Factor')}
            {renderInputNumber('snr_bg_row_width_factor', 'Row Width Factor')}
            {renderInputNumber(
              'snr_bg_col_start_factor',
              'Column Start Factor',
            )}
            {renderInputNumber(
              'snr_bg_col_width_factor',
              'Column Width Factor',
            )}
          </Panel>
          <Panel header="Ghost Image ROI">
            {renderInputNumber('ghost_im_row_start_factor', 'Row Start Factor')}
            {renderInputNumber('ghost_im_row_width_factor', 'Row Width Factor')}
            {renderInputNumber(
              'ghost_im_col_start_factor',
              'Column Start Factor',
            )}
            {renderInputNumber(
              'ghost_im_col_width_factor',
              'Column Width Factor',
            )}
          </Panel>
          <Panel header="Ghost Background ROI">
            {renderInputNumber('ghost_bg_row_start_factor', 'Row Start Factor')}
            {renderInputNumber('ghost_bg_row_width_factor', 'Row Width Factor')}
            {renderInputNumber(
              'ghost_bg_col_start_factor',
              'Column Start Factor',
            )}
            {renderInputNumber(
              'ghost_bg_col_width_factor',
              'Column Width Factor',
            )}
          </Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

FMRIPhantomQAOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

FMRIPhantomQAOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default FMRIPhantomQAOptionEditor
