import { Button, Col, Form, Row } from 'antd'
import PropTypes from 'prop-types'

import { Select } from 'components'
import { validators } from 'validators'

const UserSelectForm = ({ submitting, users, onCancel, onSubmit }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      data-testid="user-select-form"
      disabled={submitting}
      onFinish={onSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="user"
            label="User"
            rules={validators.genericRequired}
          >
            <Select
              options={users.map(({ id, username }) => ({
                label: username,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Add
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

UserSelectForm.propTypes = {
  submitting: PropTypes.bool,
  users: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default UserSelectForm
