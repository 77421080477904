import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { notification } from 'antd'
import { find, get, isObject } from 'lodash'

import { analysesApi } from 'apis'
import { SOCKET_PATH } from 'config/base'
import * as datafilesRedux from 'store/modules/datafiles'
import { prepareAnalysis } from 'utils/analyses'
import { parseError } from 'utils/error-parser'

export const listProblemSet = createAsyncThunk(
  'analyses/listProblemSet',
  async (_, { rejectWithValue }) => {
    try {
      return await analysesApi.listProblemSet()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getProblemSet = createAsyncThunk(
  'analyses/getProblemSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await analysesApi.getProblemSet(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listSolutionSet = createAsyncThunk(
  'analyses/listSolutionSet',
  async (_, { rejectWithValue }) => {
    try {
      return await analysesApi.listSolutionSet()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getSolutionSet = createAsyncThunk(
  'analyses/getSolutionSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await analysesApi.getSolutionSet(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listAnalysisType = createAsyncThunk(
  'analyses/listAnalysisType',
  async (_, { rejectWithValue }) => {
    try {
      return await analysesApi.listAnalysisType()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getAnalysisType = createAsyncThunk(
  'analyses/getAnalysisType',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = await analysesApi.getAnalysisType(payload)
      dispatch(setAnalysisType(payload))
      return data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const initAnalysis = createAsyncThunk(
  'analyses/initAnalysis',
  async (payload, { getState, rejectWithValue }) => {
    const currentFiles = datafilesRedux.selectCurrentFiles(getState())
    const parameterSets = datafilesRedux.selectParameterSets(getState())
    const { parameter_set, name, description, group_analysis, ...options } =
      payload.options
    const parameterSet = find(parameterSets, { id: parameter_set.value })

    const requestPayload = {
      file: {
        file: get(currentFiles, '0.id'),
        fields: get(currentFiles, '0.fields'),
        analysis_type: payload.analysisType,
      },
      options: {
        ...parameterSet.options,
        ...options,
        files: {
          value: currentFiles,
        },
      },
      name: name.value,
      description: get(description, 'value', ''),
      parameter_set: parameter_set.value,
      group_analysis: get(group_analysis, 'value', false),
    }

    try {
      const data = await analysesApi.initAnalysis(requestPayload)
      const socketUrl = `${SOCKET_PATH}/ws/analysis/${data.id}/`
      new WebSocket(socketUrl)
      notification.success({ message: 'Analysis Started' })

      payload.navigate('/status/')

      return
    } catch (error) {
      const err = parseError(error)
      notification.error({
        message: 'Analysis Start Failed',
        description: err.message,
      })
      return rejectWithValue(err)
    }
  },
)

export const listAnalysis = createAsyncThunk(
  'analyses/listAnalysis',
  async (payload, { rejectWithValue }) => {
    try {
      return await analysesApi.listAnalysis(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getAnalysis = createAsyncThunk(
  'analyses/getAnalysis',
  async (payload, { rejectWithValue }) => {
    try {
      return await analysesApi.getAnalysis(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateAnalysis = createAsyncThunk(
  'analyses/updateAnalysis',
  async (payload, { rejectWithValue }) => {
    try {
      return await analysesApi.updateAnalysis(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteAnalysis = createAsyncThunk(
  'analyses/deleteAnalysis',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      await analysesApi.deleteAnalysis(payload)
      dispatch(datafilesRedux.deleteAnalysis(payload))
      return payload
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listAnalysisUser = createAsyncThunk(
  'analyses/listAnalysisUser',
  async (_, { rejectWithValue }) => {
    try {
      return await analysesApi.listAnalysisUser()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

const initialState = {
  problems: [],
  problem: null,
  solutions: [],
  solution: null,
  analysisTypes: [],
  analysisType: null,
  analysis: null,
  analysisUsers: [],
  selected: {},
  options: {},
  data: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  status: 'INIT',
  error: null,
}

const analysesSlice = createSlice({
  name: 'analyses',
  initialState,
  reducers: {
    setAnalysisParameter: (state, { payload, type }) => {
      state.selected = { ...state.selected, ...payload }
      state.status = type
    },
    setAnalysisOption: (state, { payload, type }) => {
      if (isObject(get(state.options, payload.name))) {
        state.options[payload.name] = {
          ...state.options[payload.name],
          ...payload.option,
        }
      } else {
        state.options[payload.name] = payload.option
      }
      state.status = type
    },
    setAnalysis: (state, { payload, type }) => {
      state.analysis = payload
      state.status = type
    },
    initAnalysisOptions: (state, { payload, type }) => {
      state.options = { ...state.options, ...payload }
      state.status = type
    },
    clearAnalysisOptions: (state, { type }) => {
      state.options = {}
      state.status = type
    },
    setAnalysisType: (state, { payload, type }) => {
      state.selected = prepareAnalysis(
        find(state.analysisTypes, {
          id: parseInt(payload, 10),
        }),
      )
      state.status = type
    },
    clearAnalysisType: (state, { type }) => {
      state.analysisType = null
      state.status = type
    },
    clearAnalysis: (state, { type }) => {
      state.data = initialState.data
      state.status = type
    },
  },
  extraReducers: builder => {
    builder.addCase(getProblemSet.pending, state => {
      state.problem = null
    })
    builder.addCase(getSolutionSet.pending, state => {
      state.solution = null
    })
    builder.addCase(getAnalysisType.pending, state => {
      state.analysisType = null
    })
    builder.addCase(getAnalysis.pending, state => {
      state.analysis = null
    })
    builder.addCase(listProblemSet.fulfilled, (state, { payload, type }) => {
      state.problems = payload
      state.status = type
    })
    builder.addCase(getProblemSet.fulfilled, (state, { payload, type }) => {
      state.problem = payload
      state.status = type
    })
    builder.addCase(listSolutionSet.fulfilled, (state, { payload, type }) => {
      state.solutions = payload
      state.status = type
    })
    builder.addCase(getSolutionSet.fulfilled, (state, { payload, type }) => {
      state.solution = payload
      state.status = type
    })
    builder.addCase(listAnalysisType.fulfilled, (state, { payload, type }) => {
      state.analysisTypes = payload
      state.status = type
    })
    builder.addCase(getAnalysisType.fulfilled, (state, { payload, type }) => {
      state.analysisType = payload
      state.status = type
    })
    builder.addCase(listAnalysis.fulfilled, (state, { payload, type }) => {
      state.data = payload
      state.status = type
    })
    builder.addCase(getAnalysis.fulfilled, (state, { payload, type }) => {
      state.analysis = payload
      state.status = type
    })
    builder.addCase(updateAnalysis.fulfilled, (state, { payload, type }) => {
      state.analysis = payload
      state.status = type
    })
    builder.addCase(deleteAnalysis.fulfilled, (state, { payload, type }) => {
      state.data.results = state.data.results.filter(
        analysis => analysis.id !== payload,
      )
      state.status = type
    })
    builder.addCase(listAnalysisUser.fulfilled, (state, { payload, type }) => {
      state.analysisUsers = payload
      state.status = type
    })
    builder.addMatcher(isPending, (state, { type }) => {
      state.error = null
      state.status = type
    })
    builder.addMatcher(isRejected, (state, { payload, type }) => {
      state.error = payload.message
      state.status = type
    })
  },
})

export const {
  setAnalysisParameter,
  setAnalysisOption,
  setAnalysis,
  initAnalysisOptions,
  clearAnalysisOptions,
  setAnalysisType,
  clearAnalysisType,
  clearAnalysis,
} = analysesSlice.actions

export const { reducer } = analysesSlice
