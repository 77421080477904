import { Alert, Card } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { AnalysisResultSummary, Loader } from 'components'
import { ANALYSIS_RESULTS, SOCKET_PATH } from 'config/base'
import { PageLayout } from 'containers/Layouts'
import { renderErrors } from 'utils/analyses'

const AnalysisResultPage = () => {
  const { analysisId } = useParams()

  const [output, setOutput] = useState(null)
  const [status, setStatus] = useState(null)
  const [dataFile, setDataFile] = useState(null)

  const webSocket = useRef(null)

  const handleMessageReceived = message => {
    const parsedData = JSON.parse(message.data)
    setDataFile(parsedData.data_file)
    setOutput(parsedData.output)
    setStatus(parsedData.status)
  }

  useEffect(() => {
    const url = `${SOCKET_PATH}/ws/analysis/${analysisId}/`

    webSocket.current = new WebSocket(url)
    webSocket.current.onmessage = handleMessageReceived

    return () => {
      webSocket.current.close()
    }
  }, [analysisId])

  const content = useMemo(() => {
    if (status === ANALYSIS_RESULTS.Complete) {
      return (
        <Card>
          <AnalysisResultSummary
            id={parseInt(analysisId, 10)}
            data={output}
            dataFile={dataFile}
          />
        </Card>
      )
    }

    if (
      [
        ANALYSIS_RESULTS.ReadyToRun,
        ANALYSIS_RESULTS.Pending,
        ANALYSIS_RESULTS.Running,
      ].includes(status)
    ) {
      return <Alert message={output} type="info" showIcon banner />
    }

    if (status === ANALYSIS_RESULTS.Error) {
      return renderErrors(output)
    }

    return <Loader />
  }, [analysisId, dataFile, output, status])

  return <PageLayout heading="Analysis Results Page">{content}</PageLayout>
}

export default AnalysisResultPage
