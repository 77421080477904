import { Col, Collapse, Form, Input, InputNumber, Radio, Row } from 'antd'
import { capitalize, get, kebabCase, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { MiscFileSelect, Select } from 'components'
import {
  BACK_RECON,
  ICA_ALGORITHMS,
  ICA_TYPES,
  PCA_TYPES,
  PREPROC_TYPES,
  SCALE_TYPE,
  WHICH_ANALYSIS,
} from 'config/base'
import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('GICA')

const GroupICAOptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: {
        ...get(analysisOptions, optionName),
        [parameterName]: value,
      },
    })
  }

  const handleSetComponentNetworkNames = evt => {
    handleSetOption(
      'NS_comp_network_names',
      'value',
      JSON.parse(evt.target.value),
    )
  }

  const renderRadioGroup = (fieldLabel, fieldName, options) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <Radio.Group
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        style={{ width: '100%' }}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        <Row>
          {map(options, value => (
            <Col md={6} key={kebabCase(value)}>
              <Radio value={value}>{capitalize(value)}</Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </Form.Item>
  )

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const { algorithm, TS_regressors_of_interest, mask, NS_comp_network_names } =
    analysisOptions

  const networkNames = useMemo(
    () =>
      typeof NS_comp_network_names?.value === 'string'
        ? NS_comp_network_names?.value
        : JSON.stringify(NS_comp_network_names?.value),
    [NS_comp_network_names],
  )

  return (
    <Row data-testid="gica-option-editor">
      <Col span={24}>
        {renderRadioGroup('Analysis Type', 'which_analysis', WHICH_ANALYSIS)}
        {renderRadioGroup('ICA Type', 'group_ica_type', ICA_TYPES)}
        {renderRadioGroup('Group ICA Algorithm', 'algorithm', ICA_ALGORITHMS)}

        {!(
          algorithm?.value === 'GIG-ICA' ||
          algorithm?.value === 'Constrained ICA (Spatial)'
        ) &&
          renderInputNumber('Number of Components', 'numComponents', {
            min: 20,
            max: 100,
            precision: 0,
          })}

        {renderRadioGroup('Group PCA Type', 'group_pca_type', PCA_TYPES)}
        {renderRadioGroup(
          'Back-Reconstruction Type',
          'backReconType',
          BACK_RECON,
        )}
        {renderRadioGroup('Preprocessing Type', 'preproc_type', PREPROC_TYPES)}
        {renderInputNumber(
          'Number of Reduction Steps [1, 2]',
          'numReductionSteps',
          { min: 1, max: 2, precision: 0 },
        )}
        {renderInputNumber('TR', 'TR', { min: 0, step: 0.01 })}
        {renderInputNumber('Dummy Scans', 'dummy_scans', {
          min: 0,
          max: 10,
          precision: 0,
        })}
        {renderInputNumber('numWorkers', 'numWorkers', {
          min: 1,
          max: 2,
          precision: 0,
        })}
        {renderRadioGroup('Scale Type', 'scaleType', SCALE_TYPE)}

        {(algorithm?.value === 'GIG-ICA' ||
          algorithm?.value === 'Constrained ICA (Spatial)') && (
          <Form.Item
            {...formLayout}
            label="Select a template for Spatially Constrained ICA"
          >
            <Select
              disabled={readOnly}
              defaultValue={get(
                analysisOptions,
                'ica_template.value',
                'NeuroMark',
              )}
              style={{ width: '50%' }}
              options={[{ label: 'NeuroMark_fMRI_1.0', value: 'NeuroMark' }, { label: 'Neuromark_fMRI_2.1_modelorder-multi', value: 'NeuroMark_21' }, { label: 'Neuromark_fMRI_WM_97_final', value: 'NeuroMark_97' }]}
              onChange={value =>
                handleSetOption('ica_template', 'value', value)
              }
            />
          </Form.Item>
        )}
        <Form.Item {...formLayout} label="Select a Mask Type">
          <Select
            disabled={readOnly}
            defaultValue={get(analysisOptions, 'mask.value', 'default')}
            style={{ width: '25%' }}
            options={[
              { label: 'Default Mask', value: 'default' },
              { label: 'Average Mask', value: 'average' },
              { label: 'Custom Mask (Misc File)', value: 'misc' },
            ]}
            onChange={value => handleSetOption('mask', 'value', value)}
          />
          {mask?.value === 'misc' && (
            <div style={{ marginTop: 10 }}>
              <MiscFileSelect
                disabled={readOnly}
                initialValue={get(mask, 'file.id')}
                onChange={misc => handleSetOption('mask', 'file', misc)}
              />
            </div>
          )}
        </Form.Item>

        <Collapse>
          <Collapse.Panel header="Connectogram plot options">
            <Form.Item {...formLayout} label="Component network names">
              <Input
                placeholder="Component network names"
                disabled={readOnly}
                defaultValue={networkNames}
                onBlur={handleSetComponentNetworkNames}
              />
            </Form.Item>
            {renderInputNumber('Connectivity threshold', 'NS_conn_threshold', {
              step: 0.1,
            })}
            {renderInputNumber('Threshold on spatial maps', 'NS_threshold', {
              precision: 0,
            })}
          </Collapse.Panel>

          <Collapse.Panel header="Temporal sort options">
            <Form.Item
              {...formLayout}
              label="Design matrix SPM mat file exists"
              tooltip="Expects to find the design matrix SPM.mat under first subject/stats folder. Assumes same design matrix for all subjects"
            >
              <Radio.Group
                disabled={readOnly}
                value={get(analysisOptions, [
                  'TS_SPM_mat_file_exists',
                  'value',
                ])}
                onChange={evt =>
                  handleSetOption(
                    'TS_SPM_mat_file_exists',
                    'value',
                    evt.target.value,
                  )
                }
              >
                <Radio value={false}>False</Radio>
                <Radio value={true}>True</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              {...formLayout}
              label="Regressors of interest"
              tooltip="Enter the regressors of interest from the design matrix SPM.mat file"
            >
              <Input
                disabled={readOnly}
                placeholder="targets*bf(1),novels*bf(2)"
                defaultValue={TS_regressors_of_interest?.value}
                onChange={evt =>
                  handleSetOption(
                    'TS_regressors_of_interest',
                    'value',
                    evt.target.value,
                  )
                }
              />
            </Form.Item>
          </Collapse.Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

GroupICAOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

GroupICAOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default GroupICAOptionEditor
