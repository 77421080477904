import { createSlice } from '@reduxjs/toolkit'

import { SIDEBAR_PINNED } from 'config/base'
import { getItem, setItem } from 'utils/storage'

const initialState = {
  isSidebarPinned: getItem(SIDEBAR_PINNED) === 'true',
  status: 'INIT',
  error: null,
}

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleSidebarPin: (state, { type }) => {
      setItem(SIDEBAR_PINNED, !state.isSidebarPinned)
      state.isSidebarPinned = !state.isSidebarPinned
      state.status = type
    },
  },
})

export const { toggleSidebarPin } = globalSlice.actions

export const { reducer } = globalSlice
