import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { notification } from 'antd'
import { find, get } from 'lodash'

import { authApi } from 'apis'
import { parseError } from 'utils/error-parser'
import { clearAuthData, getAuthData, setAuthData } from 'utils/storage'

import { selectLoggedInUser } from './selectors'

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async (_, { rejectWithValue }) => {
    try {
      const data = await authApi.getProfile()
      const auth = getAuthData()
      auth.user = data
      setAuthData(auth)
      return data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listNotification = createAsyncThunk(
  'auth/listNotification',
  async (_, { rejectWithValue }) => {
    try {
      return await authApi.listNotification()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteNotification = createAsyncThunk(
  'auth/deleteNotification',
  async (payload, { rejectWithValue }) => {
    try {
      await authApi.deleteNotification(payload)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getMySite = createAsyncThunk(
  'auth/getMySite',
  async (_, { rejectWithValue }) => {
    try {
      const data = await authApi.getMySite()
      return data === '' ? null : data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createMySite = createAsyncThunk(
  'auth/createMySite',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = await authApi.createMySite(payload)
      dispatch(getProfile())
      return data
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteMySite = createAsyncThunk(
  'auth/deleteMySite',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await authApi.deleteMySite()
      dispatch(getProfile())
      return
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const leaveMySite = createAsyncThunk(
  'auth/leaveMySite',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await authApi.leaveMySite()
      dispatch(getProfile())
      return
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const removeMemberMySite = createAsyncThunk(
  'auth/removeMemberMySite',
  async (payload, { getState, rejectWithValue }) => {
    const loggedInUser = selectLoggedInUser(getState())
    try {
      await authApi.removeMemberMySite(payload)
      return {
        ...payload,
        isSiteOwner: loggedInUser.site === payload.siteId,
      }
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const sendInviteMySite = createAsyncThunk(
  'auth/sendInviteMySite',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await authApi.sendInviteMySite(payload)
      return {
        siteId: payload.siteId,
        invite: data,
      }
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteInviteMySite = createAsyncThunk(
  'auth/deleteInviteMySite',
  async (payload, { rejectWithValue }) => {
    try {
      await authApi.deleteInviteMySite(payload)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listUser = createAsyncThunk(
  'auth/listUser',
  async (payload, { rejectWithValue }) => {
    try {
      return await authApi.listUser(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getVersion = createAsyncThunk(
  'auth/getVersion',
  async (_, { rejectWithValue }) => {
    try {
      return await authApi.getVersion()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

const initialState = {
  user: get(getAuthData(), 'user', null),
  users: [],
  notifications: [],
  site: null,
  status: 'INIT',
  version: null,
  error: null,
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload
    },
    logOut: (state, { type }) => {
      clearAuthData()
      state.user = null
      state.status = type
    },
  },
  extraReducers: builder => {
    builder.addCase(getProfile.fulfilled, (state, { payload, type }) => {
      state.user = payload
      state.status = type
    })
    builder.addCase(listNotification.fulfilled, (state, { payload, type }) => {
      state.notifications = payload
      state.status = type
    })
    builder.addCase(
      deleteNotification.fulfilled,
      (state, { payload, type }) => {
        state.notifications = state.notifications.filter(
          elem => elem.id !== payload,
        )
        state.status = type
      },
    )
    builder.addCase(getMySite.fulfilled, (state, { payload, type }) => {
      state.site = payload
      state.status = type
    })
    builder.addCase(createMySite.fulfilled, (state, { payload, type }) => {
      state.site = payload
      state.status = type
    })
    builder.addCase(deleteMySite.fulfilled, (state, { type }) => {
      state.site = null
      state.status = type
    })
    builder.addCase(leaveMySite.fulfilled, (state, { type }) => {
      state.site = null
      state.status = type
    })
    builder.addCase(
      removeMemberMySite.fulfilled,
      (state, { payload, type }) => {
        if (payload.isSiteOwner) {
          state.site = null
        } else {
          state.site.members = state.site.members.filter(
            member => member.id !== payload.membershipId,
          )
        }
        state.status = type
      },
    )
    builder.addCase(sendInviteMySite.fulfilled, (state, { payload, type }) => {
      state.site.invites = find(state.site.invites, { id: payload.invite.id })
        ? state.site.invites.map(invite =>
            invite.id === payload.invite.id
              ? { ...invite, ...payload.invite }
              : invite,
          )
        : [payload.invite, ...state.site.invites]
      state.status = type
    })
    builder.addCase(
      deleteInviteMySite.fulfilled,
      (state, { payload, type }) => {
        state.site.invites = state.site.invites.filter(
          invite => invite.id !== payload.inviteId,
        )
        state.notifications = state.notifications.filter(
          elem => elem.site !== payload.siteId,
        )
        state.status = type
      },
    )
    builder.addCase(listUser.fulfilled, (state, { payload, type }) => {
      state.users = payload
      state.status = type
    })
    builder.addCase(getVersion.fulfilled, (state, { payload, type }) => {
      state.version = payload
      state.status = type
    })
    builder.addMatcher(isPending, (state, { type }) => {
      state.error = null
      state.status = type
    })
    builder.addMatcher(isRejected, (state, { payload, type }) => {
      state.error = payload.message
      state.status = type
    })
  },
})

export const { setUser, logOut } = authSlice.actions

export const { reducer } = authSlice
