import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const SmoothingForm = ({ analysisOptions, readOnly, setOption }) => {
  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const renderCheckbox = (fieldLabel, fieldName) => (
    <Form.Item label={fieldLabel} {...formLayout}>
      <Checkbox
        checked={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        onChange={event => setOption(fieldName, 'value', event.target.checked)}
      />
    </Form.Item>
  )

  return (
    <Row>
      <Col span={12}>
        {renderInputNumber('Smoothing FWHM(mm) along x', 'smoothing_x_mm', {
          min: 0,
          max: 10,
        })}
        {renderInputNumber('Smoothing FWHM(mm) along y', 'smoothing_y_mm', {
          min: 0,
          max: 10,
        })}
        {renderInputNumber('Smoothing FWHM(mm) along z', 'smoothing_z_mm', {
          min: 0,
          max: 10,
        })}
      </Col>
      <Col span={12}>
        {renderCheckbox('Implicit Masking', 'smoothing_implicit_masking')}
      </Col>
    </Row>
  )
}

SmoothingForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

SmoothingForm.defaultProps = {
  readOnly: false,
}

export default SmoothingForm
