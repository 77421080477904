import { Col, Form, Input, InputNumber, Row } from 'antd'
import { get, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withSizes } from 'react-sizes'

import { Select } from 'components'
import { BREAKPOINTS, SPM_GLM_BASES, SPM_GLM_TIMING_UNITS } from 'config/base'
import { listDataFile } from 'store/modules/datafiles'
import { getParameterLayouts } from 'utils/common'

import { validators } from './validators'

const { formLayout } = getParameterLayouts()

export const SPMGLMOptionEditor = ({
  analysisOptions,
  isDesktop,
  readOnly,
  setAnalysisOption,
}) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listDataFile())
  }, [dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderBasesGroup = () => {
    const bases = analysisOptions.Bases

    return (
      <Row>
        <Col lg={12} xs={24}>
          <Form.Item {...formLayout} label="Bases:">
            {readOnly ? (
              <Input
                style={{ width: 300 }}
                value={analysisOptions.Bases.value}
                disabled
              />
            ) : (
              <Select
                style={{ width: isDesktop ? 300 : '100%' }}
                defaultValue={get(analysisOptions, 'Bases.value', 'hrf')}
                options={map(SPM_GLM_BASES, unit => ({
                  label: unit,
                  value: unit,
                }))}
                onChange={value => handleSetOption('Bases', 'value', value)}
              />
            )}
          </Form.Item>
        </Col>
        <Col lg={12} xs={24}>
          {bases.value === 'hrf' ? (
            <Form.Item {...formLayout} label="Bases Derivatives">
              <Select
                style={{ width: isDesktop ? 300 : '100%' }}
                value={
                  get(bases, 'params.derivs')
                    ? JSON.stringify(get(bases, 'params.derivs'))
                    : '[1,0]'
                }
                disabled={readOnly}
                options={[
                  { label: 'No Derivatives [0, 0]', value: '[0,0]' },
                  { label: 'Time Derivatives [1, 0]', value: '[1,0]' },
                  {
                    label: 'Time and Dispersion Derivatives [1, 1]',
                    value: '[1,1]',
                  },
                ]}
                onChange={value =>
                  handleSetOption('Bases', 'params', {
                    derivs: JSON.parse(value),
                  })
                }
              />
            </Form.Item>
          ) : (
            <Row>
              <Col lg={12} xs={24}>
                <Form.Item {...formLayout} label="Bases Length">
                  <InputNumber
                    value={get(bases, 'params.length') || 0}
                    min={0}
                    max={1024}
                    precision={0}
                    disabled={readOnly}
                    onChange={value =>
                      handleSetOption('Bases', 'params', {
                        length: value,
                        order: get(bases, 'params.length'),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item {...formLayout} label="Bases Order">
                  <InputNumber
                    value={get(bases, 'params.order') || 0}
                    min={0}
                    max={1024}
                    precision={0}
                    disabled={readOnly}
                    onChange={value =>
                      handleSetOption('Bases', 'params', {
                        order: value,
                        length: get(bases, 'params.order'),
                      })
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    )
  }

  return (
    <Row data-testid="spm-glm-option-editor">
      <Col span={24}>
        {renderBasesGroup()}
        <Row>
          <Col lg={12} xs={24}>
            <Form.Item
              {...formLayout}
              label={
                <span style={{ whiteSpace: 'break-spaces', height: 'auto' }}>
                  High-pass filter cutoff in secs
                </span>
              }
              rules={validators.tr}
            >
              <InputNumber
                defaultValue={get(analysisOptions, 'TR.value', 0)}
                min={0}
                max={1024}
                precision={2}
                disabled={readOnly}
                onChange={value => handleSetOption('TR', 'value', value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={24}>
            <Form.Item {...formLayout} label="Timing Units:">
              <Select
                style={{ width: isDesktop ? 300 : '100%' }}
                defaultValue={get(
                  analysisOptions,
                  'Timing_Units.value',
                  'secs',
                )}
                disabled={readOnly}
                options={map(SPM_GLM_TIMING_UNITS, unit => ({
                  label: unit,
                  value: unit,
                }))}
                onChange={value =>
                  handleSetOption('Timing_Units', 'value', value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col lg={12} xs={24}>
            <Form.Item
              {...formLayout}
              label={
                <span style={{ whiteSpace: 'break-spaces', height: 'auto' }}>
                  High-pass filter cutoff in secs
                </span>
              }
              rules={validators.hpf_cutoff}
            >
              <InputNumber
                defaultValue={get(analysisOptions, 'HPF_Cutoff.value', 0)}
                min={0}
                max={1024}
                precision={2}
                disabled={readOnly}
                onChange={value =>
                  handleSetOption('HPF_Cutoff', 'value', value)
                }
              />
            </Form.Item>
            <Form.Item {...formLayout} label="Estimation Method">
              <Select
                style={{ width: isDesktop ? 300 : '100%' }}
                defaultValue={get(
                  analysisOptions,
                  'Estimation_Method.value',
                  'Classical',
                )}
                disabled={readOnly}
                options={[
                  { label: 'Classical', value: 'Classical' },
                  { label: 'Bayesian', value: 'Bayesian' },
                  { label: '2nd Bayesian', value: 'Bayesian2' },
                ]}
                onChange={value =>
                  handleSetOption('Estimation_Method', 'value', value)
                }
              />
            </Form.Item>
            <Form.Item {...formLayout} label="Use FWE Correction">
              <Select
                style={{ width: isDesktop ? 300 : '100%' }}
                defaultValue={get(
                  analysisOptions,
                  'Use_FWE_Correction.value',
                  true,
                ).toString()}
                disabled={readOnly}
                options={[
                  { label: 'True', value: 'true' },
                  { label: 'False', value: 'false' },
                ]}
                onChange={value =>
                  handleSetOption('Use_FWE_Correction', 'value', value)
                }
              />
            </Form.Item>
            <Form.Item
              {...formLayout}
              label="Height Threshold"
              rules={validators.height_threshold}
            >
              <InputNumber
                defaultValue={get(analysisOptions, 'Height_Threshold.value', 0)}
                min={0}
                max={1}
                placeholder={0.001}
                precision={3}
                disabled={readOnly}
                onChange={value =>
                  handleSetOption('Height_Threshold', 'value', value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

SPMGLMOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  isDesktop: PropTypes.bool,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

SPMGLMOptionEditor.defaultProps = {
  setAnalysisOption: noop,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.MD,
})

export default withSizes(sizes)(SPMGLMOptionEditor)
