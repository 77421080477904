import { Card, Col, Row } from 'antd'
import { get } from 'lodash'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

import { Loader } from 'components'
import { ANALYSIS_DISMISS_UPLOAD, ANALYSIS_LARGE_UPLOAD } from 'config/base'
import { AnalysisPredictionSection, UploadSection } from 'containers'
import { PageLayout } from 'containers/Layouts'
import {
  clearAnalysisOptions,
  getAnalysis,
  getAnalysisType,
  selectAnalysis,
  selectAnalysisType,
  setAnalysis,
} from 'store/modules/analyses'

export const AnalysisStartPage = () => {
  const analysis = useSelector(selectAnalysis)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  const params = useParams()
  const location = useLocation()

  const { analysisId } = queryString.parse(location?.search)

  useEffect(() => {
    dispatch(getAnalysisType(params.analysisType))
  }, [params.analysisType, dispatch])

  useEffect(() => {
    if (analysisId) {
      dispatch(getAnalysis(analysisId))
    } else {
      dispatch(setAnalysis(null))
      dispatch(clearAnalysisOptions())
    }
  }, [analysisId, dispatch])

  const isUploadFocusAnalysis = ANALYSIS_LARGE_UPLOAD.includes(
    get(analysisType, 'label'),
  )
  const isUploadRequired = !ANALYSIS_DISMISS_UPLOAD.includes(
    get(analysisType, 'label'),
  )

  const isAnalysisLoaded = Boolean(!analysisId || analysis)

  if (!analysisType) {
    return <Loader />
  }

  return (
    <PageLayout
      heading={`${analysisType.label} | Create Analysis`}
      subheading={analysisType.description}
    >
      <Row gutter={20}>
        <Col xs={24} xl={isUploadFocusAnalysis ? 14 : 12}>
          {isUploadRequired && (
            <Card className="mt-1">
              {isAnalysisLoaded ? <UploadSection /> : <Loader />}
            </Card>
          )}
        </Col>
        <Col xs={24} xl={isUploadFocusAnalysis ? 10 : 12}>
          <Card className="mt-1">
            {isAnalysisLoaded && <AnalysisPredictionSection />}
          </Card>
        </Col>
      </Row>
    </PageLayout>
  )
}

export default AnalysisStartPage
