import { Col, Form, Input, Radio, Row } from 'antd'
import { capitalize, kebabCase, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { FORMULA_TYPES } from 'config/base'
import { getParameterLayouts } from 'utils/common'
import { generateFormula } from 'utils/formulas'

const { formLayout } = getParameterLayouts(true)

const RegressionOptionEditor = ({
  analysisOptions,
  file,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const handleFormulaTypeChange = evt => {
    handleSetOption('formula', 'value', evt.target.value)
  }

  const renderFormulas = () => (
    <Form.Item {...formLayout} label="Formulas:">
      <Radio.Group
        onChange={handleFormulaTypeChange}
        value={analysisOptions.formula.value}
        disabled={readOnly}
        style={{ width: '100%' }}
      >
        <Row>
          {map(FORMULA_TYPES, value => (
            <Col md={6} key={kebabCase(value)}>
              <Radio value={value}>{capitalize(value)}</Radio>
            </Col>
          ))}
        </Row>
      </Radio.Group>
    </Form.Item>
  )

  const formulaValue = useMemo(() => {
    const { formula, formulaEdit } = analysisOptions

    if (formula.value === 'custom') {
      return formulaEdit.value
    }

    return generateFormula(formula.value, formulaEdit.value, file.fields)
  }, [analysisOptions, file])

  return (
    <Row data-testid="regression-option-editor">
      <Col span={24}>
        <Form.Item
          {...formLayout}
          style={{ marginBottom: 20 }}
          label="Formula:"
        >
          <Input
            style={{ width: 300 }}
            type="text"
            value={formulaValue}
            disabled={readOnly || analysisOptions.formula.value !== 'custom'}
            onChange={evt =>
              handleSetOption('formulaEdit', 'value', evt.target.value)
            }
          />
        </Form.Item>
        {renderFormulas()}
      </Col>
    </Row>
  )
}

RegressionOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  file: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

RegressionOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default RegressionOptionEditor
