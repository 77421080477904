import { Button, Card, Col, Divider, Row, Tabs } from 'antd'
import classNames from 'classnames'
import { find, get, kebabCase, map } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { Loader } from 'components'
import { PROBLEM_ORDER } from 'config/base'
import { PageLayout } from 'containers/Layouts'
import {
  listProblemSet,
  selectAnalysesStatus,
  selectProblems,
} from 'store/modules/analyses'

const AnalysisRunPage = () => {
  const problems = useSelector(selectProblems)
  const status = useSelector(selectAnalysesStatus)

  const dispatch = useDispatch()

  const [activeSolution, setActiveSolution] = useState(null)
  const [activeTab, setActiveTab] = useState(null)

  const sortedProblems = useMemo(() => {
    if (problems.length === 0) {
      return []
    }

    return PROBLEM_ORDER.reduce((acc, name) => {
      acc.push(find(problems, { name }))
      return acc
    }, []).filter(Boolean)
  }, [problems])

  const analysisTypes = useMemo(() => {
    const problem = find(sortedProblems, { name: activeTab })
    const solution = find(get(problem, 'solution_sets'), {
      name: activeSolution,
    })

    return get(solution, 'analysis_types') || []
  }, [sortedProblems, activeTab, activeSolution])

  useEffect(() => {
    if (sortedProblems.length === 0) {
      return
    }
    setActiveTab(sortedProblems[0].name)
    setActiveSolution(sortedProblems[0].solution_sets[0].name)
  }, [sortedProblems])

  useEffect(() => {
    dispatch(listProblemSet())
  }, [dispatch])

  const handleTabChange = newActiveTab => {
    const problem = find(sortedProblems, { name: newActiveTab })
    setActiveTab(newActiveTab)
    setActiveSolution(problem.solution_sets[0].name)
  }

  const handleActiveSolutionChange = newActiveSolution => {
    setActiveSolution(newActiveSolution)
  }

  const renderContent = () => {
    const loading = status === listProblemSet.pending().type

    if (loading) {
      return <Loader />
    }

    if (sortedProblems.length === 0) {
      return <div className="app-page__subheading">No Data</div>
    }

    return (
      <Row gutter={20}>
        <Col span={24}>
          <div className="analysis-run">
            <Tabs
              animated={{ inkBar: false, tabPane: false }}
              type="card"
              activeKey={activeTab}
              items={map(
                sortedProblems,
                ({ name, description, solution_sets }) => ({
                  key: name,
                  label: `${name} (${solution_sets.length})`,
                  children: (
                    <div className="analysis-run__content">
                      <Row>
                        <Col lg={6}>
                          <div className="analysis-computation">
                            <div className="analysis-computation__title">
                              Computations
                            </div>
                            <div className="analysis-computation__subtitle">
                              Select Method from Options Below
                            </div>

                            <div className="analysis-solution-links">
                              {solution_sets.map(solution_set => (
                                <div
                                  key={solution_set.id}
                                  className={classNames(
                                    'analysis-solution-link',
                                    {
                                      'analysis-solution-link--active':
                                        solution_set.name === activeSolution,
                                    },
                                  )}
                                  onClick={() =>
                                    handleActiveSolutionChange(
                                      solution_set.name,
                                    )
                                  }
                                >
                                  {solution_set.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col lg={18}>
                          <div className="analysis-detail">
                            <Row gutter={22}>
                              <Col md={12}>
                                <div className="analysis-detail__name">
                                  {name}
                                </div>
                                <div className="analysis-detail__description">
                                  {description}
                                </div>
                              </Col>
                              <Col md={12}>
                                <div className="analysis-detail__image">
                                  <img
                                    src={`/analysis-images/${kebabCase(
                                      name,
                                    )}.png`}
                                    alt={name}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Divider
                              style={{
                                backgroundColor: '#ccc',
                                margin: '32px 0',
                              }}
                            />

                            <div className="analysis-solution">
                              <div className="analysis-solution__title">
                                {activeSolution}
                              </div>

                              <Row gutter={12}>
                                {analysisTypes.map(analysisType => (
                                  <Col md={8} key={analysisType.id}>
                                    <Card title={analysisType.name}>
                                      {analysisType.description}
                                      <Link
                                        to={`/analysis-start/${analysisType.id}`}
                                      >
                                        <Button
                                          type="primary"
                                          className="analysis-solution__button"
                                        >
                                          Create Analysis
                                        </Button>
                                      </Link>
                                    </Card>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ),
                }),
              )}
              onChange={handleTabChange}
            />
          </div>
        </Col>
      </Row>
    )
  }

  return <PageLayout heading="Run an Analysis">{renderContent()}</PageLayout>
}

export default AnalysisRunPage
