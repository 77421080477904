import { Card, Descriptions, Tag } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { sitesApi } from 'apis'
import { Loader } from 'components'
import { PageLayout } from 'containers/Layouts'

const { Item } = Descriptions

export const TagDetailPage = () => {
  const { tagId } = useParams()

  const [tag, setTag] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getTag = useCallback(async () => {
    setIsLoading(true)

    try {
      const data = await sitesApi.getTag(tagId)
      setTag(data)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }, [tagId])

  useEffect(() => {
    getTag()
  }, [getTag])

  const renderElements = (elemName, elems) => {
    if (elems.length === 0) {
      return `No ${elemName}`
    }

    return elems.map(elem => (
      <Tag key={elem.id} className="my-02" data-testid={`${elemName}-tag`}>
        {elem.name}
      </Tag>
    ))
  }

  if (isLoading) {
    return <Loader />
  }

  if (!tag) {
    return null
  }

  const { label, color, studies, subjects, sessions } = tag

  return (
    <PageLayout heading={label}>
      <Card>
        <div className="w-75">
          <Descriptions bordered column={1} size="small">
            <Item label="Label">
              <Tag data-testid="tag-label" color={color}>
                {label}
              </Tag>
            </Item>
            <Item label="Studies">{renderElements('study', studies)}</Item>
            <Item label="Subjects">{renderElements('subject', subjects)}</Item>
            <Item label="Sessions">{renderElements('session', sessions)}</Item>
          </Descriptions>
        </div>
      </Card>
    </PageLayout>
  )
}

export default TagDetailPage
