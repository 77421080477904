import { AppstoreOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Card, Empty, Modal, Table, Tag, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { CheckIcon, Drawer, SiteAddForm } from 'components'
import { BREAKPOINTS, TAG_COLORS } from 'config/base'
import { PageLayout } from 'containers/Layouts'
import {
  createSite,
  deleteSite,
  listSite,
  selectSites,
  selectSitesStatus,
} from 'store/modules/sites'

export const SiteListPage = ({ isDesktop, isMobile }) => {
  const sites = useSelector(selectSites)
  const status = useSelector(selectSitesStatus)

  const dispatch = useDispatch()

  const [showAddDrawer, setShowAddDrawer] = useState(false)

  useEffect(() => {
    dispatch(listSite())
  }, [dispatch])

  useEffect(() => {
    if (status === createSite.fulfilled().type) {
      setShowAddDrawer(false)
    }
  }, [status])

  const columns = [
    isDesktop && {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
      key: 'full_name',
      render: (text, record) => <Link to={`/site/${record.id}`}>{text}</Link>,
    },
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Members',
      dataIndex: 'members',
      key: 'members',
      width: 300,
      render: (_, record) => (
        <div>
          {record.members.map(({ id, username }, ind) => (
            <Tag key={id} color={TAG_COLORS[ind]} className="my-02">
              {username}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: 'Invites',
      dataIndex: 'invites',
      key: 'invites',
      render: (_, record) => record.invites.length || 'No invites',
    },
    {
      title: 'Managed',
      dataIndex: 'managed',
      key: 'managed',
      render: (_, record) => <CheckIcon checked={record.is_managed} />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        if (record.is_managed) {
          return null
        }

        return (
          <Tooltip title="Delete">
            <Button
              shape="circle"
              danger
              icon={<DeleteOutlined />}
              size="small"
              data-testid="site-delete-button"
              onClick={() => handleShowDeleteSiteConfirm(record)}
            />
          </Tooltip>
        )
      },
    },
  ].filter(Boolean)

  const loading = status === listSite.pending().type

  const toggleShowDrawer = () => {
    if (loading) {
      return
    }

    setShowAddDrawer(prevState => !prevState)
  }

  const handleShowDeleteSiteConfirm = record => {
    Modal.confirm({
      title: `Are you sure you want to delete the site "${record.full_name}"?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteSite(record.id))
      },
    })
  }

  return (
    <PageLayout heading="Sites">
      <Card>
        <div className="text-right mb-1">
          <Button
            icon={<AppstoreOutlined />}
            type="primary"
            data-testid="site-add-button"
            onClick={toggleShowDrawer}
          >
            Add Site
          </Button>
        </div>
        {sites.length > 0 ? (
          <Table
            dataSource={sites}
            columns={columns}
            size="small"
            bordered
            rowKey="id"
            pagination={false}
            scroll={{ x: isMobile }}
          />
        ) : (
          <Empty description="No Sites" />
        )}

        <Drawer
          title="Create Site"
          open={showAddDrawer}
          onClose={toggleShowDrawer}
        >
          <SiteAddForm
            onSubmit={values => dispatch(createSite(values))}
            onCancel={toggleShowDrawer}
            submitting={loading}
          />
        </Drawer>
      </Card>
    </PageLayout>
  )
}

SiteListPage.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
  isMobile: width < BREAKPOINTS.SM,
})

export default withSizes(sizes)(SiteListPage)
