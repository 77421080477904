import { Tabs } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import SummaryTable from './SummaryTable'

const PolyssifierResult = ({ data, dataFile, token }) => {
  const renderTable = summary => {
    if (!summary) {
      return null
    }

    return (
      <div>
        {map(summary, (item, ind) => (
          <div key={ind}>
            <SummaryTable content={item} />
          </div>
        ))}
      </div>
    )
  }

  const { confusions, figures, save_path, test_summary, train_summary } = data

  return (
    <div className="analysis-result" data-testid="polyssifier-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'test-results',
            label: 'Test Results',
            children: <>{renderTable(test_summary)}</>,
          },
          {
            key: 'train-results',
            label: 'Train Results',
            children: <>{renderTable(train_summary)}</>,
          },
          {
            key: 'confusion-matrix',
            label: 'Confusion Matrix',
            children: (
              <>
                {map(confusions, (item, ind) => (
                  <div key={ind} style={{ marginTop: '1rem' }}>
                    <div className="analysis-result__subheading">{ind}</div>
                    <SummaryTable content={item} />
                  </div>
                ))}
              </>
            ),
          },
          {
            key: 'figures',
            label: 'Figures',
            children: (
              <>
                {map(figures, (item, ind) => (
                  <div key={ind} style={{ marginTop: '2.5rem' }}>
                    <img
                      id={ind}
                      alt={ind}
                      src={encodePathURL(save_path, item, token)}
                      data-testid="figure"
                    />
                  </div>
                ))}
              </>
            ),
          },
        ]}
      />
    </div>
  )
}

PolyssifierResult.propTypes = {
  data: PropTypes.shape({
    confusions: PropTypes.any,
    figures: PropTypes.array,
    save_path: PropTypes.string,
    test_summary: PropTypes.any,
    train_summary: PropTypes.any,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default PolyssifierResult
