import { DeleteOutlined, MailOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table } from 'antd'
import { compact } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import { Drawer, SiteInviteForm } from 'components'
import * as authRedux from 'store/modules/auth'
import * as sitesRedux from 'store/modules/sites'

const SiteInvites = ({ myRole, site, status, deleteInvite, sendInvite }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    if (
      [
        sitesRedux.sendInvite.fulfilled().type,
        authRedux.sendInviteMySite.fulfilled().type,
      ].includes(status)
    ) {
      setShowDrawer(false)
    }
  }, [status])

  const loading = useMemo(
    () =>
      [
        sitesRedux.sendInvite.pending().type,
        sitesRedux.deleteInvite.pending().type,
        authRedux.sendInviteMySite.pending().type,
        authRedux.deleteInviteMySite.pending().type,
      ].includes(status),
    [status],
  )

  const canEdit = ['Admin', 'SuperAdmin'].includes(myRole)

  const columns = compact([
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Sent Date',
      dataIndex: 'sent_date',
      key: 'sent_date',
      render: (_, record) =>
        moment(record.sent_date).format('YYYY-MM-DD HH:mm:ss'),
    },
    canEdit && {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="d-flex">
          <Button
            className="mr-05"
            icon={<MailOutlined />}
            shape="circle"
            size="small"
            data-testid="site-invite-resend-button"
            onClick={() => handleResend(record)}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            shape="circle"
            size="small"
            data-testid="site-invite-cancel-button"
            onClick={() => handleCancel(record)}
          />
        </div>
      ),
    },
  ])

  const toggleShowDrawer = () => setShowDrawer(prevState => !prevState)

  const handleSubmit = data => {
    sendInvite({ siteId: site.id, data })
  }

  const handleResend = invite => {
    handleSubmit({ email: invite.email })
  }

  const handleCancel = invite => {
    Modal.confirm({
      title: 'Are you sure want to cancel this invite?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteInvite({ siteId: site.id, inviteId: invite.id })
      },
    })
  }

  return (
    <div data-testid="site-invites">
      <h2 className="text-center mb-2">Invites</h2>
      {canEdit && (
        <div className="text-right mb-1">
          <Button
            icon={<MailOutlined />}
            type="primary"
            disabled={loading}
            data-testid="site-invite-send-button"
            onClick={toggleShowDrawer}
          >
            Invite
          </Button>
        </div>
      )}
      {site.invites.length > 0 ? (
        <Table
          dataSource={site.invites}
          columns={columns}
          size="small"
          bordered
          rowKey="id"
          pagination={false}
          loading={loading}
        />
      ) : (
        <Empty description="No Invites" />
      )}
      <Drawer title="Send Invite" open={showDrawer} onClose={toggleShowDrawer}>
        <SiteInviteForm
          submitting={loading}
          onSubmit={handleSubmit}
          onCancel={toggleShowDrawer}
        />
      </Drawer>
    </div>
  )
}

SiteInvites.propTypes = {
  myRole: PropTypes.string,
  site: PropTypes.object,
  status: PropTypes.string,
  deleteInvite: PropTypes.func,
  sendInvite: PropTypes.func,
}

export default SiteInvites
