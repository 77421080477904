import { Card } from 'antd'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { PageLayout } from 'containers/Layouts'
import { selectDataFilesStatus, uploadFiles } from 'store/modules/datafiles'

import DefaultFileUpload from './DefaultFileUpload'

const DataFilePage = () => {
  const dataFilesStatus = useSelector(selectDataFilesStatus)

  const navigate = useNavigate()

  useEffect(() => {
    if (dataFilesStatus === uploadFiles.fulfilled().type) {
      navigate(-1)
    }
  }, [dataFilesStatus, navigate])

  return (
    <PageLayout heading="Upload Data File">
      <Card>
        <DefaultFileUpload dataFilesStatus={dataFilesStatus} />
      </Card>
    </PageLayout>
  )
}

export default DataFilePage
