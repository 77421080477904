import { DeleteOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Empty, Input, Modal, Table } from 'antd'
import { find, get } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { MenuButton } from 'components'
import { ParameterSetForm } from 'components/Forms'
import { BREAKPOINTS } from 'config/base'
import { selectAnalysisTypes } from 'store/modules/analyses'
import {
  createParameterSet,
  deleteParameterSet,
  selectAnalysisLocation,
  selectDataFilesStatus,
  selectParameterSets,
  setAnalysisLocation,
} from 'store/modules/datafiles'
import { getAnalysisLabel } from 'utils/analyses'
import { stringContains } from 'utils/common'

const ParameterSetTable = ({ shared, title, user, isDesktop, isMobile }) => {
  const analysisLocation = useSelector(selectAnalysisLocation)
  const analysisTypes = useSelector(selectAnalysisTypes)
  const parameterSets = useSelector(selectParameterSets)
  const status = useSelector(selectDataFilesStatus)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [parameterSet, setParameterSet] = useState(null)

  const searchInput = useRef(null)

  useEffect(() => {
    if (
      [
        createParameterSet.fulfilled().type,
        deleteParameterSet.fulfilled().type,
      ].includes(status)
    ) {
      setParameterSet(null)
    }
  }, [status, dispatch])

  useEffect(() => {
    if (status === createParameterSet.fulfilled().type && analysisLocation) {
      navigate(analysisLocation)
      dispatch(setAnalysisLocation())
    }
  }, [status, analysisLocation, dispatch, navigate])

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder="Search name"
          value={selectedKeys[0]}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={confirm}
        />
        <Button
          type="primary"
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
          onClick={confirm}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters()
            confirm()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => stringContains(record.name, value),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select())
      }
    },
  })

  const handleDelete = useCallback(
    record => {
      Modal.confirm({
        title: 'Are you sure want to delete this parameter set?',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          dispatch(deleteParameterSet(record.id))
        },
      })
    },
    [dispatch],
  )

  const columns = useMemo(
    () =>
      [
        isDesktop && {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
          ...getColumnSearchProps(),
          render: (_, record) => (
            <Link to={`/parameter-set/${record.id}`}>{record.name}</Link>
          ),
        },
        {
          title: 'Analysis Type',
          dataIndex: 'analysis_type',
          key: 'analysis_type',
          filters: analysisTypes.map(analysisType => ({
            text: analysisType.label,
            value: analysisType.id,
          })),
          onFilter: (value, record) => record.analysis_type === value,
          render: (_, { analysis_type }) =>
            get(find(analysisTypes, { id: analysis_type }), 'label'),
        },
        {
          title: 'Shared Users',
          dataIndex: 'shared_users',
          key: 'shared_users',
          render: (_, record) =>
            record.shared_users.length || 'No shared users',
        },
        {
          title: 'Version',
          dataIndex: 'version',
          key: 'version',
        },
        !shared && {
          title: 'Action',
          render: (_, record) => (
            <Button
              shape="circle"
              icon={<DeleteOutlined />}
              size="small"
              danger
              onClick={() => handleDelete(record)}
            />
          ),
        },
      ].filter(Boolean),
    [analysisTypes, isDesktop, shared, handleDelete],
  )

  const handleAnalysisSelect = ({ key }) => {
    const analysisType = find(analysisTypes, { label: key })
    const { id, options } = analysisType

    setParameterSet({
      id: null,
      analysis_type: id,
      options,
      is_custom: true,
      version: null,
    })
  }

  const loading =
    [
      createParameterSet.pending().type,
      deleteParameterSet.pending().type,
    ].indexOf(status) !== -1

  return (
    <div>
      {title && <h2 className="app-page__subheading">{title}</h2>}

      {!shared && (
        <div className="text-right mb-1">
          <MenuButton
            items={analysisTypes}
            buttonName="Add Parameter Set"
            onClick={handleAnalysisSelect}
          />
        </div>
      )}

      {parameterSet && (
        <Card className="mb-2">
          <h2 className="text-center mb-2">
            {getAnalysisLabel(analysisTypes, parameterSet)} Parameter Set
          </h2>
          <ParameterSetForm
            parameterSet={parameterSet}
            analysisTypes={analysisTypes}
            user={user}
            submitting={loading}
            onCancel={() => setParameterSet(null)}
          />
        </Card>
      )}

      {parameterSets.length > 0 ? (
        <Table
          dataSource={parameterSets}
          columns={columns}
          bordered
          size="small"
          pagination={false}
          createStructuredSelector
          rowKey="id"
          loading={loading}
          scroll={{ x: isMobile }}
        />
      ) : (
        <Empty description="No Parameter sets" />
      )}
    </div>
  )
}

ParameterSetTable.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  shared: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.object,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.MD,
  isMobile: width < BREAKPOINTS.SM,
})

export default withSizes(sizes)(ParameterSetTable)
