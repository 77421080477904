import axios from 'axios'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import 'polyfills/localStorage'
import 'styles/core.scss'

import ScrollToTop from 'components/ScrollToTop'
import { API_BASE_URL } from 'config/base'
import { store } from 'store'
import { getAuthData } from 'utils/storage'

import MainApp from './MainApp'
import reportWebVitals from './reportWebVitals'

/* Set up axios request interceptor for adding authorization header */
axios.interceptors.request.use(config => {
  const authData = getAuthData()

  const newConfig = config || {}

  if (authData) {
    newConfig.headers.Authorization = `Bearer ${authData.token}`
  }

  return newConfig
})

axios.defaults.baseURL = API_BASE_URL

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <ScrollToTop />
      <MainApp />
    </BrowserRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
