import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, Row, Upload } from 'antd'
import { get, noop } from 'lodash'
import { PropTypes } from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Drawer, Select, StudyForm } from 'components'
import { selectLoggedInUser } from 'store/modules/auth'
import {
  selectDataFilesStatus,
  updateMiscFile,
  uploadMiscFiles,
} from 'store/modules/datafiles'
import {
  createStudy,
  listSite,
  listStudy,
  selectSites,
  selectSitesStatus,
  selectStudies,
} from 'store/modules/sites'
import { validators } from 'validators'

const normalizeFile = e => e?.fileList

export const MiscFileForm = ({ record, onSubmit }) => {
  const [form] = Form.useForm()

  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const sites = useSelector(selectSites)
  const sitesStatus = useSelector(selectSitesStatus)
  const studies = useSelector(selectStudies)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [showStudyDrawer, setShowStudyDrawer] = useState(false)

  const isLoading = [
    uploadMiscFiles.pending().type,
    updateMiscFile.pending().type,
  ].includes(dataFilesStatus)

  const initialValues = useMemo(() => {
    if (!record) {
      return null
    }

    return {
      study: get(record, 'study.id'),
      description: record.description,
      upload: [{ uid: '-1', name: record.file }],
    }
  }, [record])

  useEffect(() => {
    dispatch(listStudy())
    dispatch(listSite())
  }, [dispatch])

  useEffect(() => {
    if (dataFilesStatus === uploadMiscFiles.fulfilled().type) {
      form.resetFields(['study', 'description', 'upload'])
      onSubmit()
    }
  }, [dataFilesStatus, form, onSubmit])

  const toggeStudyDrawer = () => setShowStudyDrawer(prevState => !prevState)

  const handleSubmit = values => {
    const { study, description, upload } = values
    const formData = new FormData()

    formData.append('study', study)
    formData.append('description', description || '')

    upload.forEach(file => {
      if (file.originFileObj) {
        formData.append('file', file.originFileObj)
      }
    })

    if (Boolean(record)) {
      dispatch(updateMiscFile({ id: record.id, data: formData }))
    } else {
      dispatch(uploadMiscFiles(formData))
    }
  }

  const handeCreateStudy = ({ data }) => dispatch(createStudy(data))

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      disabled={isLoading}
      data-testid="misc-file-form"
      onFinish={handleSubmit}
    >
      <Form.Item label="Study" required>
        <Row>
          <Col span={21}>
            <Form.Item
              name="study"
              required
              noStyle
              rules={validators.genericRequired}
            >
              <Select
                options={studies.map(({ label, id }) => ({
                  label,
                  value: id,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={3}>
            <div className="d-flex align-items-center justify-content-end h-100">
              <Button
                type="primary"
                shape="circle"
                icon={<PlusOutlined />}
                size="small"
                data-testid="study-create-button"
                onClick={toggeStudyDrawer}
              />
            </div>
          </Col>
        </Row>
      </Form.Item>

      <Form.Item name="description" label="Description" required>
        <Input placeholder="Description" />
      </Form.Item>

      <Form.Item
        name="upload"
        label="File"
        valuePropName="fileList"
        getValueFromEvent={normalizeFile}
        required
        rules={validators.genericRequired}
      >
        <Upload beforeUpload={() => false}>
          <Button className="w-100" icon={<UploadOutlined />}>
            File
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item>
        <Row>
          <Col md={12} offset={12}>
            <Button className="w-100" type="primary" htmlType="submit">
              {record ? 'Update' : 'Upload'}
            </Button>
          </Col>
        </Row>
      </Form.Item>

      <Drawer
        title="Create study"
        open={showStudyDrawer}
        onClose={toggeStudyDrawer}
      >
        <StudyForm
          sites={sites}
          user={user}
          submitting={sitesStatus === createStudy.pending().type}
          onSubmit={handeCreateStudy}
          onCancel={toggeStudyDrawer}
        />
      </Drawer>
    </Form>
  )
}

MiscFileForm.propTypes = {
  record: PropTypes.object,
  onSubmit: PropTypes.func,
}

MiscFileForm.defaultProps = {
  onSubmit: noop,
}

export default MiscFileForm
