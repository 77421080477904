import {
  isArray,
  isEmpty,
  isString,
  lowerCase,
  pickBy,
  some,
  toString,
} from 'lodash'

export const toBase64Image = string => `data:image/jpeg;base64, ${string}`

export const getFilename = fullPath => fullPath.replace(/^.*[\\\/]/, '') //eslint-disable-line

export const getEditableSites = (sites, user) => {
  let res

  if (user.is_superuser) {
    res = sites
  } else if (user.site) {
    res = sites.filter(site => site.id === user.site)
  } else {
    res = []
  }

  return res
}

export const isSharedData = (shared_users, userId) =>
  some(shared_users, { id: userId })

export const getFullname = user => `${user.first_name} ${user.last_name}`

export const getParameterFormLayouts = (parameter, fullWidth) => {
  switch (parameter) {
    case 'GICA':
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 17 },
        },
      }
    case 'ASL':
      return {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
        labelAlign: 'left',
      }
    case 'VBM':
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 9 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 15 },
        },
      }
    case 'fMRI':
      return {
        labelCol: {
          xs: { span: 24 },
          md: { span: 12 },
        },
        wrapperCol: {
          xs: { span: 24 },
          md: { span: 12 },
        },
      }
    case 'fMRI_32ch':
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: fullWidth ? 9 : 13 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: fullWidth ? 15 : 11 },
        },
      }
    default:
      return {
        labelCol: {
          xs: { span: 24 },
          sm: { span: fullWidth ? 9 : 12 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: fullWidth ? 15 : 12 },
        },
      }
  }
}

export const getParameterLayouts = fullWidth => ({
  formLayout: {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: fullWidth ? 4 : 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
      md: { span: fullWidth ? 20 : 16 },
    },
  },
  gridLayout: fullWidth ? { xs: 24 } : { xs: 24, lg: { span: 12, offset: 6 } },
})

export const arrayMove = (arr, fromIndex, toIndex) => {
  const element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
  return arr
}

export const filterParamsUrl = paramsSearch =>
  // Pick non-empty array and string and non-null values.
  pickBy(paramsSearch, value =>
    isArray(value) || isString(value) ? !isEmpty(value) : value,
  )

export const sortBy = (arr, key) => {
  const result = [...arr]

  result.sort((first, second) => {
    const a = first[key]
    const b = second[key]

    const minLen = Math.min(a.length, b.length)

    for (let i = 0; i < minLen; i += 1) {
      const lowerA = String(a[i]).toLowerCase()
      const lowerB = String(b[i]).toLowerCase()

      if (lowerA === lowerB) {
        if (a[i] >= 'a' && a[i] <= 'z' && b[i] >= 'A' && b[i] <= 'Z') {
          return -1
        }

        if (a[i] >= 'A' && a[i] <= 'Z' && b[i] >= 'a' && b[i] <= 'z') {
          return 1
        }
      } else {
        return lowerA > lowerB ? 1 : -1
      }
    }

    return a.length > b.length ? 1 : -1
  })

  return result
}

export const stringContains = (string, word) =>
  lowerCase(toString(string)).includes(lowerCase(word))

export const sleep = ms =>
  new Promise(resolve => {
    setTimeout(resolve, ms)
  })
