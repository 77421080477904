import { Alert, Button, Checkbox, Col, Form, Input, Row } from 'antd'
import update from 'immutability-helper'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  ASLOptionEditor,
  BrainvizOptionEditor,
  DFNCOptionEditor,
  DTIOptionEditor,
  FMRI32OptionEditor,
  FMRIPhantomQAOptionEditor,
  FunctionalMRIPreprocOptionEditor,
  GroupICAOptionEditor,
  GroupSPMGLMOptionEditor,
  Loader,
  MancovaOptionEditor,
  OptionEditor,
  PolyssifierOptionEditor,
  RegressionOptionEditor,
  SPMGLMOptionEditor,
  StructuralMRIOptionEditor,
  WMHOptionEditor,
} from 'components'
import { createParameterSet, updateParameterSet } from 'store/modules/datafiles'
import { getAnalysisLabel } from 'utils/analyses'
import { getParameterLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { formLayout, gridLayout } = getParameterLayouts()

export const ParameterSetForm = ({
  analysisTypes,
  formModal,
  parameterSet,
  readOnly,
  submitting,
  user,
  onCancel,
}) => {
  const dispatch = useDispatch()

  const [filteredParameterSet, setFilteredParameterSet] = useState(parameterSet)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)
  }, [filteredParameterSet])

  const handleCancel = () => {
    setFilteredParameterSet(filteredParameterSet)
    onCancel()
  }

  const handleVersionChange = evt => {
    setFilteredParameterSet({
      ...filteredParameterSet,
      version: evt.target.value,
    })
  }

  const handleCustomChange = evt => {
    setFilteredParameterSet({
      ...filteredParameterSet,
      is_custom: evt.target.checked,
    })
  }

  const handleUpdateParameterSet = payload => {
    const { name, value } = payload

    setFilteredParameterSet({ ...filteredParameterSet, [name]: value })
  }

  const handleUpdateAnalysisOption = payload => {
    const { name, option, options } = payload

    if (options) {
      setFilteredParameterSet({ ...filteredParameterSet, options })
    } else {
      setFilteredParameterSet(
        update(filteredParameterSet, {
          options: {
            [name]: {
              [get(filteredParameterSet, ['options', name]) === undefined
                ? '$set'
                : '$merge']: option,
            },
          },
        }),
      )
    }
  }

  const handleParameterSetSubmit = () => {
    const validationResult = validateAnalysis()
    setError(validationResult)

    if (validationResult) {
      return
    }

    const {
      id,
      options,
      version,
      is_custom,
      analysis_type,
      name,
      description,
    } = filteredParameterSet

    const data = {
      name,
      description,
      options,
      version,
      is_custom,
      analysis_type,
    }

    if (!id) {
      dispatch(createParameterSet(data))
      return
    }

    dispatch(updateParameterSet({ id, data }))
  }

  const validateAnalysis = () => {
    const { options, name, version } = filteredParameterSet

    const label = getAnalysisLabel(analysisTypes, filteredParameterSet)

    if (!name) {
      return 'Please input parameter name'
    }

    if (version !== 0 && !version) {
      return 'Please input parameter version'
    }

    if (label === 'Polyssifier' && options.include.value.length === 0) {
      return 'Please select at least one classifier to run'
    }

    if (
      label === 'GICA' &&
      get(options, 'TS_SPM_mat_file_exists.value') &&
      !get(options, 'TS_regressors_of_interest.value')
    ) {
      return 'Regressors of interest is required if temporal sorting is enabled'
    }
  }

  const renderSubEditor = () => {
    const label = getAnalysisLabel(analysisTypes, filteredParameterSet)

    const editorProps = {
      file: {},
      analysisOptions: filteredParameterSet.options,
      setAnalysisOption: handleUpdateAnalysisOption,
      readOnly,
    }

    switch (label) {
      case 'ASL':
        return <ASLOptionEditor {...editorProps} />
      case 'DTI':
        return <DTIOptionEditor {...editorProps} />
      case 'Regression':
        return <RegressionOptionEditor {...editorProps} />
      case 'Polyssifier':
        return <PolyssifierOptionEditor {...editorProps} />
      case 'GICA':
        return <GroupICAOptionEditor {...editorProps} />
      case 'dFNC':
        return <DFNCOptionEditor {...editorProps} />
      case 'BViZ':
        return <BrainvizOptionEditor {...editorProps} />
      case 'VBM':
        return <StructuralMRIOptionEditor {...editorProps} />
      case 'fMRI':
        return <FunctionalMRIPreprocOptionEditor {...editorProps} />
      case 'fMRI_32ch':
        return <FMRI32OptionEditor {...editorProps} />
      case 'fMRI_PhantomQA':
        return <FMRIPhantomQAOptionEditor {...editorProps} />
      case 'SPMGLM':
        return <SPMGLMOptionEditor {...editorProps} />
      case 'GroupSPMGLM':
        return <GroupSPMGLMOptionEditor {...editorProps} />
      case 'MANCOVA':
      case 'mancova':
        return <MancovaOptionEditor {...editorProps} />
      case 'WMH':
        return <WMHOptionEditor {...editorProps} />
      default:
        return null
    }
  }

  const { version, is_custom } = filteredParameterSet

  return (
    <div data-testid="parameter-set-form">
      {submitting && (
        <div className="pos-full transparent-back">
          <Loader />
        </div>
      )}
      <OptionEditor
        parameterSet={filteredParameterSet}
        readOnly={readOnly}
        setAnalysisOption={handleUpdateAnalysisOption}
        setParameterSet={handleUpdateParameterSet}
      />
      <Row>
        <Col {...gridLayout}>
          <FormItem label="Version" {...formLayout}>
            <Input
              value={version}
              disabled={readOnly}
              onChange={handleVersionChange}
            />
          </FormItem>
          {user.is_superuser && (
            <FormItem label="Custom" {...formLayout}>
              <Checkbox
                checked={is_custom}
                disabled={readOnly}
                onChange={handleCustomChange}
              />
            </FormItem>
          )}
        </Col>
      </Row>
      {renderSubEditor()}
      {error && (
        <Alert type="warning" className="mt-1" message={error} banner />
      )}
      {!readOnly && (
        <div
          className="mt-1"
          style={{ textAlign: formModal ? 'right' : 'left' }}
        >
          <Button
            type="primary mr-05"
            disabled={submitting}
            onClick={handleParameterSetSubmit}
          >
            Submit
          </Button>
          <Button disabled={submitting} onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}

ParameterSetForm.propTypes = {
  analyses: PropTypes.array,
  analysisTypes: PropTypes.array,
  formModal: PropTypes.bool,
  parameterSet: PropTypes.shape({
    id: PropTypes.number,
    label: PropTypes.string,
    options: PropTypes.object,
    version: PropTypes.any,
    analyses: PropTypes.object,
    is_custom: PropTypes.bool,
    analysis_type: PropTypes.number,
  }),
  readOnly: PropTypes.bool,
  submitting: PropTypes.bool,
  user: PropTypes.object,
  onCancel: PropTypes.func,
}

ParameterSetForm.defaultProps = {
  formModal: false,
  readOnly: false,
}

export default ParameterSetForm
