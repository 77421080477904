import { Alert, Button } from 'antd'
import PropTypes from 'prop-types'

const EmailVerifyAlert = ({ sending, user, onSendEmail }) => {
  const { email, first_name, last_name } = user

  return (
    <Alert
      message={`Please verify your email - ${email}`}
      style={{ marginBottom: 30 }}
      description={
        <div>
          Hi,{' '}
          <b>
            {first_name} {last_name}
          </b>
          <br />
          Your email is not verified yet. Please use below button to get
          activation email.
          <br />
          <Button
            style={{ marginTop: 10 }}
            disabled={sending}
            loading={sending}
            onClick={onSendEmail}
          >
            {sending ? 'Sending...' : 'Send Email'}
          </Button>
        </div>
      }
      type="warning"
      showIcon
      banner
      data-testid="email-verify-alert"
    />
  )
}

EmailVerifyAlert.propTypes = {
  sending: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  onSendEmail: PropTypes.func.isRequired,
}

export default EmailVerifyAlert
