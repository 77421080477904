import { CloseCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { Link } from 'react-router-dom'

const Page404 = () => (
  <div className="app-page">
    <div className="app-page__subheading">
      <CloseCircleOutlined style={{ color: 'red', fontSize: '4rem' }} />
      <h2 className="mt-2" style={{ fontSize: '2rem' }}>
        404
      </h2>
      <h3>Sorry, the page you visited does not exist.</h3>
      <div className="mt-2">
        <Link to="/study">
          <Button type="primary">Back</Button>
        </Link>
      </div>
    </div>
  </div>
)

export default Page404
