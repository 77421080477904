import { Collapse, Tabs } from 'antd'
import { isArray, keys } from 'lodash'
import PropTypes from 'prop-types'

const { Panel } = Collapse

const ProvenanceView = ({
  cpuinfo,
  docker,
  mac_addr,
  meminfo,
  os_packages,
  platform,
  python_packages,
  python,
}) => {
  const getReadableMem = (num, precision = 2) => {
    const mbNum = num / (1024 * 1024)
    const gbNum = mbNum / 1024

    return gbNum > 0
      ? `${gbNum.toFixed(precision)} GB`
      : `${mbNum.toFixed(precision)} MB`
  }

  const cpuInfo = isArray(cpuinfo.flags)
    ? { ...cpuinfo, flags: cpuinfo.flags.join(', ') }
    : cpuinfo

  return (
    <Tabs
      data-testid="provenance-view"
      defaultActiveKey="overview"
      items={[
        {
          key: 'overview',
          label: 'Overview',
          children: (
            <ul data-testid="overview">
              <li>{cpuInfo.brand}</li>
              <li>RAM {getReadableMem(meminfo.total, 0)}</li>
              <li>{platform.platform}</li>
              <li>
                Python <i>{cpuInfo.python_version}</i>
              </li>
            </ul>
          ),
        },
        {
          key: 'cpuinfo',
          label: 'CPU',
          children: (
            <ul data-testid="cpuinfo">
              {keys(cpuInfo).map(key => (
                <li key={key}>
                  {key}: <i>{cpuInfo[key]}</i>
                </li>
              ))}
            </ul>
          ),
        },
        {
          key: 'meminfo',
          label: 'Memory',
          children: (
            <ul data-testid="meminfo">
              {keys(meminfo).map(key => (
                <li key={key}>
                  {key}:{' '}
                  <i>
                    {key === 'percent'
                      ? `${meminfo[key]}%`
                      : getReadableMem(meminfo[key])}
                  </i>
                </li>
              ))}
            </ul>
          ),
        },
        {
          key: 'platform',
          label: 'OS Platform',
          children: (
            <ul data-testid="platform">
              {keys(platform).map(key => (
                <li key={key}>
                  {key}: <i>{platform[key]}</i>
                </li>
              ))}
              <li key={mac_addr}>
                MAC Address: <i>{mac_addr}</i>
              </li>
            </ul>
          ),
        },
        {
          key: 'python',
          label: 'Python',
          children: (
            <ul data-testid="python">
              {keys(python).map(key => (
                <li key={key}>
                  {key}: <i>{python[key]}</i>
                </li>
              ))}
            </ul>
          ),
        },
        {
          key: 'docker',
          label: 'Docker',
          children: (
            <Collapse>
              <Panel header="Configuration">
                <ul>
                  {keys(docker).map(key => (
                    <li key={key}>
                      {key}: <i>{docker[key]}</i>
                    </li>
                  ))}
                </ul>
              </Panel>
              <Panel header="Python Packages">
                <ul>
                  {keys(python_packages).map(key => (
                    <li key={key}>
                      {key}: <i>{python_packages[key]}</i>
                    </li>
                  ))}
                </ul>
              </Panel>
              <Panel header="OS Packages">
                <ul>
                  {keys(os_packages).map(key => (
                    <li key={key}>
                      {key}: <i>{os_packages[key]}</i>
                    </li>
                  ))}
                </ul>
              </Panel>
            </Collapse>
          ),
        },
      ]}
    />
  )
}

ProvenanceView.propTypes = {
  cpuinfo: PropTypes.object,
  docker: PropTypes.object,
  mac_addr: PropTypes.string,
  meminfo: PropTypes.object,
  os_packages: PropTypes.object,
  platform: PropTypes.object,
  python_packages: PropTypes.object,
  python: PropTypes.object,
}

export default ProvenanceView
