import { Button, Table } from 'antd'
import { filter, forEach, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'

import {
  TRANSFORMATION_TYPES,
  VARIABLE_EFFECTS,
  VARIABLE_ROLES,
  VARIABLE_TYPES,
} from 'config/base'

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Select Response',
    dataIndex: 'selectResponse',
    key: 'selectResponse',
  },
  {
    title: 'Transformation',
    dataIndex: 'transformation',
    key: 'transformation',
  },
  {
    title: 'Variable Type',
    dataIndex: 'variableType',
    key: 'variableType',
  },
  {
    title: 'Effect Type',
    dataIndex: 'effectType',
    key: 'effectType',
  },
]

const VariableConfigurationTable = ({
  className,
  file,
  readOnly,
  toggleAllCurrentFilesField,
  updateCurrentFilesField,
}) => {
  const handleVariableTypeChange = useCallback(
    (type, index, value) => {
      const VARIABLE_CONSTANTS = {
        variable_role: VARIABLE_ROLES,
        transformation: TRANSFORMATION_TYPES,
        variable_type: VARIABLE_TYPES,
        effect: VARIABLE_EFFECTS,
      }

      updateCurrentFilesField({
        index,
        field: { [type]: VARIABLE_CONSTANTS[type][value].next },
      })
    },
    [updateCurrentFilesField],
  )

  const data = useMemo(() => {
    const roleNames = { x: 'Feature', y: 'Response' }
    const res = []

    forEach(
      file.fields,
      ({
        index,
        name,
        variable_role,
        transformation,
        variable_type,
        effect,
      }) => {
        res.push({
          key: index,
          name,
          selectResponse: (
            <Button
              size="small"
              data-testid="variable-role-button"
              onClick={() => {
                handleVariableTypeChange('variable_role', index, variable_role)
              }}
            >
              {roleNames[VARIABLE_ROLES[variable_role].label]}
            </Button>
          ),
          transformation: (
            <Button
              size="small"
              data-testid="transformation-button"
              onClick={() => {
                handleVariableTypeChange(
                  'transformation',
                  index,
                  transformation,
                )
              }}
            >
              {TRANSFORMATION_TYPES[transformation].label}
            </Button>
          ),
          variableType: (
            <Button
              size="small"
              data-testid="variable-type-button"
              onClick={() => {
                handleVariableTypeChange('variable_type', index, variable_type)
              }}
            >
              {VARIABLE_TYPES[variable_type].label}
            </Button>
          ),
          effectType: (
            <Button
              size="small"
              data-testid="effect-button"
              onClick={() => {
                handleVariableTypeChange('effect', index, effect)
              }}
            >
              {VARIABLE_EFFECTS[effect].label}
            </Button>
          ),
        })
      },
    )

    return res
  }, [file.fields, handleVariableTypeChange])

  const handleSelect = useCallback(
    (record, selected) => {
      updateCurrentFilesField({ index: record.key, field: { selected } })
    },
    [updateCurrentFilesField],
  )

  const tableProps = useMemo(() => {
    const selectedFields = filter(file.fields, 'selected')
    const selectedRowKeys = map(selectedFields, 'index')

    return Object.assign(
      {},
      {
        dataSource: data,
        columns: COLUMNS,
        size: 'small',
        pagination: false,
        bordered: true,
        className,
      },
      !readOnly && {
        title: () =>
          `Specify variable types for ${file.name} (click to toggle)`,
        rowSelection: {
          selectedRowKeys,
          onSelectAll: toggleAllCurrentFilesField,
          onSelect: handleSelect,
        },
      },
    )
  }, [
    className,
    data,
    readOnly,
    file,
    handleSelect,
    toggleAllCurrentFilesField,
  ])

  return data.length > 0 ? <Table {...tableProps} /> : null
}

VariableConfigurationTable.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  readOnly: PropTypes.bool,
  toggleAllCurrentFilesField: PropTypes.func,
  updateCurrentFilesField: PropTypes.func,
}

VariableConfigurationTable.defaultProps = {
  className: 'mb-2',
  readOnly: false,
  toggleAllCurrentFilesField: noop,
  updateCurrentFilesField: noop,
}

export default VariableConfigurationTable
