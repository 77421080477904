import { Col, Form, Row, Switch } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const DistortionForm = ({ analysisOptions, readOnly, setOption }) => (
  <Row>
    <Col span={12}>
      <Form.Item {...formLayout} label="Enable Distortion Correction">
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          disabled={readOnly}
          checked={get(analysisOptions, 'dist_corr.value') === 'On'}
          onChange={value =>
            setOption('dist_corr', 'value', value ? 'On' : 'Off')
          }
        />
      </Form.Item>
    </Col>
  </Row>
)

DistortionForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

DistortionForm.defaultProps = {
  readOnly: false,
}

export default DistortionForm
