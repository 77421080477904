import { Tree } from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import FileTreeBuilder from 'utils/file-tree-builder'

const { DirectoryTree } = Tree

const OutputFileTree = ({ files }) => {
  if (isEmpty(files)) {
    return null
  }

  const builder = new FileTreeBuilder()
  const treeData = builder.run(files)

  return (
    <DirectoryTree
      multiple
      treeData={treeData}
      data-testid="output-file-tree"
    />
  )
}

OutputFileTree.propTypes = {
  files: PropTypes.array,
}

export default OutputFileTree
