import { Col, Form, InputNumber, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const ReorientForm = ({ analysisOptions, readOnly, setOption }) => {
  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  return (
    <Row>
      <Col span={12}>
        {renderInputNumber('x translation in mm', 'reorient_params_x_mm', {
          min: -50,
          max: 50,
        })}
        {renderInputNumber('y translation in mm', 'reorient_params_y_mm', {
          min: -50,
          max: 50,
        })}
        {renderInputNumber('z translation in mm', 'reorient_params_z_mm', {
          min: -50,
          max: 50,
        })}
      </Col>
      <Col span={12}>
        {renderInputNumber(
          'rotation about x axis (pitch) in degrees (-360,360)',
          'reorient_params_pitch',
          { min: -360, max: 360 },
        )}
        {renderInputNumber(
          'rotation about y axis (roll) in degrees (-360,360)',
          'reorient_params_roll',
          { min: -360, max: 360 },
        )}
        {renderInputNumber(
          'rotation about z axis (yaw) in degrees (-360,360)',
          'reorient_params_yaw',
          { min: -360, max: 360 },
        )}
      </Col>
    </Row>
  )
}

ReorientForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

ReorientForm.defaultProps = {
  readOnly: false,
}

export default ReorientForm
