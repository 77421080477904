import { Card } from 'antd'

import { PageLayout } from 'containers/Layouts'

import AnalysisTypeTable from './AnalysisTypeTable'

const AnalysisTypes = () => (
  <PageLayout heading="Analysis Types">
    <Card>
      <AnalysisTypeTable />
    </Card>
  </PageLayout>
)

export default AnalysisTypes
