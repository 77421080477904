import axios from 'axios'

export const mappingsApi = {
  listProtocol: params =>
    axios.get('/protocol/', { params }).then(res => res.data),

  listProtocolMapping: config =>
    axios.get('/protocol-mapping/', config).then(res => res.data),

  createProtocolMapping: data =>
    axios.post('/protocol-mapping/', data).then(res => res.data),

  updateProtocolMapping: ({ id, data }) =>
    axios.patch(`/protocol-mapping/${id}/`, data).then(res => res.data),

  deleteProtocolMapping: protocolMappingId =>
    axios
      .delete(`/protocol-mapping/${protocolMappingId}/`)
      .then(res => res.data),

  listModality: () => axios.get('/modality/').then(res => res.data),
}
