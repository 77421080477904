import { Card, notification } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { authApi } from 'apis'
import { EmailVerifyAlert } from 'components'
import { TaskSection } from 'containers'
import { PageLayout } from 'containers/Layouts'
import { selectLoggedInUser } from 'store/modules/auth'

export const StatusPage = () => {
  const user = useSelector(selectLoggedInUser)

  const [isLoading, setIsLoading] = useState(false)

  const handleSendVerifyEmail = async () => {
    setIsLoading(true)

    try {
      await authApi.sendVerifyEmail()
      notification.success({
        message: 'Check your inbox now',
        description: 'Successfully sent the verification email.',
      })
    } catch {
      notification.error({
        message: 'Failed!',
        description: 'Failed to send the verification email.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const { email_verified } = user

  return (
    <PageLayout heading="Analysis Status">
      {!email_verified && (
        <EmailVerifyAlert
          user={user}
          sending={isLoading}
          onSendEmail={handleSendVerifyEmail}
        />
      )}
      <Card>
        <TaskSection />
      </Card>
    </PageLayout>
  )
}

export default StatusPage
