import { Button, Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

import { validators } from 'validators'

const InviteForm = ({ submitting, onCancel, onSubmit }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  }

  return (
    <Form {...formItemLayout} disabled={submitting} onFinish={onSubmit}>
      <Row>
        <Col span={24}>
          <Form.Item name="email" label="Email" rules={validators.email}>
            <Input placeholder="Email" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Send
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

InviteForm.propTypes = {
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default InviteForm
