import { Button, Input, Tooltip, TreeSelect } from 'antd'
import { find, get, isEmpty } from 'lodash'
import { PropTypes } from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader } from 'components'
import useSort from 'hooks/useSort'
import { selectAnalysisType } from 'store/modules/analyses'
import {
  getMiscFile,
  listMiscFile,
  selectAllMiscFiles,
  selectDataFilesStatus,
  selectMiscFile,
} from 'store/modules/datafiles'

import MiscFileDrawer from './MiscFileDrawer'

export const MiscFileSelect = ({
  disabled,
  initialValue,
  multiple,
  sortingInfo,
  onChange,
}) => {
  const allMiscFiles = useSelector(selectAllMiscFiles)
  const analysisType = useSelector(selectAnalysisType)
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const miscFile = useSelector(selectMiscFile)

  const dispatch = useDispatch()

  const [treeData, setTreeData] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(null)
  const [loading, setLoading] = useState(isEmpty(allMiscFiles))

  const {
    icon,
    tooltip,
    sortedData: sortedTreeData,
    buttonType,
    onChangeSort,
  } = useSort({ data: treeData, sortingInfo })

  useEffect(() => {
    dispatch(listMiscFile())
  }, [dispatch])

  useEffect(() => {
    if (initialValue) {
      dispatch(getMiscFile(initialValue))
      setSelectedKeys(`file;${initialValue}`)
    }
  }, [dispatch, initialValue])

  const createDataFileNodes = useCallback(() => {
    const newTreeData = [...treeData]

    if (!isEmpty(allMiscFiles)) {
      allMiscFiles.forEach(miscItem => {
        // Create node Study.
        const parentNode = createNode(
          newTreeData,
          'study',
          miscItem.study,
          null,
        )

        // Create node misc file.
        createNode(newTreeData, 'file', miscItem, parentNode.id)
      })
    }

    setTreeData(newTreeData)
  }, [allMiscFiles]) // eslint-disable-line

  useEffect(() => {
    createDataFileNodes()
  }, [createDataFileNodes])

  useEffect(() => {
    if (
      (miscFile && initialValue) ||
      dataFilesStatus === listMiscFile.fulfilled().type ||
      !isEmpty(allMiscFiles)
    ) {
      setLoading(false)
    }
  }, [dataFilesStatus, allMiscFiles, initialValue, miscFile])

  const createNode = (tData, fieldToLoad, elem, parentId) => {
    const elemId = `${fieldToLoad};${elem.id}`
    const existedNode = find(tData, { id: elemId })

    if (existedNode) return existedNode

    const isLeaf = fieldToLoad === 'file'
    const node = {
      id: elemId,
      title: elem.full_name || elem.file,
      pId: parentId,
      value: elem.value || elemId,
      field: fieldToLoad,
      isLeaf,
      selectable: isLeaf,
      elem,
    }

    tData.push(node)
    return node
  }

  const handleChange = value => {
    const selectedFile = find(treeData, { id: value })

    onChange(get(selectedFile, 'elem'))
    setSelectedKeys(value)
  }

  if (loading) {
    return <Loader />
  }

  const hasQuickDisplay = initialValue && miscFile && isEmpty(allMiscFiles)

  return (
    <>
      {hasQuickDisplay ? (
        <Input disabled value={miscFile?.file} />
      ) : (
        <div className="d-flex align-items-center column-gap-8">
          <TreeSelect
            treeDataSimpleMode
            value={selectedKeys}
            treeCheckable={multiple}
            disabled={disabled}
            placeholder="Please select"
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={sortedTreeData}
            onChange={handleChange}
          />
          {sortingInfo?.enabled && (
            <Tooltip title={tooltip}>
              <Button
                type={buttonType}
                className="flex-none"
                icon={icon}
                disabled={disabled}
                onClick={onChangeSort}
              />
            </Tooltip>
          )}
          {analysisType && (
            <MiscFileDrawer disabled={disabled} analysisType={analysisType} />
          )}
        </div>
      )}
    </>
  )
}

const inputFileShape = PropTypes.shape({
  created_at: PropTypes.string,
  files: PropTypes.string,
  id: PropTypes.number,
})

MiscFileSelect.propTypes = {
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.number, inputFileShape]),
  multiple: PropTypes.bool,
  sortingInfo: PropTypes.shape({
    enabled: PropTypes.bool,
    nameKey: PropTypes.string,
    chronoKey: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

MiscFileSelect.defaultProps = {
  disabled: false,
  initialValue: null,
  multiple: false,
  sortingInfo: null,
}

export default MiscFileSelect
