import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import { get, kebabCase, noop } from 'lodash'
import PropTypes from 'prop-types'

import { MANCOVA_FEATURES } from 'config/base'
import { getParameterLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { formLayout } = getParameterLayouts(true)

const MancovaOptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderButtons = () => (
    <FormItem {...formLayout} label="Features to Test">
      <Checkbox.Group
        style={{ width: '100%' }}
        disabled={readOnly}
        defaultValue={analysisOptions.features.value}
        onChange={values => handleSetOption('features', 'value', values)}
      >
        <Row>
          {MANCOVA_FEATURES.map(mancovaFeature => (
            <Col md={6} key={kebabCase(mancovaFeature.label)}>
              <Checkbox value={mancovaFeature.value}>
                {mancovaFeature.label}
              </Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </FormItem>
  )

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <FormItem {...formLayout} label={fieldLabel}>
      <InputNumber
        id={fieldName}
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  return (
    <Row data-testid="mancova-option-editor">
      <Col span={24}>
        {renderInputNumber('Temporal Resolution', 'TR', {
          min: 1,
          max: 5,
          precision: 0,
        })}
        {renderInputNumber('P-Value Threshold', 'p_threshold', {
          min: 0,
          max: 1,
          precision: 0.001,
        })}
        {renderButtons()}
      </Col>
    </Row>
  )
}

MancovaOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

MancovaOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default MancovaOptionEditor
