import { Button, Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

import { validators } from 'validators'

const SessionForm = ({ submitting, onCancel, onSubmit }) => {
  const handleSubmit = values => {
    onSubmit({ data: values })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 10 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  }

  return (
    <Form {...formItemLayout} disabled={submitting} onFinish={handleSubmit}>
      <Row>
        <Col span={24}>
          <Form.Item
            name="segment_interval"
            label="Segment Interval"
            rules={validators.genericRequired}
          >
            <Input placeholder="Segment Interval" />
          </Form.Item>

          <Form.Item name="anonymized_scan_date" label="Anonymized Scan Date">
            <Input placeholder="Anonymized Scan Date" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

SessionForm.propTypes = {
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default SessionForm
