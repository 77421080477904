import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { notification } from 'antd'

import { mappingsApi } from 'apis'
import { parseError } from 'utils/error-parser'

export const listProtocol = createAsyncThunk(
  'mappings/listProtocol',
  async (payload, { rejectWithValue }) => {
    try {
      return await mappingsApi.listProtocol(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listProtocolMapping = createAsyncThunk(
  'mappings/listProtocolMapping',
  async (payload, { rejectWithValue }) => {
    try {
      return await mappingsApi.listProtocolMapping(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const createProtocolMapping = createAsyncThunk(
  'mappings/createProtocolMapping',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = await mappingsApi.createProtocolMapping(payload)
      dispatch(listProtocolMapping())
      return data
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const updateProtocolMapping = createAsyncThunk(
  'mappings/updateProtocolMapping',
  async (payload, { rejectWithValue }) => {
    try {
      return await mappingsApi.updateProtocolMapping(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteProtocolMapping = createAsyncThunk(
  'mappings/deleteProtocolMapping',
  async (payload, { rejectWithValue }) => {
    try {
      await mappingsApi.deleteProtocolMapping(payload)
      return payload
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listModality = createAsyncThunk(
  'mappings/listModality',
  async (payload, { rejectWithValue }) => {
    try {
      return await mappingsApi.listModality(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

const initialState = {
  protocols: [],
  protocolMappings: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  modalities: [],
  status: 'INIT',
  error: null,
}

const mappingsSlice = createSlice({
  name: 'mappings',
  initialState,
  extraReducers: builder => {
    builder.addCase(listProtocol.fulfilled, (state, { payload, type }) => {
      state.protocols = payload
      state.status = type
    })
    builder.addCase(
      listProtocolMapping.fulfilled,
      (state, { payload, type }) => {
        state.protocolMappings = payload
        state.status = type
      },
    )
    builder.addCase(createProtocolMapping.fulfilled, (state, { type }) => {
      state.status = type
    })
    builder.addCase(
      updateProtocolMapping.fulfilled,
      (state, { payload, type }) => {
        state.protocolMappings.results = state.protocolMappings.results.map(
          mapping => (mapping.id === payload.id ? payload : mapping),
        )
        state.status = type
      },
    )
    builder.addCase(
      deleteProtocolMapping.fulfilled,
      (state, { payload, type }) => {
        state.protocolMappings.results = state.protocolMappings.results.filter(
          mapping => mapping.id !== payload,
        )
        state.status = type
      },
    )
    builder.addCase(listModality.fulfilled, (state, { payload, type }) => {
      state.modalities = payload
      state.status = type
    })
    builder.addMatcher(isPending, (state, { type }) => {
      state.error = null
      state.status = type
    })
    builder.addMatcher(isRejected, (state, { payload, type }) => {
      state.error = payload.message
      state.status = type
    })
  },
})

export const { reducer } = mappingsSlice
