import { Button, Card, Empty, Modal } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Drawer,
  Loader,
  SiteAddForm,
  SiteInvites,
  SiteMembers,
  Tabs,
} from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  createMySite,
  deleteInviteMySite,
  deleteMySite,
  getMySite,
  getProfile,
  leaveMySite,
  removeMemberMySite,
  selectAuthStatus,
  selectLoggedInUser,
  selectMySite,
  sendInviteMySite,
} from 'store/modules/auth'

export const MySitePage = () => {
  const site = useSelector(selectMySite)
  const status = useSelector(selectAuthStatus)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    dispatch(getMySite())
    dispatch(getProfile())
  }, [dispatch])

  useEffect(() => {
    if (
      [deleteMySite.fulfilled().type, leaveMySite.fulfilled().type].includes(
        status,
      )
    ) {
      setShowDrawer(false)
    }
  }, [status])

  const loading = useMemo(
    () =>
      [getProfile.pending().type, getMySite.pending().type].indexOf(status) !==
      -1,
    [status],
  )

  const myRole = useMemo(() => {
    if (user.is_superuser) {
      return 'SuperAdmin'
    }

    return user.site_role
  }, [user])

  const toggleShowDrawer = () => setShowDrawer(prevState => !prevState)

  const handleCreateSite = data => {
    dispatch(createMySite(data))
  }

  const handleLeave = () => {
    Modal.confirm({
      title: 'Are you sure want to leave your site?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(leaveMySite())
      },
    })
  }

  const handleDelete = () => {
    Modal.confirm({
      title: 'Are you sure want to delete your site?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteMySite())
      },
    })
  }

  const renderContent = () => {
    if (loading) {
      return <Loader />
    }

    if (!site) {
      return (
        <div>
          <Empty description={<span>No Site</span>}>
            <Button type="primary" onClick={toggleShowDrawer}>
              Create Site
            </Button>
          </Empty>
          <Drawer
            title="Create Site"
            open={showDrawer}
            onClose={toggleShowDrawer}
          >
            <SiteAddForm
              onSubmit={handleCreateSite}
              onCancel={toggleShowDrawer}
              submitting={loading}
            />
          </Drawer>
        </div>
      )
    }

    return (
      <PageLayout
        heading={site.full_name}
        subheading={`You are the ${myRole} of this site`}
      >
        <Card>
          <div className="text-right mb-2">
            {myRole === 'Admin' && (
              <Button danger onClick={handleDelete}>
                Delete
              </Button>
            )}
            <Button className="ml-1" danger onClick={handleLeave}>
              Leave
            </Button>
          </div>
          <Tabs
            items={[
              {
                key: 'members',
                label: 'Members',
                children: (
                  <SiteMembers
                    user={user}
                    site={site}
                    myRole={myRole}
                    status={status}
                    removeMember={values =>
                      dispatch(removeMemberMySite(values))
                    }
                  />
                ),
              },
              {
                key: 'invites',
                label: 'Invites',
                children: (
                  <SiteInvites
                    site={site}
                    myRole={myRole}
                    status={status}
                    sendInvite={values => dispatch(sendInviteMySite(values))}
                    deleteInvite={values =>
                      dispatch(deleteInviteMySite(values))
                    }
                  />
                ),
              },
            ]}
          />
        </Card>
      </PageLayout>
    )
  }

  return <div className="app-page">{renderContent()}</div>
}

export default MySitePage
