import { Col, Form, Row } from 'antd'
import { filter, flatten, get } from 'lodash'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { DataFileTree } from 'components'
import { selectAnalysisOptions } from 'store/modules/analyses'
import { setAllFiles, setCurrentFiles } from 'store/modules/datafiles'

const SCAN_GROUPS = {
  'One Sample T-Test': ['Scans'],
  'Two Sample T-Test': ['Scans_Group1', 'Scans_Group2'],
}

export const ScansOptionEditor = ({
  analysisType,
  dataOrder,
  readOnly,
  handleSetOption,
}) => {
  const analysisOptions = useSelector(selectAnalysisOptions)

  const dispatch = useDispatch()

  const designType = get(analysisOptions, 'Design_Type.value')
  const scanGroups = SCAN_GROUPS[designType]

  const renderScansSelect = scanKey => {
    const handleTreeChange = files => {
      const allScans = scanGroups.map(scan =>
        scan === scanKey ? files : get(analysisOptions, [scan, 'value']),
      )
      const allFiles = filter(flatten(allScans))

      dispatch(setAllFiles(allFiles))
      dispatch(setCurrentFiles(allFiles))

      handleSetOption(scanKey, 'value', files)
    }

    return (
      <Form.Item label={scanKey}>
        <DataFileTree
          analysisType={analysisType}
          dataOrder={dataOrder}
          readOnly={readOnly}
          initialValue={get(analysisOptions, [scanKey, 'value'])}
          onChange={handleTreeChange}
          multiple
        />
      </Form.Item>
    )
  }

  switch (designType) {
    case 'One Sample T-Test':
      return (
        <Row>
          <Col md={24}>{renderScansSelect('Scans')}</Col>
        </Row>
      )
    case 'Two Sample T-Test':
      return (
        <Row gutter={16}>
          <Col md={11}>{renderScansSelect('Scans_Group1')}</Col>
          <Col md={11}>{renderScansSelect('Scans_Group2')}</Col>
        </Row>
      )
    default:
      return null
  }
}

ScansOptionEditor.propTypes = {
  analysisType: PropTypes.object,
  dataOrder: PropTypes.array,
  readOnly: PropTypes.bool,
  handleSetOption: PropTypes.func,
}

export default ScansOptionEditor
