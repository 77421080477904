import { Alert, Modal, notification } from 'antd'
import update from 'immutability-helper'
import { filter, find, get, map, mapKeys, merge, noop, reduce } from 'lodash'
import moment from 'moment'

import { analysesApi, authApi } from 'apis'
import {
  ANALYSIS_STATES,
  API_BASE_URL,
  DATA_TYPES,
  DEFAULT_FORMULA_TYPE,
  VARIABLE_EFFECTS,
  VARIABLE_ROLES,
  VARIABLE_TYPES,
} from 'config/base'
import { getAuthData } from 'utils/storage'

export const prepareAnalysis = analysis =>
  merge(analysis, {
    options: {
      dropMissing: true,
      formula: undefined,
      formulaType: DEFAULT_FORMULA_TYPE,
    },
  })

export const prepareField = field => ({
  index: field.index,
  name: field.name,
  data_type: field.data_type || DATA_TYPES.numeric.code,
  data_unique: field.data_unique,
  data_labels: field.data_labels,
  selected: false,
  variable_role: field.variable_role || VARIABLE_ROLES.x.code,
  variable_type: field.variable_type || VARIABLE_TYPES.continuous.code,
  transformation: field.transformation || undefined,
  effect: field.effect || VARIABLE_EFFECTS.fixed.code,
})

export const prepareFields = fields =>
  map(fields, (field, name) => prepareField(merge(field, { name })))

export const prepareFieldNames = fieldNames =>
  reduce(
    fieldNames,
    (result, name, index) => {
      result[index] = prepareField({ name, index })
      return result
    },
    {},
  )

export const normalizeFileFields = file => {
  if (file?.fields) {
    const preparedFields = prepareFields(file.fields)
    return update(file, {
      fields: { $set: mapKeys(preparedFields, f => f.index) },
    })
  }
  return file
}

export const validateFileFields = file => {
  if (!file?.fields) return

  const numberOfFeatures = filter(
    file.fields,
    field => field.selected && field.variable_role === VARIABLE_ROLES.x.code,
  ).length

  const limit = file.number_of_rows - 1

  if (numberOfFeatures > limit) {
    return `Invalid number of features ${numberOfFeatures} is greater than ${limit} (number of rows - 1)`
  }
}

export const makeId = len => {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_'

  for (let i = 0; i < len; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length))

  return text
}

export const getAnalysisDefaultSorter = (type, stringify = true) => {
  const sorter = get(ANALYSIS_STATES, [type, 'defaultSorter'])

  return stringify ? getOrderingParam(sorter) : sorter
}

export const getOrderingParam = sorter => {
  const { field, order } = sorter

  return field && order
    ? `${order === 'descend' ? '-' : ''}${field}`
    : undefined
}

export const parseOrderingParam = ordering => {
  if (!ordering) {
    return {}
  }

  if (ordering[0] === '-') {
    return {
      order: 'descend',
      field: ordering.substr(1),
      columnKey: ordering.substr(1),
    }
  }

  return { order: 'ascend', field: ordering, columnKey: ordering }
}

export const prepareDownloadResult = id => {
  notification.success({
    message:
      'Preparing download file! Please check your notifications after a few minutes',
  })
  return analysesApi.prepareDownloadResult(id)
}

export const downloadResult = path => {
  // The process to download a large zip file is:
  // 1. Get a auth token.
  // 2. Open a new tab with the token for browser download.
  const authData = getAuthData()
  const token = get(authData, 'token')
  const url = encodePathURL(null, path, token)
  window.open(url, '_blank')
}

export const downloadCLI = () => {
  authApi.downloadCLI().then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'cli.zip')
    link.setAttribute('data-testid', 'cli-file-link')
    document.body.appendChild(link)
    link.click()
  })
}

export const downloadHTML = (id, isGICA = false) =>
  analysesApi.downloadHTML({ id, isGICA }).then(file => {
    const contentType = file.headers['content-type']
    const filename =
      contentType && contentType.indexOf('html') !== -1 ? 'result.html' : null
    if (filename !== null) {
      return file.data
    }
  })

export const getAnalysisTypeById = (analysisTypes, id) =>
  find(analysisTypes, { id })

export const getAnalysisLabel = (analysisTypes, parameterSet) =>
  get(getAnalysisTypeById(analysisTypes, parameterSet.analysis_type), 'label')

export const renderErrors = errorStr => {
  const errors = errorStr.split(';;;')

  return errors.map((error, ind) => {
    const elements = error.split(':::')
    const description = elements.slice(1).join(':::')
    const formattedDesc = description
      ? description.replace(/\\n/g, ' \n')
      : null

    return (
      formattedDesc && (
        <Alert
          key={ind}
          message={get(elements, 0)}
          description={formattedDesc}
          type="error"
          showIcon
          banner
        />
      )
    )
  })
}

export const trimFileExt = filename =>
  filename.split('.').slice(0, -1).join('.')

export const pushOrPopToArray = (arr, item) => {
  if (arr.includes(item)) {
    return arr.filter(elem => elem !== item)
  }

  return [...arr, item]
}

export const encodePathURL = (outDir, path, token) => {
  const fullPath = outDir ? `${outDir}/${path}` : path

  return `${API_BASE_URL}/files/?path=${encodeURIComponent(
    fullPath,
  )}&token=${token}`
}

export const getAnalysisLegend = analysis =>
  `${moment(analysis.anon_date).format('DD-MMM-YYYY h:mm:ss')}-${
    analysis.session
  }-${analysis.series}`

export const buildModalTitleMessage = laterAnalyses => (
  <div>
    <p>
      There {laterAnalyses.length === 1 ? 'is' : 'are'} {laterAnalyses.length}{' '}
      other {laterAnalyses.length === 1 ? 'analysis' : 'analyses'} using this
      result as an input:
    </p>
    <ul>
      {laterAnalyses.map(analysis => (
        <li key={analysis.id}>
          <a
            href={`/analysis/${analysis.id}`}
            target="_blank"
            rel="noopener noreferrer"
            data-testid="analysis-link"
          >
            {analysis.name}
          </a>
        </li>
      ))}
    </ul>
    This action will also delete the analyses listed above. Are you sure you
    want to delete these analyses?
  </div>
)

export const deleteAnalyses = async (ids, deleteFunc) => {
  const processIDs = [...ids]
  const id = processIDs.shift()

  // eslint-disable-next-line
  while (typeof id !== 'undefined') {
    return analysesApi
      .getLaterAnalyses(id)
      .then(data => {
        if (data.count >= 1) {
          const titleMsg = buildModalTitleMessage(data.later_analyses)
          Modal.confirm({
            title: titleMsg,
            okText: 'Okay',
            cancelText: 'Cancel',
            width: 500,
            onOk: () => {
              data.later_analyses.forEach(analysis => {
                deleteFunc(analysis.id)
                processIDs.splice(
                  processIDs.findIndex(processID => processID === analysis.id),
                  1,
                )
              })
              deleteFunc(id)
              deleteAnalyses(processIDs, deleteFunc)
            },
            onCancel: noop,
          })
        } else {
          deleteFunc(id)
          deleteAnalyses(processIDs, deleteFunc)
        }
      })
      .catch(e => notification.error({ message: e.toString() }))
  }
}
