import { SearchOutlined } from '@ant-design/icons'
import { Button, Input, Modal, Table, Tag, Tooltip } from 'antd'
import {
  find,
  first,
  get,
  isEmpty,
  keyBy,
  keys,
  map,
  mapValues,
  omit,
  omitBy,
  pick,
  toString,
} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useEffect, useMemo, useState } from 'react'
import ReactDOM from 'react-dom'
import Highlighter from 'react-highlight-words'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { FileInfo, Loader, ParamView, ProvenanceView } from 'components'
import { ANALYSIS_STATES, ANALYSIS_TYPES_ID, BREAKPOINTS } from 'config/base'
import {
  deleteAnalysis,
  getAnalysis,
  selectAnalysis,
} from 'store/modules/analyses'
import {
  deleteAnalyses,
  getAnalysisDefaultSorter,
  getOrderingParam,
  parseOrderingParam,
  renderErrors,
} from 'utils/analyses'
import { filterParamsUrl, stringContains } from 'utils/common'

import ActionDropDown from './ActionDropDown'

const DEFAULT_TASK_PARAMS = {
  analysis_type: null,
  page: 1,
  pageSize: 10,
  ordering: '-date_time_start',
  shared: 'off',
}

export const TaskList = ({
  analyses,
  analysisTypes,
  analysisUsers,
  isDesktop,
  isNotXLScreen,
  loading,
  selection,
  shared,
  studies,
  type,
  fetchData,
}) => {
  const { totalCount } = analyses

  const analysis = useSelector(selectAnalysis)

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [pagination, setPagination] = useState()
  const [sorter, setSorter] = useState(getAnalysisDefaultSorter(type, false))
  const [filters, setFilters] = useState({})
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [modal, setModal] = useState(null)
  const [selected, setSelected] = useState(null)

  const toggleModal = (key, id) => {
    if (id) {
      setSelected(id)

      if (id && get(analysis, 'id') !== id) {
        dispatch(getAnalysis(id))
      }
    }

    setModal(key)
  }

  const handleDeleteAnalysis = id => dispatch(deleteAnalysis(id))

  useEffect(() => {
    const paramsSearch = queryString.parse(location.search)
    const filterSearch = pick(paramsSearch, ['name', 'description'])
    const searchParams = mapValues(paramsSearch, parseInt)
    const paramsURL = filterParamsUrl({ ...searchParams, ...filterSearch })

    const newFilters = omit(paramsURL, [
      'page',
      'pageSize',
      'ordering',
      'status',
    ])
    const newPagination = {
      ...pagination,
      current: paramsURL.page || DEFAULT_TASK_PARAMS.page,
      pageSize: paramsURL.pageSize || DEFAULT_TASK_PARAMS.pageSize,
    }

    const newSorter = paramsSearch?.ordering
      ? parseOrderingParam(paramsSearch.ordering)
      : sorter

    ReactDOM.unstable_batchedUpdates(() => {
      setPagination(newPagination)
      setSorter(newSorter)
      setFilters(newFilters)
    })

    const params = getQueryParams(newPagination, newFilters, newSorter)
    fetchData(params)
  }, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const paramsSearch = queryString.parse(location.search)

    if (type !== paramsSearch.status) {
      persistURL({ status: type })
    }
  }, [type]) // eslint-disable-line react-hooks/exhaustive-deps

  const defaultParams = {
    status: type,
    shared: shared ? 'on' : 'off',
  }

  const persistURL = newParams => {
    const pickParamsUrl = filterParamsUrl({ ...newParams })
    const omitParamsSearch = omitBy(
      pickParamsUrl,
      (value, key) => DEFAULT_TASK_PARAMS[key] === value,
    )
    const searchParams = new URLSearchParams(omitParamsSearch)
    const newUrl = `?${searchParams.toString()}`
    navigate(newUrl)
  }

  const getQueryParams = (newPagination, newFilters, newSorter) => ({
    ...newFilters,
    ...defaultParams,
    page: get(newPagination, 'current', DEFAULT_TASK_PARAMS.page),
    pageSize: get(newPagination, 'pageSize', DEFAULT_TASK_PARAMS.pageSize),
    ordering: getOrderingParam(newSorter),
  })

  const handleTableChange = (newPagination, newFilters, newSorter) => {
    const params = getQueryParams(newPagination, newFilters, newSorter)
    persistURL(params)
  }

  const handleDeleteResult = ids => {
    const singularDelete = ids.length === 1
    const titleMsg = singularDelete
      ? 'Are you sure want to delete this analysis result?'
      : `Are you sure want to delete these ${ids.length} analyses results?`

    Modal.confirm({
      title: titleMsg,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        const response = deleteAnalyses(ids, handleDeleteAnalysis)
        response.then(() => setSelectedRowKeys([]))
      },
    })
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()

    setSearchText(first(selectedKeys))
    setSearchedColumn(dataIndex)
    setFilters({ ...filters, [dataIndex]: first(selectedKeys) })
  }

  const handleSearchReset = (clearFilters, confirm, dataIndex) => {
    clearFilters()
    confirm()

    setSearchText('')
    setFilters({ ...filters, [dataIndex]: '' })
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            node && node.focus()
          }}
          placeholder={`Search ${dataIndex}`}
          value={first(selectedKeys)}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleSearchReset(clearFilters, confirm, dataIndex)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => stringContains(record[dataIndex], value),
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={toString(text)}
        />
      ) : (
        text
      ),
    filteredValue: !isEmpty(get(filters, `${dataIndex}`))
      ? [get(filters, `${dataIndex}`).toLowerCase()]
      : null,
  })

  const getColumn = (title, key, render) => {
    const { field, order } = sorter

    const column = { title, key, dataIndex: key, sorter: true }

    if (field && order && field === key) {
      column.defaultSortOrder = order
    }

    if (render) {
      column.render = render
    }

    return column
  }

  const isCompletedAnalyses = useMemo(
    () =>
      [
        ANALYSIS_STATES.completed.name,
        ANALYSIS_STATES.success.name,
        ANALYSIS_STATES.error.name,
        ANALYSIS_STATES.all.name,
      ].includes(type),
    [type],
  )

  const isAllAnalysis = type === ANALYSIS_STATES.all.name

  const userColumn = () => {
    const usersMap = mapValues(keyBy(analysisUsers, 'id'), 'username')
    const column = getColumn('User', 'created_by', cell => (
      <span>{usersMap[cell.id]}</span>
    ))

    return {
      ...column,
      filters: map(analysisUsers, user => ({
        text: user.username,
        value: user.id,
      })),
      filterMultiple: false,
      filteredValue: filters.created_by && [filters.created_by],
    }
  }

  const studyColumn = () => {
    const column = getColumn('Study', 'study', (_, record) => (
      <Tooltip title={get(record, 'input_file.study_info.full_name')}>
        <Link to={`/study/${get(record, 'input_file.study_info.id')}`}>
          <Button type="link" className="p-0">
            {record.study_label}
          </Button>
        </Link>
      </Tooltip>
    ))

    return {
      ...column,
      filters: map(studies, study => ({
        text: study.label,
        value: study.id,
      })),
      filterMultiple: false,
      filteredValue: filters.study && [filters.study],
    }
  }

  const nameColumn = () => ({
    ...getColumn('Name', 'name'),
    ...getColumnSearchProps('name'),
  })

  const descriptionColumn = () => ({
    ...getColumn('Description', 'description'),
    ...getColumnSearchProps('description'),
  })

  const analysisTypeColumn = () => ({
    ...getColumn(
      'Analysis Type',
      'analysis_type',
      (_, record) => keys(ANALYSIS_TYPES_ID)[record.analysis_type - 1],
    ),
    filters: map(analysisTypes, analysisType => ({
      text: analysisType.name,
      value: analysisType.id,
    })),
    filterMultiple: false,
    filteredValue: filters.analysis_type && [filters.analysis_type],
  })

  const rowSelection = () => ({
    onSelect: (record, selectedRow, selectedRows) => {
      const analysisIds = map(selectedRows, 'id')

      setSelectedRowKeys(analysisIds)
    },
    onSelectAll: (selectedRow, selectedRows, changeRows) => {
      const analysisIds = map(selectedRows, 'id')

      setSelectedRowKeys(selectedRow ? analysisIds : [])
    },
  })

  const columns = () =>
    [
      isDesktop &&
        getColumn('Id', 'id', cell => (
          <Link to={`/analysis/${cell}`}>{cell}</Link>
        )),
      studyColumn(),
      isDesktop && userColumn(),
      nameColumn(),
      isDesktop && descriptionColumn(),
      analysisTypeColumn(),
      getColumn('Started', 'date_time_start'),
      isCompletedAnalyses && getColumn('Completed', 'date_time_end'),
      isAllAnalysis && {
        title: 'Status',
        key: 'status',
        render: (_, record) => renderTag(record),
      },
      {
        title: 'Actions',
        key: 'actions',
        render: (_, record) => (
          <ActionDropDown
            record={record}
            shared={shared}
            onToggleModal={toggleModal}
            onDeleteAnalysis={handleDeleteAnalysis}
          />
        ),
      },
    ].filter(Boolean)

  const renderTag = record => {
    const { status } = record
    const TAG_COLOR = {
      Complete: 'green',
      Pending: '',
      Error: 'red',
    }

    return <Tag color={TAG_COLOR[status]}>{status}</Tag>
  }

  const data = useMemo(() => {
    const { results } = analyses

    return map(results, analysisElem =>
      Object.assign(
        {},
        {
          ...analysisElem,
          key: analysisElem.id,
          date_time_start: moment(analysisElem.date_time_start).format(
            'M/D/YY HH:mm',
          ),
        },
        analysisElem.date_time_end &&
          isCompletedAnalyses && {
            date_time_end: moment(analysisElem.date_time_end).format(
              'M/D/YY HH:mm',
            ),
          },
      ),
    )
  }, [analyses, isCompletedAnalyses])

  const isAnalysisLoaded = analysis && analysis.id === selected

  const paramViewProps = useMemo(() => {
    const parameters = get(analysis, 'parameters')
    const analysis_type = find(analysisTypes, {
      id: get(parameters, 'analysis.analysis_type'),
    })
    const label = get(analysis_type, 'label')

    return { ...parameters, label }
  }, [analysis, analysisTypes])

  return (
    <>
      {!isEmpty(selectedRowKeys) && (
        <Button
          style={{ float: 'right', marginBottom: 16, zIndex: 1 }}
          data-testid="delete-analyses-button"
          onClick={() => handleDeleteResult(selectedRowKeys, true)}
        >
          Delete{' '}
          {selectedRowKeys.length > 1
            ? `${selectedRowKeys.length} Analyses`
            : 'Analysis'}
        </Button>
      )}
      <Table
        rowSelection={
          selection ? { type: 'checkbox', ...rowSelection() } : null
        }
        columns={columns()}
        dataSource={data}
        size="small"
        loading={loading}
        pagination={{ size: 'large', ...pagination, total: totalCount }}
        bordered
        scroll={{ x: isNotXLScreen }}
        onChange={handleTableChange}
      />
      <Modal
        title="Parameters"
        open={modal === 'paramsModal'}
        footer={null}
        onCancel={() => toggleModal(null)}
        width={1250}
        destroyOnClose
      >
        {isAnalysisLoaded ? <ParamView {...paramViewProps} /> : <Loader />}
      </Modal>
      <Modal
        title="Provenance"
        open={modal === 'provenanceModal'}
        footer={null}
        onCancel={() => toggleModal(null)}
        width={800}
        destroyOnClose
      >
        {isAnalysisLoaded ? (
          <ProvenanceView {...analysis.provenance} />
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal
        title="Inputs"
        open={modal === 'inputsModal'}
        footer={null}
        onCancel={() => toggleModal(null)}
        width={800}
        destroyOnClose
      >
        {isAnalysisLoaded ? (
          <FileInfo dataFile={analysis.input_file} />
        ) : (
          <Loader />
        )}
      </Modal>
      <Modal
        title="Error"
        open={modal === 'errorModal'}
        footer={null}
        closable
        onCancel={() => toggleModal(null)}
        width={'80%'}
        destroyOnClose
      >
        {isAnalysisLoaded ? renderErrors(analysis.error) : <Loader />}
      </Modal>
    </>
  )
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
  isNotXLScreen: width <= 1600,
})

TaskList.propTypes = {
  analyses: PropTypes.shape({
    pageSize: PropTypes.number,
    currentPage: PropTypes.number,
    totalCount: PropTypes.number,
    results: PropTypes.array,
  }),
  analysisTypes: PropTypes.array,
  analysisUsers: PropTypes.array,
  isDesktop: PropTypes.bool,
  isNotXLScreen: PropTypes.bool,
  loading: PropTypes.bool,
  selection: PropTypes.bool,
  shared: PropTypes.bool,
  studies: PropTypes.array,
  type: PropTypes.string,
  fetchData: PropTypes.func,
}

TaskList.defaultProps = {
  loading: false,
  type: ANALYSIS_STATES.completed.name,
}

export default withSizes(sizes)(TaskList)
