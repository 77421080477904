import { PlusOutlined } from '@ant-design/icons'
import { Button, Drawer } from 'antd'
import { PropTypes } from 'prop-types'
import { useState } from 'react'

import { MiscFileForm } from 'components'

export const MiscFileDrawer = ({ analysisType, disabled }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const label =
    analysisType.label === 'GICA'
      ? 'Upload a CSV GICA Order File'
      : 'Upload a miscellaneous file'

  const toggleDrawer = () => setShowDrawer(prevState => !prevState)

  return (
    <>
      <Button
        type="primary"
        shape="circle"
        icon={<PlusOutlined />}
        size="small"
        disabled={disabled}
        onClick={toggleDrawer}
      />
      <Drawer
        width={400}
        title={label}
        open={showDrawer}
        destroyOnClose
        onClose={toggleDrawer}
      >
        <MiscFileForm onSubmit={toggleDrawer} />
      </Drawer>
    </>
  )
}

MiscFileDrawer.propTypes = {
  analysisType: PropTypes.object,
  disabled: PropTypes.bool,
}

MiscFileDrawer.defaultProps = {
  disabled: false,
}

export default MiscFileDrawer
