import { List, Row, Tabs } from 'antd'
import { last, map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const { Item } = List

const FMRIResult = ({ data, dataFile, token }) => {
  const {
    all_files,
    figures,
    out_dir,
    qa_subjects,
    qa_summary,
    qc_framewise_displacement,
    save_path,
    summary,
  } = data

  const outDir = out_dir || save_path

  return (
    <div className="analysis-result" data-testid="fmri-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'output-summary',
                      label: 'Summary',
                      children: (
                        <div
                          className="analysis-result__description text-left"
                          data-testid="output-summary"
                        >
                          {summary}
                        </div>
                      ),
                    },
                    {
                      key: 'output-files',
                      label: 'Files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'quality',
            label: 'QA',
            children: (
              <div className="w-75" data-testid="quality">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'qa-summary',
                      label: 'QA Summary',
                      children: (
                        <>
                          <div className="analysis-result__description text-left">
                            {qa_summary}
                          </div>
                          <br />
                          <div className="analysis-result__description text-left">
                            QC Framewise Displacement:{' '}
                            {qc_framewise_displacement}
                          </div>
                          <br />
                          {map(figures, figure => (
                            <Row key={figure} style={{ marginBottom: 10 }}>
                              <span>{last(figure.split('/'))}</span>
                              <br />
                              <img
                                className="w-100"
                                alt={figure}
                                src={encodePathURL(outDir, figure, token)}
                              />
                            </Row>
                          ))}
                        </>
                      ),
                    },
                    {
                      key: 'qa-flagged-subjects',
                      label: 'Flagged Subjects',
                      children: (
                        <List data-testid="qa-flagged-subjects">
                          {map(qa_subjects, (corrs, subject) => (
                            <Item key={subject}>
                              {subject}: {corrs}
                            </Item>
                          ))}
                        </List>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'figures',
            label: 'Figures',
            children: <span>Under development</span>,
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

FMRIResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    all_subject_figures: PropTypes.array,
    figures: PropTypes.array,
    out_dir: PropTypes.string,
    qa_subjects: PropTypes.array,
    qa_summary: PropTypes.any,
    qc_framewise_displacement: PropTypes.string,
    save_path: PropTypes.string,
    summary: PropTypes.any,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default FMRIResult
