import { Table } from 'antd'
import { endsWith, isArray } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const FilesView = ({ dataFiles }) => {
  const allFiles = useMemo(
    () =>
      dataFiles
        .filter(dataFile => isArray(dataFile.files))
        .reduce((acc, dataFile) => {
          const res = acc.concat(
            dataFile.files
              .filter(fileName => !endsWith(fileName, '.json'))
              .map(fileName => `${dataFile.path}/${fileName}`),
          )
          return res
        }, [])
        .map((file, idx) => ({ order: idx, file })),
    [dataFiles],
  )

  const columns = [
    {
      title: 'Order',
      dataIndex: 'order',
      key: 'order',
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
      render: text => <div className="word-break-all">{text}</div>,
    },
  ]

  return (
    <Table
      columns={columns}
      dataSource={allFiles}
      bordered
      rowKey="order"
      size="small"
      data-testid="files-table"
    />
  )
}

FilesView.propTypes = {
  dataFiles: PropTypes.arrayOf(
    PropTypes.shape({
      files: PropTypes.arrayOf(PropTypes.string),
      path: PropTypes.string,
    }),
  ),
}

export default FilesView
