import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, FloatButton, Input } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { Select } from 'components'

const EditTableCell = ({
  editable,
  input,
  options,
  label,
  value,
  onChange,
}) => {
  const [editValue, setEditValue] = useState(value)
  const [isEditing, setIsEditing] = useState(false)

  const renderField = () => {
    if (input === 'select') {
      return (
        <Select
          defaultValue={value}
          options={options.map(({ text, value: val }) => ({
            label: text,
            value: val,
          }))}
          onChange={val => setEditValue(val)}
        />
      )
    }

    return (
      <Input
        size="small"
        value={editValue}
        onChange={event => setEditValue(event.target.value)}
      />
    )
  }

  const handleCancelEditing = () => {
    setIsEditing(false)
    setEditValue(value)
  }

  const handleSubmit = () => {
    setIsEditing(false)
    onChange(editValue)
  }

  if (isEditing) {
    return (
      <>
        {renderField()}
        <FloatButton
          className="m-02"
          shape="circle"
          icon={<CheckOutlined />}
          size="small"
          data-testid="edit-save-button"
          onClick={handleSubmit}
        />
        <Button
          className="m-02"
          shape="circle"
          icon={<CloseOutlined />}
          size="small"
          data-testid="edit-cancel-button"
          onClick={handleCancelEditing}
        />
      </>
    )
  }

  return (
    <>
      {label || value}
      {editable && (
        <Button
          style={{ marginLeft: 5 }}
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          data-testid="user-edit-button"
          onClick={() => setIsEditing(true)}
        />
      )}
    </>
  )
}

EditTableCell.propTypes = {
  editable: PropTypes.bool,
  input: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
}

EditTableCell.defaultProps = {
  input: '',
  value: '',
}

export default EditTableCell
