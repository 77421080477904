import { CloseOutlined, LockOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { authApi } from 'apis'
import { validators } from 'validators'

const SetPasswordForm = ({ userSelected, onClose }) => {
  const [submitting, setSubmitting] = useState(false)

  const handleSubmit = values => {
    setSubmitting(true)
    authApi
      .updateUser({ userId: userSelected.id, data: values })
      .then(() => {
        notification.success({
          message: `Successfully set password for user ${userSelected.username}`,
        })
        setSubmitting(false)
        onClose()
      })
      .catch(() => {
        notification.error({
          message: `Failed to set password for user ${userSelected.username}`,
        })
        setSubmitting(false)
      })
  }

  return (
    <Form disabled={submitting} onFinish={handleSubmit}>
      <Row>
        <Col span={24}>
          <Form.Item
            name="password"
            label="Password"
            rules={validators.password}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            loading={submitting}
            htmlType="submit"
          >
            Save
          </Button>
          <Button className="ml-1" icon={<CloseOutlined />} onClick={onClose}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

SetPasswordForm.propTypes = {
  userSelected: PropTypes.object,
  onClose: PropTypes.func,
}

export default SetPasswordForm
