import { Card } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader } from 'components'
import { PreprocessingSummaryTable } from 'containers'
import { PageLayout } from 'containers/Layouts'
import { getStudy, selectStudy } from 'store/modules/sites'
import { getFullname } from 'utils/common'

export const PreprocessingSummaryPage = () => {
  const study = useSelector(selectStudy)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getStudy(params.studyLabel))
  }, [params, dispatch])

  if (!study) {
    return <Loader size="large" />
  }

  return (
    <PageLayout
      heading={study.full_name}
      subheading={`Principal Investigator: ${getFullname(
        study.principal_investigator,
      )}`}
    >
      <Card>
        <PreprocessingSummaryTable study={study} />
      </Card>
    </PageLayout>
  )
}

export default PreprocessingSummaryPage
