import { PlusOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Form, Upload } from 'antd'
import {
  find,
  first,
  forEach,
  get,
  indexOf,
  keys,
  omit,
  orderBy,
  take,
} from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  Drawer,
  ScannerForm,
  Select,
  SeriesForm,
  SessionForm,
  StudyForm,
  SubjectForm,
} from 'components'
import { selectLoggedInUser } from 'store/modules/auth'
import { selectDataFilesStatus, uploadFiles } from 'store/modules/datafiles'
import { selectModalities } from 'store/modules/mappings'
import {
  createScanner,
  createSeries,
  createSession,
  createStudy,
  createSubject,
  listAllScanner,
  listAllSeries,
  listAllSession,
  listAllSubject,
  listSite,
  listStudy,
  selectAllScanners,
  selectAllSeries,
  selectAllSessions,
  selectAllSubjects,
  selectSites,
  selectSitesStatus,
  selectStudies,
} from 'store/modules/sites'

const FIELDS = ['study', 'scanner', 'subject', 'session', 'series']

export const DefaultFileUploadForm = () => {
  const allScanners = useSelector(selectAllScanners)
  const allSeries = useSelector(selectAllSeries)
  const allSessions = useSelector(selectAllSessions)
  const allSubjects = useSelector(selectAllSubjects)
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const modalities = useSelector(selectModalities)
  const sites = useSelector(selectSites)
  const sitesStatus = useSelector(selectSitesStatus)
  const studies = useSelector(selectStudies)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [values, setValues] = useState({
    study: '',
    scanner: '',
    subject: '',
    session: '',
    series: '',
    upload: null,
  })
  const [showStudyDrawer, setShowStudyDrawer] = useState(false)
  const [showScannerDrawer, setShowScannerDrawer] = useState(false)
  const [showSubjectDrawer, setShowSubjectDrawer] = useState(false)
  const [showSessionDrawer, setShowSessionDrawer] = useState(false)
  const [showSeriesDrawer, setShowSeriesDrawer] = useState(false)

  useEffect(() => {
    dispatch(listSite())
    dispatch(listStudy())
  }, [dispatch])

  const getStudySiteId = useCallback(
    study => get(find(studies, { id: study }), 'site.id'),
    [studies],
  )

  const handleSearchData = useCallback(
    (fieldName, value) => {
      const fields = [
        'series',
        'session',
        'subject',
        'scanner',
        'study',
        'upload',
      ]

      const newValues = take(fields, indexOf(fields, fieldName)).reduce(
        (acc, elem) => {
          acc[elem] = null
          return acc
        },
        { ...values, [fieldName]: value },
      )

      setValues(newValues)

      const { study, subject, scanner, session } = newValues

      if (fieldName === 'study') {
        const studySiteId = getStudySiteId(study)

        dispatch(listAllScanner({ params: { site: studySiteId } }))

        return
      }

      if (fieldName === 'scanner') {
        dispatch(listAllSubject({ params: { study } }))

        return
      }

      if (fieldName === 'subject') {
        dispatch(listAllSession({ params: { subject, scanner } }))

        return
      }

      if (fieldName === 'session') {
        dispatch(listAllSeries({ params: { session } }))
      }
    },
    [dispatch, getStudySiteId, values],
  )

  useEffect(() => {
    if (sitesStatus === createStudy.fulfilled().type) {
      setShowStudyDrawer(!showStudyDrawer)
      handleSearchData('study', getLastElementById(studies))
    }

    if (sitesStatus === createScanner.fulfilled().type) {
      setShowScannerDrawer(!showScannerDrawer)
      handleSearchData('scanner', getLastElementById(allScanners))
    }

    if (sitesStatus === createSubject.fulfilled().type) {
      setShowSubjectDrawer(!showSubjectDrawer)
      handleSearchData('subject', getLastElementById(allSubjects))
    }

    if (sitesStatus === createSession.fulfilled().type) {
      setShowSessionDrawer(!showSessionDrawer)
      handleSearchData('session', getLastElementById(allSessions))
    }

    if (sitesStatus === createSeries.fulfilled().type) {
      setShowSeriesDrawer(!showSeriesDrawer)
      handleSearchData('series', getLastElementById(allSeries))
      dispatch(listStudy())
    }
  }, [
    sitesStatus,
    allScanners,
    allSubjects,
    allSessions,
    allSeries,
    showScannerDrawer,
    handleSearchData,
    dispatch,
    showSeriesDrawer,
    showSessionDrawer,
    showStudyDrawer,
    showSubjectDrawer,
    studies,
  ])

  const getLastElementById = elements =>
    get(first(orderBy(elements, ['id'], ['desc'])), 'id')

  const handleChangeField = (fieldName, value) => {
    handleSearchData(fieldName, value)
  }

  const handleStudySubmit = ({ data }) => {
    dispatch(createStudy(data))
  }

  const handleScannerSubmit = ({ data }) => {
    const { study } = values
    const studySiteId = getStudySiteId(study)

    dispatch(createScanner({ ...data, site: studySiteId }))
  }

  const handleSubjectSubmit = ({ data }) => {
    const { study } = values

    dispatch(createSubject({ ...data, study }))
  }

  const handleSessionSubmit = ({ data }) => {
    const { subject, scanner } = values

    dispatch(createSession({ ...data, subject, scanner }))
  }

  const handleSeriesSubmit = ({ data }) => {
    const { session } = values

    dispatch(createSeries({ ...data, session }))
  }

  const handleSubmit = () => {
    const { upload } = values
    const formData = new FormData()

    forEach(FIELDS, field => {
      formData.append(field, get(values, field))
    })

    upload.fileList.forEach(file => {
      formData.append('files', file.originFileObj)
    })

    dispatch(uploadFiles(formData))
  }

  const canUpload = () => {
    const uploadData = omit(values, ['default'])

    for (var key of keys(uploadData)) {
      if (!uploadData[key]) {
        return false
      }
    }

    return true
  }

  const mappings = get(
    find(studies, { id: values.study }),
    'protocol_mappings',
    [],
  )

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  }

  const tailFormItemLayout = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 8, offset: 8 },
    },
  }

  const wrapperStyle = {
    display: 'flex',
    alignItems: 'center',
    height: 40,
  }

  const addBtnStyle = {
    style: { marginLeft: 10 },
    type: 'primary',
    shape: 'circle',
    icon: <PlusOutlined />,
    size: 'small',
  }

  return (
    <>
      <Form.Item {...formItemLayout} label="Study">
        <div style={wrapperStyle}>
          <Select
            className="w-100"
            value={values.study}
            options={studies.map(({ id, label }) => ({
              label,
              value: id,
            }))}
            onChange={value => {
              handleChangeField('study', value)
            }}
          />
          <Button
            {...addBtnStyle}
            onClick={() => setShowStudyDrawer(!showStudyDrawer)}
          />
        </div>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Scanner">
        <div style={wrapperStyle}>
          <Select
            className="w-100"
            value={values.scanner}
            disabled={!values.study}
            options={allScanners.map(({ id, label }) => ({ label, value: id }))}
            onChange={value => handleChangeField('scanner', value)}
          />

          <Button
            {...addBtnStyle}
            disabled={!values.study}
            onClick={() => setShowScannerDrawer(!showScannerDrawer)}
          />
        </div>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Subject">
        <div style={wrapperStyle}>
          <Select
            className="w-100"
            value={values.subject}
            disabled={!values.scanner}
            options={allSubjects.map(({ id, anon_id }) => ({
              label: anon_id,
              value: id,
            }))}
            onChange={value => handleChangeField('subject', value)}
          />
          <Button
            {...addBtnStyle}
            disabled={!values.scanner}
            onClick={() => setShowSubjectDrawer(!showSubjectDrawer)}
          />
        </div>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Session">
        <div style={wrapperStyle}>
          <Select
            className="w-100"
            value={values.session}
            disabled={!values.subject}
            options={allSessions.map(({ id, segment_interval }) => ({
              label: segment_interval,
              value: id,
            }))}
            onChange={value => handleChangeField('session', value)}
          />
          <Button
            {...addBtnStyle}
            disabled={!values.subject}
            onClick={() => setShowSessionDrawer(!showSessionDrawer)}
          />
        </div>
      </Form.Item>

      <Form.Item {...formItemLayout} label="Series">
        <div style={wrapperStyle}>
          <Select
            className="w-100"
            value={values.series}
            disabled={!values.session}
            options={allSeries.map(({ id, label }) => ({ label, value: id }))}
            onChange={value => handleChangeField('series', value)}
          />
          <Button
            {...addBtnStyle}
            disabled={!values.session}
            onClick={() => setShowSeriesDrawer(!showSeriesDrawer)}
          />
        </div>
      </Form.Item>

      <Form.Item {...formItemLayout} label="File(s)">
        <Upload
          multiple
          className="w-100"
          onChange={value => setValues({ ...values, upload: value })}
          beforeUpload={() => false}
        >
          <Button className="w-100">
            <UploadOutlined /> File
          </Button>
        </Upload>
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          className="w-100"
          type="primary"
          disabled={!canUpload()}
          loading={dataFilesStatus === uploadFiles.pending().type}
          onClick={handleSubmit}
        >
          Upload
        </Button>
      </Form.Item>

      <Drawer
        title="Create study"
        open={showStudyDrawer}
        onClose={() => setShowStudyDrawer(!showStudyDrawer)}
      >
        <StudyForm
          sites={sites}
          user={user}
          submitting={sitesStatus === createStudy.pending().type}
          onSubmit={handleStudySubmit}
          onCancel={() => setShowStudyDrawer(!showStudyDrawer)}
        />
      </Drawer>

      <Drawer
        title="Create scanner"
        open={showScannerDrawer}
        onClose={() => setShowScannerDrawer(!showScannerDrawer)}
      >
        <ScannerForm
          submitting={sitesStatus === createScanner.pending().type}
          onSubmit={handleScannerSubmit}
          onCancel={() => setShowScannerDrawer(!showScannerDrawer)}
        />
      </Drawer>

      <Drawer
        title="Create subject"
        open={showSubjectDrawer}
        onClose={() => setShowSubjectDrawer(!showSubjectDrawer)}
      >
        <SubjectForm
          submitting={sitesStatus === createSubject.pending().type}
          onSubmit={handleSubjectSubmit}
          onCancel={() => setShowSubjectDrawer(!showSubjectDrawer)}
        />
      </Drawer>

      <Drawer
        title="Create session"
        open={showSessionDrawer}
        onClose={() => setShowSessionDrawer(!showSessionDrawer)}
      >
        <SessionForm
          submitting={sitesStatus === createSession.pending().type}
          onSubmit={handleSessionSubmit}
          onCancel={() => setShowSessionDrawer(!showSessionDrawer)}
        />
      </Drawer>

      <Drawer
        title="Create series"
        open={showSeriesDrawer}
        onClose={() => setShowSeriesDrawer(!showSeriesDrawer)}
      >
        <SeriesForm
          submitting={sitesStatus === createSeries.pending().type}
          modalities={modalities}
          mappings={mappings}
          onSubmit={handleSeriesSubmit}
          onCancel={() => setShowSeriesDrawer(!showSeriesDrawer)}
        />
      </Drawer>
    </>
  )
}

export default DefaultFileUploadForm
