import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table } from 'antd'
import { compact } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'

import { Drawer, UserSelectForm } from 'components'
import * as sitesRedux from 'store/modules/sites'

const DataProviders = ({ editable, status, study, users, updateStudy }) => {
  const [showAddDrawer, setShowAddDrawer] = useState(false)

  const filteredUsers = useMemo(() => {
    const dataProviderIds = study.data_providers.map(user => user.id)
    return users.filter(user => !dataProviderIds.includes(user.id))
  }, [users, study])

  useEffect(() => {
    if (status === sitesRedux.updateStudy.fulfilled().type) {
      setShowAddDrawer(false)
    }
  }, [status])

  const columns = compact([
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    editable && {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button
          danger
          size="small"
          shape="circle"
          icon={<DeleteOutlined />}
          data-testid="study-data-provider-remove-button"
          onClick={() => handleDelete(record)}
        />
      ),
    },
  ])

  const toggleDrawer = () => setShowAddDrawer(prevState => !prevState)

  const handleAdd = data => {
    const { user } = data

    updateStudy({
      id: study.id,
      data: {
        data_providers: [...study.data_providers.map(dp => dp.id), user],
      },
    })
  }

  const handleDelete = record => {
    Modal.confirm({
      title: 'Are you sure want to revoke permission from this user?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleUpdateStudy(record)
      },
    })
  }

  const handleUpdateStudy = record => {
    const { id } = record

    updateStudy({
      id: study.id,
      data: {
        data_providers: study.data_providers
          .filter(dp => dp.id !== id)
          .map(dp => dp.id),
      },
    })
  }

  return (
    <div data-testid="study-data-providers">
      <div className="app-page__subheading">Data Providers</div>
      {editable && (
        <div className="text-right mb-1">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            data-testid="study-data-provider-add-button"
            onClick={toggleDrawer}
          >
            Add Data Provider
          </Button>
        </div>
      )}
      {study.data_providers.length > 0 ? (
        <Table
          dataSource={study.data_providers}
          columns={columns}
          size="small"
          rowKey="id"
          pagination={false}
          bordered
        />
      ) : (
        <Empty description="No Data Providers" />
      )}
      <Drawer
        title="Add Data Provider"
        open={showAddDrawer}
        onClose={toggleDrawer}
      >
        <UserSelectForm
          submitting={status === sitesRedux.updateStudy.pending().type}
          users={filteredUsers}
          onSubmit={handleAdd}
          onCancel={toggleDrawer}
        />
      </Drawer>
    </div>
  )
}

DataProviders.propTypes = {
  editable: PropTypes.bool,
  status: PropTypes.string,
  study: PropTypes.object,
  users: PropTypes.array,
  updateStudy: PropTypes.func,
}

export default DataProviders
