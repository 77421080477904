import { WarningTwoTone } from '@ant-design/icons'
import { Alert, Button, Card, Col, Modal, Row, Table, Tag, Tooltip } from 'antd'
import {
  compact,
  concat,
  filter,
  find,
  first,
  get,
  isEmpty,
  join,
  keys,
  map,
  mapValues,
  pick,
  uniqBy,
} from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { withSizes } from 'react-sizes'
import { compose } from 'redux'
import { createStructuredSelector } from 'reselect'

import { datafilesApi } from 'apis'
import {
  AnalysisPlanStepsTable,
  ParamView,
  ProvenanceView,
  Select,
  TagEditor,
} from 'components'
import { BREAKPOINTS, TAG_COLORS } from 'config/base'
import { AnalysisPredictionSection } from 'containers'
import { PageLayout } from 'containers/Layouts'
import { withRouter } from 'hoc/withRouter'
import {
  clearAnalysisOptions,
  clearAnalysisType,
  deleteAnalysis,
  getAnalysisType,
  listAnalysisType,
  selectAnalysisType,
  selectAnalysisTypes,
  selectCurrentAnalysis,
} from 'store/modules/analyses'
import { selectIsSuperUser } from 'store/modules/auth'
import {
  listDataDirectory,
  listParameterSet,
  runMultipleAnalyses,
  runSingleAnalysis,
  selectCurrentFiles,
  selectDataDirectory,
  selectDataFilesStatus,
  setCurrentFiles,
} from 'store/modules/datafiles'
import {
  listProtocol,
  selectModalities,
  selectProtocols,
} from 'store/modules/mappings'
import {
  assignTags,
  listStudy,
  listTag,
  selectStudies,
  selectTags,
} from 'store/modules/sites'
import {
  deleteAnalyses,
  getOrderingParam,
  prepareDownloadResult,
  renderErrors,
} from 'utils/analyses'

import DetailView from './DetailView'
import ExpandedRow from './ExpandedRow'
import Result from './Result'
import SearchForm from './SearchForm'

const DEFAULT_SORTER = {
  field: 'subject',
  columnKey: 'subject',
  order: 'ascend',
}

export class DataDirectoryPage extends Component {
  static propTypes = {
    analysisType: PropTypes.object,
    analysisTypes: PropTypes.array,
    currentAnalysis: PropTypes.object,
    currentFiles: PropTypes.array,
    dataDirectory: PropTypes.shape({
      pageSize: PropTypes.number,
      currentPage: PropTypes.number,
      totalCount: PropTypes.number,
      results: PropTypes.array,
    }),
    isDesktop: PropTypes.bool,
    isMobile: PropTypes.bool,
    isSuperUser: PropTypes.bool,
    location: PropTypes.object,
    modalities: PropTypes.array,
    protocols: PropTypes.array,
    status: PropTypes.string,
    studies: PropTypes.array,
    tags: PropTypes.array,
    assignTags: PropTypes.func,
    clearAnalysisOptions: PropTypes.func,
    clearAnalysisType: PropTypes.func,
    deleteAnalysis: PropTypes.func,
    getAnalysisType: PropTypes.func,
    listDataDirectory: PropTypes.func,
    listParameterSet: PropTypes.func,
    listProtocol: PropTypes.func,
    listStudy: PropTypes.func,
    listTag: PropTypes.func,
    navigate: PropTypes.func,
    runMultipleAnalyses: PropTypes.func,
    runSingleAnalysis: PropTypes.func,
    setCurrentFiles: PropTypes.func,
  }

  state = {
    detailModal: false,
    paramsModal: false,
    provenanceModal: false,
    selected: null,
    downloadingAnalysis: null,
    current: 1,
    pageSize: 10,
    filters: {},
    sorter: DEFAULT_SORTER,
    batchSelectionEnabled: false,
    selectedRowKeys: [],
    selectedAnalysisType: undefined,
    gettingAllAnalyses: false,
  }

  componentDidMount() {
    this.props.listParameterSet()
    this.props.listTag()
    this.props.listProtocol()
    this.props.listStudy()
    this.initParamFilterURL()
  }

  componentWillUnmount() {
    this.clearData()
    this.props.setCurrentFiles([])
  }

  componentDidUpdate() {
    const { dataDirectory } = this.props

    document.querySelectorAll('.ant-table-row-expand-icon').forEach(icon => {
      const analysisId = icon.closest('tr').getAttribute('data-row-key')
      const result = find(dataDirectory.results, { id: Number(analysisId) })

      icon.style.display = result.analyses.length > 0 ? 'block' : 'none'
    })

    window.onpopstate = () => this.initParamFilterURL()
  }

  initParamFilterURL = () => {
    const paramsSearch = queryString.parse(window.location.search)
    const { filters } = this.state

    if (isEmpty(paramsSearch)) return

    this.setState(
      {
        filters: {
          ...filters,
          ...mapValues(paramsSearch, parseInt),
          series: paramsSearch?.series,
          name: paramsSearch?.name,
          tags: !isEmpty(paramsSearch?.tags)
            ? paramsSearch?.tags.split(',')
            : [],
          study: !isEmpty(paramsSearch?.study)
            ? paramsSearch?.study.split(',')
            : [],
        },
      },
      this.handleFetchData,
    )
  }

  clearData = () => {
    this.props.clearAnalysisType()
    this.props.clearAnalysisOptions()
  }

  handleTableChange = (pagination, _, sorter) => {
    const { current, pageSize } = pagination

    this.setState(
      {
        current,
        pageSize,
        sorter: { ...pick(sorter, ['field', 'order', 'columnKey']) },
      },
      this.handleFetchData,
    )
  }

  handleShowSizeChange = (_, pageSize) => {
    this.setState({ pageSize, current: 1 }, this.handleFetchData)
  }

  handleFetchData = () => {
    const { current, pageSize, filters, sorter } = this.state

    // Rename subject to subject_session for sorter.
    const mappedSorter = mapValues(sorter, value =>
      value === 'subject' ? 'subject_session' : value,
    )

    const params = Object.assign(
      {
        pageSize,
        page: current,
        ordering: getOrderingParam(mappedSorter),
        ...filters,
      },
      filters.study &&
        filters.study.length > 0 && { study: filters.study.join(',') },
      filters.tags &&
        filters.tags.length > 0 && { tags: filters.tags.join(',') },
    )

    this.props.listDataDirectory({ params })
  }

  handleRunAnalysis = id => {
    this.props.runMultipleAnalyses(id)
  }

  handleSearchFormChange = payload => {
    const { filters } = this.state

    this.setState({ current: 1, filters: { ...filters, ...payload } })
  }

  handleSearchFormReset = () => {
    this.setState({ filters: {} }, this.handleFetchData)
  }

  handleRunMultipleAnalysis = () => {
    const { selectedRowKeys } = this.state
    selectedRowKeys.forEach(id => this.props.runMultipleAnalyses(id))
  }

  handleRunSingleAnalysis = (id, stepId) => {
    this.props.runSingleAnalysis({ id, stepId })
  }

  handleAssignTags = (type, id, selected) => {
    this.props.assignTags({ [type]: id, tags: selected })
  }

  get analyses() {
    const { dataDirectory } = this.props
    const allAnalyses = dataDirectory.results.reduce(
      (acc, elem) => concat(acc, elem.analyses),
      [],
    )

    return uniqBy(allAnalyses, 'id')
  }

  get paramViewProps() {
    const { analysisTypes } = this.props
    const { selected } = this.state

    const parameters = get(find(this.analyses, { id: selected }), 'parameters')
    const label = get(
      find(analysisTypes, { id: get(parameters, 'analysis.analysis_type') }),
      'label',
    )

    return { ...parameters, label }
  }

  get provenanceViewProps() {
    const { selected } = this.state

    const provenance = get(find(this.analyses, { id: selected }), 'provenance')

    return provenance
  }

  get columns() {
    const { tags, isSuperUser, isDesktop } = this.props
    const { sorter, filters } = this.state

    const columns = [
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        sorter: true,
        sortOrder: sorter.columnKey === 'subject' && sorter.order,
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <span className="mr-1">{record.subject_info.anon_id}</span>
            <TagEditor
              tags={tags}
              selectedTags={record.subject_info.tags}
              editable={isSuperUser}
              onChange={selectedTags =>
                this.handleAssignTags(
                  'subject',
                  record.subject_info.id,
                  selectedTags,
                )
              }
            />
            {record.has_missing_files && (
              <Tooltip
                title={`Missing files: ${record.missing_files.join(', ')}`}
              >
                <WarningTwoTone twoToneColor="#f5222d" />
              </Tooltip>
            )}
          </div>
        ),
      },
      {
        title: 'Session',
        dataIndex: 'session',
        key: 'session',
        render: (_, record) => (
          <div className="d-flex align-items-center">
            <span className="mr-1">{record.session.folder}</span>
            <TagEditor
              tags={tags}
              selectedTags={record.session_info.tags}
              editable={isSuperUser}
              onChange={selectedTags =>
                this.handleAssignTags(
                  'session',
                  record.session_info.id,
                  selectedTags,
                )
              }
            />
          </div>
        ),
      },
      {
        title: 'Protocol Name and Series',
        dataIndex: 'series',
        key: 'series',
        render: (_, record) => (
          <Tooltip title="View Scan Parameters">
            <Button
              type="link"
              block
              onClick={() => this.toggleModal('detailModal', record)}
            >
              {record.series.folder}
            </Button>
          </Tooltip>
        ),
      },
      {
        title: 'DataFile',
        dataIndex: 'name',
        key: 'name',
        render: (_, record) => <span>{record.name}</span>,
      },
      {
        title: 'Modalities',
        dataIndex: 'modalities',
        key: 'modalities',
        render: (_, record) => (
          <>
            {record.modalities.map(modality => (
              <Tag
                key={modality.id}
                color={TAG_COLORS[modality.id % (TAG_COLORS.length - 1)]}
                style={{ marginBottom: 5 }}
              >
                {modality.full_name}
              </Tag>
            ))}
          </>
        ),
      },
      {
        title: 'Analysis',
        dataIndex: 'analysis',
        key: 'analysis',
        render: (_, record) => {
          const { protocol } = record.series
          let { plans } = record

          if (!protocol) return null

          plans = filter(plans, { study: record.study_info.id })

          if (filters.analysis_type) {
            plans = plans.filter(plan =>
              find(plan.steps, {
                analysis_type: Number(filters.analysis_type),
              }),
            )
          }

          if (filters.parameter_set) {
            plans = plans.filter(plan =>
              find(plan.steps, {
                parameter_set: Number(filters.parameter_set),
              }),
            )
          }

          const plan = first(plans)
          return (
            plan && (
              <AnalysisPlanStepsTable
                {...record}
                steps={plan?.steps || []}
                study={record.study_info.id}
                protocolId={protocol.id}
                hideModality
                inCompactMode={!isDesktop}
                startAnalysis={step =>
                  this.handleRunSingleAnalysis(record.id, step.id)
                }
              />
            )
          )
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (_, record) =>
          join(
            record.analyses.map(analysis => analysis.status),
            ' / ',
          ),
      },
    ]

    return compact(columns)
  }

  get data() {
    const { dataDirectory, analysisTypes } = this.props
    const { downloadingAnalysis } = this.state
    const { results } = dataDirectory

    return map(results, study => ({
      ...study,
      key: study.id,
      downloadingAnalysis,
      analysisTypes,
      onToggleModal: this.toggleModal,
      onShowResult: this.handleShowResult,
      onShowError: this.handleShowError,
      onDownloadResult: this.handleDownloadResult,
      onDeleteResult: this.handleDeleteResult,
    }))
  }

  get batchAnalysisButtonLabel() {
    const { status } = this.props
    const { selectedRowKeys } = this.state

    const isAnalysisStarting = status === runMultipleAnalyses.pending().type
    const startStatus = isAnalysisStarting ? 'Starting' : 'Start'
    const analysisLabel = selectedRowKeys.length > 1 ? 'Analyses' : 'Analysis'

    return isEmpty(selectedRowKeys)
      ? 'Select Analyses to Start'
      : `${startStatus} ${selectedRowKeys.length} ${analysisLabel}`
  }

  get loading() {
    const { status } = this.props

    return [
      listDataDirectory.pending().type,
      listAnalysisType.pending().type,
      runMultipleAnalyses.pending().type,
      runSingleAnalysis.pending().type,
    ].includes(status)
  }

  get isGroupAnalysisEnabled() {
    const { analysisType, currentAnalysis } = this.props
    return analysisType && keys(currentAnalysis).length > 0
  }

  toggleModal = (key, selected) => {
    this.setState(
      Object.assign(
        {
          [key]: !this.state[key],
        },
        selected && { selected },
      ),
    )
  }

  handleDownloadResult = async id => {
    this.setState({ downloadingAnalysis: id })

    await prepareDownloadResult(id)

    this.setState({ downloadingAnalysis: null })
  }

  handleShowError = error => {
    Modal.error({
      content: renderErrors(error),
      maskClosable: true,
      icon: null,
      okText: 'Dismiss',
      width: '80%',
    })
  }

  handleShowResult = record => {
    Modal.success({
      content: <Result id={record.id} dataFile={record.input_file} />,
      maskClosable: true,
      icon: null,
      okText: 'Dismiss',
      width: '80%',
    })
  }

  handleDeleteResult = id => {
    const comp = this

    Modal.confirm({
      title: 'Are you sure want to delete this analysis result?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteAnalyses([id], comp.props.deleteAnalysis)
      },
    })
  }

  handleToggleBatchSelection = () => {
    const { batchSelectionEnabled } = this.state

    this.setState(
      Object.assign(
        { batchSelectionEnabled: !batchSelectionEnabled },
        batchSelectionEnabled && { selectedRowKeys: [] },
      ),
    )

    if (batchSelectionEnabled) {
      this.props.setCurrentFiles([])
    }
  }

  handleSelectAll = async () => {
    const { filters } = this.state
    const { study, ...restFilters } = filters

    this.setState({ gettingAllAnalyses: true })

    const params = {
      ...restFilters,
      study: study.join(','),
      group_analysis: this.isGroupAnalysisEnabled ? '1' : '0',
    }

    try {
      const data = await datafilesApi.listAllDataDirectory({ params })
      this.setState({ selectedRowKeys: data.map(datafile => datafile.id) })
      this.props.setCurrentFiles(data)
    } catch (error) {} // eslint-disable-line

    this.setState({ gettingAllAnalyses: false })
  }

  handleChangeSelectedRowKeys = selectedRowKeys => {
    const { currentFiles, dataDirectory } = this.props

    const datafiles = uniqBy(
      [...dataDirectory.results, ...currentFiles],
      'id',
    ).filter(datafile => selectedRowKeys.includes(datafile.id))

    this.props.setCurrentFiles(datafiles)

    this.setState({ selectedRowKeys })
  }

  handleSelectAnalysisType = analysisType => {
    if (analysisType) {
      this.setState({ selectedAnalysisType: analysisType })
      this.props.getAnalysisType(analysisType)
    } else {
      this.handleCloseGroupAnalysis()
    }
  }

  handleCloseGroupAnalysis = () => {
    this.clearData()
    this.props.setCurrentFiles([])
    this.setState({
      batchSelectionEnabled: false,
      selectedRowKeys: [],
      selectedAnalysisType: undefined,
    })
  }

  render() {
    const {
      analysisTypes,
      modalities,
      protocols,
      navigate,
      studies,
      tags,
      dataDirectory,
      status,
      isMobile,
    } = this.props
    const {
      current,
      pageSize,
      filters,
      selected,
      batchSelectionEnabled,
      selectedRowKeys,
      selectedAnalysisType,
      detailModal,
      paramsModal,
      provenanceModal,
      gettingAllAnalyses,
    } = this.state

    const isAnalysisStarting = status === listDataDirectory.pending().type

    const rowSelection = {
      selectedRowKeys,
      getCheckboxProps: record => ({
        disabled: record.plans.length === 0 && !this.isGroupAnalysisEnabled,
        name: record.name,
      }),
      onChange: this.handleChangeSelectedRowKeys,
    }

    return (
      <PageLayout heading="Data Directory">
        <Row gutter={12} type="flex" justify="space-between">
          <Col span={24}>
            <SearchForm
              analysisTypes={analysisTypes}
              modalities={modalities}
              protocols={protocols}
              studies={studies}
              navigate={navigate}
              tags={tags}
              values={filters}
              isMobile={isMobile}
              onChange={this.handleSearchFormChange}
              onReset={this.handleSearchFormReset}
              onSubmit={this.handleFetchData}
            />
            <Alert
              className="mt-1"
              description={
                <div>
                  To add an analysis step to a series for all subjects in a
                  study, click <Link to="/analysis-plans">here</Link>
                </div>
              }
              type="info"
              showIcon
            />
          </Col>
        </Row>
        <Card className="mt-1">
          <Row>
            <Col md={10} lg={5}>
              <Select
                placeholder="Analysis Type"
                className="w-100"
                allowClear
                value={selectedAnalysisType}
                options={analysisTypes.map(({ id, name }) => ({
                  label: name,
                  value: id,
                }))}
                onChange={this.handleSelectAnalysisType}
              />
              <Button
                className="mt-1"
                type="primary"
                disabled={!selectedAnalysisType}
                onClick={this.handleCloseGroupAnalysis}
              >
                Close Group Analysis
              </Button>
            </Col>
            <Col md={14} lg={19}>
              {this.isGroupAnalysisEnabled && (
                <div className="data-directory-analysis-configuration">
                  <AnalysisPredictionSection groupRun />
                </div>
              )}
            </Col>
          </Row>
        </Card>
        <Row className="mt-1" type="flex" justify="space-between">
          <Col className="d-flex align-items-center">
            <Button
              type="primary"
              disabled={gettingAllAnalyses}
              onClick={this.handleToggleBatchSelection}
            >
              {batchSelectionEnabled ? 'Disable' : 'Enable'} Batch Selection
            </Button>
            {batchSelectionEnabled && (
              <Tooltip title="This will select all analyses across the table">
                <Button
                  style={{ marginLeft: 10 }}
                  loading={gettingAllAnalyses}
                  onClick={this.handleSelectAll}
                >
                  Select All
                </Button>
              </Tooltip>
            )}

            {batchSelectionEnabled &&
              selectedAnalysisType &&
              selectedRowKeys.length > 0 && (
                <div className="ml-1">
                  <Tag color="magenta">{`Selected ${selectedRowKeys.length} files`}</Tag>
                </div>
              )}
          </Col>
          <Col>
            {batchSelectionEnabled && !this.isGroupAnalysisEnabled && (
              <Button
                disabled={isEmpty(selectedRowKeys) || gettingAllAnalyses}
                loading={isAnalysisStarting}
                onClick={this.handleRunMultipleAnalysis}
              >
                {this.batchAnalysisButtonLabel}
              </Button>
            )}
          </Col>
        </Row>
        <Card className="mt-1">
          <Table
            columns={this.columns}
            dataSource={this.data}
            expandedRowRender={ExpandedRow}
            bordered
            loading={this.loading}
            pagination={{
              size: 'large',
              current,
              total: dataDirectory.totalCount,
              pageSize,
              showSizeChanger: true,
              pageSizeOptions: ['10', '20', '50', '100'],
            }}
            rowKey="id"
            rowSelection={batchSelectionEnabled ? rowSelection : null}
            scroll={{ x: true }}
            onChange={this.handleTableChange}
          />
        </Card>
        {paramsModal && (
          <Modal
            title="Parameters"
            open
            footer={null}
            width={1250}
            destroyOnClose
            onOk={() => this.toggleModal('paramsModal')}
            onCancel={() => this.toggleModal('paramsModal')}
          >
            <ParamView {...this.paramViewProps} />
          </Modal>
        )}
        {provenanceModal && (
          <Modal
            title="Provenance"
            open
            footer={null}
            width={800}
            destroyOnClose
            onOk={() => this.toggleModal('provenanceModal')}
            onCancel={() => this.toggleModal('provenanceModal')}
          >
            <ProvenanceView {...this.provenanceViewProps} />
          </Modal>
        )}
        {detailModal && (
          <Modal
            title="Details"
            open
            footer={null}
            width="80%"
            destroyOnClose
            onOk={() => this.toggleModal('detailModal')}
            onCancel={() => this.toggleModal('detailModal')}
          >
            <DetailView dataFile={selected} />
          </Modal>
        )}
      </PageLayout>
    )
  }
}

const selectors = createStructuredSelector({
  analysisType: selectAnalysisType,
  analysisTypes: selectAnalysisTypes,
  currentAnalysis: selectCurrentAnalysis,
  currentFiles: selectCurrentFiles,
  dataDirectory: selectDataDirectory,
  isSuperUser: selectIsSuperUser,
  modalities: selectModalities,
  protocols: selectProtocols,
  status: selectDataFilesStatus,
  studies: selectStudies,
  tags: selectTags,
})

const actions = {
  assignTags,
  clearAnalysisOptions,
  clearAnalysisType,
  deleteAnalysis,
  getAnalysisType,
  listDataDirectory,
  listParameterSet,
  listProtocol,
  listStudy,
  listTag,
  runMultipleAnalyses,
  runSingleAnalysis,
  setCurrentFiles,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
  isMobile: width < BREAKPOINTS.MD,
})

export default compose(
  withSizes(sizes),
  withRouter,
  connect(selectors, actions),
)(DataDirectoryPage)
