import { Card } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  getProfile,
  selectAuthStatus,
  selectLoggedInUser,
} from 'store/modules/auth'
import {
  listSite,
  listStudy,
  listTag,
  selectSites,
  selectSitesStatus,
} from 'store/modules/sites'
import { getEditableSites } from 'utils/common'

import StudyTable from './StudyTable'

const StudyListPage = () => {
  const authStatus = useSelector(selectAuthStatus)
  const sites = useSelector(selectSites)
  const status = useSelector(selectSitesStatus)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('mine')

  useEffect(() => {
    const params = { shared: 'off' }
    dispatch(listStudy({ params }))
    dispatch(listSite())
    dispatch(getProfile())
    dispatch(listTag())
  }, [dispatch])

  const preparingData =
    [listSite.pending().type, listStudy.pending().type].includes(status) ||
    authStatus === getProfile.pending().type

  useEffect(() => {
    const SHARED_BY_TABS = {
      mine: 'off',
      sharedWithMe: 'on',
      datableStudies: 'data',
    }

    dispatch(listStudy({ params: { shared: SHARED_BY_TABS[activeTab] } }))
  }, [activeTab, dispatch])

  const renderContent = () => {
    const tableProps = {
      sites: getEditableSites(sites, user),
      user,
      status,
    }

    if (preparingData) {
      return <Loader />
    }

    if (user.is_superuser) {
      return (
        <Card>
          <StudyTable {...tableProps} />
        </Card>
      )
    }

    return (
      <Card>
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: 'mine',
              label: 'My Studies',
              children: <StudyTable {...tableProps} title="My Studies" />,
            },
            {
              key: 'sharedWithMe',
              label: 'Studies Shared With Me',
              children: (
                <StudyTable
                  {...tableProps}
                  title="Studies Shared With Me"
                  shared
                />
              ),
            },
            {
              key: 'datableStudies',
              label: 'Studies Accepting My Data',
              children: (
                <StudyTable
                  {...tableProps}
                  title="Studies Accepting My Data"
                  shared
                />
              ),
            },
          ]}
          onChange={setActiveTab}
        />
      </Card>
    )
  }

  return <PageLayout heading="Studies">{renderContent()}</PageLayout>
}

export default StudyListPage
