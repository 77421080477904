import { Col, Divider, Form, Row, Typography } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initAnalysisOptions, selectAnalysis } from 'store/modules/analyses'
import { setCurrentFiles } from 'store/modules/datafiles'

import DefaultUploadSection from '../DefaultUpload'
import StudySelect from './StudySelect'

const { Title } = Typography

export const FreesurferRegressionUploadSection = () => {
  const analysis = useSelector(selectAnalysis)

  const dispatch = useDispatch()

  useEffect(() => {
    if (analysis) {
      const analysisOptions = analysis.parameters.analysis.options

      dispatch(initAnalysisOptions(analysisOptions))
      dispatch(setCurrentFiles([analysis.input_file]))
    } else {
      dispatch(initAnalysisOptions({ study: { value: '' } }))
    }
  }, [dispatch, analysis])

  return (
    <Form.Provider>
      <Row>
        <Col span={24}>
          <Title level={4}>Choose Study</Title>
        </Col>
        <Col span={24}>
          <StudySelect />
        </Col>

        <Divider />

        <Col span={24}>
          <Title level={4}>Choose Predictors File</Title>
        </Col>
        <Col span={24}>
          <DefaultUploadSection />
        </Col>
      </Row>
    </Form.Provider>
  )
}

export default FreesurferRegressionUploadSection
