import { Alert, Button, Card, Col, Radio, Row } from 'antd'
import { find, get, maxBy } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { CHART_COLORS } from 'config/base'
import { getAnalysisLegend } from 'utils/analyses'

import DateChart from './DateChart'
import MetricChart from './MetricChart'
import AnalysesModal from './Modal'

const ScannerChart = ({ phantomAnalyses }) => {
  const [selectedAnalyses, setSelectedAnalyses] = useState([])
  const [chartType, setChartType] = useState('metric')
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const latestAnalysisId = get(maxBy(phantomAnalyses, 'anon_date'), 'id')
    setSelectedAnalyses([latestAnalysisId].filter(Boolean))
  }, [phantomAnalyses])

  const toggleModal = () => setShowModal(prevState => !prevState)

  const openAnalysisPage = analysisId => {
    window.open(`${window.location.origin}/analysis/${analysisId}`, '_blank')
  }

  const renderChart = () => {
    if (selectedAnalyses.length === 0) {
      return (
        <Alert
          type="warning"
          message="Please select analysis"
          banner
          className="mt-1"
        />
      )
    }

    const analyses = phantomAnalyses.filter(analysis =>
      selectedAnalyses.includes(analysis.id),
    )

    return chartType === 'metric' ? (
      <MetricChart analyses={analyses} onOpenAnalysisPage={openAnalysisPage} />
    ) : (
      <DateChart analyses={analyses} onOpenAnalysisPage={openAnalysisPage} />
    )
  }

  return (
    <div className="mt-1">
      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Radio.Group
            value={chartType}
            buttonStyle="solid"
            onChange={evt => setChartType(evt.target.value)}
          >
            <Radio.Button value="metric">By Slice</Radio.Button>
            <Radio.Button value="date">By Date</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row gutter={24} className="mt-1">
        <Col xs={24}>
          <Button onClick={toggleModal}>Select Analyses</Button>
        </Col>
        <Col xs={24} md={{ span: 12, offset: 12 }}>
          {chartType === 'metric' && selectedAnalyses.length > 0 && (
            <Card className="analysis-legend">
              {selectedAnalyses.map((analysisId, ind) => (
                <div key={analysisId} className="analysis-legend-item">
                  <span
                    className="analysis-legend-mark"
                    style={{
                      backgroundColor:
                        CHART_COLORS[ind % (CHART_COLORS.length - 1)],
                    }}
                  />
                  {getAnalysisLegend(find(phantomAnalyses, { id: analysisId }))}
                </div>
              ))}
            </Card>
          )}
        </Col>
      </Row>
      {renderChart()}
      <AnalysesModal
        open={showModal}
        analyses={phantomAnalyses}
        selected={selectedAnalyses}
        onSelect={setSelectedAnalyses}
        onToggleModal={toggleModal}
      />
    </div>
  )
}

ScannerChart.propTypes = {
  phantomAnalyses: PropTypes.arrayOf(
    PropTypes.shape({
      date_time_end: PropTypes.string,
      date_time_start: PropTypes.string,
      fmri_phantom_qa_data: PropTypes.array,
      name: PropTypes.string,
    }),
  ),
}

export default ScannerChart
