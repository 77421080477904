import { SearchOutlined } from '@ant-design/icons'
import { Button, Col, Input, Row, Table, Tabs } from 'antd'
import { compact, first, keyBy, map, toString } from 'lodash'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { withSizes } from 'react-sizes'

import { FileInfo, Select } from 'components'
import { BREAKPOINTS } from 'config/base'
import { stringContains } from 'utils/common'

import SummaryTable from './SummaryTable'

const FreeSurferRegressionResult = ({ data, dataFile, isMobile }) => {
  const searchInput = useRef(null)

  const measureOptionsLookup = keyBy(data.results_by_measure, 'name')
  const predictorOptionsLookup = keyBy(data.results_by_predictor, 'name')

  const [measureSelection, setMeasureSelection] = useState(
    first(data.results_by_measure),
  )
  const [predictorSelection, setPredictorSelection] = useState(
    first(data.results_by_predictor),
  )
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const onMeasureChange = val => {
    setMeasureSelection(measureOptionsLookup[val])
  }

  const onPredictorChange = val => {
    setPredictorSelection(predictorOptionsLookup[val])
  }

  const formatResults = results =>
    map(results, (item, index) => ({ ...item, key: index }))

  const getColumnSearchProps = dataIndex => ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={first(selectedKeys)}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
          data-testid="search-button"
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
          data-testid="search-reset-button"
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => stringContains(record[dataIndex], value),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100)
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={toString(text)}
        />
      ) : (
        text
      ),
  })

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(first(selectedKeys))
    setSearchedColumn(dataIndex)
  }

  const handleReset = (clearFilters, confirm) => {
    clearFilters()
    confirm()
    setSearchText('')
  }

  const columns = compact([
    {
      title: 'Measure',
      dataIndex: 'Measure',
      key: 'measure',
      ...getColumnSearchProps('Measure'),
    },
    {
      title: 'Beta',
      dataIndex: 'Beta',
      key: 'beta',
    },
    {
      title: 'Std Err',
      dataIndex: 'Std Err',
      key: 'stderr',
    },
    {
      title: 't-value',
      dataIndex: 't-value',
      key: 'tvalue',
    },
    {
      title: 'p-value',
      dataIndex: 'p-value',
      key: 'pvalue',
    },
    {
      title: 'sign(t) x abs(log(p))',
      dataIndex: 'sign(t) x abs(log(p))',
      key: 'signt_logp',
    },
  ])

  return (
    <div className="analysis-result" data-testid="freesurfer-regression-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'measures',
            label: 'Results by Measure',
            children: (
              <Row>
                <Col span={24}>Select measure to display results</Col>
                <Col span={24}>
                  <Select
                    className="w-33"
                    defaultValue={measureSelection.name}
                    showSearch
                    filterOption={(input, option) =>
                      stringContains(option.label, input)
                    }
                    options={map(data.results_by_measure, ({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                    onChange={onMeasureChange}
                  />
                </Col>
                <Col span={24}>
                  {map(measureSelection.results, (item, ind) => (
                    <div key={ind} style={{ margin: '2rem 0' }}>
                      <SummaryTable content={item} />
                    </div>
                  ))}
                </Col>
              </Row>
            ),
          },
          {
            key: 'predictors',
            label: 'Results by Predictor',
            children: (
              <Row>
                <Col span={24}>Select predictor to display results</Col>
                <Col span={24}>
                  <Select
                    className="w-33"
                    defaultValue={predictorSelection.name}
                    showSearch
                    filterOption={(input, option) =>
                      stringContains(option.label, input)
                    }
                    options={map(data.results_by_predictor, ({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                    onChange={onPredictorChange}
                  />
                </Col>
                <Col span={24}>
                  <Table
                    columns={columns}
                    dataSource={formatResults(predictorSelection.results)}
                    bordered
                    rowKey="Measure"
                    size="middle"
                    scroll={{ x: isMobile }}
                  />
                </Col>
              </Row>
            ),
          },
        ]}
      />
    </div>
  )
}

FreeSurferRegressionResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    description: PropTypes.string,
    has_figures: PropTypes.bool,
    out_dir: PropTypes.string,
    result_files: PropTypes.array,
    results_by_measure: PropTypes.array,
    results_by_predictor: PropTypes.array,
    save_path: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  isMobile: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isMobile: width < BREAKPOINTS.SM,
})

export default withSizes(sizes)(FreeSurferRegressionResult)
