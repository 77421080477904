import { Col, Divider, Form, Input, InputNumber, Radio, Row } from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

import { ArrayField } from 'components'
import { getParameterFormLayouts } from 'utils/common'

const { Item: FormItem } = Form
const formLayout = getParameterFormLayouts('fMRI_32ch')

const FMRI32OptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <FormItem {...formLayout} label={fieldLabel} disabled={readOnly}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  const renderInput = (fieldLabel, fieldName, help = '') => (
    <FormItem
      {...formLayout}
      label={fieldLabel}
      disabled={readOnly}
      help={help}
    >
      <Input
        placeholder={fieldLabel}
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      />
    </FormItem>
  )

  const renderRadioGroup = (fieldLabel, fieldName, options, help = '') => (
    <FormItem {...formLayout} label={fieldLabel}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        help={help}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        {options.map(option => (
          <Radio key={option.value} value={option.value}>
            {option.label}
          </Radio>
        ))}
      </Radio.Group>
    </FormItem>
  )

  return (
    <Row data-testid="fmri-32ch-option-editor">
      <Col span={24}>
        <Row gutter={24}>
          <Col md={10}>
            {renderInputNumber('Epi Base', 'Epi_Base', {
              min: 0,
              max: 1024,
              precision: 0,
            })}
          </Col>
          <Col md={10}>
            {renderRadioGroup('Tshift', 'Tshift', [
              { label: 'on', value: 'on' },
              { label: 'off', value: 'off' },
            ])}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col md={10}>
            <ArrayField
              formLayout={formLayout}
              optional={false}
              label="FWHM_fmri"
              msg="Please enter a separated by comma list of numbers"
              placeholder="6, 6, 6"
              pattern={/^-?[ 0-9]\d*(\.\d+)?$/}
              value={get(analysisOptions, 'FWHM_fmri.value')}
              disabled={readOnly}
              onChange={value => handleSetOption('FWHM_fmri', 'value', value)}
            />
          </Col>
          <Col md={10}>{renderInput('Args_alEpiAnat', 'Args_alEpiAnat')}</Col>
          <Col md={10}>{renderInput('Args_Nwarp', 'Args_Nwarp')}</Col>
          <Col md={10}>
            {renderRadioGroup('Epi2Anat', 'Epi2Anat', [
              { label: 'true', value: true },
              { label: 'false', value: false },
            ])}
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col span={10}>
            {renderRadioGroup(
              'Pass all series under session as inputs?',
              'Session_Series',
              [
                { label: 'Yes', value: true },
                { label: 'No', value: false },
              ],
              'Select to include all series under the session of Anat series',
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            {renderInput(
              'Anat',
              'Anat',
              'Please make sure this is the series of Analysis selected input, or leave blank for auto-assignment',
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            {renderInput(
              'Funct',
              'Funct',
              'Please make sure this series is in the same session with Anat',
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={10}>
            {renderInput(
              'SBRef',
              'SBRef',
              'Please make sure this series is in the same session with Anat',
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

FMRI32OptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

FMRI32OptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default FMRI32OptionEditor
