import { Card } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'
import { selectLoggedInUser } from 'store/modules/auth'
import { listParameterSet } from 'store/modules/datafiles'

import ParameterSetTable from './ParameterSetTable'

export const ParameterSetListPage = () => {
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [activeTab, setActiveTab] = useState('mine')

  useEffect(() => {
    const params = { shared: activeTab === 'shared' ? 'on' : 'off' }
    dispatch(listParameterSet({ params }))
  }, [dispatch, activeTab])

  const renderContent = () => {
    if (user.is_superuser) {
      return <ParameterSetTable user={user} shared={false} />
    }

    return (
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        items={[
          {
            key: 'mine',
            label: 'My Parameter Sets',
            children: (
              <ParameterSetTable
                user={user}
                title="My Parameter Sets"
                shared={false}
              />
            ),
          },
          {
            key: 'shared',
            label: 'Shared Parameter Sets',
            children: (
              <ParameterSetTable
                user={user}
                title="Shared Parameter Sets"
                shared
              />
            ),
          },
        ]}
      />
    )
  }

  return (
    <PageLayout heading="Parameter Sets">
      <Card>{renderContent()}</Card>
    </PageLayout>
  )
}

export default ParameterSetListPage
