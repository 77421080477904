import PropTypes from 'prop-types'

import { PAPAYA_URL } from 'config/base'

const PapayaViewer = ({ file, height, title, width }) =>
  file && (
    <iframe
      title={title}
      width={width}
      height={height}
      src={`${PAPAYA_URL}?file=${file}`}
      data-testid="papaya-viewer"
    />
  )

PapayaViewer.propTypes = {
  file: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

PapayaViewer.defaultProps = {
  title: 'Papaya',
  width: '100%',
  height: 800,
}

export default PapayaViewer
