import { Form, Input } from 'antd'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'

const ArrayField = ({
  disabled,
  formLayout,
  label,
  msg,
  optional,
  pattern,
  placeholder,
  value,
  onChange,
}) => {
  const [error, setError] = useState(null)

  const handleChange = event => {
    const { value: newValue } = event.target
    const patternRegex = new RegExp(pattern)

    setError(null)

    if (!optional && isEmpty(newValue)) {
      onChange(newValue)
      return
    }

    if (newValue[0] === '[') {
      setError(`No need to include brackets. For example: ${placeholder}`)
      return
    }

    const splitValue = newValue.split(',').map(item => item.trim())
    let hasError = false
    splitValue.forEach(item => {
      if (isEmpty(item) || (pattern && !patternRegex.test(item))) {
        setError(`${msg}. For example: ${placeholder}`)
        hasError = true
      }
    })

    if (!hasError) {
      try {
        const arrayValue = JSON.parse(`[${newValue}]`)
        onChange(arrayValue)
      } catch {
        onChange(newValue)
      }
    }
  }

  return (
    <Form.Item
      {...formLayout}
      label={label}
      validateStatus={error ? 'error' : 'success'}
      help={error}
    >
      <Input
        defaultValue={value}
        className="w-100"
        placeholder={placeholder}
        disabled={disabled}
        onChange={handleChange}
      />
    </Form.Item>
  )
}

ArrayField.propTypes = {
  disabled: PropTypes.bool,
  formLayout: PropTypes.object,
  label: PropTypes.string,
  msg: PropTypes.string,
  optional: PropTypes.bool,
  pattern: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func,
}

ArrayField.defaultProps = {
  formLayout: {},
  msg: '',
  pattern: null,
  placeholder: '',
}

export default ArrayField
