import { DeleteOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table } from 'antd'
import { compact } from 'lodash'
import PropTypes from 'prop-types'

import { CheckIcon } from 'components'

const SiteMembers = ({ myRole, site, user, removeMember }) => {
  const canEdit = ['Admin', 'SuperAdmin'].includes(myRole)

  const columns = compact([
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_, record) => record.username,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_, record) => {
        const isPI = record.created_studies.length > 0
        return `${isPI ? 'PI' : 'User'}${
          record.site_role === 'Admin' ? ' (Site Admin)' : ''
        }`
      },
    },
    {
      title: 'Managed',
      dataIndex: 'managed',
      key: 'managed',
      render: (_, record) => <CheckIcon checked={record.is_managed} />,
    },
    canEdit && {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button
          shape="circle"
          icon={<DeleteOutlined />}
          size="small"
          danger
          data-testid="site-member-remove-button"
          onClick={() => handleRemoveMember(record)}
        />
      ),
    },
  ])

  const handleRemoveMember = record => {
    Modal.confirm({
      title: (
        <div>
          Are you sure to remove <b>{record.username}</b> from this site?
          {record.site_role === 'Admin' && (
            <div className="mt-1">
              {record.username} is the admin of this site.
            </div>
          )}
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        removeMember({
          siteId: site.id,
          userId: user && user.id,
          membershipId: record.id,
        })
      },
    })
  }

  return (
    <div data-testid="site-members">
      <h2 className="text-center mb-2">Members</h2>
      {site.members.length > 0 ? (
        <Table
          dataSource={site.members}
          columns={columns}
          size="small"
          bordered
          rowKey="id"
          pagination={false}
        />
      ) : (
        <Empty description="No Members" />
      )}
    </div>
  )
}

SiteMembers.propTypes = {
  myRole: PropTypes.string,
  site: PropTypes.object,
  user: PropTypes.object,
  removeMember: PropTypes.func,
}

export default SiteMembers
