import { Descriptions } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { CheckIcon } from 'components'

const { Item } = Descriptions

const StudyInfo = ({ study }) => {
  const { full_name, label, site, principal_investigator, is_managed } = study

  return (
    <div data-testid="study-info">
      <div className="app-page__subheading">Study Info</div>

      <div className="w-75">
        <Descriptions bordered column={1} size="small">
          <Item label="Name">{full_name}</Item>
          <Item label="Label">{label}</Item>
          <Item label="Site">{get(site, 'full_name', 'No Site')}</Item>
          <Item label="Principal Investigator">
            {get(
              principal_investigator,
              'username',
              'No Principal Investigator',
            )}
          </Item>
          <Item label="Managed">
            <CheckIcon checked={is_managed} />
          </Item>
        </Descriptions>
      </div>
    </div>
  )
}

StudyInfo.propTypes = {
  editable: PropTypes.bool,
  sites: PropTypes.array,
  status: PropTypes.string,
  study: PropTypes.object,
  user: PropTypes.object,
  updateStudy: PropTypes.func,
}

export default StudyInfo
