/* eslint-disable react/no-this-in-sfc */
import { Card, Col, Row } from 'antd'
import {
  find,
  first,
  get,
  keys,
  map,
  max,
  min,
  omit,
  orderBy,
  reduce,
  sum,
  sumBy,
  toUpper,
  uniq,
  values,
} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import Highcharts from 'react-highcharts'

import { Select } from 'components'

import Summary from './Summary'

const DateChart = ({ analyses, onOpenAnalysisPage }) => {
  const [currentSlice, setCurrentSlice] = useState(0)
  const [slices, setSlices] = useState([])

  useEffect(() => {
    const analysesSlices = get(first(analyses), 'fmri_phantom_qa_data').map(
      elem => elem.slice,
    )

    setSlices(analysesSlices)
    setCurrentSlice(first(analysesSlices))
  }, [analyses])

  const summary = useMemo(() => {
    const analysesData = analyses.map(analysis =>
      omit(
        find(analysis.fmri_phantom_qa_data, { slice: currentSlice }),
        'slice',
      ),
    )

    const analysisKeys = keys(first(analysesData))

    const res = analysisKeys.reduce((acc, key) => {
      const parsedAnalysesData = analysesData.map(data => data[key])
      const analysesCount = parsedAnalysesData.length
      const mean = sum(parsedAnalysesData) / analysesCount
      const std = Math.sqrt(
        sumBy(parsedAnalysesData, value => Math.pow(value - mean, 2)) /
          analysesCount,
      )

      acc[key] = {
        Metric: toUpper(key),
        Mean: mean,
        STD: std,
      }

      return acc
    }, {})

    return values(res)
  }, [analyses, currentSlice])

  const chartData = useMemo(() => {
    const analysisKeys = keys(
      omit(get(first(analyses), 'fmri_phantom_qa_data.0'), 'slice'),
    )

    const res = reduce(
      analysisKeys,
      (acc, key) => {
        acc[key] = {
          name: toUpper(key),
          data: [],
        }

        acc[key].data = orderBy(
          map(analyses, analysis => {
            const date = Number(moment(analysis.anon_date, moment.ISO_8601))
            const value = get(
              find(analysis.fmri_phantom_qa_data, { slice: currentSlice }),
              key,
            )

            return {
              x: date,
              y: value,
              events: {
                click: () => onOpenAnalysisPage(analysis.id),
              },
            }
          }),
          elem => elem.x,
          ['asc'],
        )

        return acc
      },
      {},
    )

    return res
  }, [analyses, currentSlice, onOpenAnalysisPage])

  function getConfig(data, key) {
    const dates = uniq(analyses.map(analysis => analysis.anon_date))

    const minDate = moment(min(dates))
    const maxDate = moment(max(dates))

    const diffs = maxDate.diff(minDate, 'days')

    const dateFormat = diffs <= 1 ? 'DD-MM-YYYY' : 'DD-MM-YYYY h:mm:ss'
    const xPos = diffs > 1 ? 0 : 35

    return {
      chart: {
        type: 'line',
        zoomType: 'xy',
      },
      title: {
        text: toUpper(key),
      },
      subtitle: {
        enabled: false,
      },
      xAxis: {
        type: 'datetime',
        title: {
          text: 'Date',
        },
        labels: {
          rotation: -45,
          x: xPos,
          formatter() {
            return moment(this.value, 'x').format(dateFormat)
          },
        },
      },
      tooltip: {
        formatter() {
          return `<b>${this.series.name}</b><br/><b>Date: </b>${moment(
            this.x,
          ).format(dateFormat)}<br/><b>Value: </b> ${this.y}`
        },
      },
      yAxis: {
        title: {
          text: key === 'signal_p2p' || key === 'ghost' ? 'Percent (%)' : null,
        },
      },
      plotOptions: {
        series: {
          marker: {
            enabled: true,
          },
          cursor: 'pointer',
        },
      },
      series: [data],
      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
    }
  }

  return (
    <Row gutter={24} className="mt-1" data-testid="scanner-date-chart">
      <Col md={12}>
        Slice:{'  '}
        <Select
          value={currentSlice}
          style={{ width: 120 }}
          options={slices.map(slice => ({ label: slice, value: slice }))}
          onChange={value => setCurrentSlice(value)}
        />
      </Col>
      <Col md={12}>
        <Summary summary={summary} />
      </Col>
      {keys(chartData).map(key => (
        <Col key={key} md={12}>
          <Card className="mt-1">
            <Highcharts config={getConfig(chartData[key], key)} />
          </Card>
        </Col>
      ))}
    </Row>
  )
}

DateChart.propTypes = {
  analyses: PropTypes.arrayOf(
    PropTypes.shape({
      date_time_end: PropTypes.string,
      date_time_start: PropTypes.string,
      fmri_phantom_qa_data: PropTypes.array,
      name: PropTypes.string,
    }),
  ),
  onOpenAnalysisPage: PropTypes.func,
}

export default DateChart
