import { Pagination } from 'antd'
import { filter, forEach, last, map, some } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, SummaryTag } from 'components'
import {
  getSession,
  listSession,
  selectSessions,
  selectSitesStatus,
} from 'store/modules/sites'

import SubjectFilter from './SubjectFilter'

export const PreprocessingSummaryTable = ({ showTitle, study }) => {
  const sessions = useSelector(selectSessions)
  const status = useSelector(selectSitesStatus)

  const dispatch = useDispatch()

  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 })
  const [subjectFilter, setSubjectFilter] = useState(undefined)
  const [loading, setLoading] = useState(true)

  const handleFetchData = useCallback(() => {
    const params = Object.assign(
      {
        study: study.id,
        page: pagination.current,
        pageSize: pagination.pageSize,
      },
      subjectFilter && { subject: subjectFilter },
    )
    setLoading(true)
    dispatch(listSession({ params }))
  }, [dispatch, pagination, study.id, subjectFilter])

  useEffect(() => {
    handleFetchData()
  }, [pagination, subjectFilter, handleFetchData])

  useEffect(() => {
    if (status === listSession.fulfilled().type) {
      forEach(sessions?.results, session => {
        if (session?.id) {
          dispatch(getSession(session.id))
        }
      })
      setLoading(false)
    }
  }, [status, sessions, dispatch])

  const handleTableChange = current => {
    setPagination(prevState => ({ ...prevState, current }))
  }

  const handleShowSizeChange = (_, pageSize) => {
    setPagination({ pageSize, current: 1 })
  }

  const handleSubjectFilter = subject => {
    setSubjectFilter(subject)
  }

  const getSessionAnalyses = session => {
    const { analysis_plans: analysisPlans } = study

    return filter(session.analyses, analysis =>
      some(
        analysisPlans,
        plan =>
          plan?.parameter_set?.id === analysis.parameter_set &&
          plan?.protocol?.id === analysis.protocol_info.id,
      ),
    )
  }

  const getSessionSummaryTagProps = (session, plan) => {
    const sessionAnalyses = getSessionAnalyses(session)
    const analyses = filter(
      sessionAnalyses,
      analysis =>
        analysis.parameter_set === plan?.parameter_set?.id &&
        analysis.protocol_info.id === plan?.protocol?.id,
    )
    const latestAnalysis = last(analyses)

    if (latestAnalysis) {
      if (latestAnalysis.status === 'Pending') {
        return { tag: 'Running' }
      }

      if (latestAnalysis.status === 'Complete') {
        return { tag: 'Complete', id: latestAnalysis.id }
      }

      if (latestAnalysis.status === 'Error') {
        return { tag: 'Error', id: latestAnalysis.id }
      }

      const totalAnalyses = analyses.length
      const failedAnalyses = filter(
        analyses,
        analysis => analysis.status === 'Error',
      ).length
      const hasCompletedAnalysis = some(
        analyses,
        analysis => analysis.status === 'Complete',
      )

      if (totalAnalyses >= 5 && failedAnalyses >= 5 && !hasCompletedAnalysis) {
        return { tag: 'Data Issue', id: latestAnalysis.id }
      }
    }

    if (!session.hasOwnProperty('analyses')) {
      return { tag: 'Loading ...' }
    }

    if (!session.has_series || session.has_missing_files) {
      return { tag: 'No Data' }
    }

    if (
      some(
        session.analysis_plans,
        elem =>
          elem.protocol === plan?.protocol?.id &&
          elem.modality === plan?.modality?.id &&
          elem.analysis_type === plan?.analysis_type?.id,
      )
    ) {
      return { tag: 'Set up' }
    }

    return { tag: 'No Data' }
  }

  return (
    <div data-testid="preprocessing-summary-table">
      {showTitle && (
        <div className="app-page__subheading">Preprocessing Summary</div>
      )}
      {loading && (
        <div className="summary-loader">
          <Loader />
        </div>
      )}
      <div className="summary-table">
        <table>
          <thead>
            <tr className="summary-table__analysis">
              <th colSpan={2}>Analyses</th>
              {study.analysis_plans.map(plan => (
                <th key={plan.id}>{plan.analysis_type?.label || ''}</th>
              ))}
            </tr>
            <tr className="summary-table__parameter">
              <th colSpan={2}>Parameters</th>
              {study.analysis_plans.map(plan => (
                <th key={plan.id}>{plan.parameter_set?.name}</th>
              ))}
            </tr>
            <tr className="summary-table__subject-session">
              <th style={{ position: 'relative' }}>
                Subject ({study.subject_count})
                <SubjectFilter
                  value={subjectFilter}
                  study={study}
                  onFilter={handleSubjectFilter}
                />
              </th>
              <th>Session ({study.session_count})</th>
              {study.analysis_plans.map(plan => (
                <th key={plan.id}>{plan.protocol?.full_name}</th>
              ))}
            </tr>
            <tr className="summary-table__total">
              <th colSpan={2}>Total Completed</th>
              {study.analysis_plans.map(plan => (
                <th key={plan.id}>
                  {plan.completed}/{plan.total_sessions}
                </th>
              ))}
            </tr>
          </thead>
          {!loading && (
            <tbody>
              {sessions.results &&
                map(sessions.results, session => (
                  <tr key={session.id}>
                    <td>{session.subject}</td>
                    <td>{session.folder}</td>
                    {map(study.analysis_plans, plan => (
                      <td key={plan.id}>
                        <SummaryTag
                          {...getSessionSummaryTagProps(session, plan)}
                        />
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
        </table>
      </div>
      <div className="summary-pagination">
        <Pagination
          disabled={loading}
          current={pagination.current}
          total={sessions.totalCount}
          showSizeChanger
          pageSize={pagination.pageSize}
          pageSizeOptions={['10', '20', '50', '100']}
          onChange={handleTableChange}
          onShowSizeChange={handleShowSizeChange}
        />
      </div>
    </div>
  )
}

PreprocessingSummaryTable.propTypes = {
  showTitle: PropTypes.bool,
  study: PropTypes.object,
}

PreprocessingSummaryTable.defaultProps = {
  showTitle: false,
}

export default PreprocessingSummaryTable
