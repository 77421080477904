import { Col, Row, Tabs } from 'antd'
import { chunk, filter } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const SPMGLMLevel2Result = ({ data, dataFile, token }) => {
  const matrixFigures = useMemo(
    () => filter(data.figures, figure => figure.indexOf('DesignMatrix') > 0),
    [data],
  )

  const statFigures = useMemo(
    () => filter(data.figures, figure => figure.indexOf('Stat') > 0),
    [data],
  )

  const { all_files, out_dir, save_path } = data
  const outDir = out_dir || save_path

  return (
    <div className="analysis-result" data-testid="group-spm-glm-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'output-files',
                      label: 'Files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'design-matrix',
            label: 'Design Matrix',
            children: (
              <Row>
                {matrixFigures.map(figure => (
                  <Col key={figure} md={12} style={{ marginBottom: 10 }}>
                    <h3>{figure}</h3>
                    <img
                      className="w-100"
                      alt={figure}
                      src={encodePathURL(outDir, figure, token)}
                      data-testid="maxtrix-figure"
                    />
                  </Col>
                ))}
              </Row>
            ),
          },
          {
            key: 'stats',
            label: 'Statistical Maps',
            children: (
              <>
                {chunk(
                  statFigures.map(figure => (
                    <Col key={figure} md={12} style={{ marginBottom: 10 }}>
                      <img
                        className="w-100"
                        alt={figure}
                        src={encodePathURL(outDir, figure, token)}
                        data-testid="stat-figure"
                      />
                    </Col>
                  )),
                  2,
                ).map((cols, index) => (
                  <Row key={index} gutter={5}>
                    {cols}
                  </Row>
                ))}
              </>
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

SPMGLMLevel2Result.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    figures: PropTypes.array,
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default SPMGLMLevel2Result
