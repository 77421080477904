import { Divider, TreeSelect } from 'antd'
import { filter, get, isEmpty, uniq } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { PapayaViewer } from 'components'
import { encodePathURL } from 'utils/analyses'
import FileTreeBuilder from 'utils/file-tree-builder'
import { getAuthData } from 'utils/storage'

const VolumeViewer = ({ data }) => {
  const [papayaFile, setPapayaFile] = useState(null)

  const { all_files, out_dir, save_path } = data
  const outDir = out_dir || save_path

  const niiFiles = filter(all_files, file => file.endsWith('.nii'))
  const uniqNiis = uniq(niiFiles)

  if (isEmpty(uniqNiis)) {
    return null
  }

  const builder = new FileTreeBuilder()
  const treeData = builder.run(uniqNiis)
  const authData = getAuthData()
  const token = get(authData, 'token')

  return (
    <div data-testid="volume-viewer">
      <TreeSelect
        style={{ minWidth: 600 }}
        value={papayaFile}
        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
        treeData={treeData}
        placeholder="Please select"
        treeDefaultExpandAll
        onChange={setPapayaFile}
      />

      {papayaFile && (
        <>
          <Divider />
          <PapayaViewer file={encodePathURL(outDir, papayaFile, token)} />
        </>
      )}
    </div>
  )
}

VolumeViewer.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    description: PropTypes.string,
    has_figures: PropTypes.bool,
    name: PropTypes.string,
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
  }),
}

export default VolumeViewer
