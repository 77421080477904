import { Table } from 'antd'
import { first, forEach, map, reduce } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const AnalysisResultSummaryTable = ({ content }) => {
  const columns = useMemo(
    () =>
      map(first(content), (_, ind) => ({
        title: `h${ind}`,
        dataIndex: `h${ind}`,
        key: `h${ind}`,
      })),
    [content],
  )

  const data = useMemo(() => {
    const res = []

    forEach(content, (elem, rInd) => {
      const row = reduce(
        elem,
        (rowData, column, cInd) => {
          rowData[`h${cInd}`] = column
          return rowData
        },
        { key: rInd },
      )
      res.push(row)
    })

    return res
  }, [content])

  if (!content || content.length === 0) {
    return null
  }

  return (
    <Table
      data-testid="summary-table"
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      size="small"
      bordered
    />
  )
}

AnalysisResultSummaryTable.propTypes = {
  content: PropTypes.array,
}

export default AnalysisResultSummaryTable
