const LABELS = {
  default: {
    sortTable: 'Sort Table',
    dataOrder: 'Select file by Metadata',
  },
  GICA: {
    sortTable: 'File Order by CSV GICA file',
    dataOrder: 'Select by CSV GICA File List Order',
  },
}

export default LABELS
