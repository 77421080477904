import { FormOutlined, LoginOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authApi } from 'apis'
import { LANGUAGE } from 'config/base'
import { setUser } from 'store/modules/auth'
import { parseError } from 'utils/error-parser'
import { setAuthData } from 'utils/storage'
import { validators } from 'validators'

const RegistrationForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async payload => {
    setError(null)
    setIsLoading(true)

    try {
      const data = await authApi.register(payload)
      setAuthData(data)
      dispatch(setUser(data))
    } catch (err) {
      setError(parseError(err).message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      layout="vertical"
      disabled={isLoading}
      data-testid="register-form"
      onFinish={handleSubmit}
    >
      {error && (
        <Row>
          <Col span={24}>
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
              banner
              data-testid="register-error-alert"
            />
          </Col>
        </Row>
      )}

      <Form.Item label="Name" required>
        <Row gutter={6}>
          <Col span={12}>
            <Form.Item name="first_name" rules={validators.genericRequired}>
              <Input placeholder="First name" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="last_name" rules={validators.genericRequired}>
              <Input placeholder="Last name" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>
      <Form.Item
        name="username"
        label="Username"
        rules={validators.genericRequired}
      >
        <Input placeholder="Username" />
      </Form.Item>
      <Form.Item name="email" label="Email" rules={validators.email}>
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item name="password" label="Password" rules={validators.password}>
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: LANGUAGE.password.required,
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error(LANGUAGE.password.misMatch))
            },
          }),
        ]}
      >
        <Input.Password placeholder="Confirm Password" />
      </Form.Item>
      <Form.Item>
        <Row gutter={6}>
          <Col span={12}>
            <Button
              type="primary"
              icon={<LoginOutlined />}
              htmlType="submit"
              loading={isLoading}
            >
              Register
            </Button>
          </Col>
          <Col span={12}>
            <Button icon={<FormOutlined />} onClick={() => navigate('/login')}>
              Log In
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  )
}

export default RegistrationForm
