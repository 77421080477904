import { EditOutlined } from '@ant-design/icons'
import { Button, Descriptions, notification, Tooltip } from 'antd'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { authApi } from 'apis'
import { CheckIcon, Drawer, ProfileForm } from 'components'
import { selectLoggedInUser, setUser } from 'store/modules/auth'
import { parseError } from 'utils/error-parser'
import { updateUserInAuthData } from 'utils/storage'

const { Item } = Descriptions

export const MyProfile = () => {
  const user = useSelector(selectLoggedInUser)

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const dispatch = useDispatch()

  const [showDrawer, setShowDrawer] = useState(false)

  const handleUpdateProfile = async payload => {
    setError(null)
    setIsLoading(true)

    try {
      const data = await authApi.updateProfile(payload)
      updateUserInAuthData(data)
      notification.success({
        message: 'Success!',
        description: 'Successfully updated your profile.',
      })
      setShowDrawer(false)
      dispatch(setUser(data))
    } catch (err) {
      setError(parseError(err).message)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCloseDrawer = () => {
    setShowDrawer(false)
  }

  const {
    first_name,
    last_name,
    username,
    email,
    email_verified,
    is_superuser,
    site_role,
  } = user

  return (
    <div data-testid="my-profile">
      <div className="app-page__subheading">
        Profile{' '}
        <Button
          shape="circle"
          icon={<EditOutlined />}
          type="text"
          data-testid="profile-edit-button"
          onClick={() => setShowDrawer(true)}
        />
      </div>

      <div className="w-75">
        <Descriptions bordered column={1} size="small">
          <Item label="First name">{first_name}</Item>
          <Item label="Last name">{last_name}</Item>
          <Item label="Username">{username}</Item>
          <Item label="Email">
            {email}
            <Tooltip
              placement="top"
              title={`${
                email_verified
                  ? 'Email is verified'
                  : 'Please verify your email'
              }`}
            >
              <span
                style={{ marginLeft: 5, width: 20 }}
                data-testid="email-verification-status-icon"
              >
                <CheckIcon checked={email_verified} />
              </span>
            </Tooltip>
          </Item>
          <Item label="Role">{is_superuser ? 'Super Admin' : site_role}</Item>
        </Descriptions>
      </div>

      <Drawer
        title="Update Profile"
        open={showDrawer}
        closable={!isLoading}
        onClose={handleCloseDrawer}
      >
        <ProfileForm
          user={user}
          loading={isLoading}
          error={error}
          onCancel={handleCloseDrawer}
          onSubmit={handleUpdateProfile}
        />
      </Drawer>
    </div>
  )
}

export default MyProfile
