import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Card, Modal, Table, Tooltip } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { CheckIcon, Drawer, ScannerForm } from 'components'
import { BREAKPOINTS } from 'config/base'
import { PageLayout } from 'containers/Layouts'
import { selectLoggedInUser } from 'store/modules/auth'
import {
  createScanner,
  deleteScanner,
  listScanner,
  listSite,
  selectScanners,
  selectSites,
  selectSitesStatus,
} from 'store/modules/sites'

export const ScannerListPage = ({ isDesktop, isMobile }) => {
  const scanners = useSelector(selectScanners)
  const sites = useSelector(selectSites)
  const status = useSelector(selectSitesStatus)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const [showDrawer, setShowDrawer] = useState(false)

  const handleFetchData = useCallback(
    params => {
      dispatch(listScanner({ params }))
    },
    [dispatch],
  )

  useEffect(() => {
    if (user.is_superuser) {
      dispatch(listSite())
    }

    handleFetchData({ page: 1 })
  }, [dispatch, user, handleFetchData])

  useEffect(() => {
    if (status === createScanner.fulfilled().type) {
      setShowDrawer(false)
    }
  }, [status])

  const handleDelete = useCallback(
    record => {
      if (record.datafiles_count > 0) {
        Modal.warning({
          title: (
            <>
              <div>
                This scanner cannot be deleted because it has data associated
                with it.
              </div>
              <div>
                Please delete all data associated with the scanner first.
              </div>
            </>
          ),
        })
      } else {
        Modal.confirm({
          title: `Are you sure want to delete the scanner named "${record.full_name}"?`,
          okText: 'Yes',
          cancelText: 'No',
          onOk() {
            dispatch(deleteScanner(record.id))
          },
        })
      }
    },
    [dispatch],
  )

  const columns = useMemo(() => {
    const res = [
      isDesktop && { title: 'Id', dataIndex: 'id', key: 'id' },
      {
        title: 'Name',
        dataIndex: 'full_name',
        key: 'full_name',
        render: (text, record) => (
          <Link to={`/scanner/${record.id}`}>{text}</Link>
        ),
      },
      {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
      },
      isDesktop && {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
      },
      isDesktop && {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        key: 'manufacturer',
      },
      isDesktop && {
        title: 'Station',
        dataIndex: 'station',
        key: 'station',
      },
      isDesktop && {
        title: 'Field Strength',
        dataIndex: 'field_strength',
        key: 'field_strength',
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        superUser: true,
        render: (_, record) => record.site.label,
      },
      {
        title: 'Managed',
        dataIndex: 'managed',
        key: 'managed',
        render: (_, record) => <CheckIcon checked={record.is_managed} />,
      },
      {
        title: 'Shared',
        dataIndex: 'shared',
        key: 'shared',
        render: (_, record) => (
          <CheckIcon checked={get(record, 'created_by.id') !== user.id} />
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 150,
        render: (_, record) => {
          if (record.is_managed || user.id !== get(record, 'created_by.id')) {
            return null
          }

          return (
            <div className="d-flex">
              <Tooltip title="Delete">
                <Button
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                  size="small"
                  data-testid="scanner-delete-button"
                  onClick={() => handleDelete(record)}
                />
              </Tooltip>
            </div>
          )
        },
      },
    ].filter(Boolean)

    if (user.is_superuser) {
      return res
    }

    return res.filter(column => !column.superUser)
  }, [user, isDesktop, handleDelete])

  const loading = useMemo(() => {
    if (user.is_superuser) {
      return (
        status === listSite.pending().type ||
        status === listScanner.pending().type
      )
    }

    return status === listScanner.pending().type
  }, [user, status])

  const toggleDrawer = () => setShowDrawer(prevState => !prevState)

  const handleTableChange = pagination => {
    handleFetchData({
      page: pagination.current,
      pageSize: pagination.pageSize,
    })
  }

  const handleScannerSubmit = payload => {
    const { data } = payload

    const scanner = user.is_superuser ? data : { ...data, site: user.site }
    dispatch(createScanner(scanner))
  }

  return (
    <PageLayout heading="Scanners">
      <Card>
        <div className="text-right mb-1">
          <div className="d-inline-block">
            <Button
              icon={<PlusOutlined />}
              type="primary"
              disabled={loading}
              data-testid="scanner-add-button"
              onClick={toggleDrawer}
            >
              Add New Scanner
            </Button>
          </div>
        </div>
        <Table
          dataSource={scanners.results}
          columns={columns}
          bordered
          size="small"
          rowKey="id"
          loading={loading}
          pagination={{
            size: 'large',
            current: scanners.currentPage,
            total: scanners.totalCount,
            pageSize: scanners.pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          scroll={{ x: isMobile }}
          onChange={handleTableChange}
        />
        <Drawer title="Create Scanner" open={showDrawer} onClose={toggleDrawer}>
          <ScannerForm
            sites={sites}
            submitting={status === createScanner.pending().type}
            isSuper={user.is_superuser}
            onSubmit={handleScannerSubmit}
            onCancel={toggleDrawer}
          />
        </Drawer>
      </Card>
    </PageLayout>
  )
}

ScannerListPage.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
  isMobile: width < BREAKPOINTS.SM,
})

export default withSizes(sizes)(ScannerListPage)
