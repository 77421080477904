import { PlusOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const MenuButton = ({ buttonName, buttonProps, items, placement, onClick }) => {
  const menuItems = useMemo(
    () =>
      items.map(analysisType => ({
        key: analysisType.label,
        label: analysisType.label,
        onClick,
      })),
    [items, onClick],
  )

  return (
    <Dropdown
      menu={{ items: menuItems }}
      trigger={['click']}
      placement={placement}
    >
      <Button icon={<PlusOutlined />} type="primary" {...buttonProps}>
        {buttonName}
      </Button>
    </Dropdown>
  )
}

MenuButton.propTypes = {
  buttonName: PropTypes.string,
  buttonProps: PropTypes.any,
  items: PropTypes.array,
  placement: PropTypes.string,
  onClick: PropTypes.func,
}

MenuButton.defaultProps = {
  placement: 'bottomRight',
}

export default MenuButton
