import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectLoggedIn } from 'store/modules/auth'

const UnprotectedRoute = ({ children }) => {
  const isLoggedIn = useSelector(selectLoggedIn)

  if (isLoggedIn) {
    return <Navigate to="/" replace />
  }

  return children
}

export default UnprotectedRoute
