import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { notification } from 'antd'
import update from 'immutability-helper'
import { filter, get, keys, map, mapValues } from 'lodash'
import qs from 'qs'

import { datafilesApi } from 'apis'
import { MANAGED_SOURCE } from 'config/base'
import { normalizeFileFields } from 'utils/analyses'
import { getParameterSetErrorMessage, parseError } from 'utils/error-parser'

export const listDataFile = createAsyncThunk(
  'datafiles/listDataFile',
  async (payload, { rejectWithValue }) => {
    try {
      const updatedPayload = {
        ...payload,
        paramsSerializer: params =>
          qs.stringify(params, { arrayFormat: 'repeat' }),
      }
      return await datafilesApi.listDataFile(updatedPayload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getDataFile = createAsyncThunk(
  'datafiles/getDataFile',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.getDataFile(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteDataFile = createAsyncThunk(
  'datafiles/deleteDataFile',
  async (payload, { rejectWithValue }) => {
    try {
      await datafilesApi.deleteDataFile(payload)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listDataDirectory = createAsyncThunk(
  'datafiles/listDataDirectory',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.listDataDirectory(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const runMultipleAnalyses = createAsyncThunk(
  'datafiles/runMultipleAnalyses',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.runMultipleAnalyses(payload)
      notification.success({ message: 'Started analyses successfully!' })
      return data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const runSingleAnalysis = createAsyncThunk(
  'datafiles/runSingleAnalysis',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.runSingleAnalysis(payload)
      notification.success({ message: 'Started analysis successfully!' })
      return data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const uploadFiles = createAsyncThunk(
  'datafiles/uploadFiles',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.uploadFiles(payload)
      notification.success({ message: 'Uploaded datafiles successfully!' })
      return data
    } catch (error) {
      notification.error({ message: 'Failed to upload datafiles!' })
      return rejectWithValue(parseError(error))
    }
  },
)

export const uploadMiscFiles = createAsyncThunk(
  'datafiles/uploadMiscFiles',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.uploadMiscFiles(payload)
      notification.success({
        message: 'Uploaded miscellaneous file successfully!',
      })
      return data
    } catch (error) {
      notification.error({ message: 'Failed to upload misc file!' })
      return rejectWithValue(parseError(error))
    }
  },
)

export const listProtocolData = createAsyncThunk(
  'datafiles/listProtocolData',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.listProtocolData(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listParameterSet = createAsyncThunk(
  'datafiles/listParameterSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.listParameterSet(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createParameterSet = createAsyncThunk(
  'datafiles/createParameterSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.createParameterSet(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: getParameterSetErrorMessage(err) })
      return rejectWithValue(err)
    }
  },
)

export const getParameterSet = createAsyncThunk(
  'datafiles/getParameterSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.getParameterSet(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateParameterSet = createAsyncThunk(
  'datafiles/updateParameterSet',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.updateParameterSet(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: getParameterSetErrorMessage(err) })
      return rejectWithValue(err)
    }
  },
)

export const deleteParameterSet = createAsyncThunk(
  'datafiles/deleteParameterSet',
  async (payload, { rejectWithValue }) => {
    try {
      await datafilesApi.deleteParameterSet(payload)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createAnalysisPlan = createAsyncThunk(
  'datafiles/createAnalysisPlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.createAnalysisPlan(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const updateAnalysisPlan = createAsyncThunk(
  'datafiles/updateAnalysisPlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.updateAnalysisPlan(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteAnalysisPlan = createAsyncThunk(
  'datafiles/deleteAnalysisPlan',
  async (payload, { rejectWithValue }) => {
    try {
      await datafilesApi.deleteAnalysisPlan(payload.id)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getMetadata = createAsyncThunk(
  'datafiles/getMetadata',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.readMiscFile(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listMiscFile = createAsyncThunk(
  'datafiles/listMiscFile',
  async (_, { rejectWithValue }) => {
    try {
      return await datafilesApi.listMiscFile()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getMiscFile = createAsyncThunk(
  'datafiles/getMiscFile',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.getMiscFile(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteMiscFile = createAsyncThunk(
  'datafiles/deleteMiscFile',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.deleteMiscFile(payload)
      notification.success({
        message: 'Delete miscellaneous file successfully!',
      })
      return data
    } catch (error) {
      notification.error({ message: 'Failed to delete misc file!' })
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateMiscFile = createAsyncThunk(
  'datafiles/updateMiscFile',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.updateMiscFile(payload)
      notification.success({
        message: 'Update miscellaneous file successfully!',
      })
      return data
    } catch (error) {
      notification.error({ message: 'Failed to update misc file!' })
      return rejectWithValue(parseError(error))
    }
  },
)

export const listAnalysisPlan = createAsyncThunk(
  'datafiles/listAnalysisPlan',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.listAnalysisPlan(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createAnalysisPlanStep = createAsyncThunk(
  'datafiles/createAnalysisPlanStep',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.createAnalysisPlanStep(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateAnalysisPlanStep = createAsyncThunk(
  'datafiles/updateAnalysisPlanStep',
  async (payload, { rejectWithValue }) => {
    try {
      return await datafilesApi.updateAnalysisPlanStep(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteAnalysisPlanStep = createAsyncThunk(
  'datafiles/deleteAnalysisPlanStep',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await datafilesApi.deleteAnalysisPlanStep(payload)
      notification.success({
        message: 'Delete analysis plan step successfully!',
      })
      return data
    } catch (error) {
      notification.error({ message: 'Failed to delete analysis plan step!' })
      return rejectWithValue(parseError(error))
    }
  },
)

const initialState = {
  allFiles: [],
  allMiscFiles: [],
  analysisPlans: [],
  miscFile: null,
  dataFile: null,
  currentFiles: [
    {
      id: undefined,
      object: undefined,
      fields: {},
      options: {
        splitFile: false,
        splitType: undefined,
      },
    },
  ],
  dataDirectory: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  parameterSets: [],
  parameterSet: null,
  protocolData: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  analysisLocation: null,
  analysisPlanStep: null,
  metadata: null,
  tags: [],
  status: 'INIT',
  error: null,
}

const dafafilesSlice = createSlice({
  name: 'datafiles',
  initialState,
  reducers: {
    assignTags: (state, { payload }) => {
      const payloadKeys = keys(payload)

      if (payloadKeys.includes('subject') || payloadKeys.includes('session')) {
        const key = payloadKeys.includes('subject') ? 'subject' : 'session'
        const info = `${key}_info`
        state.dataDirectory.results = state.dataDirectory.results.map(
          datafile =>
            datafile[info].id === payload[key]
              ? {
                  ...datafile,
                  [info]: {
                    ...datafile[info],
                    tags: payload.tags,
                  },
                }
              : datafile,
        )
      }
    },
    clearProtocolData: (state, { type }) => {
      state.protocolData = initialState.protocolData
      state.status = type
    },
    deleteAnalysis: (state, { payload }) => {
      state.dataDirectory.results = state.dataDirectory.results.map(study => ({
        ...study,
        analyses: study.analyses.filter(analysis => analysis.id !== payload),
      }))
    },
    setCurrentFiles: (state, { payload, type }) => {
      state.currentFiles = map(payload, normalizeFileFields)
      state.status = type
    },
    setAllFiles: (state, { payload, type }) => {
      state.allFiles = map(payload, normalizeFileFields)
      state.status = type
    },
    toggleAllCurrentFilesField: (state, { payload, type }) => {
      state.currentFiles = update(state, {
        currentFiles: [
          {
            fields: {
              $apply: fields =>
                mapValues(fields, field =>
                  update(field, {
                    selected: { $set: payload },
                  }),
                ),
            },
          },
        ],
      }).currentFiles
      state.status = type
    },
    updateCurrentFilesField: (state, { payload, type }) => {
      state.currentFiles = update(state, {
        currentFiles: [
          {
            fields: {
              $apply: fields =>
                mapValues(fields, field =>
                  field.index === payload.index
                    ? update(field, { $merge: payload.field })
                    : field,
                ),
            },
          },
        ],
      }).currentFiles
      state.status = type
    },
    updateCurrentFileFields: (state, { payload, type }) => {
      state.currentFiles = update(state, {
        currentFiles: {
          $apply: files =>
            map(files, file =>
              file.id === payload.file
                ? update(file, { fields: { $set: payload.fields } })
                : file,
            ),
        },
      }).currentFiles
      state.status = type
    },
    initializeCurrentFiles: (state, { type }) => {
      state.currentFiles = [
        {
          id: undefined,
          object: undefined,
          fields: {},
          options: {
            splitFile: false,
            splitType: undefined,
          },
        },
      ]
      state.status = type
    },
    setAnalysisLocation: (state, { payload, type }) => {
      state.analysisLocation = payload
      state.status = type
    },
  },
  extraReducers: builder => {
    builder.addCase(listDataFile.fulfilled, (state, { payload, type }) => {
      const managedFiles = filter(state.allFiles, { source: MANAGED_SOURCE })
      const newUploadedFiles = map(payload, normalizeFileFields)
      state.allFiles = [...managedFiles, ...newUploadedFiles]
      state.status = type
    })
    builder.addCase(getDataFile.fulfilled, (state, { payload, type }) => {
      state.dataFile = payload
      state.status = type
    })
    builder.addCase(deleteDataFile.fulfilled, (state, { payload, type }) => {
      state.allFiles = state.allFiles.filter(file => file.id !== payload)
      state.status = type
    })
    builder.addCase(listDataDirectory.fulfilled, (state, { payload, type }) => {
      state.dataDirectory = payload
      state.status = type
    })
    builder.addCase(
      runMultipleAnalyses.fulfilled,
      (state, { payload, type }) => {
        state.dataDirectory.results = state.dataDirectory.results.map(result =>
          result.id !== payload.id ? result : payload,
        )
        state.status = type
      },
    )
    builder.addCase(runSingleAnalysis.fulfilled, (state, { payload, type }) => {
      state.dataDirectory.results = state.dataDirectory.results.map(result =>
        result.id !== payload.id ? result : payload,
      )
      state.status = type
    })
    builder.addCase(uploadFiles.fulfilled, (state, { payload, type }) => {
      const uploadedFiles = map(payload, file => normalizeFileFields(file))
      state.allFiles = [...state.allFiles, ...uploadedFiles]
      state.currentFiles = uploadedFiles
      state.status = type
    })
    builder.addCase(uploadMiscFiles.fulfilled, (state, { payload, type }) => {
      const uploadedFiles = map(payload, file => normalizeFileFields(file))
      state.allFiles = [...state.allFiles, ...uploadedFiles]
      state.currentFiles = uploadedFiles
      state.status = type
    })
    builder.addCase(listProtocolData.fulfilled, (state, { payload, type }) => {
      state.protocolData = payload
      state.status = type
    })
    builder.addCase(listParameterSet.fulfilled, (state, { payload, type }) => {
      state.parameterSets = payload
      state.status = type
    })
    builder.addCase(getParameterSet.fulfilled, (state, { payload, type }) => {
      state.parameterSet = payload
      state.status = type
    })
    builder.addCase(
      createParameterSet.fulfilled,
      (state, { payload, type }) => {
        state.parameterSets = [payload, ...state.parameterSets]
        state.status = type
      },
    )
    builder.addCase(
      updateParameterSet.fulfilled,
      (state, { payload, type }) => {
        state.parameterSet = payload
        state.parameterSets = state.parameterSets.map(parameterSet =>
          parameterSet.id === payload.id ? payload : parameterSet,
        )
        state.status = type
      },
    )
    builder.addCase(
      deleteParameterSet.fulfilled,
      (state, { payload, type }) => {
        state.parameterSets = state.parameterSets.filter(
          ps => ps.id !== payload,
        )
        state.status = type
      },
    )
    builder.addCase(getMetadata.fulfilled, (state, { payload, type }) => {
      state.metadata = payload
      state.status = type
    })
    builder.addCase(listMiscFile.fulfilled, (state, { payload, type }) => {
      state.allMiscFiles = payload
      state.status = type
    })
    builder.addCase(getMiscFile.fulfilled, (state, { payload, type }) => {
      state.miscFile = payload
      state.status = type
    })
    builder.addCase(deleteMiscFile.fulfilled, (state, { payload, type }) => {
      state.allMiscFiles = state.allMiscFiles.filter(
        file => file.id !== payload,
      )
      state.status = type
    })
    builder.addCase(updateMiscFile.fulfilled, (state, { payload, type }) => {
      state.miscFile = payload
      state.status = type
    })
    builder.addCase(
      createAnalysisPlan.fulfilled,
      (state, { payload, type }) => {
        state.dataDirectory.results = state.dataDirectory.results.map(study =>
          get(study, 'series.protocol.id') === payload.protocol
            ? {
                ...study,
                plans: [...study.plans, payload],
              }
            : study,
        )
        state.protocolData.results = state.protocolData.results.map(protocol =>
          protocol.id === payload.protocol
            ? {
                ...protocol,
                plans: [...protocol.plans, payload],
              }
            : protocol,
        )
        state.status = type
      },
    )
    builder.addCase(
      updateAnalysisPlan.fulfilled,
      (state, { payload, type }) => {
        state.dataDirectory.results = state.dataDirectory.results.map(study =>
          get(study, 'series.protocol.id') === payload.protocol
            ? {
                ...study,
                plans: study.plans.map(plan =>
                  plan.id === payload.id ? payload : plan,
                ),
              }
            : study,
        )
        state.protocolData.results = state.protocolData.results.map(protocol =>
          protocol.id === payload.protocol
            ? {
                ...protocol,
                plans: protocol.plans.map(plan =>
                  plan.id === payload.id ? payload : plan,
                ),
              }
            : protocol,
        )
        state.status = type
      },
    )
    builder.addCase(
      deleteAnalysisPlan.fulfilled,
      (state, { payload, type }) => {
        state.dataDirectory.results = state.dataDirectory.results.map(study =>
          get(study, 'series.protocol.id') === payload.protocol
            ? {
                ...study,
                plans: study.plans.filter(plan => plan.id !== payload.id),
              }
            : study,
        )
        state.protocolData.results = state.protocolData.results.map(protocol =>
          protocol.id === payload.protocol
            ? {
                ...protocol,
                plans: protocol.plans.filter(plan => plan.id !== payload.id),
              }
            : protocol,
        )
        state.status = type
      },
    )
    builder.addCase(listAnalysisPlan.fulfilled, (state, { payload, type }) => {
      state.analysisPlans = payload
      state.status = type
    })
    builder.addCase(
      createAnalysisPlanStep.fulfilled,
      (state, { payload, type }) => {
        state.analysisPlanStep = payload
        state.status = type
      },
    )
    builder.addCase(
      updateAnalysisPlanStep.fulfilled,
      (state, { payload, type }) => {
        state.analysisPlanStep = payload
        state.status = type
      },
    )
    builder.addCase(deleteAnalysisPlanStep.fulfilled, (state, { type }) => {
      state.analysisPlanStep = null
      state.status = type
    })
    builder.addMatcher(isPending, (state, { type }) => {
      state.error = null
      state.status = type
    })
    builder.addMatcher(isRejected, (state, { payload, type }) => {
      state.error = payload.message
      state.status = type
    })
  },
})

export const {
  assignTags,
  clearProtocolData,
  deleteAnalysis,
  setCurrentFiles,
  setAllFiles,
  toggleAllCurrentFilesField,
  updateCurrentFilesField,
  updateCurrentFileFields,
  initializeCurrentFiles,
  setAnalysisLocation,
} = dafafilesSlice.actions

export const { reducer } = dafafilesSlice
