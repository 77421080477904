import { Drawer as AntDrawer } from 'antd'
import PropTypes from 'prop-types'
import { withSizes } from 'react-sizes'

import { BREAKPOINTS } from 'config/base'

const Drawer = ({ isMobile, children, ...props }) => (
  <AntDrawer width={isMobile ? '100%' : 500} destroyOnClose {...props}>
    <div data-testid={isMobile ? 'mobile-drawer' : 'desktop-drawer'}>
      {children}
    </div>
  </AntDrawer>
)

const sizes = ({ width }) => ({
  isMobile: width < BREAKPOINTS.LG,
})

Drawer.propTypes = {
  isMobile: PropTypes.bool,
}

export default withSizes(sizes)(Drawer)
