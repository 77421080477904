import { Col, Divider, Form, InputNumber, Radio, Row } from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from 'components'
import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('ASL')

const ASLOptionEditor = ({ analysisOptions, readOnly, setAnalysisOption }) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderInputNumber = (fieldLabel, fieldName, precision = 2) => (
    <Form.Item {...formLayout} labelAlign="left" label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        precision={precision}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const renderSwitch = (fieldLabel, fieldName) => (
    <Form.Item {...formLayout} labelAlign="left" label={fieldLabel}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        <Radio value={false}>No</Radio>
        <Radio value={true}>Yes</Radio>
      </Radio.Group>
    </Form.Item>
  )

  const renderSwitchCalibrationMethod = (fieldLabel, fieldName) => (
    <Form.Item labelAlign="left" label={fieldLabel}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        <Radio value={'single'}>Single</Radio>
        <Radio value={'voxel'}>Voxelwise</Radio>
      </Radio.Group>
    </Form.Item>
  )

  const renderSelect = (fieldLabel, fieldName, fieldOptions) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <Select
        name={fieldName}
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        options={fieldOptions.map(option => ({ label: option, value: option }))}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const CalibrationAcquired = get(analysisOptions, 'Calib_Acq.value')
  const CalibrationPartOfTheASL = get(analysisOptions, 'Calib_notSep.value')

  return (
    <Row gutter={24} data-testid="asl-option-editor">
      <Col md={24}>
        <Divider>Acquisition Parameter Set</Divider>
        {renderSelect('Type of ASL data: ', 'ASL_Type', [
          'pASL',
          'cASL',
          'pcASL',
        ])}
        {renderSelect('How were label/control volumes acquired: ', 'IAF', [
          'tc',
          'ct',
          'diff',
        ])}
        {renderSelect('Sequence of label/control volumes: ', 'IBF', [
          'rpt',
          'tis',
        ])}
        <Form.Item {...formLayout} labelAlign="left" label="Inversion Time(s)">
          <InputNumber
            value={get(analysisOptions, ['TIs', 'value'])}
            disabled={readOnly}
            precision="2"
            onChange={value => handleSetOption('TIs', 'value', [value])}
          />
        </Form.Item>
        {renderInputNumber('Bolus Duration: ', 'Bolus_Duration')}
        {renderSwitch('Was bolus duration fixed?: ', 'Fixbolus')}

        <Divider>Calibration Parameters</Divider>
        {renderSwitch('Was Calibration Image Acquired?: ', 'Calib_Acq')}
        {CalibrationAcquired === false &&
          renderInputNumber('Calibration Volume Index: ', 'Calib_Num', 0)}
        {CalibrationAcquired &&
          renderSwitch(
            'Was the calibration image part of the ASL sequence?:',
            'Calib_notSep',
          )}
        {CalibrationAcquired &&
          CalibrationPartOfTheASL &&
          renderInputNumber('Calibration Volume Index: ', 'Calib_Num', 0)}
        {CalibrationAcquired &&
          CalibrationPartOfTheASL &&
          renderInputNumber(
            'Indicate Volume Index of the first ASL volume : ',
            'Calib_Offset',
            0,
          )}

        <Divider>Analysis Parameters</Divider>
        {renderSwitchCalibrationMethod('Calibration method', 'Calib_Method')}
        {renderSwitch('White Paper Optimal settings to be applied:', 'WP')}
        {renderSwitch('Arterial blood signal modeling:', 'Artsoff')}
        {renderSwitch('Apply Motion Correction:', 'amc')}
        {renderSwitch('Spatial Smoothing:', 'Spatial_Flag')}
        {renderSwitch('Bias Field Correction:', 'Senscorr')}
      </Col>
    </Row>
  )
}

ASLOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

ASLOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default ASLOptionEditor
