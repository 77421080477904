import { CheckOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table } from 'antd'
import moment from 'moment'
import { useEffect, useState } from 'react'

import { authApi } from 'apis'

export const MyInvite = () => {
  const [invites, setInvites] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const handleGetInvites = async () => {
    setIsLoading(true)

    try {
      const data = await authApi.listMyInvite()
      setInvites(data)
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    handleGetInvites()
  }, [])

  const handleAcceptInvite = async inviteId => {
    setIsLoading(true)

    try {
      await authApi.acceptMyInvite(inviteId)
      setInvites(prevState =>
        prevState.filter(invite => invite.id !== inviteId),
      )
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const handleRejectInvite = async inviteId => {
    setIsLoading(true)

    try {
      await authApi.rejectMyInvite(inviteId)
      setInvites(prevState =>
        prevState.filter(invite => invite.id !== inviteId),
      )
      setIsLoading(false)
    } catch {
      setIsLoading(false)
    }
  }

  const columns = [
    {
      title: 'Site',
      dataIndex: 'site',
      key: 'site',
      render: (_, record) => record.site.full_name,
    },
    {
      title: 'Sent Date',
      dataIndex: 'sent_date',
      key: 'sent_date',
      render: (_, record) =>
        moment(record.sent_date).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <div className="d-flex">
          <Button
            className="mr-05"
            icon={<CheckOutlined />}
            shape="circle"
            size="small"
            data-testid="accept-invite-button"
            onClick={() => handleShowAcceptInviteConfirm(record)}
          />
          <Button
            icon={<DeleteOutlined />}
            shape="circle"
            size="small"
            danger
            data-testid="reject-invite-button"
            onClick={() => handleShowRejectInviteConfirm(record)}
          />
        </div>
      ),
    },
  ]

  const handleShowAcceptInviteConfirm = record => {
    Modal.confirm({
      title: `Are you sure you want to join the site "${record.site.full_name}"? You'll be removed from your current site.`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleAcceptInvite(record.id)
      },
    })
  }

  const handleShowRejectInviteConfirm = record => {
    Modal.confirm({
      title: 'Are you sure to reject this invite?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleRejectInvite(record.id)
      },
    })
  }

  return (
    <div data-testid="my-invite">
      <div className="app-page__subheading">Invites</div>
      {invites.length > 0 ? (
        <Table
          dataSource={invites}
          columns={columns}
          loading={isLoading}
          rowKey="id"
          size="small"
          bordered
          pagination={false}
        />
      ) : (
        <Empty description="No Invites" />
      )}
    </div>
  )
}

export default MyInvite
