import { Card, Tabs } from 'antd'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader } from 'components'
import { PageLayout } from 'containers/Layouts'
import { selectAnalysisTypes } from 'store/modules/analyses'
import {
  getProfile,
  listUser,
  selectAuthStatus,
  selectLoggedInUser,
  selectUsers,
} from 'store/modules/auth'
import {
  getParameterSet,
  selectDataFilesStatus,
  selectParameterSet,
} from 'store/modules/datafiles'
import { isSharedData } from 'utils/common'

import ParameterSetInfo from './ParameterSetInfo'
import SharedUsers from './SharedUsers'

export const ParameterSetDetailPage = () => {
  const analysisTypes = useSelector(selectAnalysisTypes)
  const authStatus = useSelector(selectAuthStatus)
  const parameterSet = useSelector(selectParameterSet)
  const status = useSelector(selectDataFilesStatus)
  const user = useSelector(selectLoggedInUser)
  const users = useSelector(selectUsers)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(listUser())
    dispatch(getParameterSet(params.parameterSetId))
    dispatch(getProfile())
  }, [dispatch, params])

  const preparing = useMemo(
    () =>
      status === getParameterSet.pending().type ||
      [listUser.pending().type, getProfile.pending().type].indexOf(
        authStatus,
      ) !== -1,
    [status, authStatus],
  )

  if (preparing) {
    return <Loader />
  }

  if (!parameterSet) {
    return null
  }

  const subProps = {
    parameterSet,
    user,
    users,
    analysisTypes,
    status,
    editable:
      user.is_superuser || !isSharedData(parameterSet.shared_users, user.id),
  }

  return (
    <PageLayout heading={parameterSet.name}>
      <Card>
        <Tabs
          animated={false}
          items={[
            {
              key: 'info',
              label: 'Parameter Set Info',
              children: <ParameterSetInfo {...subProps} />,
            },
            {
              key: 'shared-users',
              label: 'Shared Users',
              children: <SharedUsers {...subProps} />,
            },
          ]}
        />
      </Card>
    </PageLayout>
  )
}

export default ParameterSetDetailPage
