import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const CheckIcon = ({ checked }) =>
  checked ? (
    <CheckCircleOutlined style={{ color: '#52c41a' }} />
  ) : (
    <CloseCircleOutlined style={{ color: '#f5222d' }} />
  )

CheckIcon.propTypes = {
  checked: PropTypes.bool,
}

export default CheckIcon
