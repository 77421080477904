import { Card } from 'antd'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader, Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  deleteAnalysis,
  getAnalysis,
  selectAnalysesStatus,
  selectAnalysis,
  selectAnalysisTypes,
  updateAnalysis,
} from 'store/modules/analyses'
import {
  listUser,
  selectAuthStatus,
  selectLoggedInUser,
  selectUsers,
} from 'store/modules/auth'
import { isSharedData } from 'utils/common'

import AnalysisInfo from './AnalysisInfo'
import SharedUsers from './SharedUsers'

export const AnalysisDetailPage = () => {
  const analysis = useSelector(selectAnalysis)
  const analysisTypes = useSelector(selectAnalysisTypes)
  const authStatus = useSelector(selectAuthStatus)
  const status = useSelector(selectAnalysesStatus)
  const user = useSelector(selectLoggedInUser)
  const users = useSelector(selectUsers)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getAnalysis(params.analysisId))
    dispatch(listUser())
  }, [dispatch, params])

  const preparingData = useMemo(
    () =>
      status === getAnalysis.pending().type ||
      authStatus === listUser.pending().type,
    [status, authStatus],
  )

  if (preparingData) {
    return <Loader />
  }

  if (!analysis) {
    return null
  }

  const editable =
    user.is_superuser || !isSharedData(analysis.shared_users, user.id)

  return (
    <PageLayout heading={analysis.name}>
      <Card>
        <Tabs
          items={[
            {
              key: 'info',
              label: 'Analysis Info',
              children: (
                <AnalysisInfo
                  analysis={analysis}
                  analysisTypes={analysisTypes}
                  status={status}
                  deleteAnalysis={values => dispatch(deleteAnalysis(values))}
                />
              ),
            },
            {
              key: 'shared-users',
              label: 'Shared Users',
              children: (
                <SharedUsers
                  analysis={analysis}
                  updateAnalysis={values => dispatch(updateAnalysis(values))}
                  users={users}
                  user={user}
                  editable={editable}
                  status={status}
                />
              ),
            },
          ]}
        />
      </Card>
    </PageLayout>
  )
}

export default AnalysisDetailPage
