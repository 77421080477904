import { Tabs as AntTabs } from 'antd'
import PropTypes from 'prop-types'
import { withSizes } from 'react-sizes'

import { BREAKPOINTS } from 'config/base'

export const ResponsiveTabs = ({ isMobile, ...props }) => (
  <AntTabs
    tabPosition={isMobile ? 'top' : 'left'}
    animated={false}
    data-testid={isMobile ? 'tabs-mobile' : 'tabs-desktop'}
    {...props}
  />
)

const sizes = ({ width }) => ({
  isMobile: width < BREAKPOINTS.LG,
})

ResponsiveTabs.propTypes = {
  isMobile: PropTypes.bool,
}

export default withSizes(sizes)(ResponsiveTabs)
