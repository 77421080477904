import { Button, Spin, Switch, Tooltip, TreeSelect } from 'antd'
import axios from 'axios'
import {
  debounce,
  filter,
  find,
  findIndex,
  first,
  get,
  has,
  isArray,
  isEmpty,
  isInteger,
  last,
  map,
  uniq,
  uniqBy,
  values,
} from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { unstable_batchedUpdates } from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'

import { datafilesApi } from 'apis'
import { ANALYSIS_ALLOWED_FILE_TYPES, MAX_SELECT_FILES } from 'config/base'
import useSort from 'hooks/useSort'
import { selectAnalysis } from 'store/modules/analyses'
import { setAllFiles, setCurrentFiles } from 'store/modules/datafiles'
import { arrayMove } from 'utils/common'

import LABELS from './labels'
import SortTable from './SortTable'

const LOAD_MAP = {
  init: 'site',
  site: 'pi',
  pi: 'study',
  study: 'subject',
  subject: 'session',
  session: 'series',
  series: 'datafile',
}
const LABEL_MAP = {
  site: 'full_name',
  pi: 'username',
  study: 'full_name',
  subject: 'anon_id',
  session: 'segment_interval',
  series: 'label',
  datafile: 'name',
}
const DATAFILE_PARENTS = [
  'site_info',
  'pi_info',
  'study_info',
  'subject_info',
  'session_info',
  'series_info',
]

export const DataFileTree = ({
  analysisType,
  className,
  dataOrder,
  debounceDelay,
  disabled,
  initialValue,
  multiple,
  name,
  pattern,
  sortingInfo,
  onChange,
  onSelectedFiles,
  onUpdateFields,
}) => {
  const analysis = useSelector(selectAnalysis)

  const dispatch = useDispatch()

  let axiosCancelSource

  const [treeData, setTreeData] = useState([])
  const [expandedKeys, setExpandedKeys] = useState([])
  const [selectedKeys, setSelectedKeys] = useState(multiple ? [] : null)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [tableSwitched, setTableSwitched] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    pageSize: 10,
    totalCount: 0,
    page: 1,
  })
  const [label, setLabel] = useState(LABELS.default)

  const popupClassName = `datafile-dropdown-${name}`
  const querySelector = document.querySelector(`.${popupClassName}`)

  const isSearching = !isEmpty(searchValue)
  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((value, page) => handleSearch(value, page), debounceDelay),
    [expandedKeys],
  )

  const {
    icon,
    tooltip,
    sortedData: sortedTreeData,
    buttonType,
    onChangeSort,
  } = useSort({ data: treeData, sortingInfo })

  useEffect(() => {
    axiosCancelSource = axios.CancelToken.source() // eslint-disable-line react-hooks/exhaustive-deps
  })

  useEffect(() => {
    if (!isEmpty(searchValue)) {
      debouncedSearch(searchValue, 1)
    }
  }, [searchValue, debouncedSearch])

  useEffect(() => {
    const shouldLoadInitValue = isEmpty(selectedKeys) && isEmpty(selectedFiles)

    setInitialValue(shouldLoadInitValue ? initialValue : [])
  }, [initialValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    querySelector && querySelector.addEventListener('scroll', handleScroll)

    return () => {
      querySelector && querySelector.removeEventListener('scroll', handleScroll)
    }
  })

  useEffect(() => {
    if (dataOrder) {
      const treeDataSort = sortData(treeData)
      setTreeData(treeDataSort)
      setInitialValue(map(dataOrder, '_df'))
    }
  }, [dataOrder]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const key = analysisType.label in LABELS ? analysisType.label : 'default'
    setLabel(LABELS[key])
  }, [analysisType])

  const setInitialValue = async newInitialValue => {
    let inputFiles

    if (isInteger(newInitialValue)) {
      // Scenario 1: if a datafile ID is given. Query this value.
      const data = await getDataFile({ id: newInitialValue })
      inputFiles = data.results
    } else if (isArray(newInitialValue)) {
      // Scenario 2: if an array is given. This is a list of multiple file inputs.
      inputFiles = newInitialValue
    } else {
      // Scenario 3: object input file is given.
      inputFiles = [newInitialValue]
    }

    inputFiles = filter(inputFiles)

    if (isEmpty(inputFiles)) {
      loadData({ props: { field: 'init' } })
    } else {
      const createdNodes = createDataFileNodes(inputFiles)
      const newSelectedKeys = multiple
        ? map(createdNodes, 'id')
        : map(filter(createdNodes, 'isLeaf'), 'id')

      handleChange(inputFiles)
      unstable_batchedUpdates(() => {
        setSelectedKeys(newSelectedKeys)
        setSelectedFiles(inputFiles)
      })

      if (get(analysis, 'parameters.file.fields') && onUpdateFields) {
        const inputFile = first(inputFiles)
        onUpdateFields({
          file: inputFile.id,
          fields: analysis.parameters.file.fields,
        })
      }
    }
  }

  const getDataFile = params =>
    datafilesApi.listDataFile({
      params,
      cancelToken: axiosCancelSource.token,
      paramsSerializer: data =>
        queryString.stringify(data, { arrayFormat: 'repeat' }),
    })

  const getAdditionalFilters = () => {
    if (!analysisType) {
      return {}
    }

    const allowedFileTypes =
      ANALYSIS_ALLOWED_FILE_TYPES[analysisType.name] || []

    return { files: allowedFileTypes }
  }

  const getFieldToLoad = nodeProps => {
    const { field: currentField } = nodeProps
    return LOAD_MAP[currentField]
  }

  const getAPIUrl = nodeProps => {
    const fieldToLoad = getFieldToLoad(nodeProps)
    const API_URL = `/run-analysis-data/${fieldToLoad}/`

    if (fieldToLoad === 'site') {
      return API_URL
    }

    const additionalFilters =
      fieldToLoad === 'datafile' ? getAdditionalFilters() : {}
    const queryParam = getQueryParam(nodeProps, additionalFilters)
    return `${API_URL}?${queryString.stringify(queryParam)}`
  }

  const getQueryParam = (nodeProps, queryParam) => {
    const [field, id] = nodeProps.value.split(';')
    queryParam[field] = id

    if (field === 'site') {
      return queryParam
    }
    const parentNode = find(treeData, { id: nodeProps.pId })

    return parentNode ? getQueryParam(parentNode, queryParam) : queryParam
  }

  const sortBy = tData =>
    tData.sort((a, b) => {
      if (a.field === b.field) {
        const aIndex = findIndex(dataOrder, { [a.field]: a.title })
        const bIndex = findIndex(dataOrder, { [b.field]: b.title })

        if (aIndex > -1 || bIndex > -1) return aIndex - bIndex
      }

      // Keep the same order if unspecified.
      const aCurrentIndex = findIndex(tData, { id: a.id })
      const bCurrentIndex = findIndex(tData, { id: b.id })
      return aCurrentIndex - bCurrentIndex
    })

  const sortData = tData => {
    if (dataOrder) {
      return sortBy(tData)
    }
    // Default sorting if no dataOrder is specified.
    return tData.sort((a, b) => {
      // Sort study according to hrrc_num.
      if (a.field === 'study' && b.field === 'study') {
        const aHrrc = last(a.title.split('_'))
        const bHrrc = last(b.title.split('_'))

        if (isInteger(aHrrc) & isInteger(bHrrc)) {
          return parseInt(aHrrc) - parseInt(bHrrc)
        }
      }

      // Keep the same order if unspecified.
      const aCurrentIndex = findIndex(tData, { id: a.id })
      const bCurrentIndex = findIndex(tData, { id: b.id })
      return aCurrentIndex - bCurrentIndex
    })
  }

  const loadData = async ({ props: nodeProps }) => {
    const url = getAPIUrl(nodeProps)

    try {
      let { data } = await axios.get(url, {
        cancelToken: axiosCancelSource.token,
      })

      if (pattern && nodeProps.field === 'series' && analysisType) {
        data = data.filter(elem => pattern.test(elem.name))
      }

      parseResponse(data, nodeProps)
    } catch {} // eslint-disable-line
  }

  const parseResponse = (data, nodeProps) => {
    const fieldToLoad = getFieldToLoad(nodeProps)
    const parentId = get(nodeProps, 'id', 0)
    const newTreeData = [...treeData]

    data.forEach(elem => {
      if (
        !find(newTreeData, {
          pId: parentId,
          value: `${fieldToLoad};${elem.id}`,
        })
      ) {
        const isLeaf = fieldToLoad === last(values(LOAD_MAP))

        if (isLeaf) {
          elem.files.forEach(file => {
            const fileValue = {
              id: `${elem.id};${file}`,
              name: file,
              datafile: elem,
            }
            createNode(newTreeData, fieldToLoad, fileValue, parentId)
          })
        } else {
          createNode(newTreeData, fieldToLoad, elem, parentId)
        }
      }
    })

    const treeDataSort = isSearching ? newTreeData : sortData(newTreeData)
    setTreeData(treeDataSort)
  }

  const createNode = (tData, fieldToLoad, elem, parentId) => {
    if (!elem) return null
    if (fieldToLoad === 'datafile' && elem.name.includes('.json')) return null

    const elemId = `${fieldToLoad};${elem.id}`
    const existedNode = find(tData, { id: elemId })

    if (existedNode) return { node: existedNode, created: false }
    const isLeaf = fieldToLoad === last(values(LOAD_MAP))

    const title = has(elem, 'sort_order')
      ? `${elem.name}_${get(elem, 'sort_order')}`
      : elem[LABEL_MAP[fieldToLoad]] || elem.label

    const node = {
      id: elemId,
      title,
      pId: parentId,
      value: elem.value || elemId,
      field: fieldToLoad,
      isLeaf,
      elem: isLeaf ? elem.datafile : elem,
    }

    tData.push(node)

    return { node, created: true }
  }

  const handleScroll = e => {
    const contentHeight = e.target.scrollHeight - e.target.offsetHeight

    if (contentHeight <= e.target.scrollTop) {
      handleLoadMore()
    }
  }

  const createDataFileNodes = datafiles => {
    const nodes = [...treeData]
    const newNodes = []

    datafiles.forEach(elem => {
      let parentNode = { id: 0 }

      DATAFILE_PARENTS.forEach(fieldName => {
        const fieldToLoad = fieldName.replace('_info', '')
        const newNode = createNode(
          nodes,
          fieldToLoad,
          elem[fieldName],
          parentNode.id,
        )

        if (newNode) {
          parentNode = newNode.node
          newNodes.push(parentNode)
        }
      })

      elem.files &&
        elem.files.forEach(file => {
          const fileValue = {
            id: `${elem.id};${file}`,
            name: file,
            datafile: elem,
          }
          const newNode = createNode(
            nodes,
            'datafile',
            fileValue,
            parentNode.id,
          )

          newNode && newNodes.push(newNode.node)
        })
    })

    const mapNewNodes = map(uniq(newNodes), 'id')

    unstable_batchedUpdates(() => {
      setTreeData(nodes)
      setExpandedKeys(uniq(expandedKeys.concat(mapNewNodes)))
    })

    return newNodes
  }

  const handleLoadMore = () => {
    const { page, totalCount, pageSize } = pagination

    // Calculate if there is still more data to load the next page.
    const currentCount = page * pageSize
    if (currentCount < totalCount) {
      debouncedSearch(searchValue, page + 1)
    }
  }

  const handleRowMove = (oldIndex, newIndex) => {
    const sortSelectedFiles = arrayMove(selectedFiles, oldIndex, newIndex)
    const sortOrder = map(sortSelectedFiles, 'subject_info.anon_id')
    const sortTreeData = sortBy(treeData, sortOrder)

    setTreeData(sortTreeData)
    setSelectedFiles([...sortSelectedFiles])
    handleChange(sortSelectedFiles)
  }

  const handleNodeSelect = (_, node) => {
    const nextExpandedKeys = node.props.expanded
      ? expandedKeys.filter(key => key !== node.props.eventKey)
      : expandedKeys.concat(node.props.eventKey)

    setExpandedKeys(uniq([...expandedKeys, ...nextExpandedKeys]))
  }

  const handleTreeChange = (value, _, extra) => {
    let newSelectedKeys = multiple ? value : [value]
    const node = extra.triggerNode

    if (isSearching) {
      newSelectedKeys = filter(newSelectedKeys, key =>
        key.toLowerCase().includes(searchValue.toLowerCase()),
      )
    }

    setSelectedKeys(newSelectedKeys)

    const selectedNodes = map(newSelectedKeys, id => find(treeData, { id }))
    const isParentSelected =
      !isEmpty(newSelectedKeys) && !get(node, 'props.isLeaf')

    if (isParentSelected && !isSearching) {
      if (extra.triggerValue) {
        // Scenario 1: select parent & no search => find, select and expand files tree.
        const [field, id] = extra.triggerValue.split(';')

        // Get filter requirements from analysis type.
        const analysisTypeFilter = getAdditionalFilters()

        getDataFile({
          ...analysisTypeFilter,
          [field]: id,
          pageSize: MAX_SELECT_FILES,
        }).then(data => {
          if (!isEmpty(get(data, 'results'))) {
            const newSelectedNodes = createDataFileNodes(get(data, 'results'))
            const allSelectedNodes = uniqBy(
              [...selectedNodes, ...newSelectedNodes],
              'id',
            )
            const allSelectedLeafNodes = filter(allSelectedNodes, 'isLeaf')
            const newSelectedFiles = map(allSelectedLeafNodes, 'elem')

            handleChange(newSelectedFiles)
            setSelectedFiles(newSelectedFiles)
          }
        })
      } else {
        setSelectedKeys([])
        handleChange([])
      }
    } else {
      // Scenario 2: select leaf nodes or select during search => only select searched files.
      const selectedLeafNodes = filter(selectedNodes, 'isLeaf')
      const newSelectedFiles = map(selectedLeafNodes, 'elem')

      handleChange(newSelectedFiles)
      setSelectedFiles(newSelectedFiles)
    }
  }

  const handleSearch = async (value, page = 1) => {
    setLoading(true)

    if (isEmpty(value)) {
      loadData({ props: { field: 'init' } })
      setLoading(false)
    } else {
      const data = await getDataFile({ files: value, page })

      if (get(data, 'results')) {
        createDataFileNodes(get(data, 'results'))
      }

      setPagination({
        pageSize: get(data, 'pageSize'),
        totalCount: get(data, 'totalCount'),
        page: get(data, 'currentPage'),
      })
      setLoading(false)
    }
  }

  const handleChange = files => {
    if (onChange) {
      onChange(files)
      return
    }

    if (onSelectedFiles) {
      onSelectedFiles(files)
      dispatch(setAllFiles(files))
      dispatch(setCurrentFiles(files))
    }
  }

  const noFileSelected = isEmpty(selectedFiles)

  return (
    <Spin spinning={loading}>
      <div className={className} style={{ position: 'relative' }}>
        {!noFileSelected && multiple && selectedFiles.length > 1 && (
          <div className="mb-05">
            <span>{selectedFiles.length} files selected</span>
            <Switch
              checkedChildren={label.sortTable}
              unCheckedChildren={label.sortTable}
              style={{
                position: 'absolute',
                top: 0,
                right: 0,
              }}
              onChange={() => setTableSwitched(!tableSwitched)}
            />
          </div>
        )}
        {tableSwitched ? (
          <SortTable
            selectedKeys={selectedKeys}
            selectedFiles={selectedFiles}
            onChange={(oldIndex, newIndex) => handleRowMove(oldIndex, newIndex)}
          />
        ) : (
          <div className="d-flex align-items-center column-gap-8">
            <TreeSelect
              onScroll={handleScroll}
              allowClear
              treeDataSimpleMode
              showSearch
              autoClearSearchValue={false}
              searchValue={searchValue}
              treeCheckable={multiple}
              value={selectedKeys}
              treeExpandedKeys={expandedKeys}
              popupClassName={popupClassName}
              className="w-100"
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              placeholder="Please select"
              treeData={sortedTreeData}
              multiple={multiple}
              disabled={disabled}
              onSelect={handleNodeSelect}
              onChange={handleTreeChange}
              onTreeExpand={value => setExpandedKeys(uniq(value))}
              onSearch={value => setSearchValue(value)}
              loadData={loadData}
            />
            {sortingInfo?.enabled && (
              <Tooltip title={tooltip}>
                <Button
                  type={buttonType}
                  className="flex-none"
                  icon={icon}
                  disabled={disabled}
                  onClick={onChangeSort}
                />
              </Tooltip>
            )}
          </div>
        )}
      </div>
    </Spin>
  )
}

const inputFileShape = PropTypes.shape({
  files: PropTypes.array,
  id: PropTypes.number,
  name: PropTypes.string,
  path: PropTypes.string,
})

DataFileTree.propTypes = {
  analysisType: PropTypes.object,
  className: PropTypes.string,
  dataOrder: PropTypes.array,
  debounceDelay: PropTypes.number,
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.number,
    inputFileShape,
  ]),
  multiple: PropTypes.bool,
  name: PropTypes.string,
  pattern: PropTypes.string,
  sortingInfo: PropTypes.shape({
    enabled: PropTypes.bool,
    nameKey: PropTypes.string,
    chronoKey: PropTypes.string,
  }),
  onChange: PropTypes.func,
  onSelectedFiles: PropTypes.func,
  onUpdateFields: PropTypes.func,
}

DataFileTree.defaultProps = {
  className: '',
  dataOrder: null,
  debounceDelay: 300, // 300 ms.
  disabled: false,
  multiple: false,
  name: 'default',
  pattern: null,
  sortingInfo: {
    enabled: true,
    nameKey: 'title',
    chronoKey: 'elem.id',
  },
}

export default DataFileTree
