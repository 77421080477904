import { Col, Form, Row } from 'antd'
import PropTypes from 'prop-types'
import { Component } from 'react'
import { connect } from 'react-redux'

import { sitesApi } from 'apis'
import { Loader, Select } from 'components'
import { setAnalysisOption } from 'store/modules/analyses'

export class StudySelect extends Component {
  static propTypes = {
    setAnalysisOption: PropTypes.func,
  }

  constructor(props) {
    super(props)

    this.state = {
      sites: [],
      pis: [],
      studies: [],
      site: null,
      pi: null,
      study: null,
    }
  }

  componentDidMount() {
    this.getSites()
  }

  handleChangeField = (fieldName, data) => {
    const newState = Object.assign(
      { [fieldName]: data },
      fieldName === 'site' && { pi: null, study: null },
      fieldName === 'pi' && { study: null },
    )
    if (fieldName === 'site' || fieldName === 'pi') {
      this.setState(newState, () => this.handleSearch(fieldName))
    } else if (fieldName === 'study') {
      this.setState(newState, () => this.handleStudySelect())
    }
  }

  handleStudySelect = () => {
    this.handleSetOption('study', 'value', this.state.study)
  }

  handleSearch = fieldName => {
    if (fieldName === 'site') {
      this.getPIs()
    } else if (fieldName === 'pi') {
      this.getStudies()
    }
  }

  getSites = async () => {
    this.setState({ pis: [], studies: [] })

    try {
      const data = await sitesApi.listSite()
      this.setState(
        Object.assign(
          {
            sites: data,
          },
          data.length === 1 && { site: data[0].id.toString() },
        ),
        () => {
          if (data.length === 1) {
            this.handleSearch('site')
          }
        },
      )
    } catch {} // eslint-disable-line
  }

  getStudies = async () => {
    try {
      const data = await sitesApi.listStudy()
      const { pi } = this.state
      const study_array = data.filter(
        x => x.principal_investigator.id === parseInt(pi),
      )
      this.setState(
        Object.assign(
          {
            studies: study_array,
          },
          study_array.length === 1 && { study: study_array[0].id.toString() },
        ),
        () => {
          if (study_array.length === 1) {
            this.handleSetOption('study', 'value', this.state.study)
          }
        },
      )
    } catch {} // eslint-disable-line
  }

  getPIs = () => {
    this.setState({ studies: [] })
    const { site } = this.state
    try {
      const siteData = this.state.sites.find(x => x.id === parseInt(site))
      const sitePIs = siteData.principal_investigators

      this.setState(
        Object.assign(
          {
            pis: sitePIs,
          },
          sitePIs.length === 1 && { pi: sitePIs[0].id.toString() },
        ),
        () => {
          if (sitePIs.length === 1) {
            this.handleSearch('pi')
          }
        },
      )
    } catch {} // eslint-disable-line
  }

  handleSetOption = (optionName, parameterName, value) => {
    this.props.setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  render() {
    const { sites, pis, studies, site, pi, study } = this.state

    if (this.loading) {
      return <Loader />
    }

    return (
      <Form layout="vertical">
        <Row gutter={24}>
          <Col md={8}>
            <Form.Item label="Site">
              <Select
                style={{ width: '100%' }}
                value={site}
                disabled={sites.length === 0}
                options={sites.map(({ id, label }) => ({
                  label,
                  value: id,
                }))}
                onChange={value => this.handleChangeField('site', value)}
              />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Principal Investigator">
              <Select
                style={{ width: '100%' }}
                value={pi}
                disabled={pis.length === 0}
                options={pis.map(({ id, username }) => ({
                  label: username,
                  value: id,
                }))}
                onChange={value => this.handleChangeField('pi', value)}
              />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item label="Study">
              <Select
                style={{ width: '100%' }}
                value={study}
                disabled={studies.length === 0}
                options={studies.map(({ id, label }) => ({
                  label,
                  value: id,
                }))}
                onChange={value => this.handleChangeField('study', value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  }
}

const actions = {
  setAnalysisOption,
}

export default connect(null, actions)(StudySelect)
