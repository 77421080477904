import { Button, Descriptions, Modal, Space, Tag } from 'antd'
import { find, get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import {
  CheckIcon,
  FilesView,
  MiscFileCSVTable,
  ParamView,
  ProvenanceView,
} from 'components'
import { TAG_COLORS } from 'config/base'
import * as analysesRedux from 'store/modules/analyses'
import {
  deleteAnalyses,
  prepareDownloadResult,
  renderErrors,
} from 'utils/analyses'

const { Item } = Descriptions

export const AnalysisInfo = ({
  analysis,
  analysisTypes,
  status,
  deleteAnalysis,
}) => {
  const [paramsModal, setParamsModal] = useState(false)
  const [filesModal, setFilesModal] = useState(false)
  const [miscFileCSVModal, setMiscFileCSVModal] = useState(false)
  const [provenanceModal, setProvenanceModal] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)

  const navigate = useNavigate()

  const {
    id,
    name,
    analysis_type,
    input_file,
    input_series,
    has_figures,
    date_time_start,
    date_time_end,
    error,
    provenance,
    created_by,
    parameters,
    save_path,
  } = analysis

  const completed = analysis.status === 'Complete'
  const failed = analysis.status === 'Error'

  const isDeleting = status === analysesRedux.deleteAnalysis.pending().type

  const analysisLabel = useMemo(
    () =>
      get(
        find(analysisTypes, { id: get(parameters, 'analysis.analysis_type') }),
        'label',
      ),
    [analysisTypes, parameters],
  )

  const inputSeries = useMemo(
    () =>
      input_series
        ? input_series.map(item => (
            <span key={item.id}>
              {item?.label}
              <br />
            </span>
          ))
        : '',
    [input_series],
  )

  const modalities = useMemo(() => {
    const seriesModality = get(analysis, 'input_file.series.modality')
    const seriesProtocolModalities = get(
      analysis,
      'input_file.series.protocol.modalities',
    )

    if (seriesModality) {
      return [seriesModality]
    }

    if (seriesProtocolModalities && seriesProtocolModalities.length > 0) {
      return seriesProtocolModalities
    }

    return []
  }, [analysis])

  useEffect(() => {
    if (status === analysesRedux.deleteAnalysis.fulfilled().type) {
      navigate('/study')
    }
  }, [status, navigate])

  const toggleParamsModal = () => setParamsModal(prevState => !prevState)

  const toggleFilesModal = () => setFilesModal(prevState => !prevState)

  const toggleProvenanceModal = () =>
    setProvenanceModal(prevState => !prevState)

  const toggleMiscFileCSVModal = () =>
    setMiscFileCSVModal(prevState => !prevState)

  const handleDownloadResult = async () => {
    setIsDownloading(true)

    await prepareDownloadResult(analysis.id)

    setIsDownloading(false)
  }

  const handleShowError = () => {
    Modal.error({
      content: renderErrors(error),
      maskClosable: true,
      icon: null,
      okText: 'Dismiss',
      width: '80%',
    })
  }

  const handleDeleteResult = () => {
    Modal.confirm({
      title: 'Are you sure want to delete this analysis result?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        deleteAnalyses([analysis.id], deleteAnalysis)
      },
    })
  }

  const miscFileId = get(parameters, 'analysis.options.Metadata.value.id')

  return (
    <div>
      <h2 className="text-center mb-2">Analysis Info</h2>
      <div className="w-75">
        <Descriptions size="small" bordered column={1}>
          <Item label="Name">{name}</Item>
          <Item label="Actions">
            <Space wrap>
              <Button size="small" onClick={toggleParamsModal}>
                Parameters
              </Button>
              <Button size="small" onClick={toggleFilesModal}>
                Files
              </Button>
              {completed && (
                <Link to={`/analysis/${id}/result`}>
                  <Button type="default" size="small">
                    Results
                  </Button>
                </Link>
              )}
              {completed && has_figures && (
                <Button
                  type="default"
                  size="small"
                  loading={isDownloading}
                  disabled={isDownloading}
                  onClick={handleDownloadResult}
                >
                  Prepare Download
                </Button>
              )}
              {failed && (
                <Button danger size="small" onClick={handleShowError}>
                  Errors
                </Button>
              )}
              {provenance && (
                <Button size="small" onClick={toggleProvenanceModal}>
                  Provenance
                </Button>
              )}
              {miscFileId && (
                <Button size="small" onClick={toggleMiscFileCSVModal}>
                  CSV
                </Button>
              )}
              {(completed || failed) &&
                get(parameters, 'analysis.analysis_type') && (
                  <Link
                    to={`/analysis-start/${parameters.analysis.analysis_type}?analysisId=${id}`}
                  >
                    <Button size="small">Redo</Button>
                  </Link>
                )}
              {(completed || failed) && (
                <Button
                  size="small"
                  danger
                  loading={isDeleting}
                  disabled={isDeleting}
                  onClick={handleDeleteResult}
                >
                  Delete
                </Button>
              )}
            </Space>
          </Item>
          <Item label="Analysis Type">
            {get(find(analysisTypes, { id: analysis_type }), 'label')}
          </Item>
          <Item label="Series">{inputSeries}</Item>
          <Item label="Session">
            {get(input_file, 'session_info.segment_interval')}
          </Item>
          <Item label="Modalities">
            {modalities.map(modality => (
              <Tag
                key={modality.id}
                color={TAG_COLORS[modality.id % (TAG_COLORS.length - 1)]}
                data-testid="analysis-modality"
              >
                {modality.full_name}
              </Tag>
            ))}
          </Item>
          <Item label="Scanner">
            {get(input_file, 'scanner_info.full_name')}
          </Item>
          <Item label="PI">
            <Tag>{get(input_file, 'pi_info.username')}</Tag>
          </Item>
          <Item label="Study">{get(input_file, 'study_info.full_name')}</Item>
          <Item label="Site">{get(input_file, 'site_info.full_name')}</Item>
          <Item label="Subject">{get(input_file, 'subject_info.anon_id')}</Item>
          <Item label="Status">{analysis.status}</Item>
          <Item label="Result path">{save_path}</Item>
          <Item label="Figures">
            <CheckIcon checked={has_figures} />
          </Item>
          <Item label="Started">
            {date_time_start &&
              moment(date_time_start).format('YYYY-MM-DD HH:mm:ss')}
          </Item>
          <Item label="Ended">
            {date_time_end &&
              moment(date_time_end).format('YYYY-MM-DD HH:mm:ss')}
          </Item>
          <Item label="Started by">{created_by.username}</Item>
        </Descriptions>
      </div>

      <Modal
        title="Parameters"
        open={paramsModal}
        footer={null}
        onOk={toggleParamsModal}
        onCancel={toggleParamsModal}
        width={1250}
        destroyOnClose
      >
        <ParamView {...parameters} label={analysisLabel} />
      </Modal>

      <Modal
        title="Files"
        open={filesModal}
        footer={null}
        onOk={toggleFilesModal}
        onCancel={toggleFilesModal}
        width={800}
        destroyOnClose
      >
        <FilesView dataFiles={get(analysis, 'input_files')} />
      </Modal>

      <Modal
        title="Provenance"
        open={provenanceModal}
        footer={null}
        onOk={toggleProvenanceModal}
        onCancel={toggleProvenanceModal}
        width={800}
        destroyOnClose
      >
        <ProvenanceView {...analysis.provenance} />
      </Modal>

      <Modal
        title={get(parameters, 'analysis.options.Metadata.value.name')}
        open={miscFileCSVModal}
        footer={null}
        onOk={toggleMiscFileCSVModal}
        onCancel={toggleMiscFileCSVModal}
        width={1250}
        destroyOnClose
      >
        <MiscFileCSVTable
          miscFileId={miscFileId}
          searchDataFile
          showWarning={false}
        />
      </Modal>
    </div>
  )
}

AnalysisInfo.propTypes = {
  analysis: PropTypes.object,
  analysisTypes: PropTypes.array,
  status: PropTypes.string,
  deleteAnalysis: PropTypes.func,
}

export default AnalysisInfo
