import { Col, Row, Tabs, TreeSelect } from 'antd'
import { filter, isEmpty, uniq } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { FileInfo, PapayaViewer } from 'components'
import { encodePathURL } from 'utils/analyses'
import FileTreeBuilder from 'utils/file-tree-builder'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const WMH_MASK_BIN = 'WMH_Mask_bin.nii'

const WMHResult = ({ data, dataFile, token }) => {
  const { all_files, base_image, out_dir, save_path, wmh_mask_vol } = data

  const [baseImage, setBaseImage] = useState(base_image)
  const [overlayImage, setOverlayImage] = useState(
    all_files.includes(WMH_MASK_BIN) ? WMH_MASK_BIN : null,
  )
  const [treeData, setTreeData] = useState([])

  const outDir = out_dir || save_path
  const baseImagePath = encodePathURL(outDir, baseImage, token)
  const overlayImagePath = overlayImage
    ? encodePathURL(outDir, overlayImage, token)
    : ''

  useEffect(() => {
    const niiFiles = filter(all_files, file => file.endsWith('.nii'))
    const uniqNiis = uniq(niiFiles)

    if (isEmpty(uniqNiis)) {
      setTreeData([])
    } else {
      const builder = new FileTreeBuilder()
      const newTreeData = builder.run(uniqNiis)
      setTreeData(newTreeData)
    }
  }, [all_files])

  return (
    <div className="analysis-result" data-testid="wmh-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'wmh-mask-vol',
                      label: 'WMH Mask Vol',
                      children: <span>{wmh_mask_vol}</span>,
                    },
                    {
                      key: 'output-files',
                      label: 'Files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'wmh-viewer',
            label: 'WMH Mask bin',
            children: (
              <>
                <Row gutter={12}>
                  <Col span={10} offset={1} style={{ textAlign: 'left' }}>
                    <Row style={{ marginBottom: 10 }}>
                      <strong>Overlay Image</strong>
                    </Row>
                    <Row>
                      <TreeSelect
                        className="w-100"
                        value={overlayImage}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={treeData}
                        placeholder="Please select Overlay Image"
                        treeDefaultExpandAll
                        onChange={value => setOverlayImage(value)}
                      />
                    </Row>
                  </Col>
                  <Col span={10} offset={1} style={{ textAlign: 'left' }}>
                    <Row style={{ marginBottom: 10 }}>
                      <strong>Base Image</strong>
                    </Row>
                    <Row>
                      <TreeSelect
                        className="w-100"
                        value={baseImage}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={treeData}
                        placeholder="Please select Base Image"
                        treeDefaultExpandAll
                        onChange={value => setBaseImage(value)}
                      />
                    </Row>
                  </Col>
                </Row>
                <Row style={{ marginTop: 30 }}>
                  {overlayImage && (
                    <PapayaViewer
                      file={`${overlayImagePath},${baseImagePath}`}
                    />
                  )}
                </Row>
              </>
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

WMHResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    base_image: PropTypes.string,
    figures: PropTypes.array,
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
    wmh_mask_vol: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default WMHResult
