import classNames from 'classnames'
import { kebabCase } from 'lodash'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SummaryTag = ({ id, tag }) => {
  const tagElement = (
    <div
      className={classNames(`summary-tag ${kebabCase(tag)}`, {
        button: Boolean(id),
      })}
      data-testid={`summary-tag-${kebabCase(tag)}`}
    >
      {tag}
    </div>
  )

  if (!id) {
    return tagElement
  }

  return (
    <Link
      to={`/analysis/${id}/result`}
      data-testid={`summary-tag-link-${kebabCase(tag)}`}
    >
      {tagElement}
    </Link>
  )
}

SummaryTag.propTypes = {
  id: PropTypes.number,
  tag: PropTypes.string,
}

export default SummaryTag
