import { Col, Divider, Form, Row, Spin } from 'antd'
import { get, isEmpty, map, orderBy } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MetadataEditor, Select } from 'components'
import { ANALYSIS_RESULTS, ANALYSIS_TYPES_ID } from 'config/base'
import {
  initAnalysisOptions,
  listAnalysis,
  selectAnalyses,
  selectAnalysis,
  selectAnalysisOptions,
  setAnalysisOption,
} from 'store/modules/analyses'
import { setAllFiles, setCurrentFiles } from 'store/modules/datafiles'

import DefaultUploadSection from '../DefaultUpload'

export const MancovaUploadSection = () => {
  const analyses = useSelector(selectAnalyses)
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)

  const dispatch = useDispatch()

  const icaParent = !isEmpty(analysisOptions?.ica_parent)

  useEffect(() => {
    const params = {
      pageSize: 1000,
      status: ANALYSIS_RESULTS.Complete,
      analysis_type: ANALYSIS_TYPES_ID.GICA,
    }

    dispatch(listAnalysis({ params }))
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(analyses?.results)) {
      if (analysis) {
        const { options } = analysis.parameters.analysis
        dispatch(initAnalysisOptions(options))
      } else {
        dispatch(
          initAnalysisOptions({
            ica_parent: { value: 'default' },
          }),
        )
      }
    }
  }, [analysis, analyses, dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const sortedAnalyses = orderBy(analyses.results, ['id'], ['desc'])

  return (
    <>
      <Spin size="large" spinning={!icaParent}>
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <MetadataEditor
                initialValue={get(
                  analysis,
                  'parameters.analysis.options.Metadata.value',
                )}
                onFileChange={file => {
                  dispatch(setAllFiles([file]))
                  dispatch(setCurrentFiles([file]))
                }}
              />
            </Col>
            <Col span={24}>
              <Divider />
              <Form.Item label="Select a previously run Group ICA:">
                <Select
                  style={{ minWidth: 300 }}
                  defaultValue={['default']}
                  value={analysisOptions?.ica_parent?.value || 'default'}
                  options={[
                    { label: 'Select a previous Group ICA', value: 'default' },
                    ...map(sortedAnalyses, ({ id, name }) => ({
                      label: `${id} - ${name}`,
                      value: id,
                    })),
                  ]}
                  onChange={value =>
                    handleSetOption('ica_parent', 'value', value)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      <DefaultUploadSection />
    </>
  )
}

export default MancovaUploadSection
