import { Card, Tabs } from 'antd'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader } from 'components'
import { PreprocessingSummaryTable } from 'containers'
import { PageLayout } from 'containers/Layouts'
import {
  getProfile,
  listUser,
  selectAuthStatus,
  selectLoggedInUser,
  selectUsers,
} from 'store/modules/auth'
import {
  getStudy,
  listSite,
  selectSites,
  selectSitesStatus,
  selectStudy,
  updateStudy,
} from 'store/modules/sites'
import { getEditableSites, getFullname } from 'utils/common'

import DataProviders from './DataProviders'
import Download from './Download'
import SharedUsers from './SharedUsers'
import StudyInfo from './StudyInfo'

export const StudyDetailPage = () => {
  const authStatus = useSelector(selectAuthStatus)
  const sites = useSelector(selectSites)
  const status = useSelector(selectSitesStatus)
  const study = useSelector(selectStudy)
  const user = useSelector(selectLoggedInUser)
  const users = useSelector(selectUsers)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(listSite())
    dispatch(listUser())
    dispatch(getStudy(params.studyId))
    dispatch(getProfile())
  }, [dispatch, params])

  const preparingData = useMemo(
    () =>
      [listSite.pending().type, getStudy.pending().type].indexOf(status) !==
        -1 ||
      [getProfile.pending().type, listUser.pending().type].indexOf(
        authStatus,
      ) !== -1,
    [status, authStatus],
  )

  if (preparingData) {
    return <Loader />
  }

  if (!study) {
    return null
  }

  const editableSites = getEditableSites(sites, user)

  const subProps = {
    study,
    sites: editableSites,
    user,
    users,
    status,
    updateStudy: values => dispatch(updateStudy(values)),
    editable: user.is_superuser || study.created_by.id === user.id,
  }

  return (
    <PageLayout
      heading={study.full_name}
      subheading={`Principal Investigator: ${getFullname(
        study.principal_investigator,
      )}`}
    >
      <Card>
        <Tabs
          animated={false}
          items={[
            {
              key: 'preprocessing-summary',
              label: 'Preprocessing Summary',
              children: <PreprocessingSummaryTable study={study} showTitle />,
            },
            {
              key: 'study-info',
              label: 'Study Info',
              children: <StudyInfo {...subProps} />,
            },
            {
              key: 'data-providers',
              label: 'Data Providers',
              children: <DataProviders {...subProps} />,
            },
            {
              key: 'shared-users',
              label: 'Shared Users',
              children: <SharedUsers {...subProps} />,
            },
            {
              key: 'download',
              label: 'Download',
              children: <Download {...subProps} />,
            },
          ]}
        />
      </Card>
    </PageLayout>
  )
}

export default StudyDetailPage
