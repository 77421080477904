import {
  AppstoreOutlined,
  AuditOutlined,
  BookOutlined,
  CheckCircleOutlined,
  CodeOutlined,
  ContainerOutlined,
  DashboardOutlined,
  DatabaseOutlined,
  OrderedListOutlined,
  PlaySquareOutlined,
  ScanOutlined,
  SelectOutlined,
  TagOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Menu as AntMenu, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

import {
  selectIsStaff,
  selectIsSuperUser,
  selectVersion,
} from 'store/modules/auth'

const Menu = ({ collapsed }) => {
  const isStaff = useSelector(selectIsStaff)
  const isSuperUser = useSelector(selectIsSuperUser)
  const version = useSelector(selectVersion)

  const location = useLocation()
  const navigate = useNavigate()

  const handleMenuClick = ({ key }) => {
    navigate(key)
  }

  return (
    <>
      <div
        className="sidebar__logo"
        data-testid="sidebar-logo"
        onClick={() => {
          handleMenuClick({ key: '/study' })
        }}
      >
        {collapsed ? (
          <>
            <img
              src="/logos/brainforge_brain_icon.svg"
              alt="BrainForge!"
              data-testid="sidebar-logo-collapsed"
            />
            <img src="/logos/brainforge_text.svg" alt="BrainForge!" />
          </>
        ) : (
          <img
            src="/logos/brainforge_logo.svg"
            alt="BrainForge!"
            data-testid="sidebar-logo-expanded"
          />
        )}
      </div>
      <AntMenu
        theme="dark"
        mode="inline"
        selectedKeys={[location.pathname]}
        items={[
          { key: '/study', label: 'Studies', icon: <BookOutlined /> },
          {
            key: '/analysis-run',
            label: 'Run Analysis',
            icon: <PlaySquareOutlined />,
          },
          { key: '/status', label: 'Status', icon: <DashboardOutlined /> },
          {
            key: '/data-directory',
            label: 'Data Directory',
            icon: <AuditOutlined />,
          },
          {
            key: '/parameter-set',
            label: 'Parameter Sets',
            icon: <OrderedListOutlined />,
          },
          {
            key: '/analysis-plans',
            label: 'Analysis Plans',
            icon: <SelectOutlined />,
          },
          isSuperUser && {
            key: '/analysis-types',
            label: 'Analysis Types',
            icon: <ContainerOutlined />,
          },
          isSuperUser && {
            key: '/site',
            label: 'Sites',
            icon: <AppstoreOutlined />,
          },
          !isSuperUser && {
            key: '/my-site',
            label: 'My Site',
            icon: <AppstoreOutlined />,
          },
          { key: '/scanner', label: 'Scanners', icon: <ScanOutlined /> },
          {
            label: 'Upload Data',
            icon: <DatabaseOutlined />,
            children: [
              { key: '/data/new', label: 'DataFile' },
              { key: '/misc', label: 'MiscFile' },
            ],
          },
          isSuperUser && { key: '/tag', label: 'Tags', icon: <TagOutlined /> },
          (isSuperUser || isStaff) && {
            key: '/users',
            label: 'Users',
            icon: <UserOutlined />,
          },
          {
            key: '/cli-download',
            label: (
              <Tooltip title={version} placement="topRight">
                <CodeOutlined />
                <span>CLI</span>
              </Tooltip>
            ),
          },
          {
            key: '/about',
            label: (
              <Tooltip title={version} placement="topRight">
                <CheckCircleOutlined />
                <span>About</span>
              </Tooltip>
            ),
          },
        ].filter(Boolean)}
        onClick={handleMenuClick}
      />
    </>
  )
}

Menu.propTypes = {
  collapsed: PropTypes.bool,
}

Menu.defaultProps = {
  collapsed: false,
}

export default Menu
