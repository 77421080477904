import { Col, Form, Input, Row } from 'antd'
import { get, map, noop } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from 'components'
import { SLURM_DEFAULT_PARTITION, SLURM_PARTITIONS } from 'config/base'
import { getParameterLayouts } from 'utils/common'

const OptionEditor = ({
  fullWidth,
  parameterSet,
  readOnly,
  setAnalysisOption,
  setParameterSet,
}) => {
  const { formLayout, gridLayout } = getParameterLayouts(fullWidth)

  const renderInput = (fieldLabel, fieldName) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <Input
        disabled={readOnly}
        value={get(parameterSet, fieldName)}
        onChange={evt =>
          setParameterSet({ name: fieldName, value: evt.target.value })
        }
      />
    </Form.Item>
  )

  return (
    <Row>
      <Col {...gridLayout}>
        {renderInput('Enter a name:', 'name')}
        {renderInput('Description:', 'description')}
        <Form.Item label="Slurm Partition:" {...formLayout}>
          <Select
            disabled={readOnly}
            value={
              parameterSet?.options?.slurm_partition?.value ||
              SLURM_DEFAULT_PARTITION
            }
            options={map(SLURM_PARTITIONS, partition => ({
              label: partition,
              value: partition,
            }))}
            onChange={value =>
              setAnalysisOption({ name: 'slurm_partition', option: { value } })
            }
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

OptionEditor.propTypes = {
  fullWidth: PropTypes.bool,
  parameterSet: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
  setParameterSet: PropTypes.func,
}

OptionEditor.defaultProps = {
  fullWidth: false,
  readOnly: false,
  setAnalysisOption: noop,
}

export default OptionEditor
