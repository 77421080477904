import { Col, Form, Row, Switch } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const SBRefForm = ({ analysisOptions, readOnly, setOption }) => {
  const [enableSBRef, setEnableSBRef] = useState(
    Boolean(get(analysisOptions, 'SBRef_file.value')),
  )

  return (
    <Row>
      <Col span={12}>
        <Form.Item label="Attach SBRef" {...formLayout}>
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={readOnly}
            checked={enableSBRef}
            onChange={value => {
              setEnableSBRef(value)
              setOption('SBRef_file', 'value', value)
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

SBRefForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

SBRefForm.defaultProps = {
  readOnly: false,
}

export default SBRefForm
