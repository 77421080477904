import { Card } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'

import MyInvite from './Invite'
import MyNotification from './Notification'
import MyProfile from './Profile'

const MyInfo = () => {
  const [activeTab, setActiveTab] = useState('profile')

  const navigate = useNavigate()
  const params = useParams()

  const handleNavigation = useCallback(() => {
    const { page } = params
    const availableTabs = ['profile', 'notification', 'invite']

    if (availableTabs.includes(page)) {
      setActiveTab(page)
    } else {
      navigate('/not-found')
    }
  }, [params, navigate])

  useEffect(() => {
    handleNavigation()
  }, [handleNavigation])

  const handleTabClick = tab => {
    navigate(`/me/${tab}`)
  }

  return (
    <PageLayout>
      <Card>
        <Tabs
          activeKey={activeTab}
          items={[
            { key: 'profile', label: 'Profile', children: <MyProfile /> },
            {
              key: 'notification',
              label: 'Notifications',
              children: <MyNotification />,
            },
            {
              key: 'invite',
              label: 'Received Invites',
              children: <MyInvite />,
            },
          ]}
          onTabClick={handleTabClick}
        />
      </Card>
    </PageLayout>
  )
}

export default MyInfo
