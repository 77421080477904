import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  FieldTimeOutlined,
  HolderOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { lowerCase, orderBy } from 'lodash'
import { useMemo, useState } from 'react'

import { SORTING_MODES, SORTING_TOOLTIP } from 'config/base'

const useSort = ({ data, sortingInfo }) => {
  const [sorting, setSorting] = useState(0)
  const currentSortingMode = SORTING_MODES[sorting]

  const icon = useMemo(() => {
    if (['alphaAsc', 'alphaDesc'].includes(currentSortingMode)) {
      return currentSortingMode === 'alphaAsc' ? (
        <SortAscendingOutlined />
      ) : (
        <SortDescendingOutlined />
      )
    }

    if (['chronoAsc', 'chronoDesc'].includes(currentSortingMode)) {
      return (
        <span
          className="d-flex align-items-center justify-content-center"
          style={{ fontSize: 12 }}
        >
          <FieldTimeOutlined />
          {currentSortingMode === 'chronoAsc' ? (
            <ArrowDownOutlined />
          ) : (
            <ArrowUpOutlined />
          )}
        </span>
      )
    }

    return <HolderOutlined />
  }, [currentSortingMode])

  const sortedData = useMemo(() => {
    if (!sortingInfo || !sortingInfo.enabled) {
      return data
    }

    if (currentSortingMode === 'alphaAsc' && sortingInfo.nameKey) {
      return orderBy(data, elem => lowerCase(elem[sortingInfo.nameKey]), [
        'asc',
      ])
    }

    if (currentSortingMode === 'alphaDesc' && sortingInfo.nameKey) {
      return orderBy(data, elem => lowerCase(elem[sortingInfo.nameKey]), [
        'desc',
      ])
    }

    if (currentSortingMode === 'chronoAsc' && sortingInfo.chronoKey) {
      return orderBy(data, elem => lowerCase(elem[sortingInfo.chronoKey]), [
        'asc',
      ])
    }

    if (currentSortingMode === 'chronoDesc' && sortingInfo.chronoKey) {
      return orderBy(data, elem => lowerCase(elem[sortingInfo.chronoKey]), [
        'desc',
      ])
    }

    return data
  }, [data, sortingInfo, currentSortingMode])

  const onChangeSort = () => {
    setSorting(prevState =>
      prevState < SORTING_MODES.length - 1 ? prevState + 1 : 0,
    )
  }

  return {
    icon,
    tooltip: SORTING_TOOLTIP[currentSortingMode],
    sortedData,
    buttonType: currentSortingMode === 'none' ? 'default' : 'primary',
    onChangeSort,
  }
}

export default useSort
