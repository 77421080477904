import { Button, Col, Form, Input, Row } from 'antd'
import { find } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'
import { validators } from 'validators'

const SeriesForm = ({
  mappings,
  modalities,
  submitting,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm()
  const selectedModality = Form.useWatch('modality', form)

  const protocols = useMemo(() => {
    if (!selectedModality) {
      return []
    }

    return mappings
      .filter(mapping =>
        Boolean(find(mapping.modalities, elem => elem.id === selectedModality)),
      )
      .map(mapping => mapping.protocol)
  }, [mappings, selectedModality])

  const handleSubmit = values => {
    onSubmit({ data: values })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      disabled={submitting}
      form={form}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="label"
            label="Label"
            rules={validators.genericRequired}
          >
            <Input placeholder="Label" />
          </Form.Item>

          <Form.Item name="study_code_label" label="Study code label">
            <Input placeholder="Study code label" />
          </Form.Item>

          <Form.Item
            name="modality"
            label="Modality"
            rules={validators.genericRequired}
          >
            <Select
              options={modalities.map(({ id, full_name }) => ({
                label: full_name,
                value: id,
              }))}
              onChange={() => form.setFieldsValue({ protocol: null })}
            />
          </Form.Item>

          <Form.Item name="protocol" label="Protocol">
            <Select
              options={protocols.map(({ id, full_name }) => ({
                label: full_name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Add
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

SeriesForm.propTypes = {
  mappings: PropTypes.array,
  modalities: PropTypes.array,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default SeriesForm
