import {
  ClusterOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  RedoOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Button, Empty, Table, Tooltip } from 'antd'
import { find, get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const ExpandedRow = ({
  analyses,
  analysisTypes,
  downloadingAnalysis,
  onDeleteResult,
  onDownloadResult,
  onShowError,
  onShowResult,
  onToggleModal,
}) => {
  if (analyses.length === 0) {
    return <Empty />
  }

  const columns = [
    {
      title: 'Analysis Type',
      key: 'analysis_type',
      render: (_, record) =>
        get(find(analysisTypes, { id: record.analysis_type }), 'label'),
    },
    {
      title: 'Parameter Set',
      dataIndex: 'parameter_set_name',
      key: 'parameter_set_name',
      render: (text, record) => (
        <Button
          className="p-0"
          type="link"
          onClick={() => onToggleModal('paramsModal', record.id)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: 'Date Started',
      dataIndex: 'date_time_start',
      key: 'date_time_start',
    },
    {
      title: 'Date Completed',
      dataIndex: 'date_time_end',
      key: 'date_time_end',
    },
    {
      title: 'Performed By',
      key: 'created_by',
      render: (_, record) => get(record, 'created_by.username'),
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (text, record) => {
        if (text === 'Complete') {
          return (
            <Button
              size="small"
              type="primary"
              onClick={() => onShowResult(record)}
            >
              Completed
            </Button>
          )
        }

        if (text === 'Error') {
          return (
            <Button
              size="small"
              danger
              onClick={() => onShowError(record.error)}
            >
              Error
            </Button>
          )
        }

        return text
      },
    },
  ]

  columns.push({
    title: 'Actions',
    key: 'actions',
    render: (_, record) => {
      const { id, status, error, has_figures, provenance, parameters } = record
      const completed = status === 'Complete'
      const failed = status === 'Error'

      return (
        <div className="d-flex">
          <Tooltip title="Parameters">
            <Button
              className="mr-05"
              icon={<SettingOutlined />}
              size="small"
              shape="circle"
              onClick={() => onToggleModal('paramsModal', id)}
            />
          </Tooltip>
          {completed && (
            <Tooltip title="Results">
              <Link to={`/analysis/${id}/result`}>
                <Button
                  className="mr-05"
                  type="default"
                  icon={<EyeOutlined />}
                  size="small"
                  shape="circle"
                />
              </Link>
            </Tooltip>
          )}
          {completed && has_figures && (
            <Tooltip title="Download">
              <Button
                className="mr-05"
                type="default"
                icon={<DownloadOutlined />}
                size="small"
                shape="circle"
                loading={record.id === downloadingAnalysis}
                disabled={Boolean(downloadingAnalysis)}
                onClick={() => onDownloadResult(id)}
              />
            </Tooltip>
          )}
          {failed && (
            <Tooltip title="Errors">
              <Button
                className="mr-05"
                danger
                icon={<ExclamationCircleOutlined />}
                size="small"
                shape="circle"
                onClick={() => onShowError(error)}
              />
            </Tooltip>
          )}
          {provenance && (
            <Tooltip title="Provenance">
              <Button
                className="mr-05"
                icon={<ClusterOutlined />}
                size="small"
                shape="circle"
                onClick={() => onToggleModal('provenanceModal', id)}
              />
            </Tooltip>
          )}
          {(completed || failed) && (
            <>
              <Link
                to={`/analysis-start/${get(
                  parameters,
                  'analysis.analysis_type',
                )}?analysisId=${record.id}`}
              >
                <Tooltip title="Redo">
                  <Button
                    className="mr-05"
                    icon={<RedoOutlined />}
                    size="small"
                    shape="circle"
                  />
                </Tooltip>
              </Link>
              <Tooltip title="Delete">
                <Button
                  icon={<DeleteOutlined />}
                  size="small"
                  shape="circle"
                  danger
                  onClick={() => onDeleteResult(id)}
                />
              </Tooltip>
            </>
          )}
        </div>
      )
    },
  })

  const data = analyses.map(analysis => ({
    ...analysis,
    date_time_start: moment(analysis.date_time_start).format(
      'MMM Do YYYY, h:mm A',
    ),
    date_time_end: analysis.date_time_end
      ? moment(analysis.date_time_end).format('MMM Do YYYY, h:mm A')
      : '',
  }))

  return (
    <Table
      columns={columns}
      dataSource={data}
      pagination={false}
      rowKey="id"
      size="small"
    />
  )
}

ExpandedRow.propTypes = {
  analyses: PropTypes.array,
  analysisTypes: PropTypes.array,
  downloadingAnalysis: PropTypes.any,
  onDeleteResult: PropTypes.func,
  onDownloadResult: PropTypes.func,
  onShowError: PropTypes.func,
  onShowResult: PropTypes.func,
  onToggleModal: PropTypes.func,
}

export default ExpandedRow
