import { Button, Select as AntSelect, Tooltip } from 'antd'
import PropTypes from 'prop-types'

import useSort from 'hooks/useSort'

export const { Option } = AntSelect

const Select = ({
  data,
  disabled,
  labelRenderer,
  optionRenderer,
  size,
  sortingInfo,
  valueRenderer,
  ...restProps
}) => {
  const { icon, tooltip, sortedData, buttonType, onChangeSort } = useSort({
    data,
    sortingInfo,
  })

  const getLabel = option => {
    if (typeof labelRenderer === 'function') {
      return labelRenderer(option)
    }

    return option[labelRenderer]
  }

  const getValue = option => {
    if (typeof valueRenderer === 'function') {
      return valueRenderer(option)
    }

    return option[valueRenderer]
  }

  return (
    <div className="d-flex column-gap-8">
      <AntSelect
        size={size}
        disabled={disabled}
        getPopupContainer={trigger => trigger.parentNode}
        {...restProps}
      >
        {sortedData.map(option =>
          optionRenderer ? (
            optionRenderer(option)
          ) : (
            <Option
              key={getValue(option)}
              value={getValue(option)}
              data-testid="select-option"
            >
              {getLabel(option)}
            </Option>
          ),
        )}
      </AntSelect>
      {sortingInfo?.enabled && (
        <Tooltip title={tooltip}>
          <Button
            type={buttonType}
            className="flex-none"
            icon={icon}
            size={size}
            disabled={disabled}
            data-testid="sort-button"
            onClick={onChangeSort}
          />
        </Tooltip>
      )}
    </div>
  )
}

Select.propTypes = {
  data: PropTypes.array,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  sortingInfo: PropTypes.shape({
    enabled: PropTypes.bool,
    nameKey: PropTypes.string,
    chronoKey: PropTypes.string,
  }),
  labelRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  optionRenderer: PropTypes.func,
  valueRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

Select.defaultProps = {
  disabled: false,
  size: 'medium',
  sortingInfo: null,
  labelRenderer: 'label',
  valueRenderer: 'id',
}

export default Select
