import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { REDIRECT_URL } from 'config/base'
import BFRoutes from 'routes'
import { listAnalysisType } from 'store/modules/analyses'
import { getVersion, selectLoggedIn } from 'store/modules/auth'
import { listModality } from 'store/modules/mappings'
import { removeItem } from 'utils/storage'

const MainApp = () => {
  const loggedIn = useSelector(selectLoggedIn)

  const dispatch = useDispatch()

  const fetchRequiredData = useCallback(() => {
    dispatch(listAnalysisType())
    dispatch(listModality())
  }, [dispatch])

  useEffect(() => {
    dispatch(getVersion())
  }, [dispatch])

  useEffect(() => {
    if (loggedIn) {
      fetchRequiredData()
      removeItem(REDIRECT_URL)
    }
  }, [fetchRequiredData, loggedIn])

  return <BFRoutes />
}

export default MainApp
