import { Tabs } from 'antd'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const ASLResult = ({ data, dataFile }) => {
  const { all_files } = data

  const items = [
    {
      key: 'meta-data',
      label: 'Input',
      children: (
        <div className="w-50">
          <FileInfo dataFile={dataFile} />
        </div>
      ),
    },
    {
      key: 'output',
      label: 'Output',
      children: (
        <div className="w-75">
          <Tabs
            tabPosition="left"
            items={[
              {
                key: 'output-files',
                label: 'Files',
                children: (
                  <div className="w-33 mx-auto text-left">
                    <OutputFileTree files={all_files} />
                  </div>
                ),
              },
            ]}
          />
        </div>
      ),
    },
    {
      label: 'Volume Viewer',
      key: 'volume-viewer',
      children: <VolumeViewer data={data} />,
    },
  ]

  return (
    <div className="analysis-result" data-testid="asl-result">
      <Tabs animated={false} centered items={items} />
    </div>
  )
}

ASLResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    figures: PropTypes.array,
    out_dir: PropTypes.string,
  }),
  dataFile: PropTypes.object,
}

export default ASLResult
