import { Col, Form, InputNumber, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const ScansForm = ({ analysisOptions, readOnly, setOption }) => (
  <Row>
    <Col span={12}>
      <Form.Item label="Number of volumes to remove" {...formLayout}>
        <InputNumber
          value={get(analysisOptions, 'num_vols_to_remove.value')}
          step={1}
          disabled={readOnly}
          onChange={value => setOption('num_vols_to_remove', 'value', value)}
        />
      </Form.Item>
    </Col>
  </Row>
)

ScansForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

ScansForm.defaultProps = {
  readOnly: false,
}

export default ScansForm
