import { Col, Row } from 'antd'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const PageLayout = ({ children, heading, subheading }) => (
  <div className="app-page" data-testid="page-layout">
    <Row>
      {heading && (
        <Col span={24}>
          <div className="app-page__heading">{heading}</div>
          {subheading && (
            <div className="app-page__subheading-width-limit">{subheading}</div>
          )}
        </Col>
      )}
      <Col span={24}>
        <div className={classNames('app-page__content', { 'pt-0': !heading })}>
          {children}
        </div>
      </Col>
    </Row>
  </div>
)

PageLayout.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.any,
  subheading: PropTypes.any,
}

export default PageLayout
