import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Card, Col, Dropdown, Form, Modal, Row, Table, Tag } from 'antd'
import { first, isEmpty, map, toNumber } from 'lodash'
import qs from 'query-string'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { sitesApi } from 'apis'
import { AnalysisPlanStepsTable, Drawer, Select } from 'components'
import AnalsysisPlanStepForm from 'components/AnalysisPlanStepsTable/AnalysisPlanStepForm'
import { BREAKPOINTS, TAG_COLORS } from 'config/base'
import { PageLayout } from 'containers/Layouts'
import { selectAnalysisTypes } from 'store/modules/analyses'
import {
  createAnalysisPlan,
  createAnalysisPlanStep,
  deleteAnalysisPlan,
  deleteAnalysisPlanStep,
  listAnalysisPlan,
  listParameterSet,
  selectAnalysisPlans,
  selectDataFilesStatus,
  selectParameterSets,
  updateAnalysisPlan,
  updateAnalysisPlanStep,
} from 'store/modules/datafiles'
import {
  listProtocol,
  selectModalities,
  selectProtocols,
} from 'store/modules/mappings'
import { listStudy, selectStudies } from 'store/modules/sites'

import AssignModalityForm from './AssignModalityForm'

export const AnalysisPlanPage = ({ isDesktop }) => {
  const studies = useSelector(selectStudies)
  const analysisPlans = useSelector(selectAnalysisPlans)
  const modalities = useSelector(selectModalities)
  const protocols = useSelector(selectProtocols)
  const status = useSelector(selectDataFilesStatus)
  const analysisTypes = useSelector(selectAnalysisTypes)
  const parameterSets = useSelector(selectParameterSets)

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = qs.parse(location.search)

  const [filters, setFilters] = useState({})
  const [study, setStudy] = useState(queryParams.study)
  const [selectedRecord, setSelectedRecord] = useState(null)
  const [showDrawerModality, setShowDrawerModality] = useState(false)
  const [showDrawerPlanStep, setShowDrawerPlanStep] = useState(null)

  const handleGetAnalysisPlans = useCallback(() => {
    const params = {
      study,
      protocols: first(filters.protocols),
      modalities: first(filters.modalities),
    }

    dispatch(listAnalysisPlan({ params }))
  }, [dispatch, filters, study])

  const getStudyDetail = useCallback(async studyId => {
    try {
      const data = await sitesApi.getStudy(studyId)
      setStudy(data.id)
    } catch {
      setStudy(undefined)
    }
  }, [])

  const getStudyPlans = useCallback(() => {
    if (queryParams.study) {
      getStudyDetail(queryParams.study)
    }
  }, [getStudyDetail, queryParams.study])

  useEffect(() => {
    if (study) {
      handleGetAnalysisPlans()
      dispatch(listProtocol({ study }))
    }
  }, [dispatch, study, handleGetAnalysisPlans])

  useEffect(() => {
    dispatch(listStudy())
  }, [dispatch])

  useEffect(() => {
    dispatch(listStudy())
    dispatch(listParameterSet())
    getStudyPlans()
  }, [dispatch, getStudyPlans])

  useEffect(() => {
    if (
      [
        createAnalysisPlanStep.fulfilled().type,
        updateAnalysisPlanStep.fulfilled().type,
        deleteAnalysisPlanStep.fulfilled().type,
        deleteAnalysisPlan.fulfilled().type,
      ].includes(status)
    ) {
      handleGetAnalysisPlans()
    }
  }, [status, handleGetAnalysisPlans])

  useEffect(() => {
    if (
      [
        createAnalysisPlan.fulfilled().type,
        updateAnalysisPlan.fulfilled().type,
      ].includes(status)
    ) {
      handleGetAnalysisPlans()
      toggleDrawerModality()
    }
  }, [status, handleGetAnalysisPlans])

  const isSubmitting = [
    createAnalysisPlan.pending().type,
    updateAnalysisPlan.pending().type,
  ].includes(status)

  const toggleDrawerModality = () => {
    setShowDrawerModality(prevState => !prevState)
  }

  const toggleDrawerPlanStep = () => {
    setShowDrawerPlanStep(prevState => !prevState)
  }

  const persistURL = studyId => {
    const searchParams = new URLSearchParams({ study: studyId })
    const newUrl = `?${searchParams.toString()}`
    navigate(newUrl)
  }

  const handleSubmitPlanStep = values => {
    dispatch(createAnalysisPlanStep(values))
    toggleDrawerPlanStep()
  }

  const handleDelete = plan => {
    Modal.confirm({
      title: 'Are you sure want to delete this plan?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteAnalysisPlan(plan))
      },
    })
  }

  const handleChangeStudy = value => {
    setStudy(value)
    persistURL(value)
  }

  const handleTableChange = (_, newFilters) => {
    setFilters(newFilters)
  }

  const handleAddPlan = () => {
    setSelectedRecord(null)
    toggleDrawerModality()
  }

  const columns = () => [
    {
      title: 'Protocol Name',
      dataIndex: 'protocols',
      key: 'protocols',
      width: '30%',
      filters: protocols.map(protocol => ({
        text: protocol.full_name,
        value: protocol.id,
      })),
      filterMultiple: false,
      render: (_, record) => (
        <>
          {record.protocols.map(protocol => (
            <Tag
              key={protocol.id}
              color={TAG_COLORS[protocol.id % (TAG_COLORS.length - 1)]}
              style={{
                marginBottom: 5,
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
              }}
            >
              {protocol.full_name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Modalities',
      dataIndex: 'modalities',
      key: 'modalities',
      width: '15%',
      filters: modalities.map(modality => ({
        text: modality.full_name,
        value: modality.id,
      })),
      filterMultiple: false,
      render: (_, record) => (
        <>
          {record.modalities.map(modality => (
            <Tag
              key={modality.id}
              color={TAG_COLORS[modality.id % (TAG_COLORS.length - 1)]}
              style={{ marginBottom: 5 }}
            >
              {modality.full_name}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Steps',
      dataIndex: 'steps',
      key: 'steps',
      width: '55%',
      render: (_, record) => (
        <AnalysisPlanStepsTable
          {...record}
          steps={record.steps}
          status={status}
          study={toNumber(study)}
          isEditable
          hideModality={!isDesktop}
        />
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      width: '10%',
      render: (_, record) => (
        <Dropdown
          menu={{
            items: [
              {
                key: 'add-step',
                label: 'Add Step',
                icon: <PlusOutlined />,
                onClick: () => {
                  setSelectedRecord(record)
                  toggleDrawerPlanStep()
                },
              },
              {
                key: 'edit',
                label: 'Edit',
                icon: <EditOutlined />,
                onClick: () => {
                  setSelectedRecord(record)
                  toggleDrawerModality()
                },
              },
              {
                key: 'delete',
                label: 'Delete',
                icon: <DeleteOutlined />,
                onClick: () => handleDelete(record),
              },
            ],
          }}
          trigger={['click']}
        >
          <Button size="small" data-testid="action-button">
            Action <DownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ]

  return (
    <PageLayout heading="Analysis Plans">
      <Card>
        <div className="app-page__subheading">
          Select PI, Study, and Site to see available protocols to link
        </div>

        <Form layout="vertical">
          <Row gutter={12}>
            <Col xs={18} md={12}>
              <Form.Item label="Study">
                <Select
                  value={study ? Number(study) : study}
                  disabled={isEmpty(studies)}
                  options={map(studies, ({ id, label, full_name }) => ({
                    label: `${label} - ${full_name}`,
                    value: id,
                  }))}
                  onChange={handleChangeStudy}
                />
              </Form.Item>
            </Col>
            <Col xs={6} md={12} style={{ textAlign: 'right' }}>
              <Button
                style={{ marginTop: 34 }}
                icon={<PlusOutlined />}
                type="primary"
                data-testid="add-new-plan-button"
                onClick={handleAddPlan}
              >
                Add New Plan
              </Button>
            </Col>
          </Row>
        </Form>

        <Table
          className="table-analysis-plan"
          dataSource={analysisPlans}
          columns={columns()}
          size="small"
          bordered
          rowKey="id"
          pagination={{ size: 'large' }}
          scroll={{ x: !isDesktop }}
          onChange={handleTableChange}
        />

        <Drawer
          title={
            selectedRecord?.id ? 'Update Analysis Plan' : 'Create Analysis Plan'
          }
          open={showDrawerModality}
          onClose={toggleDrawerModality}
        >
          <AssignModalityForm
            initialValues={{
              study: Number(study) || null,
              protocols: map(selectedRecord?.protocols, 'id'),
              modalities: map(selectedRecord?.modalities, 'id'),
            }}
            analysisPlanId={selectedRecord?.id}
            modalities={modalities}
            protocols={protocols}
            studies={studies}
            submitting={isSubmitting}
            onCancel={toggleDrawerModality}
          />
        </Drawer>

        <Drawer
          title="Add Analysis Plan Step"
          open={showDrawerPlanStep}
          onClose={toggleDrawerPlanStep}
        >
          <AnalsysisPlanStepForm
            analysisPlanId={selectedRecord?.id}
            analysisTypes={analysisTypes}
            modalities={modalities}
            parameterSets={parameterSets}
            onSubmit={handleSubmitPlanStep}
            onCancel={toggleDrawerPlanStep}
          />
        </Drawer>
      </Card>
    </PageLayout>
  )
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
})

export default withSizes(sizes)(AnalysisPlanPage)
