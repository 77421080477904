import { Button, Col, Divider, Row } from 'antd'
import { each, filter, get, last, zipWith } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'

import SingleContrastOptionEditor from './SingleContrastOptionEditor'

const DUAL_RUN_NUM = 2
const DEFAULT_CONTRAST = {
  Contrast_Name: '',
  Contrast_Type: 'T',
  Contrast_Events: '',
  Contrast_Weights: '',
}

export const ContrastsOptionEditor = ({ numOnsets, readOnly }) => {
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  const [numContrasts, setNumContrasts] = useState(
    get(analysisOptions, 'Contrasts.value.length', 0),
  )

  const canGenerateContrasts = useMemo(
    () => !readOnly && numContrasts === 0 && numOnsets > 0,
    [numOnsets, readOnly, numContrasts],
  )

  const handleAddContrast = () => {
    const contrasts = analysisOptions.Contrasts.value
    const newContrast = contrasts.length ? last(contrasts) : DEFAULT_CONTRAST
    contrasts.push({ ...newContrast })

    handleSetOption(contrasts)
  }

  const handleRemoveContrast = index => {
    const contrasts = analysisOptions.Contrasts.value
    contrasts.splice(index, 1)

    handleSetOption(contrasts)
  }

  const handleSetOption = contrasts => {
    dispatch(
      setAnalysisOption({ name: 'Contrasts', option: { value: contrasts } }),
    )
    setNumContrasts(contrasts.length)
  }

  const handleSetContrastOption = (index, optionName, value) => {
    const contrasts = analysisOptions.Contrasts.value
    contrasts[index][optionName] = value
    handleSetOption(contrasts)
  }

  /**
   * Generate contrasts from runs configuration
   * by pairing event and onset in the same order.
   */
  const generateContrasts = () => {
    const validRunOptions = filter(
      analysisOptions.Runs.value,
      run => run.Event_name && run.onsets,
    )
    const contrasts = analysisOptions.Contrasts.value

    // Generate contrasts for Weight [1]
    each(validRunOptions, run => {
      zipWith(run.Event_name, run.onsets, (eventName, onset) => {
        if (eventName && onset) {
          contrasts.push({
            Contrast_Name: eventName,
            Contrast_Type: 'T',
            Contrast_Events: eventName,
            Contrast_Weights: '1',
          })
        }
      })
    })

    // Generate contrasts for Weight [0.5, 0.5]
    if (validRunOptions.length === DUAL_RUN_NUM) {
      const [run1, run2] = validRunOptions
      zipWith(
        run1.Event_name,
        run2.Event_name,
        run1.onsets,
        run2.onsets,
        (r1Evt, r2Evt, r1Onset, r2Onset) => {
          contrasts.push({
            Contrast_Name: `${r1Evt}_run2`,
            Contrast_Type: 'T',
            Contrast_Events: [r1Evt, r2Evt].join(', '),
            Contrast_Weights: '0.5, 0.5',
          })
        },
      )
    }

    handleSetOption(contrasts)
  }

  const contrasts = get(analysisOptions, 'Contrasts.value', [])

  return (
    <>
      {!readOnly && (
        <Button className="mb-1" onClick={handleAddContrast}>
          Add Contrast
        </Button>
      )}
      {canGenerateContrasts && (
        <Button style={{ marginLeft: 10 }} onClick={generateContrasts}>
          Generate Contrasts from Runs
        </Button>
      )}
      <Row>
        {contrasts.map((contrast, index) => (
          <Col key={`contrast_${index}`} md={11} offset={index % 2}>
            <SingleContrastOptionEditor
              contrast={contrast}
              analysisOptions={analysisOptions}
              analysisType={analysisType}
              index={index}
              readOnly={readOnly}
              setContrastOption={(optionName, value) =>
                handleSetContrastOption(index, optionName, value)
              }
              handleRemoveContrast={() => handleRemoveContrast(index)}
            />
            <Divider dashed />
          </Col>
        ))}
      </Row>
    </>
  )
}

ContrastsOptionEditor.propTypes = {
  numOnsets: PropTypes.number,
  readOnly: PropTypes.bool,
}

ContrastsOptionEditor.defaultProps = {
  readOnly: false,
}

export default ContrastsOptionEditor
