import {
  FormOutlined,
  LockOutlined,
  LoginOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Alert, Button, Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { authApi } from 'apis'
import { setUser } from 'store/modules/auth'
import { parseError } from 'utils/error-parser'
import { setAuthData } from 'utils/storage'
import { validators } from 'validators'

const LogInForm = ({ onChangeForm }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async payload => {
    setError(null)
    setIsLoading(true)

    try {
      const data = await authApi.logIn(payload)
      setAuthData(data)
      dispatch(setUser(data.user))
    } catch (err) {
      setError(parseError(err).message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form disabled={isLoading} data-testid="login-form" onFinish={handleSubmit}>
      <Row>
        {error && (
          <Col span={24}>
            <Alert
              message={error}
              type="error"
              showIcon
              style={{ marginBottom: 20 }}
              banner
              data-testid="login-error-alert"
            />
          </Col>
        )}

        <Col span={24}>
          <Form.Item
            name="username"
            rules={validators.genericRequired}
            hasFeedback
          >
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
              placeholder="Username or Email"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="password" rules={validators.password} hasFeedback>
            <Input.Password
              prefix={<LockOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />}
              placeholder="Password"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={6}>
        <Col span={12}>
          <Button
            type="primary"
            icon={<LoginOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Log In
          </Button>
        </Col>
        <Col span={12}>
          <Button icon={<FormOutlined />} onClick={() => navigate('/register')}>
            Register
          </Button>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Button type="link" onClick={onChangeForm}>
            Forgot Password
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

LogInForm.propTypes = {
  onChangeForm: PropTypes.func,
}

export default LogInForm
