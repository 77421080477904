import { Col, Row, Tabs } from 'antd'
import { filter, find, includes, map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const FMRI32Result = ({ data, dataFile, token }) => {
  const { all_files, figures, out_dir, save_path } = data

  const outDir = out_dir || save_path

  const alEpiFigures = filter(figures, fig => includes(fig, 'Epi_Motion'))
  const qaRegFigures = filter(figures, fig => includes(fig, 'QAreg'))
  const simpleProcedure = find(figures, fig => includes(fig, 'graph.png'))
  const detailedProcedure = find(figures, fig =>
    includes(fig, 'graph_detailed.png'),
  )

  return (
    <div className="analysis-result" data-testid="fmri-32ch-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'output-files',
                      label: 'Files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'al-epi-anat-figures',
            label: 'AlEpiAnat Figures',
            children: (
              <Row>
                {map(alEpiFigures, figure => (
                  <Col key={figure} style={{ marginBottom: 10 }}>
                    <img
                      className="w-100"
                      alt={figure}
                      src={encodePathURL(outDir, figure, token)}
                      data-testid="al-epic-figure"
                    />
                  </Col>
                ))}
              </Row>
            ),
          },
          {
            key: 'qa-reg-figures',
            label: 'QAReg Figures',
            children: (
              <Row gutter={16}>
                {map(qaRegFigures, figure => (
                  <Col key={figure} md={12} style={{ marginBottom: 10 }}>
                    <span>{figure}</span>
                    <img
                      className="w-100"
                      alt={figure}
                      src={encodePathURL(outDir, figure, token)}
                      data-testid="qa-reg-figure"
                    />
                  </Col>
                ))}
              </Row>
            ),
          },
          {
            key: 'procedure',
            label: 'Procedure',
            children: (
              <Tabs
                tabPosition="left"
                items={[
                  {
                    key: 'simple',
                    label: 'Simple',
                    children: (
                      <>
                        <Row>
                          <span>{simpleProcedure}</span>
                        </Row>
                        <Row>
                          <img
                            alt={simpleProcedure}
                            src={encodePathURL(outDir, simpleProcedure, token)}
                            data-testid="simple-procedure-figure"
                          />
                        </Row>
                      </>
                    ),
                  },
                  {
                    key: 'detailed',
                    label: 'Detailed',
                    children: (
                      <>
                        <span>{detailedProcedure}</span>
                        <img
                          className="w-100"
                          alt={detailedProcedure}
                          src={encodePathURL(outDir, detailedProcedure, token)}
                          data-testid="detail-procedure-figure"
                        />
                      </>
                    ),
                  },
                ]}
              />
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

FMRI32Result.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    figures: PropTypes.array,
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default FMRI32Result
