import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { Select } from 'components'
import { getAnalysisLegend } from 'utils/analyses'

const AnalysesModal = ({
  analyses,
  open,
  selected,
  onSelect,
  onToggleModal,
}) => {
  const [selectedAnalyses, setSelectedAnalyses] = useState([])

  useEffect(() => {
    setSelectedAnalyses(selected)
  }, [selected])

  const handleSelectAll = () => {
    setSelectedAnalyses(analyses.map(analysis => analysis.id))
  }

  const handleDeselectAll = () => {
    setSelectedAnalyses([])
  }

  const handleOk = () => {
    onSelect(selectedAnalyses)
    onToggleModal()
  }

  return (
    <Modal
      open={open}
      title="Select Analyses"
      onOk={handleOk}
      onCancel={onToggleModal}
    >
      <div className="analysis-selector">
        Analyses:&nbsp;
        <Select
          mode="multiple"
          placeholder="Please select"
          value={selectedAnalyses}
          style={{ width: '100%' }}
          options={analyses.map(analysis => ({
            label: getAnalysisLegend(analysis),
            value: analysis.id,
          }))}
          onChange={setSelectedAnalyses}
        />
      </div>

      <Button
        type="primary"
        size="small"
        disabled={selectedAnalyses.length === analyses.length}
        onClick={handleSelectAll}
      >
        Select All
      </Button>

      <Button
        className="ml-05"
        type="primary"
        size="small"
        disabled={selectedAnalyses.length === 0}
        onClick={handleDeselectAll}
      >
        Deselect All
      </Button>
    </Modal>
  )
}

AnalysesModal.propTypes = {
  analyses: PropTypes.array,
  open: PropTypes.bool,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  onToggleModal: PropTypes.func,
}

export default AnalysesModal
