import { SearchOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'

import SubjectSearchForm from '../SubjectSearchForm'

const SubjectFilter = ({ study, value, onFilter }) => {
  const [showForm, setShowForm] = useState(false)

  const toggleShowForm = () => setShowForm(prevState => !prevState)

  const handleSubmit = val => {
    onFilter(val)
    toggleShowForm()
  }

  return (
    <div className="subject-filter">
      <Button
        shape="circle"
        icon={<SearchOutlined />}
        size="small"
        type={value ? 'primary' : 'default'}
        onClick={toggleShowForm}
      />
      <SubjectSearchForm
        visible={showForm}
        study={study}
        onSubmit={handleSubmit}
      />
      {showForm && (
        <div
          className="subject-filter-backdrop"
          role="button"
          onClick={toggleShowForm}
        />
      )}
    </div>
  )
}

SubjectFilter.propTypes = {
  study: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFilter: PropTypes.func,
}

export default SubjectFilter
