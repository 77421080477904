import { Card } from 'antd'
import { keys } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader, Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'
import { selectLoggedInUser } from 'store/modules/auth'
import {
  getScanner,
  selectScanner,
  selectSitesStatus,
} from 'store/modules/sites'

import Chart from './Chart'
import Info from './Info'

export const getPhantomAnalysesFromScanner = scanner =>
  scanner.fmri_phantom_qa_analyses
    .filter(analysis => Boolean(analysis.fmri_phantom_qa_data))
    .map(analysis => ({
      ...analysis,
      fmri_phantom_qa_data: analysis.fmri_phantom_qa_data.map(data =>
        keys(data).reduce((acc, elem) => {
          acc[elem] = Number(Number(data[elem]).toFixed(2))
          return acc
        }, {}),
      ),
    }))

const ScannerDetailPage = () => {
  const scanner = useSelector(selectScanner)
  const status = useSelector(selectSitesStatus)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()

  const params = useParams()

  useEffect(() => {
    dispatch(getScanner(params.scannerId))
  }, [dispatch, params])

  if (status === getScanner.pending().type) {
    return <Loader />
  }

  if (!scanner) {
    return null
  }

  const analyses = getPhantomAnalysesFromScanner(scanner)

  return (
    <PageLayout heading={scanner.full_name} description={scanner.label}>
      <Card>
        <Tabs
          tabPosition="top"
          items={[
            {
              key: 'info',
              label: 'Info',
              children: <Info scanner={scanner} user={user} />,
            },
            {
              key: 'chart',
              label: 'Chart',
              children: <Chart phantomAnalyses={analyses} />,
            },
          ]}
        />
      </Card>
    </PageLayout>
  )
}

export default ScannerDetailPage
