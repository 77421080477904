import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import { POLYSSIFIER_INCLUDES } from 'config/base'
import { getParameterLayouts } from 'utils/common'

const { formLayout } = getParameterLayouts(true)

const PolyssifierOptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderButtons = () => {
    const { include } = analysisOptions

    return (
      <Checkbox.Group
        style={{ width: '100%' }}
        disabled={readOnly}
        defaultValue={include?.value}
        onChange={values => handleSetOption('include', 'value', values)}
      >
        <Row>
          {POLYSSIFIER_INCLUDES.map(elem => (
            <Col md={6} key={elem.value}>
              <Checkbox value={elem.value}>{elem.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    )
  }

  return (
    <Row data-testid="polyssifier-option-editor">
      <Col span={24}>
        <Form.Item {...formLayout} label="Number of Folds">
          <InputNumber
            value={analysisOptions?.n_folds?.value}
            min={2}
            max={10}
            disabled={readOnly}
            precision={0}
            onChange={value => handleSetOption('n_folds', 'value', value)}
          />
        </Form.Item>

        <Form.Item {...formLayout} label="Classifiers to Run">
          {renderButtons()}
        </Form.Item>
      </Col>
    </Row>
  )
}

PolyssifierOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

PolyssifierOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default PolyssifierOptionEditor
