import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table } from 'antd'
import { useEffect, useMemo, useState } from 'react'

import { Drawer, UserSelectForm } from 'components'
import * as datafilesRedux from 'store/modules/datafiles'

const SharedUsers = ({
  editable,
  parameterSet,
  status,
  users,
  updateParameterSet,
}) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const filteredUsers = useMemo(() => {
    const sharedUserIds = parameterSet.shared_users.map(user => user.id)
    return users.filter(user => !sharedUserIds.includes(user.id))
  }, [users, parameterSet])

  useEffect(() => {
    if (status === datafilesRedux.updateParameterSet.fulfilled().type) {
      setShowDrawer(false)
    }
  }, [status])

  const columns = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    editable && {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => (
        <Button
          danger
          size="small"
          shape="circle"
          icon={<DeleteOutlined />}
          data-testid="parameter-set-shared-user-remove-button"
          onClick={() => handleDelete(record)}
        />
      ),
    },
  ].filter(Boolean)

  const toggleDrawer = () => setShowDrawer(prevState => !prevState)

  const handleAdd = ({ user }) => {
    updateParameterSet({
      id: parameterSet.id,
      data: {
        shared_users: [...parameterSet.shared_users.map(dp => dp.id), user],
      },
    })
  }

  const handleDelete = record => {
    Modal.confirm({
      title: 'Are you sure want to revoke permission from this user?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleDeleteUser(record)
      },
    })
  }

  const handleDeleteUser = ({ id }) => {
    updateParameterSet({
      id: parameterSet.id,
      data: {
        shared_users: parameterSet.shared_users
          .filter(dp => dp.id !== id)
          .map(dp => dp.id),
      },
    })
  }

  return (
    <div>
      <h2 className="text-center mb-2">Shared Users</h2>
      {editable && (
        <div className="text-right mb-1">
          <Button
            type="primary"
            icon={<PlusOutlined />}
            data-testid="parameter-set-shared-user-add-button"
            onClick={toggleDrawer}
          >
            Add User
          </Button>
        </div>
      )}
      {parameterSet.shared_users.length > 0 ? (
        <Table
          dataSource={parameterSet.shared_users}
          columns={columns}
          size="small"
          rowKey="id"
          pagination={false}
          bordered
        />
      ) : (
        <Empty description="No Shared Users" />
      )}
      <Drawer title="Add User" open={showDrawer} onClose={toggleDrawer}>
        <UserSelectForm
          submitting={
            status === datafilesRedux.updateParameterSet.pending().type
          }
          users={filteredUsers}
          onSubmit={handleAdd}
          onCancel={toggleDrawer}
        />
      </Drawer>
    </div>
  )
}

export default SharedUsers
