import { Col, Divider, Form, Row, Switch } from 'antd'
import { first, get, map } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MetadataEditor, Option, Select } from 'components'
import { SPM_GLM_DESIGN_TYPES } from 'config/base'
import ContrastsOptionEditor from 'containers/Sections/Upload/SPMGLM/ContrastsOptionEditor'
import {
  initAnalysisOptions,
  selectAnalysis,
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'

import ScansOptionEditor from './ScansOptionEditor'

const IMPLEMENTED_DESIGN_TYPES = ['One Sample T-Test', 'Two Sample T-Test']

export const GroupSPMGLMUploadSection = ({ readOnly }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  const initialMetadataValue = get(
    analysis,
    'parameters.analysis.options.Metadata.value',
  )
  const [isMetadataSelected, setIsMetadataSelected] = useState(
    Boolean(initialMetadataValue),
  )
  const [dataOrder, setDataOrder] = useState([])

  useEffect(() => {
    // Initialize analysis options.
    if (analysis) {
      const { options } = analysis.parameters.analysis

      dispatch(initAnalysisOptions(options))
    } else {
      dispatch(
        initAnalysisOptions({
          Contrasts: { value: [] },
          Scans: { value: [] },
          Design_Type: { value: first(IMPLEMENTED_DESIGN_TYPES) },
          Scans_Source: { value: 'datafile' },
        }),
      )
    }
  }, [analysis, dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const isImplementedDesignType = designType =>
    IMPLEMENTED_DESIGN_TYPES.includes(designType)
  const designType = get(analysisOptions, 'Design_Type.value')

  return (
    <Form.Provider>
      <Form layout="vertical">
        <Row gutter={24}>
          <Col md={8}>
            <Form.Item
              label="Design Type:"
              validateStatus={designType ? 'success' : 'error'}
            >
              <Select
                className="w-100"
                value={designType || undefined}
                disabled={readOnly}
                placeholder="Please select the design type"
                onChange={value =>
                  handleSetOption('Design_Type', 'value', value)
                }
              >
                {map(SPM_GLM_DESIGN_TYPES, design_type => (
                  <Option
                    key={design_type}
                    value={design_type}
                    disabled={!isImplementedDesignType(design_type)}
                  >
                    {design_type}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col md={8}>
            <Switch
              style={{ marginTop: 34 }}
              checkedChildren="SPM GLM Metadata"
              unCheckedChildren="SPM GLM Metadata"
              checked={isMetadataSelected}
              onChange={() => setIsMetadataSelected(!isMetadataSelected)}
            />
          </Col>
        </Row>

        {isMetadataSelected && (
          <>
            <Divider />
            <MetadataEditor
              searchDataFile
              initialValue={initialMetadataValue}
              allowedHeaders={['subject', 'session', 'series', 'datafile']}
              onChange={metadata => setDataOrder(get(metadata, 'rows'))}
            />
          </>
        )}
        <Divider />

        <ScansOptionEditor
          readOnly={readOnly}
          analysisType={analysisType}
          dataOrder={dataOrder}
          handleSetOption={handleSetOption}
        />
      </Form>

      <ContrastsOptionEditor readOnly={readOnly} />
    </Form.Provider>
  )
}

GroupSPMGLMUploadSection.propTypes = {
  readOnly: PropTypes.bool,
}

GroupSPMGLMUploadSection.defaultProps = {
  readOnly: false,
}

export default GroupSPMGLMUploadSection
