import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ASLOptionEditor,
  BrainvizOptionEditor,
  DFNCOptionEditor,
  DTIOptionEditor,
  FMRI32OptionEditor,
  FMRIPhantomQAOptionEditor,
  FunctionalMRIPreprocOptionEditor,
  GroupICAOptionEditor,
  GroupSPMGLMOptionEditor,
  Loader,
  MancovaOptionEditor,
  OptionEditor,
  PolyssifierOptionEditor,
  RegressionOptionEditor,
  SPMGLMOptionEditor,
  StructuralMRIOptionEditor,
  VariableConfigurationTable,
  WMHOptionEditor,
} from 'components'
import {
  getParameterSet,
  selectDataFilesStatus,
  selectParameterSet,
} from 'store/modules/datafiles'

export const ParamView = ({ analysis, file, label }) => {
  const parameterSet = useSelector(selectParameterSet)
  const status = useSelector(selectDataFilesStatus)

  const dispatch = useDispatch()

  useEffect(() => {
    if (parameterSet?.id !== analysis.parameter_set) {
      dispatch(getParameterSet(analysis.parameter_set))
    }
  }, [dispatch, parameterSet, analysis])

  const renderSubEditor = () => {
    const props = {
      analysisOptions: analysis.parameters,
      file,
      readOnly: true,
    }

    switch (label) {
      case 'Regression':
        return <RegressionOptionEditor {...props} />
      case 'Polyssifier':
        return <PolyssifierOptionEditor {...props} />
      case 'GICA':
        return <GroupICAOptionEditor {...props} />
      case 'dFNC':
        return <DFNCOptionEditor {...props} />
      case 'BViZ':
        return <BrainvizOptionEditor {...props} />
      case 'MANCOVA':
        return <MancovaOptionEditor {...props} />
      case 'VBM':
        return <StructuralMRIOptionEditor {...props} />
      case 'fMRI':
        return <FunctionalMRIPreprocOptionEditor {...props} />
      case 'fMRI_32ch':
        return <FMRI32OptionEditor {...props} />
      case 'DTI':
        return <DTIOptionEditor {...props} />
      case 'SPMGLM':
        return <SPMGLMOptionEditor {...props} />
      case 'GroupSPMGLM':
        return <GroupSPMGLMOptionEditor {...props} />
      case 'ASL':
        return <ASLOptionEditor {...props} />
      case 'fMRI_PhantomQA':
        return <FMRIPhantomQAOptionEditor {...props} />
      case 'WMH':
        return <WMHOptionEditor {...props} />
      default:
        return null
    }
  }

  const isLoading = status === getParameterSet.pending().type

  if (!parameterSet || isLoading) {
    return <Loader />
  }

  return (
    <div data-testid="parameter-view">
      <VariableConfigurationTable file={file} readOnly />
      <OptionEditor parameterSet={parameterSet} readOnly fullWidth />
      <div>{renderSubEditor()}</div>
    </div>
  )
}

ParamView.propTypes = {
  analysis: PropTypes.object,
  file: PropTypes.object,
  label: PropTypes.string,
}

export default ParamView
