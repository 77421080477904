import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { selectIsSuperUser } from 'store/modules/auth'

const AdminRoute = ({ children }) => {
  const isSuperUser = useSelector(selectIsSuperUser)

  if (!isSuperUser) {
    return <Navigate to="/not-found" replace />
  }

  return children
}

export default AdminRoute
