import { Button, Col, Form, Input, Row } from 'antd'
import { concat, find, get, pick, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'
import { validators } from 'validators'

const StudyForm = ({ sites, study, submitting, user, onCancel, onSubmit }) => {
  const [form] = Form.useForm()
  const selectedSite = Form.useWatch('site', form)

  const initialValues = useMemo(() => {
    if (!study) {
      return {}
    }

    return {
      ...pick(study, 'full_name', 'label'),
      site: get(study, 'site.id'),
      principal_investigator: get(study, 'principal_investigator.id'),
    }
  }, [study])

  const availablePIs = useMemo(() => {
    let PIs = []

    if (selectedSite && user.site_role !== 'Member') {
      PIs = get(find(sites, { id: selectedSite }), 'members', [])

      if (study) {
        PIs = uniqBy(concat(PIs, study.created_by), 'id')
      }
    }

    return PIs
  }, [selectedSite, sites, user, study])

  const handleSubmit = values => {
    onSubmit({ id: get(study, 'id'), data: values })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      disabled={submitting}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="full_name"
            label="Name"
            extra="Full name of the study"
            rules={validators.genericRequired}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="label"
            label="Label"
            extra="Abbreviation for study, no spaces"
            rules={validators.genericRequired}
          >
            <Input placeholder="Label" />
          </Form.Item>

          {sites.length > 0 && (
            <>
              <Form.Item
                name="site"
                label="Site"
                rules={validators.genericRequired}
              >
                <Select
                  name="site"
                  options={sites.map(({ id, label }) => ({
                    label,
                    value: id,
                  }))}
                  onChange={() =>
                    form.setFieldsValue({ principal_investigator: null })
                  }
                />
              </Form.Item>

              <Form.Item
                name="principal_investigator"
                label="PI"
                rules={validators.genericRequired}
              >
                <Select
                  disabled={availablePIs.length === 0}
                  options={availablePIs.map(({ id, username }) => ({
                    label: username,
                    value: id,
                  }))}
                />
              </Form.Item>
            </>
          )}
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {!study ? 'Add' : 'Save'}
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

StudyForm.propTypes = {
  sites: PropTypes.array,
  study: PropTypes.object,
  submitting: PropTypes.bool,
  user: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default StudyForm
