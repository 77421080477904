import { Form, Spin } from 'antd'
import { isEmpty, map, orderBy } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from 'components'
import { ANALYSIS_RESULTS, ANALYSIS_TYPES_ID } from 'config/base'
import {
  initAnalysisOptions,
  listAnalysis,
  selectAnalyses,
  selectAnalysis,
  selectAnalysisOptions,
  setAnalysisOption,
} from 'store/modules/analyses'

import DefaultUploadSection from '../DefaultUpload'

export const DFNCUploadSection = () => {
  const analyses = useSelector(selectAnalyses)
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)

  const dispatch = useDispatch()

  const icaParent = !isEmpty(analysisOptions?.ica_parent)

  useEffect(() => {
    const params = {
      pageSize: 1000,
      status: ANALYSIS_RESULTS.Complete,
      analysis_type: ANALYSIS_TYPES_ID.GICA,
    }

    dispatch(listAnalysis({ params }))
  }, [dispatch])

  useEffect(() => {
    if (!isEmpty(analyses?.results)) {
      if (analysis) {
        const { options } = analysis.parameters.analysis
        dispatch(initAnalysisOptions(options))
      } else {
        dispatch(initAnalysisOptions({ ica_parent: { value: 'default' } }))
      }
    }
  }, [analysis, analyses, dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const sortedAnalyses = orderBy(analyses.results, ['id'], ['desc'])

  return (
    <>
      <Spin size="large" spinning={!icaParent}>
        <Form layout="vertical">
          <Form.Item label="Select a previously run Group ICA:">
            <Select
              style={{ width: '100%' }}
              defaultValue={['default']}
              value={analysisOptions?.ica_parent?.value || 'default'}
              options={[
                { label: 'Select a previous Group ICA', value: 'default' },
                ...map(sortedAnalyses, ({ id, name }) => ({
                  label: `${id} - ${name}`,
                  value: id,
                })),
              ]}
              onChange={value => handleSetOption('ica_parent', 'value', value)}
            />
          </Form.Item>
        </Form>
      </Spin>
      <DefaultUploadSection />
    </>
  )
}

export default DFNCUploadSection
