import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from 'components'
import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('VBM')

const SegmentationForm = ({ analysisOptions, readOnly, setOption }) => {
  const updateArrayIndex = (sourceArr, arrName, index, value) => {
    const destArr = sourceArr.map((elem, eIdx) =>
      eIdx === index ? parseFloat(value) : elem,
    )

    setOption(arrName, 'value', destArr)
  }

  const renderArrayForm = (fieldLabel, fieldName, inputProps) => {
    const fieldValue = get(analysisOptions, [fieldName, 'value'])
    return (
      <Form.Item label={fieldLabel} {...formLayout}>
        {fieldValue.map((_, id) => (
          <InputNumber
            disabled={readOnly}
            style={{ marginLeft: id ? 5 : 0 }}
            key={`${fieldName}_${id}`}
            id={`${fieldName}_${id}`}
            value={fieldValue[id]}
            {...inputProps}
            onChange={value =>
              updateArrayIndex(fieldValue, fieldName, id, value)
            }
          />
        ))}
      </Form.Item>
    )
  }

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item label={fieldLabel} {...formLayout}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const renderSelect = (fieldLabel, fieldName, options) => (
    <Form.Item label={fieldLabel} {...formLayout}>
      <Select
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        options={options}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const renderCheckbox = (fieldLabel, fieldName) => (
    <Form.Item label={fieldLabel} {...formLayout}>
      <Checkbox
        disabled={readOnly}
        checked={get(analysisOptions, [fieldName, 'value'])}
        onChange={event => setOption(fieldName, 'value', event.target.checked)}
      />
    </Form.Item>
  )

  return (
    <Row>
      <Col span={18} style={{ paddingRight: 26 }}>
        {renderSelect(
          'Bias regularization options [Extremely light-Very heavy]',
          'BIAS_REGULARIZATION',
          [
            { value: 0, label: 'None (0)' },
            { value: 0.00001, label: 'Extremely Light (0.00001)' },
            { value: 0.0001, label: 'Very Light (0.0001)' },
            { value: 0.001, label: 'Light (0.001)' },
            { value: 0.01, label: 'Medium (0.01)' },
            { value: 0.1, label: 'Heavy (0.1)' },
            { value: 1, label: 'Very Heavy (1.0)' },
            { value: 10, label: 'Very Heavy (10.0)' },
          ],
        )}
        {renderInputNumber(
          'FWHM_GAUSSIAN_SMOOTH_BIAS(mm)',
          'FWHM_GAUSSIAN_SMOOTH_BIAS',
          { min: 0, step: 0.0001 },
        )}
        {renderSelect('Affine Regularization', 'affine_regularization', [
          { value: 'mni', label: 'MNI' },
          { value: 'eastern', label: 'Eastern' },
          { value: 'subj', label: 'Subject' },
          { value: 'none', label: 'None' },
        ])}

        {renderArrayForm('Warping Regularization', 'warping_regularization', {
          min: 0,
          max: 1,
          step: 0.1,
        })}

        {renderInputNumber('Sampling Distance', 'sampling_distance', {
          min: 0,
          step: 0.0001,
          max: 10,
        })}
        {renderInputNumber('Markov Random Field Weighting', 'mrf_weighting', {
          min: 0,
          step: 0.0001,
          max: 10,
        })}
        {renderInputNumber('Cleanup', 'cleanup', { min: 0, max: 2 })}
        {renderCheckbox('Implicit Masking?', 'smoothing_implicit_masking')}
      </Col>
    </Row>
  )
}

SegmentationForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

SegmentationForm.defaultProps = {
  readOnly: false,
}

export default SegmentationForm
