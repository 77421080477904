import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Button, Layout } from 'antd'
import classNames from 'classnames'
import { useState } from 'react'

import Menu from './Menu'

const MobileSidebar = () => {
  const [opened, setOpened] = useState(false)

  const toggleOpened = () => setOpened(prevState => !prevState)

  return (
    <>
      <Layout.Sider
        width={250}
        trigger={null}
        data-testid="mobile-sidebar"
        className={classNames('sidebar', { 'sidebar--opened': opened })}
      >
        <Button
          className="sidebar__toggler"
          icon={opened ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
          type="primary"
          data-testid="mobile-sidebar-toggler"
          onClick={toggleOpened}
        />
        <Menu />
      </Layout.Sider>

      {opened && (
        <div
          className="sidebar__back"
          data-testid="mobile-sidebar-backdrop"
          onClick={toggleOpened}
        />
      )}
    </>
  )
}

export default MobileSidebar
