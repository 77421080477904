import { Tabs, Typography } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { encodePathURL } from 'utils/analyses'

const { Title } = Typography

const BrainvizResult = ({ data, token }) => {
  const { figures, label_confidence, label_prediction, save_path } = data

  return (
    <div className="analysis-result" data-testid="bviz-result">
      <div>
        <img src="/logos/brainviz_logo.png" alt="BrainViz!" />
      </div>
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'label-prediction',
            label: 'Label Prediction',
            children: (
              <>
                <Title level={2}>{label_prediction}</Title>
                <Title level={3}>Confidence: {label_confidence}</Title>
              </>
            ),
          },
        ]}
      />

      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'figures',
            label: 'Discriminative Networks',
            children: (
              <>
                {figures &&
                  map(figures, (item, ind) => (
                    <div
                      key={ind}
                      style={{ marginTop: '1.5rem' }}
                      data-testid="brainviz-figure"
                    >
                      <img
                        id={ind}
                        alt={ind}
                        src={encodePathURL(save_path, item, token)}
                      />
                    </div>
                  ))}
              </>
            ),
          },
        ]}
      />
    </div>
  )
}

BrainvizResult.propTypes = {
  data: PropTypes.shape({
    figures: PropTypes.array,
    label_confidence: PropTypes.any,
    label_prediction: PropTypes.any,
    save_path: PropTypes.string,
  }),
  token: PropTypes.string,
}

export default BrainvizResult
