import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Typography } from 'antd'
import classNames from 'classnames'
import queryString from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { authApi } from 'apis'
import { parseError } from 'utils/error-parser'

export const PasswordResetPage = () => {
  const dispatch = useDispatch()
  const { search } = useLocation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const [newPassword, setNewPassword] = useState(null)

  const handleVerifyEmail = useCallback(async code => {
    setError(null)
    setIsLoading(true)

    try {
      const data = await authApi.verifyEmail({ token: code })
      setNewPassword(data.new_password)
    } catch (err) {
      setError(parseError(err).message)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    const { code } = queryString.parse(search)

    if (code) {
      handleVerifyEmail({ token: code })
    } else {
      navigate('/login')
    }
  }, [search, dispatch, navigate, handleVerifyEmail])

  const icon = useMemo(() => {
    const className = classNames('password-reset__icon', {
      loading: isLoading,
      success: !error,
      fail: Boolean(error),
    })

    if (isLoading) {
      return <LoadingOutlined className={className} />
    }

    if (!error) {
      return <CheckCircleOutlined className={className} />
    }

    return <ExclamationCircleOutlined className={className} />
  }, [error, isLoading])

  return (
    <div className="password-reset">
      <div className="password-reset__message">
        {icon}

        <div style={{ marginTop: 40 }}>
          {isLoading && (
            <Typography.Title>Resetting your password...</Typography.Title>
          )}

          {!isLoading && !error && (
            <>
              <Typography.Title>Password reset successful.</Typography.Title>

              <Typography.Title level={5}>
                New password is {newPassword}.<br />
                You can change this on your profile page.
                <br />
                <Link to="/login">Click here</Link> to go to main page.
              </Typography.Title>
            </>
          )}
          {!isLoading && Boolean(error) && (
            <>
              <Typography.Title>
                Failed to reset your password.
              </Typography.Title>

              <Typography.Title level={5}>
                {error}
                <br />
                <Link to="/login">Click here</Link> to go to main page.
              </Typography.Title>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default PasswordResetPage
