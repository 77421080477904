import { Tabs } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { datafilesApi } from 'apis/datafiles'
import { parseError } from 'utils/error-parser'

import DataFileTab from './DataFileTab'
import ScanParamsTab from './ScanParamsTab'

const DetailView = ({ dataFile }) => {
  const [scanParamsData, setScanParamsData] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  const getScanParameters = useCallback(async () => {
    try {
      const data = await datafilesApi.getScanParams(dataFile.id)

      setScanParamsData(data)
      setLoading(false)
    } catch (err) {
      setError(get(parseError(err), 'message', 'Failed to get scan parameters'))
      setLoading(false)
    }
  }, [dataFile.id])

  useEffect(() => {
    getScanParameters()
  }, [getScanParameters])

  return (
    <Tabs
      animated={false}
      items={[
        {
          key: 'file',
          label: 'File',
          children: <DataFileTab dataFile={dataFile} />,
        },
        {
          key: 'scan-parameters',
          label: 'Scan Parameters',
          children: (
            <ScanParamsTab
              data={scanParamsData}
              loading={loading}
              error={error}
            />
          ),
        },
      ]}
    />
  )
}

DetailView.propTypes = {
  datafile: PropTypes.object.isRequired,
}

export default DetailView
