import axios from 'axios'

export const datafilesApi = {
  listDataFile: config =>
    axios.get('/data-file/', config).then(res => res.data),

  getDataFile: dataFileId =>
    axios.get(`/data-file/${dataFileId}/`).then(res => res.data),

  deleteDataFile: dataFileId =>
    axios.delete(`/data-file/${dataFileId}/`).then(res => res.data),

  listDataDirectory: config =>
    axios.get('/data-directory/', config).then(res => res.data),

  listAllDataDirectory: config =>
    axios.get('/data-directory-all/', config).then(res => res.data),

  runMultipleAnalyses: payload =>
    axios.patch(`/data-directory/${payload}/run-multi/`).then(res => res.data),

  runSingleAnalysis: ({ id, stepId }) =>
    axios
      .patch(`/data-directory/${id}/run-single/${stepId}/`)
      .then(res => res.data),

  uploadFiles: data => axios.post('/data-file/', data).then(res => res.data),

  uploadMiscFiles: data =>
    axios.post('/misc-file/', data).then(res => res.data),

  listProtocolData: config =>
    axios.get('/protocol-data/', config).then(res => res.data),

  listParameterSet: config =>
    axios.get('/parameter-set/', config).then(res => res.data),

  createParameterSet: data =>
    axios.post('/parameter-set/', data).then(res => res.data),

  getParameterSet: parameterSetId =>
    axios.get(`/parameter-set/${parameterSetId}/`).then(res => res.data),

  updateParameterSet: ({ id, data }) =>
    axios.patch(`/parameter-set/${id}/`, data).then(res => res.data),

  deleteParameterSet: parameterSetId =>
    axios.delete(`/parameter-set/${parameterSetId}/`).then(res => res.data),

  createAnalysisPlan: data =>
    axios.post('/analysis-plan/', data).then(res => res.data),

  updateAnalysisPlan: ({ id, data }) =>
    axios.patch(`/analysis-plan/${id}/`, data).then(res => res.data),

  deleteAnalysisPlan: analysisPlanId =>
    axios.delete(`/analysis-plan/${analysisPlanId}/`).then(res => res.data),

  listMetadata: config =>
    axios.get('/data-file/', config).then(res => res.data),

  getMetadata: url => axios.get(url).then(res => res.data),

  listMiscFile: () => axios.get('/misc-file/').then(res => res.data),

  getMiscFile: miscFileId =>
    axios.get(`/misc-file/${miscFileId}/`).then(res => res.data),

  deleteMiscFile: miscFileId =>
    axios.delete(`/misc-file/${miscFileId}/`).then(res => res.data),

  updateMiscFile: ({ id, data }) =>
    axios.patch(`/misc-file/${id}/`, data).then(res => res.data),

  readMiscFile: ({ id, searchDataFile }) =>
    axios
      .get(`/misc-file/${id}/read/`, { params: { datafile: searchDataFile } })
      .then(res => res.data),

  listAnalysisPlan: config =>
    axios.get('/analysis-plan/', config).then(res => res.data),

  createAnalysisPlanStep: data =>
    axios.post('/analysis-plan-step/', data).then(res => res.data),

  updateAnalysisPlanStep: ({ id, data }) =>
    axios.put(`/analysis-plan-step/${id}/`, data).then(res => res.data),

  deleteAnalysisPlanStep: analysisPlanStepId =>
    axios
      .delete(`/analysis-plan-step/${analysisPlanStepId}/`)
      .then(res => res.data),

  getScanParams: payload =>
    axios.get(`/data-directory/${payload}/scan_params/`).then(res => res.data),
}
