import { List, Tabs } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const { Item } = List

const VBMResult = ({ data, dataFile, token }) => {
  const {
    all_files,
    all_subject_figures,
    summary,
    qa_summary,
    qa_subjects,
    out_dir,
    save_path,
  } = data
  const outDir = out_dir || save_path

  return (
    <div className="analysis-result" data-testid="vbm-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      label: 'Summary',
                      key: 'output-summary',
                      children: (
                        <div
                          className="analysis-result__description text-left"
                          data-testid="output-summary"
                        >
                          {summary}
                        </div>
                      ),
                    },
                    {
                      label: 'Files',
                      key: 'output-files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'quality',
            label: 'Quality',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'qa-summary',
                      label: 'Summary',
                      children: (
                        <div
                          className="analysis-result__description text-left"
                          data-testid="qa-summary"
                        >
                          {qa_summary}
                        </div>
                      ),
                    },
                    {
                      key: 'qa-flagged-subjects',
                      label: 'Flagged Subjects',
                      children: (
                        <List data-testid="qa-flagged-subjects">
                          {map(qa_subjects, (corrs, subject) => (
                            <Item key={subject}>
                              {subject}: {corrs}
                            </Item>
                          ))}
                        </List>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'figures',
            label: 'Figures',
            children: (
              <>
                {map(all_subject_figures, (subject, ind) => (
                  <div
                    key={ind}
                    style={{ marginTop: '1rem' }}
                    data-testid="figure"
                  >
                    <div className="analysis-result__subheading">
                      Subject {ind + 1}
                    </div>
                    <Tabs
                      defaultActiveKey="Grey matter"
                      tabPosition="left"
                      animated={false}
                      items={map(subject, (something, anat_type) => ({
                        key: anat_type,
                        label: anat_type,
                        children: (
                          <Tabs
                            defaultActiveKey="warped"
                            tabPosition="top"
                            animated={false}
                            items={map(something, (img, pp_type) => ({
                              key: pp_type,
                              label: pp_type,
                              children: (
                                <img
                                  id={pp_type}
                                  alt={pp_type}
                                  src={encodePathURL(outDir, img, token)}
                                />
                              ),
                            }))}
                          />
                        ),
                      }))}
                    />
                  </div>
                ))}
              </>
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

VBMResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    all_subject_figures: PropTypes.array,
    out_dir: PropTypes.string,
    qa_subjects: PropTypes.any,
    qa_summary: PropTypes.any,
    save_path: PropTypes.string,
    summary: PropTypes.any,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default VBMResult
