import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Modal, Switch, Table, Tooltip } from 'antd'
import { compact, find, get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Drawer } from 'components'
import { selectAnalysisTypes } from 'store/modules/analyses'
import {
  deleteAnalysisPlanStep,
  selectDataFilesError,
  selectDataFilesStatus,
  selectParameterSets,
  updateAnalysisPlanStep,
} from 'store/modules/datafiles'

import AnalysisPlanStepForm from './AnalysisPlanStepForm'

export const AnalysisPlanStepsTable = ({
  id: analysisPlanId,
  inCompactMode,
  isEditable,
  steps,
  startAnalysis,
}) => {
  const analysisTypes = useSelector(selectAnalysisTypes)
  const error = useSelector(selectDataFilesError)
  const parameterSets = useSelector(selectParameterSets)
  const status = useSelector(selectDataFilesStatus)

  const dispatch = useDispatch()

  const [editingRecord, setEditingRecord] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)

  useEffect(() => {
    if (status === updateAnalysisPlanStep.fulfilled().type) {
      setEditingRecord(null)
      setShowDrawer(null)
    }
  }, [status])

  useEffect(() => {
    if (!showDrawer) {
      setEditingRecord(null)
    }
  }, [showDrawer])

  const submitting = status === updateAnalysisPlanStep.pending().type

  const columns = compact([
    {
      title: 'Analysis',
      dataIndex: 'analysis_type',
      key: 'analysis_type',
      width: '20%',
      render: text => get(find(analysisTypes, { id: text }), 'label'),
    },
    {
      title: 'Parameter Set',
      dataIndex: 'parameter_set',
      key: 'parameter_set',
      width: '50%',
      render: text => renderParameterSetName(text),
    },
    {
      title: 'Automatic',
      dataIndex: 'automatic',
      key: 'automatic',
      width: '11%',
      render: (checked, record) => (
        <Switch
          checked={checked}
          onChange={value =>
            handleToggleAutomatic({
              ...record,
              automatic: value,
              analysis_plan: analysisPlanId,
            })
          }
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      width: '9%',
      render: (_, record) => renderActions(record),
    },
  ])

  const renderParameterSetName = parameterSetId => {
    const parameterSet = find(parameterSets, { id: parameterSetId })
    return `${parameterSet?.name} - v${parameterSet?.version}`
  }

  const renderActions = step => {
    if (!isEditable) {
      return (
        <Button size="small" onClick={() => startAnalysis(step)}>
          Start
        </Button>
      )
    }

    return (
      <div className="d-flex">
        <Tooltip title="Edit">
          <Button
            className="mr-05"
            shape="circle"
            icon={<EditOutlined />}
            size="small"
            data-testid="analysis-plan-step-edit-button"
            onClick={() => handleEdit(step)}
          />
        </Tooltip>
        <Tooltip title="Delete">
          <Button
            shape="circle"
            icon={<DeleteOutlined />}
            danger
            size="small"
            data-testid="analysis-plan-step-delete-button"
            onClick={() => handleDelete(step)}
          />
        </Tooltip>
      </div>
    )
  }

  const toggleDrawer = () => {
    setShowDrawer(prevState => !prevState)
  }

  const handleEdit = record => {
    setEditingRecord(record)
    setShowDrawer(true)
  }

  const handleToggleAutomatic = record => {
    if (record.automatic) {
      Modal.confirm({
        title:
          'Are you sure want this analysis step to run automatically? This may start analyses immediately.',
        okText: 'Yes',
        cancelText: 'No',
        onOk() {
          handleSubmit(record)
        },
      })
    } else {
      handleSubmit(record)
    }
  }

  const handleSubmit = values => {
    const { id, ...data } = values
    dispatch(updateAnalysisPlanStep({ id, data }))
  }

  const handleDelete = record => {
    Modal.confirm({
      title: 'Are you sure want to delete this step?',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteAnalysisPlanStep(record.id))
      },
    })
  }

  const renderTable = () => {
    if (steps.length === 0) return null

    if (inCompactMode) {
      return (
        <table>
          <tbody>
            {steps.map(step => (
              <tr key={step.id}>
                <td className="pr-05">
                  {renderParameterSetName(step.parameter_set)}
                </td>
                <td>{renderActions(step)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )
    }

    return (
      <Table
        dataSource={steps}
        columns={columns}
        bordered
        size="small"
        pagination={false}
        rowKey="id"
      />
    )
  }

  const filteredAnalysisTypes = analysisTypes.filter(
    ({ label }) => label !== 'Regression' && label !== 'Polyssifier',
  )

  return (
    <div>
      {renderTable()}

      <Drawer
        title="Edit Analysis Plan Step"
        open={showDrawer}
        onClose={toggleDrawer}
      >
        <AnalysisPlanStepForm
          initialValues={editingRecord}
          analysisPlanId={analysisPlanId}
          error={error}
          analysisTypes={filteredAnalysisTypes}
          parameterSets={parameterSets}
          submitting={submitting}
          onSubmit={handleSubmit}
          onCancel={toggleDrawer}
        />
      </Drawer>
    </div>
  )
}

AnalysisPlanStepsTable.propTypes = {
  id: PropTypes.number,
  inCompactMode: PropTypes.bool,
  isEditable: PropTypes.bool,
  steps: PropTypes.array,
  startAnalysis: PropTypes.func,
}

export default AnalysisPlanStepsTable
