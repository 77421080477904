import { Button, Table } from 'antd'
import { filter, forEach, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'
import {
  COVARIATE_VARIABLE_ROLES,
  COVARIATE_VARIABLE_TYPES,
  TRANSFORMATION_TYPES,
  VARIABLE_EFFECTS,
} from 'config/base'

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Select Variable Interactions',
    dataIndex: 'selectResponse',
    key: 'selectResponse',
  },
  {
    title: 'Transformation',
    dataIndex: 'transformation',
    key: 'transformation',
  },
  {
    title: 'Variable Type',
    dataIndex: 'variableType',
    key: 'variableType',
  },
]

const CovariateConfigurationTable = ({
  className,
  file,
  readOnly,
  toggleAllCurrentFilesField,
  updateCurrentFilesField,
}) => {
  const handleVariableTypeChange = (type, index, value) => {
    const VARIABLE_CONSTANTS = {
      variable_role: COVARIATE_VARIABLE_ROLES,
      transformation: TRANSFORMATION_TYPES,
      variable_type: COVARIATE_VARIABLE_TYPES,
      effect: VARIABLE_EFFECTS,
    }

    updateCurrentFilesField({
      index,
      field: { [type]: VARIABLE_CONSTANTS[type][value].next },
    })
  }

  const handleSelect = (record, selected) => {
    updateCurrentFilesField({ index: record.key, field: { selected } })
  }

  const data = useMemo(() => {
    const res = []

    forEach(file.fields, ({ index, name, transformation, variable_type }) => {
      const interactions = [name]

      forEach(file.fields, field => {
        if (name !== field.name && field.selected) {
          interactions.push(`${name} X ${field.name}`)
        }
      })

      res.push({
        key: index,
        name,
        selectResponse: (
          <Select
            mode="multiple"
            defaultValue={[]}
            style={{ width: '100%' }}
            options={map(interactions, interaction => ({
              label: interaction,
              value: interaction,
            }))}
            onChange={e => {
              updateCurrentFilesField({
                index,
                field: { variable_interactions: { interaction: e } },
              })
            }}
          />
        ),
        transformation: (
          <Button
            size="small"
            data-testid="transformation-button"
            onClick={() => {
              handleVariableTypeChange('transformation', index, transformation)
            }}
          >
            {TRANSFORMATION_TYPES[transformation].label}
          </Button>
        ),
        variableType: (
          <Button
            size="small"
            data-testid="variable-type-button"
            onClick={() => {
              handleVariableTypeChange('variable_type', index, variable_type)
            }}
          >
            {COVARIATE_VARIABLE_TYPES[variable_type].label}
          </Button>
        ),
      })
    })

    return res
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableProps = useMemo(() => {
    const selectedFields = filter(file.fields, 'selected')
    const selectedRowKeys = map(selectedFields, 'index')

    return Object.assign(
      {},
      {
        dataSource: data,
        columns: COLUMNS,
        size: 'small',
        pagination: false,
        bordered: true,
        className,
        scroll: { x: true },
      },
      !readOnly && {
        title: () => `Configure covariates for ${file.name} (click to toggle)`,
        rowSelection: {
          selectedRowKeys,
          onSelectAll: toggleAllCurrentFilesField,
          onSelect: handleSelect,
        },
      },
    )
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  return data.length > 0 ? <Table {...tableProps} /> : null
}

CovariateConfigurationTable.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  readOnly: PropTypes.bool,
  toggleAllCurrentFilesField: PropTypes.func,
  updateCurrentFilesField: PropTypes.func,
}

CovariateConfigurationTable.defaultProps = {
  className: 'mb-4',
  readOnly: false,
  updateCurrentFilesField: noop,
  toggleAllCurrentFilesField: noop,
}

export default CovariateConfigurationTable
