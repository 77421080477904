import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { Select } from 'components'

const TagEditor = ({ editable, isLoading, selectedTags, tags, onChange }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [selected, setSelected] = useState([])

  const initializeTags = useCallback(() => {
    setSelected(selectedTags.map(tag => tag.id))
  }, [selectedTags])

  useEffect(() => {
    initializeTags()
  }, [initializeTags])

  const handleStartEditing = () => setIsEditing(true)

  const handleCancelEditing = () => {
    setIsEditing(false)
    initializeTags()
  }

  const handleSubmit = () => {
    setIsEditing(false)
    onChange(selected)
  }

  if (isEditing) {
    return (
      <div className="d-flex align-items-center">
        <Select
          mode="multiple"
          style={{ width: 200 }}
          placeholder="Select tags"
          value={selected}
          className="mr-02"
          options={tags.map(({ id, label }) => ({
            label,
            value: id,
          }))}
          onChange={setSelected}
        />
        <Button
          className="m-02"
          shape="circle"
          icon={<CheckOutlined />}
          size="small"
          disabled={isLoading}
          data-testid="save-tags"
          onClick={handleSubmit}
        />
        <Button
          className="m-02"
          shape="circle"
          icon={<CloseOutlined />}
          size="small"
          disabled={isLoading}
          data-testid="cancel-save-tags"
          onClick={handleCancelEditing}
        />
      </div>
    )
  }

  return (
    <div className="d-flex align-items-center">
      {selectedTags.map(tag => (
        <Tag key={tag.id} color={tag.color} className="my-02" data-testid="tag">
          {tag.label}
        </Tag>
      ))}
      {editable && (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          disabled={isLoading}
          data-testid="edit-tags"
          onClick={handleStartEditing}
        />
      )}
    </div>
  )
}

TagEditor.propTypes = {
  editable: PropTypes.bool,
  isLoading: PropTypes.bool,
  selectedTags: PropTypes.array,
  tags: PropTypes.array,
  onChange: PropTypes.func,
}

TagEditor.defaultProps = {
  selectedTags: [],
}

export default TagEditor
