import { Row, Tabs, Typography } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'

const { Text } = Typography

function formatNumber(num) {
  return (Math.round(num * 100) / 100).toFixed(2)
}

const FMRIPhantomQAResult = ({ data, dataFile, token }) => {
  const { all_files, figure_lookup, out_dir, report, save_path } = data

  const outDir = out_dir || save_path

  return (
    <div className="analysis-result" data-testid="fmri-phantom-qa-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'report',
            label: 'Report',
            children: (
              <div className="w-33 mx-auto text-left" data-testid="report">
                <Row>
                  <Text>Slice: {get(report, 'slice')}</Text>
                </Row>
                <Row>
                  <Text>Signal: {formatNumber(get(report, 'signal'))}</Text>
                </Row>
                <Row>
                  <Text>
                    Signal P2P (%):{' '}
                    {formatNumber(get(report, 'signal_p2p (%)'))}
                  </Text>
                </Row>
                <Row>
                  <Text>SNR: {formatNumber(get(report, 'snr'))}</Text>
                </Row>
                <Row>
                  <Text>
                    Ghost (%): {formatNumber(get(report, 'ghost (%)'))}
                  </Text>
                </Row>
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-33 mx-auto text-left">
                <OutputFileTree files={all_files} />
              </div>
            ),
          },
          {
            key: 'figures',
            label: 'Figures',
            children: (
              <div className="mt-1" data-testid="figures">
                <Tabs
                  defaultActiveKey="Montage"
                  tabPosition="left"
                  animated={false}
                  items={[
                    {
                      key: 'montage',
                      label: 'Montage',
                      children: (
                        <Tabs
                          defaultActiveKey="Mean Signal"
                          tabPosition="top"
                          animated={false}
                          items={[
                            {
                              label: 'Mean Signal',
                              key: 'mean_signal_montage',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Mean signal montage"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.mean_signal_montage,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              label: 'Min - Mean Signal',
                              key: 'min_mean_signal_montage',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Min - mean signal montage"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.min_mean_signal_montage,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              label: 'Standard Deviation',
                              key: 'std_dev_montage',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Standard deviation montage"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.std_dev_montage,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              label: 'Temporal SNR',
                              key: 'temporal_snr_montage',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Temporal SNR montage"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.temporal_snr_montage,
                                    token,
                                  )}
                                />
                              ),
                            },
                          ]}
                        />
                      ),
                    },
                    {
                      key: 'by_slice',
                      label: 'By Slice',
                      children: (
                        <Tabs
                          defaultActiveKey="Mean Signal"
                          tabPosition="top"
                          animated={false}
                          items={[
                            {
                              key: 'mean_signal_by_slice',
                              label: 'Mean Signal',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Mean signal by slice"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.mean_signal_by_slice,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              key: 'signal_p2p_by_slice',
                              label: 'Signal P2P',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Signal P2P by slice"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.signal_p2p_by_slice,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              key: 'snr_by_slice',
                              label: 'SNR',
                              children: (
                                <img
                                  className="w-100"
                                  alt="SNR by slice"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.snr_by_slice,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              key: 'ghost_by_slice',
                              label: 'Ghost',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Ghost level by slice"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.ghost_by_slice,
                                    token,
                                  )}
                                />
                              ),
                            },
                          ]}
                        />
                      ),
                    },
                    {
                      key: 'by_time',
                      label: 'By Time',
                      children: (
                        <Tabs
                          defaultActiveKey="Mean Signal"
                          tabPosition="top"
                          animated={false}
                          items={[
                            {
                              key: 'mean_signal_by_time',
                              label: 'Mean Signal',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Mean signal by time"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.mean_signal_by_time,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              key: 'snr_by_time',
                              label: 'SNR',
                              children: (
                                <img
                                  className="w-100"
                                  alt="SNR by time"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.snr_by_time,
                                    token,
                                  )}
                                />
                              ),
                            },
                            {
                              key: 'ghost_by_time',
                              label: 'Ghost',
                              children: (
                                <img
                                  className="w-100"
                                  alt="Ghost level by slice"
                                  src={encodePathURL(
                                    outDir,
                                    figure_lookup.ghost_by_time,
                                    token,
                                  )}
                                />
                              ),
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  )
}

FMRIPhantomQAResult.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    figure_lookup: PropTypes.object,
    out_dir: PropTypes.string,
    report: PropTypes.object,
    save_path: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default FMRIPhantomQAResult
