import { Checkbox, Table } from 'antd'
import { filter, forEach, map, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Univariate Regression',
    dataIndex: 'regression',
    key: 'regression',
  },
  {
    title: 'One-Sample T-Test',
    dataIndex: 't_test',
    key: 't_test',
  },
  {
    title: 'Two-Sample T-Test',
    dataIndex: 't_test_twosample',
    key: 't_test_twosample',
  },
  {
    title: 'Select Nuisance Variables',
    dataIndex: 'selectNuisance',
    key: 'selectNuisance',
  },
  {
    title: 'Select Paired Regressors',
    dataIndex: 'selectTwoTailed',
    key: 'selectTwoTailed',
  },
  {
    title: 'Select Groups',
    dataIndex: 'selectLabels',
    key: 'selectLabels',
  },
]

export const UnivariateConfigurationTable = ({
  className,
  file,
  readOnly,
  toggleAllCurrentFilesField,
  updateCurrentFilesField,
}) => {
  const data = useMemo(() => {
    const res = []

    forEach(
      file.fields,
      ({
        index,
        name,
        variable_paired_univariate,
        variable_ttest,
        variable_ttest_twosample,
        variable_regression,
        data_labels,
      }) => {
        const variable_names = []
        const interactions = []

        forEach(file.fields, field => {
          if (name !== field.name && field.selected) {
            variable_names.push(field.name)
            interactions.push(`${name} X ${field.name}`)
          }
        })

        res.push({
          key: index,
          name,
          regression: (
            <Checkbox
              data-testid="variable-regression-checkbox"
              checked={variable_regression}
              onClick={() => {
                updateCurrentFilesField({
                  index,
                  field: { variable_regression: !variable_regression },
                })
                if (!variable_regression) {
                  handleUnsetTwoSampleTTest()
                  handleUnsetTTest()
                }
              }}
            />
          ),
          t_test: (
            <Checkbox
              data-testid="variable-ttest-checkbox"
              checked={variable_ttest}
              onClick={() => {
                updateCurrentFilesField({
                  index,
                  field: { variable_ttest: !variable_ttest },
                })
                if (!variable_ttest) {
                  handleUnsetPairedUnivariate()
                  handleUnsetTwoSampleTTest()
                  handleUnsetRegression()
                  handleUnsetTTest(index)
                }
              }}
            />
          ),
          t_test_twosample: (
            <Checkbox
              data-testid="variable-ttest-twosample-checkbox"
              checked={variable_ttest_twosample}
              onClick={() => {
                updateCurrentFilesField({
                  index,
                  field: {
                    variable_ttest_twosample: !variable_ttest_twosample,
                  },
                })
                if (!variable_ttest_twosample) {
                  handleUnsetPairedUnivariate()
                  handleUnsetTTest()
                  handleUnsetRegression()
                  handleUnsetTwoSampleTTest(index)
                }
              }}
            />
          ),
          selectNuisance: (
            <Select
              mode="multiple"
              defaultValue={[]}
              style={{ width: '100%' }}
              options={map(variable_names, variableName => ({
                label: variableName,
                value: variableName,
              }))}
              onChange={e => {
                updateCurrentFilesField({
                  index,
                  field: { variable_nuisance: e },
                })
              }}
            />
          ),
          selectTwoTailed: (
            <Select
              mode="multiple"
              defaultValue={[]}
              style={{ width: '100%' }}
              value={variable_paired_univariate}
              options={map(interactions, interaction => ({
                label: interaction,
                value: interaction,
              }))}
              onChange={e => {
                updateCurrentFilesField({
                  index,
                  field: { variable_paired_univariate: e },
                })
              }}
            />
          ),
          selectLabels: data_labels && (
            <Select
              mode="multiple"
              defaultValue={[]}
              style={{ width: '100%' }}
              options={map(Object.keys(data_labels), dataLabel => ({
                label: dataLabel,
                value: dataLabel,
              }))}
              onChange={e => {
                updateCurrentFilesField({
                  index,
                  field: { selected_labels: e },
                })
              }}
            />
          ),
        })
      },
    )

    return res
  }, [file]) // eslint-disable-line react-hooks/exhaustive-deps

  const tableProps = () => {
    const selectedFields = filter(file.fields, 'selected')
    const selectedRowKeys = map(selectedFields, 'index')

    return Object.assign(
      {},
      {
        dataSource: data,
        columns: COLUMNS,
        size: 'small',
        pagination: false,
        bordered: true,
        className,
        scroll: { x: true },
      },
      !readOnly && {
        title: () =>
          `Configure univariate analyses for ${file.name}. One of the following may be selected: Univariate Regression, One-Sample T-Tests, or Two-Sample T-Tests. Only one variable may be run at a time for t-tests. Multiple variables and paired regressors/nuisance varaibles`,
        rowSelection: {
          selectedRowKeys,
          onSelectAll: toggleAllCurrentFilesField,
          onSelect: handleSelect,
        },
      },
    )
  }

  const handleUnsetTTest = refIndex => {
    forEach(file.fields, ({ index }) => {
      if (refIndex === undefined || index !== refIndex) {
        updateCurrentFilesField({
          index,
          field: { variable_ttest: false },
        })
      }
    })
  }

  const handleUnsetRegression = () => {
    forEach(file.fields, ({ index }) => {
      updateCurrentFilesField({
        index,
        field: { variable_regression: false },
      })
    })
  }

  const handleUnsetTwoSampleTTest = refIndex => {
    forEach(file.fields, ({ index }) => {
      if (refIndex === undefined || index !== refIndex) {
        updateCurrentFilesField({
          index,
          field: { variable_ttest_twosample: false },
        })
      }
    })
  }

  const handleUnsetPairedUnivariate = () => {
    forEach(file.fields, ({ index }) => {
      updateCurrentFilesField({
        index,
        field: { variable_paired_univariate: [] },
      })
    })
  }

  const handleSelect = (record, selected) => {
    updateCurrentFilesField({ index: record.key, field: { selected } })
  }

  return data.length > 0 ? <Table {...tableProps()} /> : null
}

UnivariateConfigurationTable.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  readOnly: PropTypes.bool,
  toggleAllCurrentFilesField: PropTypes.func,
  updateCurrentFilesField: PropTypes.func,
}

UnivariateConfigurationTable.defaultProps = {
  className: 'mb-4',
  readOnly: false,
  toggleAllCurrentFilesField: noop,
  updateCurrentFilesField: noop,
}

export default UnivariateConfigurationTable
