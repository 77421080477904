import PropTypes from 'prop-types'
import { withSizes } from 'react-sizes'

import { BREAKPOINTS } from 'config/base'

import DesktopSidebar from './Desktop'
import MobileSidebar from './Mobile'

export const Sidebar = ({ isDesktop }) =>
  isDesktop ? <DesktopSidebar /> : <MobileSidebar />

Sidebar.propTypes = {
  isDesktop: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isDesktop: width >= BREAKPOINTS.XL,
})

export default withSizes(sizes)(Sidebar)
