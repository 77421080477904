import { Layout } from 'antd'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { selectIsSidebarPinned } from 'store/modules/global'

import Menu from './Menu'

const DesktopSidebar = () => {
  const isSidebarPinned = useSelector(selectIsSidebarPinned)

  const [collapsed, setCollapsed] = useState(false)

  useEffect(() => {
    setCollapsed(!isSidebarPinned)
  }, [isSidebarPinned])

  const toggleCollapse = () => setCollapsed(prevState => !prevState)

  const handleMouseEvent = () => {
    if (isSidebarPinned) {
      return
    }

    toggleCollapse()
  }

  return (
    <Layout.Sider
      style={{ overflowY: 'auto' }}
      collapsible
      collapsed={collapsed}
      trigger={null}
      width={250}
      data-testid="desktop-sidebar"
      onCollapse={toggleCollapse}
      onMouseEnter={handleMouseEvent}
      onMouseLeave={handleMouseEvent}
    >
      <Menu collapsed={collapsed} />
    </Layout.Sider>
  )
}

export default DesktopSidebar
