import { MailOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Col, Form, Input, notification, Row } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { authApi } from 'apis'
import { validators } from 'validators'

const ForgotPasswordForm = ({ onChangeForm }) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async payload => {
    setIsLoading(true)

    try {
      await authApi.sendPasswordResetEmail(payload)
      notification.success({
        message: 'Check your inbox now',
        description: 'Successfully sent the password reset email.',
      })
    } catch {
      notification.error({
        message: 'Failed!',
        description: 'Failed to send the password reset email.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form
      disabled={isLoading}
      data-testid="forgot-password-form"
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="email" rules={validators.email} hasFeedback>
            <Input
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Button
            type="primary"
            icon={<MailOutlined />}
            htmlType="submit"
            loading={isLoading}
          >
            Send Email
          </Button>
        </Col>

        <Col span={24}>
          <Button type="link" onClick={onChangeForm}>
            Log In
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

ForgotPasswordForm.propTypes = {
  onChangeForm: PropTypes.func,
}

export default ForgotPasswordForm
