import { Button, Spin, Tooltip } from 'antd'
import axios from 'axios'
import { debounce, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { Select } from 'components'
import useSort from 'hooks/useSort'

const CustomAsyncSelect = ({
  disabled,
  fetchUrl,
  placeholder,
  searchByDefault,
  sortingInfo,
  value,
  filterData,
  labelRenderer,
  onChange,
  onSetData,
  valueRenderer,
  debounceTimeout,
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [options, setOptions] = useState([])

  const { icon, tooltip, sortedData, buttonType, onChangeSort } = useSort({
    data: options,
    sortingInfo,
  })

  const getLabel = useCallback(
    option => {
      if (typeof labelRenderer === 'function') {
        return labelRenderer(option)
      }

      return option[labelRenderer]
    },
    [labelRenderer],
  )

  const getValue = useCallback(
    option => {
      if (typeof valueRenderer === 'function') {
        return valueRenderer(option)
      }

      return option[valueRenderer]
    },
    [valueRenderer],
  )

  const loadOptions = async search => {
    setOptions([])
    setIsFetching(true)

    const { base, queryParams } = fetchUrl
    const params = Object.assign({ ...queryParams }, search && { search })

    try {
      const { data } = await axios.get(base, { params })

      onSetData(data)
      setOptions(data)
      setIsFetching(false)
    } catch {
      setOptions([])
      setIsFetching(false)
    }
  }

  const debounceFetcher = useMemo(
    () => debounce(loadOptions, debounceTimeout),
    [debounceTimeout, fetchUrl], // eslint-disable-line
  )

  useEffect(() => {
    if (searchByDefault && !disabled) {
      debounceFetcher()
    }
  }, []) // eslint-disable-line

  const notFoundContent = useMemo(() => {
    if (isFetching) {
      return <Spin size="small" />
    }

    return <div>Not Found</div>
  }, [isFetching])

  const handleFocus = () => loadOptions()

  const formattedOptions = useMemo(() => {
    const filteredOptions =
      typeof filterData === 'function' ? filterData(sortedData) : sortedData

    return filteredOptions.map(option => ({
      label: getLabel(option),
      value: getValue(option),
    }))
  }, [filterData, sortedData, getLabel, getValue])

  const handleChange = option => onChange(option)

  return (
    <div className="d-flex align-items-center column-gap-8">
      <Select
        allowClear
        placeholder={placeholder}
        filterOption={false}
        notFoundContent={notFoundContent}
        showSearch
        disabled={disabled}
        options={formattedOptions}
        value={value}
        onFocus={handleFocus}
        onSearch={debounceFetcher}
        onChange={handleChange}
      />

      {sortingInfo?.enabled && (
        <Tooltip title={tooltip}>
          <Button
            type={buttonType}
            className="flex-none"
            icon={icon}
            size="medium"
            disabled={disabled}
            onClick={onChangeSort}
          />
        </Tooltip>
      )}
    </div>
  )
}

CustomAsyncSelect.propTypes = {
  disabled: PropTypes.bool,
  fetchUrl: PropTypes.shape({
    base: PropTypes.string.isRequired,
    queryParams: PropTypes.object,
  }).isRequired,
  placeholder: PropTypes.string,
  searchByDefault: PropTypes.bool,
  sortingInfo: PropTypes.shape({
    enabled: PropTypes.bool,
    nameKey: PropTypes.string,
    chronoKey: PropTypes.string,
  }),
  value: PropTypes.any,
  debounceTimeout: PropTypes.number,
  filterData: PropTypes.func,
  labelRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onChange: PropTypes.func,
  onSetData: PropTypes.func,
  valueRenderer: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
}

CustomAsyncSelect.defaultProps = {
  sortingInfo: null,
  valueRenderer: 'id',
  labelRenderer: 'name',
  debounceTimeout: 500,
  onSetData: noop,
}

export default CustomAsyncSelect
