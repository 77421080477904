import { combineReducers } from '@reduxjs/toolkit'

import { reducer as analysesReducer } from 'store/modules/analyses'
import { reducer as authReducer } from 'store/modules/auth'
import { reducer as datafilesReducer } from 'store/modules/datafiles'
import { reducer as globalReducer } from 'store/modules/global'
import { reducer as mappingsReducer } from 'store/modules/mappings'
import { reducer as sitesReducer } from 'store/modules/sites'

const reducers = combineReducers({
  analyses: analysesReducer,
  auth: authReducer,
  datafiles: datafilesReducer,
  global: globalReducer,
  mappings: mappingsReducer,
  sites: sitesReducer,
})

export default reducers
