import { Button, Col, Row } from 'antd'
import {
  cloneDeep,
  fill,
  filter,
  find,
  get,
  isEmpty,
  last,
  map,
  sumBy,
} from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { analysesApi, datafilesApi } from 'apis'
import {
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import { trimFileExt } from 'utils/analyses'

import SingleRunOptionEditor from './SingleRunOptionEditor'

const INPUT_SEPARATOR = ','

export const EMPTY_RUN_OPTION = {
  Func_Runs: null,
  onsets: [null],
  Event_name: [null],
  durations: [],
  regressor_names: [],
  regressors: null,
}

export const RunsOptionEditor = ({ readOnly, setNumOnSets }) => {
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  useEffect(() => {
    setNumOnSets(getNumOnsets(get(analysisOptions, 'Runs.value', [])))
  }, [analysisOptions, setNumOnSets])

  const getNumOnsets = runOptions =>
    sumBy(runOptions, run => filter(run.onsets).length)

  const getDataFile = params => {
    if (params.id) {
      return datafilesApi.getDataFile(params.id)
    }

    return analysesApi.getAnalysisDataFile({ params })
  }

  const getCompatibleParam = (index, value) => {
    const indexRegex = new RegExp(`${index}$`)

    return value.match(indexRegex) && value.replace(indexRegex, index + 1)
  }

  const handleAddRun = () => {
    const runOptions = cloneDeep(get(analysisOptions, 'Runs.value', []))
    const newRun = cloneDeep(EMPTY_RUN_OPTION)

    if (!isEmpty(runOptions)) {
      const runIndex = runOptions.length
      const lastRun = last(runOptions)
      const numOnsets = lastRun.onsets.length

      // Initialize fields with the right value and length.
      newRun.durations = cloneDeep(lastRun.durations)
      newRun.onsets = fill(Array(numOnsets), null)
      newRun.Event_name = fill(Array(numOnsets), null)

      // Auto create regressor name with the right RunIndex.
      if (!isEmpty(get(lastRun, 'regressor_names'))) {
        const regressorNames = lastRun.regressor_names.split(INPUT_SEPARATOR)
        const newRegressorNames = map(regressorNames, name =>
          getCompatibleParam(runIndex, name),
        )

        newRun.regressor_names = newRegressorNames.join(INPUT_SEPARATOR)
      }

      // Search for compatible onsets and Event_name with the new run based on previous run.
      lastRun.onsets.forEach((onset, onsetIndex) => {
        const fileName = get(onset, 'name') && trimFileExt(onset.name)
        const compFileName = fileName && getCompatibleParam(runIndex, fileName)

        if (compFileName) {
          getDataFile({ files: compFileName }).then(data => {
            const compOnset =
              find(
                data,
                ot => ot.series_info.label === onset.series_info.label,
              ) ||
              find(
                data,
                ot =>
                  ot.session_info.segment_interval ===
                  onset.session_info.segment_interval,
              ) ||
              find(
                data,
                ot => ot.subject_info.anon_id === onset.subject_info.anon_id,
              )

            if (compOnset) {
              newRun.onsets[onsetIndex] = compOnset.id
              newRun.Event_name[onsetIndex] = trimFileExt(compOnset.name)
              runOptions[runIndex] = newRun

              handleSetOption(runOptions)
            }
          })
        }
      })
    }

    runOptions.push(newRun)

    handleSetOption(runOptions)
  }

  const handleRemoveRun = index => {
    const runOptions = cloneDeep(get(analysisOptions, 'Runs.value', []))
    runOptions.splice(index, 1)

    handleSetOption(runOptions)
  }

  const handleSetOption = runOptions => {
    dispatch(
      setAnalysisOption({
        name: 'Runs',
        option: { value: runOptions },
      }),
    )
    setNumOnSets(getNumOnsets(runOptions))
  }

  const handleSetRunOption = (index, optionName, value) => {
    const runOptions = cloneDeep(get(analysisOptions, 'Runs.value', []))

    // Assign a new value to the option.
    runOptions[index][optionName] = value
    handleSetOption(runOptions)
  }

  const analysisRunOptions = get(analysisOptions, 'Runs.value', [])

  return (
    <Row>
      <Col span={24}>
        {!readOnly && (
          <Button className="mb-1" onClick={handleAddRun}>
            Add Run
          </Button>
        )}
      </Col>
      <Col span={24}>
        {map(analysisRunOptions, (runOption, index) => (
          <SingleRunOptionEditor
            readOnly={readOnly}
            runOption={runOption}
            index={index}
            key={index}
            analysisType={analysisType}
            setRunOption={(optionName, value) =>
              handleSetRunOption(index, optionName, value)
            }
            handleRemoveRun={() => handleRemoveRun(index)}
          />
        ))}
      </Col>
    </Row>
  )
}

RunsOptionEditor.propTypes = {
  readOnly: PropTypes.bool,
  setNumOnSets: PropTypes.func,
}

RunsOptionEditor.defaultProps = {
  readOnly: false,
}

export default RunsOptionEditor
