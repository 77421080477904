import {
  ArrowLeftOutlined,
  CaretDownOutlined,
  LogoutOutlined,
  NotificationOutlined,
  PushpinOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Badge, Button, Col, Dropdown, Layout, Row } from 'antd'
import PropTypes from 'prop-types'
import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { BREAKPOINTS } from 'config/base'
import {
  listNotification,
  logOut,
  selectLoggedInUser,
  selectNotifications,
} from 'store/modules/auth'
import { selectIsSidebarPinned, toggleSidebarPin } from 'store/modules/global'
import { getNotificationSocket } from 'utils/sockets'

const { Header: LayoutHeader } = Layout

let socket = null

const Header = ({ isDesktop }) => {
  const isSidebarPinned = useSelector(selectIsSidebarPinned)
  const notifications = useSelector(selectNotifications)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleMessageReceived = useCallback(
    message => {
      if (message.data === 'update') {
        dispatch(listNotification())
      }
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(listNotification())
  }, [user.id, dispatch])

  useEffect(() => {
    socket = getNotificationSocket(user.id)
    socket.onmessage = handleMessageReceived
  }, [user.id, handleMessageReceived])

  const handleGoBack = () => {
    navigate(-1)
  }

  const handleNotificationClick = () => {
    navigate('/me/notification')
  }

  const items = [
    {
      key: '/me/profile',
      label: 'Profile',
      icon: <UserOutlined />,
      onClick: () => navigate('/me/profile'),
    },
    {
      type: 'divider',
    },
    {
      key: 'logOut',
      label: 'Log out',
      icon: <LogoutOutlined />,
      onClick: () => dispatch(logOut()),
    },
  ]

  const { first_name, last_name } = user

  return (
    <LayoutHeader className="header">
      <Row type="flex" justify="space-between">
        <Col>
          {isDesktop && (
            <Button
              icon={<PushpinOutlined />}
              shape="circle"
              className="mr-05"
              data-testid="header-toggle-sidebar-button"
              type={isSidebarPinned ? 'primary' : 'default'}
              onClick={() => dispatch(toggleSidebarPin())}
            />
          )}
          <Button
            icon={<ArrowLeftOutlined />}
            shape="circle"
            data-testid="header-go-back-button"
            onClick={handleGoBack}
          />
        </Col>
        <Col>
          <Badge count={notifications.length} className="mr-1">
            <Button
              icon={<NotificationOutlined />}
              data-testid="header-notifications-button"
              onClick={handleNotificationClick}
            />
          </Badge>
          <Dropdown
            menu={{ items }}
            trigger={['click']}
            placement="bottomRight"
          >
            <Button data-testid="header-menu-button">
              {`${first_name} ${last_name}`} <CaretDownOutlined />
            </Button>
          </Dropdown>
        </Col>
      </Row>
    </LayoutHeader>
  )
}

Header.propTypes = {
  isDesktop: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.XL,
})

export default withSizes(sizes)(Header)
