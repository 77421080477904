import { Select } from 'antd'
import PropTypes from 'prop-types'

export const { Option } = Select

const CustomSelect = ({ children, ...props }) => (
  <Select
    virtual={false}
    {...props}
    getPopupContainer={trigger => trigger.parentNode}
  >
    {children}
  </Select>
)

CustomSelect.propTypes = {
  children: PropTypes.node,
}

export default CustomSelect
