import { Col, Form, InputNumber, Row, Switch } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { ArrayField } from 'components'
import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const SliceTimingCorrectionForm = ({
  analysisOptions,
  readOnly,
  setOption,
}) => {
  const slicetimeValue = get(analysisOptions, 'slicetime_acq_order.value')
  const turnedOn = get(analysisOptions, 'stc_flag.value') === 'On'
  const { slicetime_ref_slice } = analysisOptions

  return (
    <Row>
      <Col span={12}>
        <Form.Item {...formLayout} label="Slice Timing Correction">
          <Switch
            checkedChildren="On"
            unCheckedChildren="Off"
            disabled={readOnly}
            checked={turnedOn}
            onChange={value => {
              setOption('stc_flag', 'value', value ? 'On' : 'Off')
            }}
          />
        </Form.Item>
        {turnedOn && (
          <>
            <ArrayField
              formLayout={formLayout}
              optional={false}
              label="Slice Acquisition Order"
              msg="Please enter a separated by comma list of numbers"
              placeholder="2, 4, 6, ..."
              pattern={/^-?[ 0-9]\d*(\.\d+)?$/}
              value={slicetimeValue}
              disabled={readOnly}
              onChange={value =>
                setOption('slicetime_acq_order', 'value', value)
              }
            />
            <Form.Item {...formLayout} label="Reference Slice for Reslicing">
              <InputNumber
                value={get(slicetime_ref_slice, 'value')}
                step={0.1}
                disabled={readOnly}
                onChange={value =>
                  setOption('slicetime_ref_slice', 'value', value)
                }
              />
            </Form.Item>
          </>
        )}
      </Col>
    </Row>
  )
}

SliceTimingCorrectionForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

SliceTimingCorrectionForm.defaultProps = {
  readOnly: false,
}

export default SliceTimingCorrectionForm
