import axios from 'axios'

export const sitesApi = {
  listSite: () => axios.get('/site/').then(res => res.data),

  createSite: data => axios.post('/site/', data).then(res => res.data),

  getSite: siteId => axios.get(`/site/${siteId}/`).then(res => res.data),

  deleteSite: siteId => axios.delete(`/site/${siteId}/`).then(res => res.data),

  sendInvite: ({ siteId, data }) =>
    axios.post(`/site/${siteId}/invite/`, data).then(res => res.data),

  deleteInvite: ({ siteId, inviteId }) =>
    axios.delete(`/site/${siteId}/invite/${inviteId}/`).then(res => res.data),

  setAdmin: ({ siteId, userId }) =>
    axios
      .patch(`/site/${siteId}/admin/`, { user: userId })
      .then(res => res.data),

  removeMember: ({ siteId, membershipId }) =>
    axios
      .delete(`/site/${siteId}/member/${membershipId}/`)
      .then(res => res.data),

  listStudy: config => axios.get('/study/', config).then(res => res.data),

  createStudy: data => axios.post('/study/', data).then(res => res.data),

  getStudy: studyId => axios.get(`/study/${studyId}/`).then(res => res.data),

  updateStudy: ({ id, data }) =>
    axios.patch(`/study/${id}/`, data).then(res => res.data),

  deleteStudy: studyId =>
    axios.delete(`/study/${studyId}/`).then(res => res.data),

  getStudyDownloadInfo: ({ id, config }) =>
    axios.post(`/study/${id}/download-info/`, config).then(res => res.data),

  downloadStudyData: ({ id, data }) =>
    axios.post(`/study/${id}/download-data/`, data).then(res => res.data),

  listUploadableStudy: () =>
    axios.get('/study/uploadable/').then(res => res.data),

  listAllScanner: config =>
    axios.get('/scanner/all/', config).then(res => res.data),

  listScanner: config => axios.get('/scanner/', config).then(res => res.data),

  createScanner: data => axios.post('/scanner/', data).then(res => res.data),

  getScanner: scannerId =>
    axios.get(`/scanner/${scannerId}/`).then(res => res.data),

  updateScanner: ({ id, data }) =>
    axios.patch(`/scanner/${id}/`, data).then(res => res.data),

  deleteScanner: scannerId =>
    axios.delete(`/scanner/${scannerId}/`).then(res => res.data),

  listAllSubject: config =>
    axios.get('/subject/all/', config).then(res => res.data),

  createSubject: data => axios.post('/subject/', data).then(res => res.data),

  listAllSession: config =>
    axios.get('/session/all/', config).then(res => res.data),

  listSession: config => axios.get('/session/', config).then(res => res.data),

  getSession: sessionId =>
    axios.get(`/session/${sessionId}/`).then(res => res.data),

  createSession: data => axios.post('/session/', data).then(res => res.data),

  listAllSeries: config =>
    axios.get('/series/all/', config).then(res => res.data),

  listSeries: config => axios.get('/series/', config).then(res => res.data),

  createSeries: data => axios.post('/series/', data).then(res => res.data),

  listTag: config => axios.get('/tag/', config).then(res => res.data),

  getTag: tagId => axios.get(`/tag/${tagId}/`).then(res => res.data),

  createTag: data => axios.post('/tag/', data).then(res => res.data),

  updateTag: ({ id, data }) =>
    axios.patch(`/tag/${id}/`, data).then(res => res.data),

  deleteTag: tagId => axios.delete(`/tag/${tagId}/`).then(res => res.data),

  assignTags: data => axios.post('/tag-assign/', data).then(res => res.data),
}
