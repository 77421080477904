import axios from 'axios'

export const authApi = {
  logIn: data => axios.post('/auth/login/', data).then(res => res.data),

  register: data => axios.post('/auth/register/', data).then(res => res.data),

  sendPasswordResetEmail: data =>
    axios.post('/auth/password-reset/', data).then(res => res.data),

  sendVerifyEmail: () => axios.get('/auth/email-verify/').then(res => res.data),

  verifyEmail: data =>
    axios.post('/auth/email-verify/', data).then(res => res.data),

  getProfile: () => axios.get('/auth/profile/').then(res => res.data),

  updateProfile: data =>
    axios.patch('/auth/profile/', data).then(res => res.data),

  listNotification: () =>
    axios.get('/auth/notification/').then(res => res.data),

  deleteNotification: notificationId =>
    axios.delete(`/auth/notification/${notificationId}/`).then(res => res.data),

  listMyInvite: () => axios.get('/auth/invite/').then(res => res.data),

  acceptMyInvite: inviteId =>
    axios.patch(`/auth/invite/${inviteId}/`).then(res => res.data),

  rejectMyInvite: inviteId =>
    axios.delete(`/auth/invite/${inviteId}/`).then(res => res.data),

  getMySite: () => axios.get('/auth/site/').then(res => res.data),

  createMySite: data => axios.post('/auth/site/', data).then(res => res.data),

  deleteMySite: () => axios.delete('/auth/site/').then(res => res.data),

  leaveMySite: () => axios.delete('/auth/site/').then(res => res.data),

  sendInviteMySite: ({ siteId, data }) =>
    axios.post(`/site/${siteId}/invite/`, data).then(res => res.data),

  deleteInviteMySite: ({ siteId, inviteId }) =>
    axios.delete(`/site/${siteId}/invite/${inviteId}/`).then(res => res.data),

  removeMemberMySite: ({ siteId, membershipId }) =>
    axios
      .delete(`/site/${siteId}/member/${membershipId}/`)
      .then(res => res.data),

  listUser: config => axios.get('/auth/user/', config).then(res => res.data),

  updateUser: ({ userId, data }) =>
    axios.patch(`/auth/user/${userId}/`, data).then(res => res.data),

  getVersion: () => axios.get('/auth/version/').then(res => res.data),

  downloadCLI: () => axios.get('/download-cli', { responseType: 'blob' }),
}
