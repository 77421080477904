import { Card, Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { ArrayField, Select } from 'components'

const SPM_DESIGN_TYPES = {
  ONE_SAMPLE: 'One Sample T-Test',
}

const SingleContrastOptionEditor = ({
  analysisOptions,
  analysisType,
  contrast,
  index,
  readOnly,
  handleRemoveContrast,
  setContrastOption,
}) => {
  const [placeholder, setPlaceholder] = useState({
    Contrast_Events: '',
    Contrast_Weights: '',
  })

  const initPlaceholder = useCallback(
    label => {
      let newPlaceholder = { Contrast_Events: '', Contrast_Weights: '' }

      switch (label) {
        case 'SPMGLM':
          newPlaceholder = {
            Contrast_Events: contrast?.Contrast_Name,
            Contrast_Weights: '1',
          }
          break
        case 'GroupSPMGLM':
          analysisOptions?.Design_Type?.value === SPM_DESIGN_TYPES.ONE_SAMPLE
            ? (newPlaceholder = {
                Contrast_Events: 'mean',
                Contrast_Weights: '1',
              })
            : (newPlaceholder = {
                Contrast_Events: 'Group_{1}, Group_{2}',
                Contrast_Weights: '1, -1',
              })
          break
        default:
          break
      }

      setPlaceholder(newPlaceholder)
    },
    [analysisOptions, contrast],
  )

  useEffect(() => {
    initPlaceholder(analysisType.label)
  }, [index, analysisOptions, analysisType.label, initPlaceholder])

  const handleSetContrastOption = (optionName, value) => {
    setContrastOption && setContrastOption(optionName, value)
  }

  return (
    <Form
      layout="vertical"
      initialValues={{
        Contrast_Name: contrast?.Contrast_Name,
        Contrast_Type: contrast?.Contrast_Type,
        Contrast_Events: contrast?.Contrast_Events,
        Contrast_Weights: contrast?.Contrast_Weights,
      }}
    >
      <Card
        key={`card_${index}`}
        title={`Contrast ${index}`}
        extra={
          !readOnly && (
            <a href={`#contrast_${index}`} onClick={handleRemoveContrast}>
              X
            </a>
          )
        }
      >
        <Form.Item label="Contrast Name">
          <Input
            style={{ width: '100%' }}
            disabled={readOnly}
            placeholder={`contrast_name_${index}`}
            value={contrast?.Contrast_Name}
            onChange={evt => {
              handleSetContrastOption('Contrast_Name', evt.target.value)
              initPlaceholder(analysisType.label)
            }}
          />
        </Form.Item>
        <Form.Item label="Contrast Type">
          {readOnly ? (
            <Input value={contrast?.Contrast_Type} disabled />
          ) : (
            <Select
              style={{ width: '100%' }}
              value={contrast?.Contrast_Type}
              options={[
                { label: 'T', value: 'T' },
                { label: 'F', value: 'F' },
              ]}
              onChange={value =>
                handleSetContrastOption('Contrast_Type', value)
              }
            />
          )}
        </Form.Item>
        <ArrayField
          optional={false}
          label="Contrast Events"
          placeholder={placeholder.Contrast_Events}
          msg="Please enter a comma-separated list of Events"
          disabled={readOnly}
          onChange={value => handleSetContrastOption('Contrast_Events', value)}
          value={contrast?.Contrast_Events}
        />
        <ArrayField
          optional={false}
          label="Contrast Weights"
          msg="Please enter a comma-separated list of numbers"
          placeholder={placeholder.Contrast_Weights}
          pattern={/^-?[ 0-9]\d*(\.\d+)?$/}
          disabled={readOnly}
          onChange={value => handleSetContrastOption('Contrast_Weights', value)}
          value={contrast?.Contrast_Weights}
        />
      </Card>
    </Form>
  )
}

SingleContrastOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  analysisType: PropTypes.object,
  contrast: PropTypes.object,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  handleRemoveContrast: PropTypes.func,
  setContrastOption: PropTypes.func,
}

export default SingleContrastOptionEditor
