import {
  CloseOutlined,
  FileDoneOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Table,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import {
  concat,
  find,
  flatten,
  get,
  includes,
  intersectionBy,
  isEmpty,
  isEqual,
  map,
  uniqBy,
} from 'lodash'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import { Component } from 'react'

import { CustomAsyncSelect } from 'components'
import SelectWithSort, { Option } from 'components/SelectWithSort'
import { filterParamsUrl, stringContains } from 'utils/common'

const { Title } = Typography

export default class SearchForm extends Component {
  static propTypes = {
    analysisTypes: PropTypes.array,
    isMobile: PropTypes.bool,
    modalities: PropTypes.array,
    protocols: PropTypes.array,
    studies: PropTypes.array,
    tags: PropTypes.array,
    values: PropTypes.object,
    navigate: PropTypes.func,
    onChange: PropTypes.func,
    onReset: PropTypes.func,
    onSubmit: PropTypes.func,
  }

  state = {
    fileData: {
      content: [],
      name: null,
    },
    paramsUrl: {},
    studies: [],
    showModal: false,
    showAdvancedSearch: false,
  }

  componentDidMount() {
    const paramsSearch = queryString.parse(window.location.search)
    if (paramsSearch) {
      this.setState({ paramsUrl: paramsSearch })
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { studies } = this.state

    if (
      this.props.values.tags &&
      !isEqual(this.props.values.tags, nextProps.values.tags)
    ) {
      const { tags, values } = nextProps

      this.props.onChange({
        study: intersectionBy(
          this.getStudiesInTags(tags, values),
          studies,
          'key',
        ).map(study => String(study.key)),
      })
    }
  }

  persistURL = () => {
    const { navigate } = this.props
    const { paramsUrl } = this.state

    if (!isEmpty(paramsUrl)) {
      // Remove undefined properties or empty.
      const selectedParamsUrl = filterParamsUrl(paramsUrl)

      const searchParams = new URLSearchParams(selectedParamsUrl)
      const newUrl = `?${searchParams.toString()}`
      navigate({ search: newUrl })
    }
  }

  handleSubmit = () => {
    const { fileData } = this.state
    const payload = {
      subject_sessions:
        fileData.content.length === 0
          ? undefined
          : fileData.content
              .map(elem => `${elem.subject}/${elem.session}`)
              .join(','),
    }

    this.persistURL()
    this.props.onChange(payload)
    this.props.onSubmit()
  }

  handleReset = () => {
    this.handleFileRemove()
    this.props.onReset()

    this.setState({ paramsUrl: {} })
  }

  handleFileChange = payload => {
    const { file } = payload

    if (file.status === 'removed') {
      return
    }

    const reader = new FileReader()
    reader.onload = async e => {
      const { result } = e.target

      this.setState({
        fileData: {
          content: result
            .split('\n')
            .filter(Boolean)
            .map((elem, id) => ({
              id,
              subject: elem.split('/')[0],
              session: elem.split('/')[1],
            })),
          name: file.name,
        },
      })
    }
    reader.readAsText(file)
  }

  handleFileRemove = () => {
    this.setState({ fileData: { content: [], name: null } })
  }

  handleChange = (key, evt) => {
    const { paramsUrl } = this.state
    const value =
      get(evt, 'target.value') === undefined ? evt : evt.target.value

    this.setState(
      {
        paramsUrl: { ...paramsUrl, [key]: value },
      },
      this.props.onChange({ [key]: value }),
    )
  }

  toggleFileModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  toggleAdvancedSearch = () => {
    const { showAdvancedSearch } = this.state
    this.setState({ showAdvancedSearch: !showAdvancedSearch })
  }

  parameterSetFilter = data => {
    const { values } = this.props
    const { analysis_type } = values

    if (!analysis_type) {
      return data
    }

    return data.filter(elem => elem.analysis_type === Number(analysis_type))
  }

  renderMissingStudiesError = () => {
    const { tags, values } = this.props
    const { studies } = this.state

    if (this.getStudiesInTags(tags, values).length > studies.length) {
      return (
        <Alert
          type="warning"
          showIcon
          message="There are other studies under this tag that you do not have access to, which are not included in this selection."
        />
      )
    }

    return null
  }

  getStudiesInTags = (tags, values) =>
    uniqBy(
      flatten(
        concat(
          tags
            .filter(tag => get(values, 'tags', []).includes(String(tag.id)))
            .map(tag => tag.studies),
        ),
      ),
      'id',
    ).map(study => ({ key: study.id, label: study.name }))

  get isStudySelected() {
    const { values } = this.props
    return values.study && values.study.length > 0
  }

  listProtocolsByStudy = () => {
    const { values, protocols, studies } = this.props

    return uniqBy(
      flatten(
        concat(
          map(values.study, item =>
            protocols.filter(protocol =>
              includes(
                get(
                  find(studies, study => String(study.id) === item),
                  'all_protocols',
                  [],
                ).map(protocolData => protocolData.id),
                protocol.id,
              ),
            ),
          ),
        ),
      ),
    )
  }

  render() {
    const paramsSearch = queryString.parse(window.location.search)
    const { analysisTypes, modalities, tags, values, isMobile, studies } =
      this.props
    const { fileData, showModal, showAdvancedSearch } = this.state
    const formItemLayout = {
      labelCol: {
        lg: { span: 24 },
        xl: { span: 7 },
      },
      wrapperCol: {
        lg: { span: 24 },
        xl: { span: 16 },
      },
    }

    const status = [
      { id: 0, value: 'Ready to run' },
      { id: 1, value: 'Pending' },
      { id: 2, value: 'Complete' },
      { id: 3, value: 'Error' },
    ]

    if (isEmpty(values.study) || isEmpty(this.listProtocolsByStudy())) {
      values.protocol = []
    }

    return (
      <>
        <Card>
          <Form layout={isMobile ? 'vertical' : 'horizontal'}>
            <Row gutter={24}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Title level={4}>Basic Search</Title>
              </Col>

              <Col xs={12} sm={12}>
                <Form.Item label="Studies" {...formItemLayout}>
                  <SelectWithSort
                    placeholder="Studies"
                    mode="multiple"
                    value={values.study}
                    data={studies}
                    allowClear
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'label',
                      chronoKey: 'id',
                    }}
                    optionRenderer={study => (
                      <Option key={study.id}>
                        <Tooltip title={study.full_name} placement="right">
                          {study.label}
                        </Tooltip>
                      </Option>
                    )}
                    onChange={evt => this.handleChange('study', evt)}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item label="Protocol Name" {...formItemLayout}>
                  <SelectWithSort
                    placeholder="Protocol Name"
                    disabled={!this.isStudySelected}
                    className="w-100"
                    value={values.protocol}
                    data={this.listProtocolsByStudy()}
                    showSearch
                    allowClear
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'full_name',
                      chronoKey: 'id',
                    }}
                    filterOption={(input, option) =>
                      stringContains(option.label, input)
                    }
                    labelRenderer="full_name"
                    onChange={evt => this.handleChange('protocol', evt)}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12}>
                <Form.Item label="Subject" {...formItemLayout}>
                  <CustomAsyncSelect
                    placeholder="Subject"
                    searchByDefault
                    fetchUrl={{
                      base: '/data-directory-filter/subject/',
                      queryParams: {
                        study: values.study
                          ? values.study.join(',')
                          : undefined,
                      },
                    }}
                    value={values.subject}
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'name',
                      chronoKey: 'id',
                    }}
                    onChange={evt => this.handleChange('subject', evt)}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item label="Series" {...formItemLayout}>
                  <Input
                    placeholder="Series"
                    value={values.series}
                    onChange={evt => this.handleChange('series', evt)}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12}>
                <Form.Item label="Parameter Set" {...formItemLayout}>
                  <CustomAsyncSelect
                    placeholder="Parameter Set"
                    fetchUrl={{ base: '/data-directory-filter/parameterset/' }}
                    filterData={this.parameterSetFilter}
                    value={values.parameter_set}
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'name',
                      chronoKey: 'id',
                    }}
                    searchByDefault
                    onChange={evt => this.handleChange('parameter_set', evt)}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item label="DataFile" {...formItemLayout}>
                  <Input
                    placeholder="DataFile"
                    value={values.name}
                    onChange={evt => this.handleChange('name', evt)}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12}>
                <Form.Item label="Source" {...formItemLayout}>
                  <SelectWithSort
                    placeholder="Source"
                    className="w-100"
                    allowClear
                    value={values.source}
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'label',
                      chronoKey: 'id',
                    }}
                    data={[
                      { id: 0, label: 'Uploaded' },
                      { id: 1, label: 'Managed' },
                      { id: 3, label: 'Result' },
                    ]}
                    onChange={evt => this.handleChange('source', evt)}
                  />
                </Form.Item>
              </Col>
              <Col xs={12} sm={12}>
                <Form.Item label="Modality" {...formItemLayout}>
                  <SelectWithSort
                    placeholder="Modality"
                    className="w-100"
                    value={values.modality}
                    labelRenderer="full_name"
                    data={modalities}
                    allowClear
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'full_name',
                      chronoKey: 'id',
                    }}
                    onChange={evt => this.handleChange('modality', evt)}
                  />
                </Form.Item>
              </Col>

              <Col xs={12} sm={12}>
                <Form.Item label="Status" {...formItemLayout}>
                  <SelectWithSort
                    placeholder="Status"
                    className="w-100"
                    value={values.status}
                    data={status}
                    labelRenderer="value"
                    allowClear
                    sortingInfo={{
                      enabled: true,
                      nameKey: 'value',
                      chronoKey: 'id',
                    }}
                    onChange={evt => this.handleChange('status', evt)}
                  />
                </Form.Item>
              </Col>
              {this.isStudySelected && (
                <Col xs={12} sm={12}>
                  <Form.Item label="Subject/Session" {...formItemLayout}>
                    <Upload
                      accept=".txt"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={this.handleFileChange}
                      onRemove={this.handleFileRemove}
                    >
                      <Button>
                        <UploadOutlined /> Upload
                      </Button>
                    </Upload>
                    {fileData.content.length > 0 && (
                      <>
                        <Tooltip title={fileData.name}>
                          <Button
                            className="ml-1"
                            icon={<FileDoneOutlined />}
                            size="small"
                            shape="circle"
                            type="primary"
                            onClick={this.toggleFileModal}
                          />
                        </Tooltip>
                        <Button
                          className="ml-05"
                          icon={<CloseOutlined />}
                          size="small"
                          shape="circle"
                          danger
                          onClick={this.handleFileRemove}
                        />
                      </>
                    )}
                  </Form.Item>
                </Col>
              )}
            </Row>
            {showAdvancedSearch && (
              <Row gutter={24}>
                <Divider />
                <Col span={24} style={{ textAlign: 'center' }}>
                  <Title level={4}>Advanced Search</Title>
                </Col>

                <Col xs={12} sm={12}>
                  <Form.Item label="Site" {...formItemLayout}>
                    <CustomAsyncSelect
                      placeholder="Site"
                      fetchUrl={{ base: '/data-directory-filter/site/' }}
                      value={values.site}
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      labelRenderer="label"
                      searchByDefault
                      onChange={evt => this.handleChange('site', evt)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Item label="Scanner" {...formItemLayout}>
                    <CustomAsyncSelect
                      placeholder="Scanner"
                      fetchUrl={{ base: '/data-directory-filter/scanner/' }}
                      value={values.scanner}
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      searchByDefault
                      labelRenderer="label"
                      onChange={evt => this.handleChange('scanner', evt)}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12}>
                  <Form.Item label="Session" {...formItemLayout}>
                    <CustomAsyncSelect
                      placeholder="Session"
                      searchByDefault={!isEmpty(paramsSearch?.session)}
                      fetchUrl={{ base: '/data-directory-filter/session/' }}
                      value={values.session}
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      onChange={evt => this.handleChange('session', evt)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Item label="User" {...formItemLayout}>
                    <CustomAsyncSelect
                      placeholder="User"
                      fetchUrl={{ base: '/data-directory-filter/user/' }}
                      value={values.user}
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      searchByDefault
                      onChange={evt => this.handleChange('user', evt)}
                    />
                  </Form.Item>
                </Col>

                <Col xs={12} sm={12}>
                  <Form.Item label="Analysis Type" {...formItemLayout}>
                    <SelectWithSort
                      placeholder="Analysis Type"
                      className="w-100"
                      value={values.analysis_type}
                      data={analysisTypes}
                      labelRenderer="label"
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      allowClear
                      onChange={evt => this.handleChange('analysis_type', evt)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12} sm={12}>
                  <Form.Item label="PI" {...formItemLayout}>
                    <CustomAsyncSelect
                      placeholder="PI"
                      fetchUrl={{ base: '/data-directory-filter/pi/' }}
                      value={values.pi}
                      sortingInfo={{
                        enabled: true,
                        nameKey: 'name',
                        chronoKey: 'id',
                      }}
                      searchByDefault
                      onChange={evt => this.handleChange('pi', evt)}
                    />
                  </Form.Item>
                </Col>

                {tags.length > 0 && (
                  <Col xs={12} sm={12}>
                    <Form.Item label="Tags" {...formItemLayout}>
                      <SelectWithSort
                        placeholder="Tags"
                        mode="multiple"
                        className="w-100"
                        value={values.tags}
                        data={tags}
                        allowClear
                        sortingInfo={{
                          enabled: true,
                          nameKey: 'label',
                          chronoKey: 'id',
                        }}
                        onChange={evt => this.handleChange('tags', evt)}
                      />
                      {this.renderMissingStudiesError()}
                    </Form.Item>
                  </Col>
                )}
              </Row>
            )}
            <Row gutter={24}>
              {isMobile ? (
                <>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="link" onClick={this.toggleAdvancedSearch}>
                      {showAdvancedSearch ? 'Hide' : 'Show'} Advanced Search
                    </Button>
                  </Col>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Button type="primary" onClick={this.handleSubmit}>
                      Search
                    </Button>
                    <Button className="ml-1" onClick={this.handleReset}>
                      Clear
                    </Button>
                  </Col>
                </>
              ) : (
                <Col span={24} style={{ textAlign: 'right' }}>
                  <Button type="link" onClick={this.toggleAdvancedSearch}>
                    {showAdvancedSearch ? 'Hide' : 'Show'} Advanced Search
                  </Button>
                  <Button type="primary" onClick={this.handleSubmit}>
                    Search
                  </Button>
                  <Button className="ml-1" onClick={this.handleReset}>
                    Clear
                  </Button>
                </Col>
              )}
            </Row>
          </Form>
        </Card>

        <Modal
          open={showModal}
          footer={null}
          destroyOnClose
          title={fileData.name}
          onOk={this.toggleFileModal}
          onCancel={this.toggleFileModal}
        >
          <Table
            dataSource={fileData.content}
            columns={[
              { title: 'Subject', dataIndex: 'subject', key: 'subject' },
              { title: 'Session', dataIndex: 'session', key: 'session' },
            ]}
            rowKey="id"
            size="small"
            pagination={false}
            bordered
          />
        </Modal>
      </>
    )
  }
}
