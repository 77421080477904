import { Alert, Button, Col, Form, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'
import { validators } from 'validators'

const AnalysisPlanStepForm = ({
  analysisPlanId,
  analysisTypes,
  error,
  initialValues,
  parameterSets,
  submitting,
  onCancel,
  onSubmit,
}) => {
  const [form] = Form.useForm()
  const selectedAnalysisType = Form.useWatch('analysis_type', form)

  const filteredParameterSets = useMemo(() => {
    if (!selectedAnalysisType) {
      return []
    }

    return parameterSets.filter(
      parameterSet => parameterSet.analysis_type === selectedAnalysisType,
    )
  }, [selectedAnalysisType, parameterSets])

  const handleSubmit = values => {
    const payload = {
      ...values,
      id: get(initialValues, 'id'),
      analysis_plan: analysisPlanId,
    }

    onSubmit(payload)
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      disabled={submitting}
      initialValues={initialValues}
      form={form}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="analysis_type"
            label="Analysis"
            rules={validators.genericRequired}
          >
            <Select
              options={analysisTypes.map(({ id, label }) => ({
                label,
                value: id,
              }))}
              onChange={() => form.setFieldsValue({ parameter_set: null })}
            />
          </Form.Item>

          <Form.Item
            name="parameter_set"
            label="Parameter Set"
            rules={validators.genericRequired}
          >
            <Select
              options={filteredParameterSets.map(({ id, name, version }) => ({
                label: `${name} - v${version}`,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>

        {error && (
          <Alert
            className="mt-05"
            message={error}
            type="error"
            showIcon
            banner
          />
        )}

        <Col className="mt-1" span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            {initialValues ? 'Save' : 'Add'}
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

AnalysisPlanStepForm.propTypes = {
  analysisPlanId: PropTypes.number,
  analysisTypes: PropTypes.array,
  error: PropTypes.string,
  initialValues: PropTypes.object,
  parameterSets: PropTypes.array,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AnalysisPlanStepForm
