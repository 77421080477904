import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { downloadHTML, encodePathURL } from 'utils/analyses'

const GiftResult = ({ data, id, token }) => {
  const [resultHtml, setResultHtml] = useState('')
  const [networkSummaryHtml, setNetworkSummaryHtml] = useState('')

  const updateImageSrc = useCallback(
    isNetworkSummaryResult => {
      const { out_dir, save_path } = data
      const outDir = out_dir || save_path

      const selector = isNetworkSummaryResult
        ? '.network-summary-result img'
        : '.original-result img'

      const images = document.querySelectorAll(selector)
      images.forEach(image => {
        const src = image.getAttribute('src')
        const path = isNetworkSummaryResult ? `network_summary/${src}` : src
        image.src = encodePathURL(outDir, path, token)
      })
    },
    [data, token],
  )

  const handleDownloadResult = useCallback(() => {
    downloadHTML(id).then(setResultHtml)
    downloadHTML(id, true).then(setNetworkSummaryHtml)
  }, [id])

  useEffect(() => {
    handleDownloadResult()
  }, [handleDownloadResult])

  useEffect(() => {
    updateImageSrc(false)
  }, [resultHtml, updateImageSrc])

  useEffect(() => {
    updateImageSrc(true)
  }, [networkSummaryHtml, updateImageSrc])

  return (
    <Tabs
      animated={false}
      centered
      data-testid="gica-result"
      items={[
        {
          key: 'ica-result',
          label: 'ICA Results',
          forceRender: true,
          children: (
            <div
              className="analysis-result original-result"
              data-testid="ica-result"
              dangerouslySetInnerHTML={{ __html: resultHtml }}
            />
          ),
        },
        {
          key: 'network-summary',
          label: 'Network Summary',
          forceRender: true,
          children: (
            <div
              className="analysis-result network-summary-result"
              data-testid="network-summary"
              dangerouslySetInnerHTML={{ __html: networkSummaryHtml }}
            />
          ),
        },
      ]}
    />
  )
}

GiftResult.propTypes = {
  data: PropTypes.shape({
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
  }),
  id: PropTypes.number,
  token: PropTypes.string,
}

export default GiftResult
