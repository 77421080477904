import { WarningOutlined } from '@ant-design/icons'
import { Table, Tooltip } from 'antd'
import {
  drop,
  filter,
  get,
  head,
  isEmpty,
  map,
  uniqBy,
  zipObject,
} from 'lodash'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getMetadata,
  selectDataFilesStatus,
  selectMetadata,
} from 'store/modules/datafiles'

const MAX_NUM_FILTER_OPTIONS = 10
const HIDDEN_METADATA_COLUMNS = ['_df']

export const MiscFileCSVTable = ({
  allowedHeaders,
  miscFileId,
  searchDataFile,
  showWarning,
}) => {
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const metadata = useSelector(selectMetadata)

  const dispatch = useDispatch()

  const [tableData, setTableData] = useState({
    metadataValue: [],
    tableColumns: [],
  })

  const warningDFColumn = useMemo(
    () => ({
      title: '',
      dataIndex: '_df',
      key: 'warning_df',
      filters: [
        {
          text: 'Found',
          value: 'found',
        },
        {
          text: 'Not Found',
          value: null,
        },
      ],
      filterMultiple: false,
      render: record =>
        !record && (
          <Tooltip placement="rightTop" title="DataFile is not found">
            <WarningOutlined style={{ color: 'red' }} />
          </Tooltip>
        ),
      onFilter: (value, record) =>
        value === null ? isEmpty(get(record, '_df')) : get(record, '_df'),
    }),
    [],
  )

  const initMetadataTable = useCallback(() => {
    // Get table column header from first line.
    const headerMetadata = head(metadata)

    // Remove the header row.
    const dataRows = drop(metadata)

    // Generate dataSource with headerMetadata.
    const parsedMetadata = map(dataRows, (row, index) => ({
      ...zipObject(headerMetadata, row),
      index,
    }))

    const dataColumns = map(headerMetadata, key => {
      const uniqValues = uniqBy(parsedMetadata, key)

      // Note: only columns with small number of distinct values will be used as filters.
      const colFilters =
        uniqValues.length < MAX_NUM_FILTER_OPTIONS
          ? map(uniqValues, val => ({
              text: get(val, key),
              value: get(val, key),
            }))
          : null

      return {
        title: (
          <>
            {key}{' '}
            {showWarning && allowedHeaders && !allowedHeaders.includes(key) && (
              <WarningOutlined />
            )}
          </>
        ),
        dataIndex: key,
        key,
        filters: colFilters,
        onFilter: (value, record) => get(record, key).indexOf(value) === 0,
      }
    })

    const newTableColumns =
      searchDataFile && showWarning
        ? [warningDFColumn, ...dataColumns]
        : dataColumns

    setTableData({
      metadataValue: parsedMetadata,
      tableColumns: newTableColumns,
    })
  }, [allowedHeaders, metadata, showWarning, searchDataFile, warningDFColumn])

  useEffect(() => {
    dispatch(getMetadata({ id: miscFileId }))
  }, [miscFileId, dispatch])

  useEffect(() => {
    if (dataFilesStatus === getMetadata.fulfilled().type) {
      initMetadataTable()
    }
  }, [dataFilesStatus, initMetadataTable])

  const { tableColumns, metadataValue } = tableData

  const displayTableColumns = filter(
    tableColumns,
    col => !HIDDEN_METADATA_COLUMNS.includes(col.key),
  )
  const loading = dataFilesStatus !== getMetadata.fulfilled().type

  return (
    <Table
      rowKey="index"
      scroll={{ x: '100%' }}
      columns={displayTableColumns}
      dataSource={metadataValue}
      loading={loading}
      pagination={{ pageSize: 5, showSizeChanger: false }}
      size="small"
      data-testid="miscfile-csv-table"
    />
  )
}

MiscFileCSVTable.propTypes = {
  allowedHeaders: PropTypes.array,
  miscFileId: PropTypes.number,
  searchDataFile: PropTypes.bool,
  showWarning: PropTypes.bool,
}

MiscFileCSVTable.defaultProps = {
  allowedHeaders: null,
  searchDataFile: false,
  showWarning: true,
}

export default MiscFileCSVTable
