import { Card } from 'antd'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import { Loader, SiteAdmin, SiteInvites, SiteMembers, Tabs } from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  deleteInvite,
  getSite,
  removeMember,
  selectSite,
  selectSitesStatus,
  sendInvite,
  setAdmin,
} from 'store/modules/sites'

export const SiteDetailPage = () => {
  const site = useSelector(selectSite)
  const status = useSelector(selectSitesStatus)

  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(getSite(params.siteId))
  }, [dispatch, params])

  if (status === getSite.pending().type) {
    return <Loader />
  }

  if (!site) {
    return null
  }

  return (
    <PageLayout heading={site.full_name}>
      <Card>
        <Tabs
          items={[
            {
              key: 'admin',
              label: 'Admin',
              children: (
                <SiteAdmin
                  site={site}
                  myRole="SuperAdmin"
                  status={status}
                  setAdmin={values => dispatch(setAdmin(values))}
                />
              ),
            },
            {
              key: 'members',
              label: 'Members',
              children: (
                <SiteMembers
                  site={site}
                  myRole="SuperAdmin"
                  status={status}
                  removeMember={values => dispatch(removeMember(values))}
                />
              ),
            },
            {
              key: 'invites',
              label: 'Invites',
              children: (
                <SiteInvites
                  site={site}
                  myRole="SuperAdmin"
                  status={status}
                  sendInvite={values => dispatch(sendInvite(values))}
                  deleteInvite={values => dispatch(deleteInvite(values))}
                />
              ),
            },
          ]}
        />
      </Card>
    </PageLayout>
  )
}

export default SiteDetailPage
