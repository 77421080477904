import { Col, Form, InputNumber, Row } from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { withSizes } from 'react-sizes'

import { Select } from 'components'
import { BREAKPOINTS } from 'config/base'
import { listDataFile } from 'store/modules/datafiles'
import { getParameterLayouts } from 'utils/common'

import { validators } from './validators'

export const GroupSPMGLMOptionEditor = ({
  analysisOptions,
  isDesktop,
  readOnly,
  setAnalysisOption,
}) => {
  const { formLayout } = getParameterLayouts(isDesktop)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listDataFile())
  }, [dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  return (
    <Row data-testid="group-spm-glm-option-editor">
      <Col span={24}>
        <Form.Item {...formLayout} label="Estimation Method">
          <Select
            style={{ width: isDesktop ? 300 : '100%' }}
            defaultValue={get(
              analysisOptions,
              'Estimation_Method.value',
              'Classical',
            )}
            disabled={readOnly}
            options={[
              { label: 'Classical', value: 'Classical' },
              { label: 'Bayesian', value: 'Bayesian' },
              { label: '2nd Bayesian', value: 'Bayesian2' },
            ]}
            onChange={value =>
              handleSetOption('Estimation_Method', 'value', value)
            }
          />
        </Form.Item>
        <Form.Item {...formLayout} label="Use FWE Correction">
          <Select
            style={{ width: isDesktop ? 300 : '100%' }}
            defaultValue={get(
              analysisOptions,
              'Use_FWE_Correction.value',
              true,
            )}
            disabled={readOnly}
            options={[
              { label: 'True', value: true },
              { label: 'False', value: false },
            ]}
            onChange={value =>
              handleSetOption('Use_FWE_Correction', 'value', value)
            }
          />
        </Form.Item>
        <Form.Item
          {...formLayout}
          label="Height Threshold"
          rules={validators.height_threshold}
        >
          <InputNumber
            defaultValue={get(analysisOptions, 'Height_Threshold.value', 0)}
            min={0}
            max={1}
            placeholder={0.001}
            precision={3}
            disabled={readOnly}
            onChange={value =>
              handleSetOption('Height_Threshold', 'value', value)
            }
          />
        </Form.Item>
      </Col>
    </Row>
  )
}

GroupSPMGLMOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  isDesktop: PropTypes.bool,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

GroupSPMGLMOptionEditor.defaultProps = {
  setAnalysisOption: noop,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.MD,
})

export default withSizes(sizes)(GroupSPMGLMOptionEditor)
