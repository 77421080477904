import { Table } from 'antd'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import withSizes from 'react-sizes'

import { BREAKPOINTS } from 'config/base'
import {
  listAnalysisType,
  selectAnalysesStatus,
  selectAnalysisTypes,
} from 'store/modules/analyses'

export const AnalysisTypeTable = ({ isDesktop }) => {
  const analysisStatus = useSelector(selectAnalysesStatus)
  const analysisTypes = useSelector(selectAnalysisTypes)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listAnalysisType())
  }, [dispatch])

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label',
      key: 'label',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: isDesktop && 400,
    },
    {
      title: 'Result Regex Map',
      dataIndex: 'result_regex_map',
      key: 'result_regex_map',
    },
    {
      title: 'Version',
      dataIndex: 'version',
      key: 'version',
    },
  ]

  const loading = analysisStatus !== listAnalysisType.fulfilled().type

  return (
    <Table
      bordered
      size="small"
      dataSource={analysisTypes}
      columns={columns}
      rowKey="id"
      loading={loading}
      tableLayout="auto"
      scroll={{ x: 900 }}
      pagination={{ size: 'large' }}
    />
  )
}

AnalysisTypeTable.propTypes = {
  isDesktop: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
})

export default withSizes(sizes)(AnalysisTypeTable)
