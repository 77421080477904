import { Switch, Tabs } from 'antd'
import { capitalize, get } from 'lodash'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { TaskList } from 'components'
import { ANALYSIS_OWNERS, ANALYSIS_STATES } from 'config/base'
import {
  listAnalysis,
  listAnalysisUser,
  selectAnalyses,
  selectAnalysesStatus,
  selectAnalysisTypes,
  selectAnalysisUsers,
} from 'store/modules/analyses'
import { selectLoggedInUser } from 'store/modules/auth'
import { listStudy, selectStudies } from 'store/modules/sites'

export const TaskSection = () => {
  const analyses = useSelector(selectAnalyses)
  const analysisTypes = useSelector(selectAnalysisTypes)
  const analysisUsers = useSelector(selectAnalysisUsers)
  const status = useSelector(selectAnalysesStatus)
  const studies = useSelector(selectStudies)
  const user = useSelector(selectLoggedInUser)

  const dispatch = useDispatch()
  const location = useLocation()

  const paramsSearch = queryString.parse(location.search)
  const [ownerType, setOwnerType] = useState(ANALYSIS_OWNERS.Owned)
  const [tabKey, setTabKey] = useState(
    paramsSearch?.status || ANALYSIS_STATES.all.name,
  )
  const [selection, setSelection] = useState(false)

  useEffect(() => {
    dispatch(listAnalysisUser())
    dispatch(listStudy())
  }, [dispatch])

  const preparingData = status === listAnalysis.pending().type

  const isShared = ownerType === ANALYSIS_OWNERS.Shared

  const handleFetchData = params => {
    const analysisStatus = get(ANALYSIS_STATES, [get(params, 'status'), 'id'])
    dispatch(listAnalysis({ params: { ...params, status: analysisStatus } }))
  }

  const subProps = {
    analysisTypes,
    analyses,
    user,
    analysisUsers,
    selection,
    studies,
    loading: preparingData,
    fetchData: handleFetchData,
  }

  const renderTabs = () => (
    <Tabs
      size="large"
      activeKey={tabKey}
      animated={false}
      onChange={setTabKey}
      tabBarExtraContent={
        (user.is_superuser || !isShared) && (
          <Switch
            checkedChildren="Shared"
            unCheckedChildren="Not Shared"
            checked={selection}
            onChange={setSelection}
          />
        )
      }
      items={[
        {
          label: capitalize(ANALYSIS_STATES.all.name),
          key: ANALYSIS_STATES.all.name,
        },
        {
          label: capitalize(ANALYSIS_STATES.success.name),
          key: ANALYSIS_STATES.success.name,
        },
        {
          label: capitalize(ANALYSIS_STATES.error.name),
          key: ANALYSIS_STATES.error.name,
        },
        {
          label: capitalize(ANALYSIS_STATES.running.name),
          key: ANALYSIS_STATES.running.name,
        },
      ]}
    />
  )

  return user.is_superuser ? (
    <>
      {renderTabs()}
      <TaskList {...subProps} type={tabKey} shared={isShared} />
    </>
  ) : (
    <Tabs
      size="small"
      tabPosition="left"
      animated={false}
      items={[
        {
          key: ANALYSIS_OWNERS.Owned,
          label: 'My Analyses Results',
          children: (
            <>
              {renderTabs()}
              <TaskList {...subProps} type={tabKey} shared={isShared} />
            </>
          ),
        },
        {
          key: ANALYSIS_OWNERS.Shared,
          label: 'Shared Analyses Results',
          children: (
            <>
              {renderTabs()}
              <TaskList {...subProps} type={tabKey} shared={isShared} />
            </>
          ),
        },
      ]}
      onChange={setOwnerType}
    />
  )
}

export default TaskSection
