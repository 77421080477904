import { Form } from 'antd'
import { map, orderBy } from 'lodash'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Loader, Select } from 'components'
import { ANALYSIS_RESULTS, ANALYSIS_TYPES_ID } from 'config/base'
import {
  initAnalysisOptions,
  listAnalysis,
  selectAnalyses,
  selectAnalysesStatus,
  selectAnalysis,
  selectAnalysisOptions,
  setAnalysisOption,
} from 'store/modules/analyses'

export const BrainvizUploadSection = () => {
  const analyses = useSelector(selectAnalyses)
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisStatus = useSelector(selectAnalysesStatus)

  const dispatch = useDispatch()

  useEffect(() => {
    if (analysis) {
      const { options } = analysis.parameters.analysis
      dispatch(initAnalysisOptions(options))
    } else {
      dispatch(
        initAnalysisOptions({
          ica_parent: { value: 'default' },
        }),
      )
    }

    const params = {
      pageSize: 1000,
      status: ANALYSIS_RESULTS.Complete,
      analysis_type: ANALYSIS_TYPES_ID.GICA,
    }

    dispatch(listAnalysis({ params }))
  }, [analysis, dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const sortedAnalyses = orderBy(analyses.results, ['id'], ['desc'])
  const loading = analysisStatus === listAnalysis.pending().type

  if (loading) return <Loader />

  return (
    <Form.Provider>
      <Form layout="vertical">
        <Form.Item label="Select a previously run Group ICA:">
          {analysisOptions.ica_parent && (
            <Select
              style={{ width: '100%' }}
              defaultValue={['default']}
              value={analysisOptions.ica_parent.value || 'default'}
              options={[
                { label: 'Select a previous Group ICA', value: 'default' },
                ...map(sortedAnalyses, ({ id, name }) => ({
                  label: `${id} - ${name}`,
                  value: id,
                })),
              ]}
              onChange={value => handleSetOption('ica_parent', 'value', value)}
            />
          )}
        </Form.Item>
      </Form>
    </Form.Provider>
  )
}

export default BrainvizUploadSection
