import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Empty, Modal, Table, Tooltip } from 'antd'
import { compact, get, map, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { withSizes } from 'react-sizes'

import { Drawer, StudyForm, TagEditor } from 'components'
import { BREAKPOINTS } from 'config/base'
import {
  assignTags,
  createStudy,
  deleteStudy,
  selectStudies,
  selectTags,
} from 'store/modules/sites'

export const StudyTable = ({
  isDesktop,
  isMobile,
  shared,
  sites,
  status,
  title,
  user,
}) => {
  const studies = useSelector(selectStudies)
  const tags = useSelector(selectTags)

  const { is_superuser: isSuperUser } = user

  const [showAddDrawer, setShowAddDrawer] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (status === createStudy.fulfilled().type) {
      setShowAddDrawer(false)
    }
  }, [status])

  const columns = compact([
    isDesktop && {
      title: 'Short Name',
      dataIndex: 'label',
      key: 'label',
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Full Name',
      dataIndex: 'full_name',
      key: 'full_name',
      width: isDesktop && 400,
      render: (_, record) => (
        <Link to={`/study/${record.id}`}>{record.full_name}</Link>
      ),
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Affiliate',
      dataIndex: 'site',
      key: 'site',
      onFilter: (value, record) => record.site?.label?.includes(value),
      filters: map(sites, site => ({
        text: site.full_name,
        value: site.label,
      })),
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => {
        const siteFullName = get(record, 'site.full_name', '')
        const siteId = get(record, 'site.id', '')

        if (user.is_superuser) {
          return <Link to={`/site/${siteId}`}>{siteFullName}</Link>
        }

        if (!shared) {
          return <Link to="/my-site">{siteFullName}</Link>
        }

        return siteFullName
      },
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      onFilter: (value, record) => record.tags.some(tag => tag.label === value),
      filters: map(tags, tag => ({ text: tag.label, value: tag.label })),
      render: (_, record) => (
        <TagEditor
          tags={tags}
          selectedTags={record.tags}
          editable={isSuperUser}
          onChange={selectedTags => handleAssignTags(record.id, selectedTags)}
        />
      ),
    },
    {
      title: 'PI',
      dataIndex: 'pi',
      key: 'pi',
      onFilter: (value, record) =>
        get(record, 'principal_investigator.username', '').includes(value),
      filters: uniqBy(
        map(studies, study => ({
          text: get(study, 'principal_investigator.username', ''),
          value: get(study, 'principal_investigator.username', ''),
        })),
        'value',
      ),
      sorter: (a, b) => a.label.localeCompare(b.label),
      sortDirections: ['descend', 'ascend'],
      render: (_, record) => get(record, 'principal_investigator.username', ''),
    },
    {
      title: 'Analysis Plan',
      dataIndex: 'plan',
      key: 'plan',
      onFilter: (value, record) => record.has_plan === value,
      filters: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
      filterMultiple: false,
      render: (_, record) => (
        <Link to={`/analysis-plans?study=${record.id}`}>
          {record.has_plan ? 'Yes' : 'No'}
        </Link>
      ),
    },
    isDesktop && {
      title: 'Analyses',
      dataIndex: 'analyses',
      key: 'analyses',
      render: (_, record) => (
        <Link to={`/preprocessing-summary/${record.label}`}>
          <Button size="small" style={{ display: 'block', marginBottom: 5 }}>
            Preprocessing
          </Button>
        </Link>
      ),
    },
    !shared && {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        if (
          (user.is_superuser ||
            get(record, 'created_by.id') === user.id ||
            (record.site.id === user.site && user.site_role === 'SiteAdmin')) &&
          !record.is_managed
        ) {
          return (
            <Tooltip title="Delete">
              <Button
                icon={<DeleteOutlined />}
                shape="circle"
                size="small"
                danger
                data-testid="study-delete-button"
                onClick={() => handleDelete(record)}
              />
            </Tooltip>
          )
        }
      },
    },
  ])

  const loading = [
    createStudy.pending().type,
    deleteStudy.pending().type,
    assignTags.pending().type,
  ].includes(status)

  const toggleAddDrawer = () => setShowAddDrawer(prevState => !prevState)

  const handleDelete = record => {
    Modal.confirm({
      title: `Are you sure want to delete the study "${record.full_name}"?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        dispatch(deleteStudy(record.id))
      },
    })
  }

  const handleSubmit = ({ data }) => {
    dispatch(createStudy(data))
  }

  const handleAssignTags = (studyId, selected) => {
    dispatch(assignTags({ study: studyId, tags: selected }))
  }

  return (
    <>
      {title && <h2 className="app-page__subheading">{title}</h2>}
      {!shared && (
        <div className="text-right mb-1">
          <Button
            icon={<PlusOutlined />}
            type="primary"
            disabled={loading}
            data-testid="study-add-button"
            onClick={toggleAddDrawer}
          >
            Add New Study
          </Button>
        </div>
      )}
      {studies.length > 0 ? (
        <Table
          dataSource={studies}
          columns={columns}
          pagination
          size="small"
          bordered
          loading={loading}
          rowKey="id"
          scroll={{ x: isMobile }}
        />
      ) : (
        <Empty description="No Studies" />
      )}
      {!shared && (
        <Drawer
          title="Create Study"
          open={showAddDrawer}
          onClose={toggleAddDrawer}
        >
          <StudyForm
            sites={sites}
            user={user}
            submitting={loading}
            onSubmit={handleSubmit}
            onCancel={toggleAddDrawer}
          />
        </Drawer>
      )}
    </>
  )
}

StudyTable.propTypes = {
  isDesktop: PropTypes.bool,
  isMobile: PropTypes.bool,
  isSuperUser: PropTypes.bool,
  shared: PropTypes.bool,
  sites: PropTypes.array,
  status: PropTypes.string,
  studies: PropTypes.array,
  title: PropTypes.string,
  user: PropTypes.object,
  assignTags: PropTypes.func,
  createStudy: PropTypes.func,
  deleteStudy: PropTypes.func,
}

StudyTable.defaultProps = {
  shared: false,
}

const sizes = ({ width }) => ({
  isDesktop: width > BREAKPOINTS.LG,
  isMobile: width < BREAKPOINTS.XXL,
})

export default withSizes(sizes)(StudyTable)
