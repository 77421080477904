import { Col, Form, Row } from 'antd'
import { find, first, get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DataFileTree } from 'components'
import {
  initAnalysisOptions,
  selectAnalysis,
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import {
  selectParameterSets,
  setAllFiles,
  setCurrentFiles,
} from 'store/modules/datafiles'

export const ASLUploadSection = ({ readOnly }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)
  const parameterSets = useSelector(selectParameterSets)

  const dispatch = useDispatch()

  useEffect(() => {
    if (analysis) {
      const { options } = analysis.parameters.analysis

      dispatch(initAnalysisOptions(options))
      dispatch(setCurrentFiles([analysis.input_file]))
    }
  }, [dispatch, analysis])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const handleInputImageChange = files => {
    const structuralImage = get(analysisOptions, 'Structural_Image.value')
    const currentFiles = structuralImage ? files.concat(structuralImage) : files

    dispatch(setAllFiles(currentFiles))
    dispatch(setCurrentFiles(currentFiles))

    handleSetOption('Input_Image', 'value', first(files))
  }

  const handleStructuralImageChange = files => {
    const inputImage = get(analysisOptions, 'Input_Image.value')
    const currentFiles = inputImage ? files.concat(inputImage) : files

    dispatch(setAllFiles(currentFiles))
    dispatch(setCurrentFiles(currentFiles))

    handleSetOption('Structural_Image', 'value', first(files))
  }

  const handleCalibrationImageChange = files => {
    const calibrationImage = get(analysisOptions, 'Calibration_Image.value')
    const currentFiles = calibrationImage
      ? files.concat(calibrationImage)
      : files

    dispatch(setAllFiles(currentFiles))
    dispatch(setCurrentFiles(currentFiles))

    handleSetOption('Calibration_Image', 'value', first(files))
  }

  const parameterSetId = get(analysisOptions, 'parameter_set.value')
  const parameterSet = find(parameterSets, { id: parameterSetId })
  const isCalibrationImgPartOfASL = get(
    parameterSet,
    'options.Calib_notSep.value',
    true,
  )

  const initialOptions = get(analysis, 'parameters.analysis.options')

  return (
    <Form layout="vertical">
      <Row gutter={24}>
        <Col md={24}>
          <Form.Item label="Please select Input Image">
            <DataFileTree
              analysisType={analysisType}
              analysis={analysis}
              disabled={readOnly}
              initialValue={get(initialOptions, 'Input_Image.value')}
              onChange={handleInputImageChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col md={24}>
          <Form.Item label="Please select Structural Image">
            <DataFileTree
              analysisType={analysisType}
              name={'structural-image'}
              disabled={readOnly}
              analysis={analysis}
              initialValue={get(initialOptions, 'Structural_Image.value')}
              onChange={handleStructuralImageChange}
            />
          </Form.Item>
        </Col>
      </Row>
      {!isCalibrationImgPartOfASL && (
        <Row gutter={24}>
          <Col md={24}>
            <Form.Item label="Please select Calibration Image">
              <DataFileTree
                analysisType={analysisType}
                name={'calibration-image'}
                disabled={readOnly}
                analysis={analysis}
                initialValue={get(initialOptions, 'Calibration_Image.value')}
                onChange={handleCalibrationImageChange}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  )
}

ASLUploadSection.propTypes = {
  readOnly: PropTypes.bool,
}

export default ASLUploadSection
