import { Col, Form, Input, InputNumber, Radio, Row } from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

import { ArrayField } from 'components'
import { getParameterLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { formLayout } = getParameterLayouts()

const WMHOptionEditor = ({ analysisOptions, readOnly, setAnalysisOption }) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderInput = (fieldName, placeholder, extra) => (
    <FormItem {...formLayout} label={fieldName} extra={extra}>
      <Input
        placeholder={placeholder}
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      />
    </FormItem>
  )

  const renderRadioGroup = (fieldName, values) => (
    <FormItem {...formLayout} label={fieldName}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        {values.map(value => (
          <Radio value={value} key={value}>
            {value}
          </Radio>
        ))}
      </Radio.Group>
    </FormItem>
  )

  const renderInputNumber = (fieldName, precision) => (
    <FormItem {...formLayout} label={fieldName}>
      <InputNumber
        style={{ width: '100%' }}
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        precision={precision}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  const renderSwitch = fieldName => (
    <FormItem {...formLayout} label={fieldName}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        <Radio value={false}>false</Radio>
        <Radio value={true}>true</Radio>
      </Radio.Group>
    </FormItem>
  )

  const PatchSizesValue = get(analysisOptions, 'PatchSizes.value')
  const FeatureSubsetValue = get(analysisOptions, 'FeatureSubset.value')

  return (
    <Row gutter={24} data-testid="wmh-option-editor">
      <Col md={10}>
        {renderRadioGroup('Base_Space', ['T1', 'T2', 'FLAIR'])}
        {renderInputNumber('BrainMaskFeatureNum', 0)}
        {renderInputNumber('QuerySubjectNum', 0)}
        {renderInputNumber('LabelFeatureNum', 0)}
        {renderInput('TrainingNums', 'all', 'all, or list of ints')}
        {renderRadioGroup('SelectPts', ['any', 'noborder', 'surround'])}
        {renderInputNumber('TrainingPts', 0)}
        {renderInputNumber('NonLesPts', 0)}
      </Col>
      <Col md={10} offset={2}>
        {renderSwitch('Patch3D')}
        <ArrayField
          formLayout={formLayout}
          label="PatchSizes"
          msg="Please enter a separated by comma list of numbers"
          placeholder="3"
          pattern={/^-?[ 0-9]\d*(\.\d+)?$/}
          value={PatchSizesValue}
          disabled={readOnly}
          onChange={value => handleSetOption('PatchSizes', 'value', value)}
        />
        <ArrayField
          formLayout={formLayout}
          optional={false}
          label="FeatureSubset"
          msg="Please enter a separated by comma list of numbers"
          placeholder="3"
          pattern={/^-?[ 0-9]\d*(\.\d+)?$/}
          value={FeatureSubsetValue}
          disabled={readOnly}
          onChange={value => handleSetOption('FeatureSubset', 'value', value)}
        />
        {renderInputNumber('MatFeatureNum', 0)}
        {renderInputNumber('SpatialWeight', 0)}
        {renderInputNumber('Postprocess_Threshold', 2)}
      </Col>
    </Row>
  )
}

WMHOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

WMHOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default WMHOptionEditor
