import { Checkbox, Col, Form, InputNumber, Radio, Row } from 'antd'
import { capitalize, get, kebabCase, map, noop } from 'lodash'
import PropTypes from 'prop-types'

import { DISTANCE_METHODS } from 'config/base'
import { getParameterFormLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { Group: RadioGroup } = Radio

const formLayout = getParameterFormLayouts()

const DFNCOptionEditor = ({ analysisOptions, readOnly, setAnalysisOption }) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderRadioGroup = (fieldLabel, fieldName, options) => (
    <FormItem {...formLayout} label={fieldLabel}>
      <RadioGroup
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        style={{ width: '100%' }}
      >
        <Row>
          {map(options, value => (
            <Col md={12} key={kebabCase(value)}>
              <Radio value={value}>{capitalize(value)}</Radio>
            </Col>
          ))}
        </Row>
      </RadioGroup>
    </FormItem>
  )

  const renderCheckbox = (fieldLabel, fieldName) => (
    <FormItem {...formLayout} label={fieldLabel}>
      <Checkbox
        disabled={readOnly}
        checked={get(analysisOptions, [fieldName, 'value']) === 'yes'}
        onChange={evt =>
          handleSetOption(fieldName, 'value', evt.target.checked ? 'yes' : 'no')
        }
      />
    </FormItem>
  )

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <FormItem {...formLayout} label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  return (
    <Row data-testid="dfnc-option-editor">
      <Col span={12}>
        {renderInputNumber(
          'Clustering: Maximum Iterations',
          'kmeans_max_iter',
          { min: 1, max: 1024, precision: 0 },
        )}
        {renderRadioGroup(
          'Clustering: Distance Metric',
          'dmethod',
          DISTANCE_METHODS,
        )}
        {renderInputNumber('Window Size', 'wsize', {
          min: 1,
          max: 150,
          precision: 0,
        })}
        {renderInputNumber('Temporal Resolution', 'TR', {
          min: 1,
          max: 5,
          precision: 0,
        })}
      </Col>
      <Col span={12}>
        {renderInputNumber(
          'Number of Temporal Trends to Remove',
          'tc_detrend',
          0,
          3,
        )}
        {renderCheckbox('Remove Spikes from Time Courses', 'tc_despike')}
        {renderInputNumber('High Frequency Cutoff', 'tc_filter', {
          min: 0,
          max: 1,
          precision: 2,
          step: 0.01,
        })}
        {renderInputNumber('Gaussian Window Alpha Value', 'window_alpha', {
          min: 0,
          max: 5,
          precision: 0,
        })}
        {renderInputNumber('Clustering: Number of Clusters', 'num_clusters', {
          min: 1,
          max: 99,
          precision: 0,
        })}
      </Col>
    </Row>
  )
}

DFNCOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

DFNCOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default DFNCOptionEditor
