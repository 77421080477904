import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  EditOutlined,
  LockOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import {
  Button,
  Drawer,
  Dropdown,
  Input,
  Modal,
  notification,
  Table,
} from 'antd'
import { find, first, get, lowerCase } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { withSizes } from 'react-sizes'

import { authApi } from 'apis'
import { BOOL_FILTERS, BREAKPOINTS, SITE_ROLES } from 'config/base'
import UserProfile from 'containers/Pages/User/Profile'
import { stringContains } from 'utils/common'

import EditTableCell from './EditTableCell'
import SetPasswordForm from './SetPasswordForm'

const CONFIRM_SUPERUSER = {
  PROMOTION: (
    <>
      <div>Are you sure you want to promote this user to SuperUser?</div>
      <div>
        This user will have the highest permissions for all sites and studies.
      </div>
    </>
  ),
  DEMOTION: (
    <>
      <div>Are you sure you want to demote this user from SuperUser?</div>
      <div>This user will no longer have control over all sites.</div>
    </>
  ),
}

export const UserTable = ({ editable, isNotDesktop }) => {
  const [passwordModal, setPasswordModal] = useState(false)
  const [userSelected, setUserSelected] = useState(null)
  const [showDrawer, setShowDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState(null)

  const listUser = async () => {
    setLoading(true)

    try {
      const data = await authApi.listUser()
      setUsers(data)
      setLoading(false)
    } catch {
      setUsers([])
      setLoading(false)
    }
  }

  useEffect(() => {
    listUser()
  }, [])

  const handleConfirmSuperUser = record => {
    Modal.confirm({
      title: record.is_superuser
        ? CONFIRM_SUPERUSER.DEMOTION
        : CONFIRM_SUPERUSER.PROMOTION,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        handleEditValue({
          record,
          column: 'is_superuser',
          value: !record.is_superuser,
          success: record.is_superuser
            ? 'Demoted successfully'
            : 'Promoted successfully',
          error: record.is_superuser ? 'Failed to demote' : 'Failed to promote',
        })
      },
    })
  }

  const renderTableDropdown = (_, record) => {
    const items = [
      {
        key: 'set-password',
        label: 'Set Password',
        icon: <LockOutlined />,
        onClick: () => handleSetPassword(record),
      },
      {
        key: 'activate',
        label: record.is_active ? 'Deactivate User' : 'Activate User',
        icon: record.is_active ? (
          <CloseCircleOutlined />
        ) : (
          <CheckCircleOutlined />
        ),
        onClick: () =>
          handleEditValue({
            record,
            column: 'is_active',
            value: !record.is_active,
            success: record.is_active
              ? 'Deactivated successfully'
              : 'Activated successfully',
            error: record.is_active
              ? 'Failed to deactivated'
              : 'Failed to activated',
          }),
      },
      {
        key: 'promote',
        label: record.is_superuser ? 'Demote SuperUser' : 'Promote SuperUser',
        icon: record.is_superuser ? <ArrowDownOutlined /> : <ArrowUpOutlined />,
        onClick: () => handleConfirmSuperUser(record),
      },
      {
        key: 'report',
        label: record.receives_report
          ? 'Unsubscribe from Email Reports'
          : 'Subscribe to Email Reports',
        icon: record.receives_report ? (
          <CloseCircleOutlined />
        ) : (
          <CheckCircleOutlined />
        ),
        onClick: () =>
          handleEditValue({
            record,
            column: 'receives_report',
            value: !record.receives_report,
            success: record.receives_report
              ? 'Unsubscribed from email reports successfully'
              : 'Subscribed to email reports successfully',
            error: record.receives_report
              ? 'Failed to unsubscribe from email reports'
              : 'Failed to subscribe to email reports',
          }),
      },
    ]

    return (
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button size="small" data-testid="action-button">
          Action
          <DownOutlined />
        </Button>
      </Dropdown>
    )
  }

  const renderBoolean = value => (value ? 'Yes' : 'No')

  const renderTitle = name => (
    <div>
      {name}&nbsp;
      {editable && <EditOutlined data-testid="user-editable-icon" />}
    </div>
  )

  const handleSetPassword = record => {
    setUserSelected(record)
    setPasswordModal(true)
  }

  const handleEditValue = async ({ record, column, value, success, error }) => {
    try {
      await authApi.updateUser({
        userId: record.id,
        data: { [column]: value },
      })
      notification.success({ message: success })
      listUser()
    } catch {
      notification.error({ message: error })
    }
  }

  const handleSearch = confirm => {
    confirm()
  }

  const handleSearchReset = (clearFilters, confirm) => {
    clearFilters()
    confirm()
  }

  const handleOpenProfile = record => {
    setUser(record)
    setShowDrawer(true)
  }

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            node && node.focus()
          }}
          placeholder={`Search ${lowerCase(dataIndex)}`}
          value={first(selectedKeys)}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(confirm)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleSearchReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    onFilter: (value, record) => stringContains(record[dataIndex], value),
    filterIcon: filtered => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  })

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      fixed: isNotDesktop && 'left',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      fixed: isNotDesktop && 'left',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.username.localeCompare(b.username),
      ...getColumnSearchProps('username'),
      render: (_, record) => (
        <Button
          style={{ padding: 0 }}
          type="link"
          onClick={() => handleOpenProfile(record)}
        >
          {record.username}
        </Button>
      ),
    },
    {
      title: renderTitle('Email'),
      dataIndex: 'email',
      key: 'email',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchProps('email'),
      render: (_, record) => (
        <EditTableCell
          editable={editable}
          value={record.email}
          onChange={value =>
            handleEditValue({
              record,
              column: 'email',
              value,
              success: 'Updated email successfully',
              error: 'Failed to update email',
            })
          }
        />
      ),
    },
    {
      title: renderTitle('First Name'),
      dataIndex: 'first',
      key: 'first_name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      ...getColumnSearchProps('first_name'),
      render: (_, record) => (
        <EditTableCell
          editable={editable}
          value={record.first_name}
          onChange={value =>
            handleEditValue({
              record,
              column: 'first_name',
              value,
              success: 'Updated first name successfully',
              error: 'Failed to update first name',
            })
          }
        />
      ),
    },
    {
      title: renderTitle('Last Name'),
      dataIndex: 'last_name',
      key: 'last_name',
      sortDirections: ['descend', 'ascend'],
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      ...getColumnSearchProps('last_name'),
      render: (_, record) => (
        <EditTableCell
          editable={editable}
          value={record.last_name}
          onChange={value =>
            handleEditValue({
              record,
              column: 'last_name',
              value,
              success: 'Updated last name successfully',
              error: 'Failed to update last name',
            })
          }
        />
      ),
    },
    {
      title: 'Superuser',
      dataIndex: 'is_superuser',
      key: 'is_superuser',
      filterMultiple: false,
      filters: BOOL_FILTERS,
      onFilter: (value, record) => record.is_superuser === value,
      render: (_, record) => renderBoolean(record.is_superuser),
    },
    {
      title: 'Site',
      dataIndex: 'site',
      key: 'site',
    },
    {
      title: renderTitle('Site Role'),
      dataIndex: 'site_role',
      key: 'site_role',
      filters: SITE_ROLES.map(siteRole => ({
        text: siteRole.text,
        value: siteRole.text,
      })),
      onFilter: (value, record) => record.site_role.includes(value),
      render: (_, record) => (
        <EditTableCell
          editable={editable}
          options={SITE_ROLES}
          input="select"
          value={record.site_role}
          label={get(find(SITE_ROLES, { value: record.site_role }), 'text')}
          onChange={value =>
            handleEditValue({
              record,
              column: 'site_role',
              value,
              success: 'Updated role successfully',
              error: 'Failed to update role',
            })
          }
        />
      ),
    },
    {
      title: 'Email Subscription',
      dataIndex: 'receives_report',
      key: 'receives_report',
      filterMultiple: false,
      filters: BOOL_FILTERS,
      onFilter: (value, record) => record.receives_report === value,
      render: (_, record) => renderBoolean(record.receives_report),
    },
    {
      title: 'Action',
      key: 'operation',
      fixed: isNotDesktop && 'right',
      render: renderTableDropdown,
    },
  ]

  return (
    <>
      <Table
        bordered
        size="small"
        dataSource={users}
        columns={columns}
        rowKey="id"
        loading={loading}
        tableLayout="auto"
        scroll={{ x: 900 }}
        pagination={{ size: 'large' }}
        data-testid="users-table"
      />
      <Drawer
        title="User Profile"
        width={400}
        open={showDrawer}
        destroyOnClose
        onClose={() => setShowDrawer(false)}
      >
        <UserProfile user={user} />
      </Drawer>
      <Modal
        title="Set Password"
        open={passwordModal}
        footer={null}
        onCancel={() => setPasswordModal(false)}
        width={400}
        destroyOnClose
      >
        <SetPasswordForm
          userSelected={userSelected}
          onClose={() => setPasswordModal(false)}
        />
      </Modal>
    </>
  )
}

UserTable.propTypes = {
  editable: PropTypes.bool,
  isNotDesktop: PropTypes.bool,
}

const sizes = ({ width }) => ({
  isNotDesktop: width <= BREAKPOINTS.LG,
})

export default withSizes(sizes)(UserTable)
