import { MinusSquareTwoTone, PlusCircleTwoTone } from '@ant-design/icons'
import { Card, Col, Form, Input, Row } from 'antd'
import { concat, first, get, map, zip } from 'lodash'
import PropTypes from 'prop-types'

import { DataFileTree, MiscFileSelect } from 'components'
import { trimFileExt } from 'utils/analyses'

import { validators } from './validators'

const SingleRunOptionEditor = ({
  analysisType,
  index,
  readOnly,
  runOption,
  handleRemoveRun,
  setRunOption,
}) => {
  const handleSetRunOption = (optionName, value) => {
    setRunOption(optionName, value)
  }

  const handleSetValueByIndex = (idx, field, value) => {
    const fieldValues = runOption[field]

    fieldValues[idx] = value
    handleSetRunOption(field, fieldValues)
  }

  const handleAddOnsetEvent = () => {
    handleSetRunOption('onsets', concat(runOption.onsets, null))
    handleSetRunOption('Event_name', concat(runOption.Event_name, ''))
  }

  const handleSetOnsetValue = (idx, files) => {
    if (files && files.name) {
      const fileName = trimFileExt(files.name)

      handleSetValueByIndex(idx, 'onsets', files)
      handleSetValueByIndex(idx, 'Event_name', fileName)
    }
  }

  const handleRemoveOnsetEvent = idx => {
    runOption.onsets.splice(idx, 1)
    runOption.Event_name.splice(idx, 1)
    handleSetRunOption('onsets', runOption.onsets)
    handleSetRunOption('Event_name', runOption.Event_name)
  }

  return (
    <Card
      title={`Run Number ${index + 1}`}
      extra={
        !readOnly && (
          <a href={`#run_${index}`} onClick={handleRemoveRun}>
            X
          </a>
        )
      }
    >
      <Form.Item label="fMRI Result" rules={validators.func_runs}>
        <DataFileTree
          analysisType={analysisType}
          initialValue={runOption?.Func_Runs}
          name={'func-runs'}
          disabled={readOnly}
          onSelectedFiles={value =>
            handleSetRunOption('Func_Runs', first(value))
          }
        />
      </Form.Item>
      <Form.Item>
        <Row>
          <Col md={11}>
            <div className="mb-05">Onsets (Timing) File</div>
          </Col>
          <Col md={10} offset={1}>
            <div className="mb-05">Event Name</div>
          </Col>
          <Col md={1} offset={1}>
            <PlusCircleTwoTone onClick={handleAddOnsetEvent} />
          </Col>
        </Row>
        {map(
          zip(runOption.onsets, runOption.Event_name),
          ([onset, event_name], idx) => (
            <Row key={idx}>
              <Col md={11}>
                <MiscFileSelect
                  disabled={readOnly}
                  initialValue={get(onset, 'id')}
                  name={`onset-${idx}`}
                  onChange={value => handleSetOnsetValue(idx, value)}
                />
              </Col>
              <Col md={10} offset={1}>
                <Input
                  value={event_name}
                  placeholder={`onset_event_${idx}`}
                  onChange={evt =>
                    handleSetValueByIndex(idx, 'Event_name', evt.target.value)
                  }
                />
              </Col>
              <Col md={1} offset={1}>
                <MinusSquareTwoTone
                  key={idx}
                  onClick={() => handleRemoveOnsetEvent(idx)}
                />
              </Col>
            </Row>
          ),
        )}
      </Form.Item>
      <Form.Item
        label="Durations (separated by comma)"
        rules={validators.durations}
      >
        <Input
          onChange={evt => handleSetRunOption('durations', evt.target.value)}
          disabled={readOnly}
          placeholder="[0], [0], [0]"
          defaultValue={runOption?.durations}
        />
      </Form.Item>
      <Form.Item
        label="Regressor Names (separated by comma)"
        rules={validators.regressor_names}
      >
        <Input
          onChange={evt =>
            handleSetRunOption('regressor_names', evt.target.value)
          }
          disabled={readOnly}
          placeholder="x1, y1, z1, pitch1, roll1, yaw1"
          defaultValue={runOption?.regressor_names}
        />
      </Form.Item>
      <Form.Item label="Regressors" rules={validators.regressors}>
        {readOnly ? (
          <Input defaultValue={runOption.regressors} disabled />
        ) : (
          <DataFileTree
            analysisType={analysisType}
            initialValue={runOption?.regressors}
            name={'regressors'}
            onChange={value => handleSetRunOption('regressors', first(value))}
          />
        )}
      </Form.Item>
    </Card>
  )
}

SingleRunOptionEditor.propTypes = {
  analysisType: PropTypes.object,
  index: PropTypes.number,
  readOnly: PropTypes.bool,
  runOption: PropTypes.object,
  handleRemoveRun: PropTypes.func,
  setRunOption: PropTypes.func,
}

export default SingleRunOptionEditor
