import {
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  EditOutlined,
  FileOutlined,
} from '@ant-design/icons'
import { Button, Card, Col, Drawer, Dropdown, Modal, Row, Table } from 'antd'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MiscFileCSVTable, MiscFileForm } from 'components'
import { PageLayout } from 'containers/Layouts'
import {
  deleteMiscFile,
  listMiscFile,
  selectAllMiscFiles,
  selectDataFilesStatus,
  updateMiscFile,
  uploadMiscFiles,
} from 'store/modules/datafiles'
import { downloadResult } from 'utils/analyses'

const MiscFilePage = () => {
  const allMiscFiles = useSelector(selectAllMiscFiles)
  const dataFilesStatus = useSelector(selectDataFilesStatus)

  const dispatch = useDispatch()

  const [showDrawer, setShowDrawer] = useState(false)
  const [recordMiscFile, setRecordMiscFile] = useState({})
  const [miscFileCSVModal, setMiscFileCSVModal] = useState(false)

  useEffect(() => {
    dispatch(listMiscFile())
  }, [dispatch])

  useEffect(() => {
    if (
      [
        deleteMiscFile.fulfilled().type,
        updateMiscFile.fulfilled().type,
        uploadMiscFiles.fulfilled().type,
      ].includes(dataFilesStatus)
    ) {
      dispatch(listMiscFile())
      setShowDrawer(false)
    }
  }, [dataFilesStatus, dispatch])

  const toggleMiscFileCSVModal = record => {
    setRecordMiscFile(record)
    setMiscFileCSVModal(!miscFileCSVModal)
  }

  const handleDownloadResult = url => {
    downloadResult(url)
  }

  const handleDeleteMiscFile = record =>
    Modal.confirm({
      title: 'Are you sure want to delete this misc file?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        dispatch(deleteMiscFile(record.id))
      },
    })

  const handleUpdate = record => {
    setRecordMiscFile(record)
    setShowDrawer(true)
  }

  const renderDropdown = record => {
    const miscFileExist = record && get(record, 'file').includes('.csv')

    const items = [
      miscFileExist && {
        key: 'view',
        label: 'View',
        icon: <FileOutlined />,
        onClick: () => toggleMiscFileCSVModal(record),
      },
      {
        key: 'update',
        label: 'Update',
        icon: <EditOutlined />,
        onClick: () => handleUpdate(record),
      },
      {
        key: 'download',
        label: 'Download',
        icon: <DownloadOutlined />,
        onClick: () => handleDownloadResult(get(record, 'path')),
      },
      {
        key: 'delete',
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: () => handleDeleteMiscFile(record),
      },
    ].filter(Boolean)

    return (
      <Dropdown menu={{ items }} trigger={['click']}>
        <Button size="small" data-testid="action-button">
          Action <DownOutlined />
        </Button>
      </Dropdown>
    )
  }

  const miscTableColumns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
    },
    {
      title: 'File',
      dataIndex: 'file',
      key: 'file',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Study name',
      dataIndex: 'study',
      key: 'study',
      render: record => <span>{record.label}</span>,
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: record => renderDropdown(record),
    },
  ]

  return (
    <PageLayout heading="Upload Misc File">
      <Card>
        <Row>
          <Col md={12} offset={4}>
            <MiscFileForm onSubmit={listMiscFile} />
          </Col>
        </Row>
        {allMiscFiles && (
          <Row style={{ marginTop: 20 }}>
            <Col md={24}>
              <Table
                rowKey="id"
                dataSource={allMiscFiles}
                columns={miscTableColumns}
                size="small"
                bordered
              />
            </Col>
          </Row>
        )}
      </Card>

      <Drawer
        width={500}
        title="Update miscellaneous file"
        open={showDrawer}
        destroyOnClose
        onClose={() => setShowDrawer(!showDrawer)}
      >
        <MiscFileForm record={recordMiscFile} />
      </Drawer>

      <Modal
        title={recordMiscFile?.name}
        open={miscFileCSVModal}
        footer={null}
        onCancel={toggleMiscFileCSVModal}
        width={1250}
        destroyOnClose
      >
        <MiscFileCSVTable miscFileId={recordMiscFile?.id} searchDataFile />
      </Modal>
    </PageLayout>
  )
}

export default MiscFilePage
