import { Button, Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

import { validators } from 'validators'

const SiteAddForm = ({ submitting, onCancel, onSubmit }) => {
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      data-testid="site-add-form"
      disabled={submitting}
      onFinish={onSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="full_name"
            label="Name"
            extra="Full name of the site"
            rules={validators.genericRequired}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            name="label"
            label="Label"
            extra="Abbreviation for site, no spaces"
            rules={validators.genericRequired}
          >
            <Input placeholder="Label" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Add
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

SiteAddForm.propTypes = {
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default SiteAddForm
