import { Tag } from 'antd'
import { map } from 'lodash'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { TAG_COLORS } from 'config/base'

export const CardDescription = ({ baseUrl, description, sets }) => {
  const navigate = useNavigate()

  const handleTagClick = id => {
    navigate(`${baseUrl}/${id}`)
  }

  if (sets.length === 0) {
    return description
  }

  return (
    <div>
      <div className="mb-1">{description}</div>
      <div>
        {map(sets, ({ id, name }, ind) => (
          <Tag
            key={id}
            color={TAG_COLORS[ind]}
            style={{ marginTop: 5 }}
            data-testid="card-tag"
            onClick={() => handleTagClick(id)}
          >
            {name}
          </Tag>
        ))}
      </div>
    </div>
  )
}

CardDescription.propTypes = {
  baseUrl: PropTypes.string,
  description: PropTypes.string,
  sets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ),
}

export default CardDescription
