import { Form } from 'antd'
import { get, map, sortBy, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DataFileTree, Select } from 'components'
import {
  initAnalysisOptions,
  selectAnalysis,
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import { setAllFiles, setCurrentFiles } from 'store/modules/datafiles'

export const FMRI32UploadSection = ({ readOnly }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisOptions = useSelector(selectAnalysisOptions)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  const [filesSeries, setFilesSeries] = useState(null)

  useEffect(() => {
    if (analysis) {
      const { options } = analysis.parameters.analysis

      dispatch(initAnalysisOptions(options))
      dispatch(setCurrentFiles([analysis.input_file]))
    }
  }, [dispatch, analysis])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const handleTreeChange = files => {
    dispatch(setAllFiles(files))
    dispatch(setCurrentFiles(files))

    const newFilesSeries = map(files, 'series')
    const uniqSeries = uniqBy(newFilesSeries, 'id')
    const sortedSeries = sortBy(uniqSeries, 'label')

    setFilesSeries(sortedSeries)
  }

  const initialFiles = get(analysisOptions, 'files.value')

  return (
    <Form layout="vertical">
      <div className="app-page__subheading">Data Sets</div>
      <Form.Item label="Please select datafiles">
        <DataFileTree
          multiple
          analysisType={analysisType}
          analysis={analysis}
          initialValue={initialFiles}
          onChange={handleTreeChange}
        />
      </Form.Item>
      <Form.Item label="Anat">
        <Select
          style={{ width: '100%' }}
          placeholder="Please select Anat serie"
          value={get(analysisOptions, 'Anat.value') || undefined}
          disabled={readOnly}
          options={map(filesSeries, ({ id, label }) => ({
            label,
            value: id,
          }))}
          onSelect={value => handleSetOption('Anat', 'value', value)}
        />
      </Form.Item>
      <Form.Item label="Funct">
        <Select
          style={{ width: '100%' }}
          placeholder="Please select Funct serie"
          value={get(analysisOptions, 'Funct.value') || undefined}
          disabled={readOnly}
          options={map(filesSeries, ({ id, label }) => ({
            label,
            value: id,
          }))}
          onSelect={value => handleSetOption('Funct', 'value', value)}
        />
      </Form.Item>
      <Form.Item label="SBRef (Optional)">
        <Select
          style={{ width: '100%' }}
          placeholder="Please select SBRef serie (optional)"
          value={get(analysisOptions, 'SBRef.value') || undefined}
          disabled={readOnly}
          options={[
            { label: '--------', value: null },
            ...map(filesSeries, ({ id, label }) => ({
              label,
              value: id,
            })),
          ]}
          onSelect={value => handleSetOption('SBRef', 'value', value)}
        />
      </Form.Item>
    </Form>
  )
}

FMRI32UploadSection.propTypes = {
  readOnly: PropTypes.bool,
}

export default FMRI32UploadSection
