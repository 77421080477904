import { Alert, Card } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'

import { AnalysisResultSummary, Loader } from 'components'
import { ANALYSIS_RESULTS, SOCKET_PATH } from 'config/base'
import { renderErrors } from 'utils/analyses'

const AnalysisResult = ({ dataFile, id }) => {
  const [output, setOutput] = useState(null)
  const [status, setStatus] = useState(null)

  const webSocket = useRef(null)

  const handleMessageReceived = message => {
    const parsedData = JSON.parse(message.data)
    setOutput(parsedData.output)
    setStatus(parsedData.status)
  }

  useEffect(() => {
    const url = `${SOCKET_PATH}/ws/analysis/${id}/`

    webSocket.current = new WebSocket(url)
    webSocket.current.onmessage = handleMessageReceived

    return () => {
      webSocket.current.close()
    }
  }, [id])

  const content = useMemo(() => {
    if (status === ANALYSIS_RESULTS.Complete) {
      return (
        <Card>
          <AnalysisResultSummary id={id} data={output} dataFile={dataFile} />
        </Card>
      )
    }

    if (
      [
        ANALYSIS_RESULTS.ReadyToRun,
        ANALYSIS_RESULTS.Pending,
        ANALYSIS_RESULTS.Running,
      ].includes(status)
    ) {
      return <Alert message={output} type="info" showIcon banner />
    }

    if (status === ANALYSIS_RESULTS.Error) {
      return renderErrors(output)
    }

    return <Loader />
  }, [output, status, dataFile, id])

  return <div>{content}</div>
}

AnalysisResult.propTypes = {
  dataFile: PropTypes.object,
  id: PropTypes.number,
}

export default AnalysisResult
