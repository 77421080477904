import { Col, Row, Tabs } from 'antd'
import { filter, map } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'
import { encodePathURL } from 'utils/analyses'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const DTIResult = ({ data, dataFile, token }) => {
  const { all_files, figures, out_dir, save_path } = data

  const outDir = out_dir || save_path

  const eddyFigures = filter(figures, figure => figure.includes('eddy'))
  const tbssFigures = filter(figures, figure => figure.includes('tbss'))

  return (
    <div className="analysis-result" data-testid="dti-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'output',
            label: 'Output',
            children: (
              <div className="w-75">
                <Tabs
                  tabPosition="left"
                  items={[
                    {
                      key: 'output-files',
                      label: 'Files',
                      children: (
                        <div className="w-33 mx-auto text-left">
                          <OutputFileTree files={all_files} />
                        </div>
                      ),
                    },
                  ]}
                />
              </div>
            ),
          },
          {
            key: 'qa-figures',
            label: 'QA Figures',
            children: (
              <Row gutter={16}>
                {map(eddyFigures, figure => (
                  <Col key={figure} md={12} style={{ marginBottom: 10 }}>
                    <img
                      className="w-100"
                      alt={figure}
                      data-testid="eddy-figure"
                      src={encodePathURL(outDir, figure, token)}
                    />
                  </Col>
                ))}
              </Row>
            ),
          },
          {
            key: 'dti-scalar',
            label: 'Registered FA Maps',
            children: (
              <Row gutter={16}>
                {map(tbssFigures, figure => (
                  <Col key={figure} style={{ marginBottom: 10 }}>
                    <img
                      className="w-100"
                      alt={figure}
                      data-testid="tbss-figure"
                      src={encodePathURL(outDir, figure, token)}
                    />
                  </Col>
                ))}
              </Row>
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

DTIResult.propTypes = {
  data: PropTypes.object,
  dataFile: PropTypes.object,
  token: PropTypes.string,
}

export default DTIResult
