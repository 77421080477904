import { Button, Col, Form, Row } from 'antd'
import { map, orderBy, size } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Select } from 'components'
import { createAnalysisPlan, updateAnalysisPlan } from 'store/modules/datafiles'
import { listModality } from 'store/modules/mappings'
import { listStudy } from 'store/modules/sites'
import { stringContains } from 'utils/common'

export const AssignModalityForm = ({
  analysisPlanId,
  initialValues,
  modalities,
  protocols,
  studies,
  submitting,
  onCancel,
}) => {
  const dispatch = useDispatch()

  const [form] = Form.useForm()

  const selectedStudy = Form.useWatch('study', form)
  const selectedProtocols = Form.useWatch('protocols', form)

  const sortedStudies = orderBy(studies, [study => study.label.toLowerCase()])
  const sortedProtocols = orderBy(protocols, [
    protocol => protocol?.full_name?.toLowerCase(),
  ])

  useEffect(() => {
    dispatch(listStudy())
    dispatch(listModality())
  }, [dispatch])

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const handleSubmit = values => {
    const data = values
    const params = { id: analysisPlanId, data }

    if (analysisPlanId) {
      dispatch(updateAnalysisPlan(params))
    } else {
      dispatch(createAnalysisPlan(data))
    }
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      initialValues={initialValues}
      submitting={submitting}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="study"
            label="Study"
            rules={[{ required: true, message: 'Please select study!' }]}
          >
            <Select
              options={sortedStudies.map(({ id, label }) => ({
                label,
                value: id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="protocols"
            label="Protocols"
            rules={[{ required: true, message: 'Please select protocol!' }]}
          >
            <Select
              mode="multiple"
              disabled={!selectedStudy}
              showSearch
              filterOption={(input, option) =>
                stringContains(option.label, input)
              }
              options={map(sortedProtocols, ({ id, full_name }) => ({
                label: full_name,
                value: id,
              }))}
            />
          </Form.Item>

          <Form.Item
            name="modalities"
            label="Modalities"
            rules={[{ required: true, message: 'Please select modalities!' }]}
          >
            <Select
              mode="multiple"
              disabled={size(selectedProtocols) === 0}
              options={modalities.map(({ id, full_name }) => ({
                label: full_name,
                value: id,
              }))}
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

AssignModalityForm.propTypes = {
  analysisPlanId: PropTypes.number,
  initialValues: PropTypes.object,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default AssignModalityForm
