import { Alert, Button } from 'antd'
import { filter, find, get, size, values } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Loader } from 'components'
import { ANALYSIS_DISMISS_FILE_SELECT } from 'config/base'
import { AnalysisConfiguration } from 'containers'
import {
  getAnalysisType,
  initAnalysis,
  listAnalysisType,
  selectAnalysesStatus,
  selectAnalysis,
  selectAnalysisOptions,
  selectAnalysisType,
  setAnalysisType,
} from 'store/modules/analyses'
import {
  listParameterSet,
  selectCurrentFiles,
  selectDataFilesStatus,
  selectParameterSets,
} from 'store/modules/datafiles'

export const AnalysisPredictionSection = ({ groupRun }) => {
  const analysesStatus = useSelector(selectAnalysesStatus)
  const analysis = useSelector(selectAnalysis)
  const analysisType = useSelector(selectAnalysisType)
  const currentFiles = useSelector(selectCurrentFiles)
  const dataFilesStatus = useSelector(selectDataFilesStatus)
  const options = useSelector(selectAnalysisOptions)
  const parameterSets = useSelector(selectParameterSets)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [initingAnalysis, setInitingAnalysis] = useState(false)

  useEffect(() => {
    dispatch(listParameterSet())
  }, [dispatch])

  const handleRunAnalysis = () => {
    dispatch(setAnalysisType(analysisType.id))
    dispatch(initAnalysis({ options, navigate, analysisType: analysisType.id }))
    setInitingAnalysis(true)
  }

  const validateAnalysis = () => {
    const { label } = analysisType

    const isRegression = label === 'Regression'
    const isPolyssifier = label === 'Polyssifier'
    const isMancova = label === 'MANCOVA'
    const isASL = label === 'ASL'
    const isGICA = label === 'GICA'

    if (parameterSets.length <= 0) {
      return 'No parameter set to select. Please create parameter set'
    }

    if (!get(options, 'name.value')) {
      return 'Please input analysis name'
    }

    if (!get(options, 'parameter_set.value')) {
      return 'Please select parameter'
    }

    if (isASL) {
      const parameterSetId = get(options, 'parameter_set.value')
      const parameterSet = find(parameterSets, { id: parameterSetId })

      if (
        get(options, ['Calibration_Image', 'value'], '') === '' &&
        get(parameterSet, 'options.Calib_notSep.value') === false
      ) {
        return 'Conflicting calibration image parameters'
      }

      if (
        get(parameterSet, 'options.Calib_notSep.value') &&
        ['', null].includes(get(parameterSet, 'options.Calib_Offset.value'))
      ) {
        return 'Calibration Volume Index is required'
      }

      if (get(parameterSet, 'options.ASL_Type.value') === '') {
        return 'Type of ASL data is required'
      }

      if (get(parameterSet, 'options.IAF.value') === '') {
        return 'How were label/control volumes acquired is required'
      }

      if (get(parameterSet, 'options.IBF.value') === '') {
        return 'Sequence of label/control volumes is required'
      }

      if (
        get(parameterSet, 'options.TIs.value') &&
        [null, undefined].includes(get(parameterSet, 'options.TIs.value.0'))
      ) {
        return 'Inversion Time(s) is required'
      }

      if (get(parameterSet, 'options.Bolus_Duration.value') === '') {
        return 'Bolus Duration is required'
      }

      if (get(parameterSet, 'options.Fixbolus.value') === '') {
        return 'Was bolus duration fixed is required'
      }

      if (get(parameterSet, 'options.Calib_Acq.value') === '') {
        return 'Was Calibration Image Acquired is required'
      }
    }

    if (isPolyssifier) {
      if (get(currentFiles[0], 'name', '').endsWith('.nii')) {
        return 'Nii files are not allowed in polyssifier analysis'
      }
    }

    if (isRegression || isPolyssifier) {
      const selectedCount = size(
        filter(values(get(currentFiles[0], 'fields')), field => field.selected),
      )
      const xCount = size(
        filter(
          values(get(currentFiles[0], 'fields')),
          field => field.variable_role === 'x' && field.selected,
        ),
      )
      const yCount = size(
        filter(
          values(get(currentFiles[0], 'fields')),
          field => field.variable_role === 'y' && field.selected,
        ),
      )

      if (selectedCount === 0) {
        return 'Please select at least one variable'
      }

      if (xCount === 0) {
        return 'Please select at least one feature variable'
      }

      if (yCount === 0) {
        return 'Please select at least one response variable'
      }
    }

    if (isMancova) {
      if (get(options, ['ica_parent', 'value']) === 'default') {
        return 'Please select a previously run Group ICA'
      }
    }

    if (isGICA) {
      const parameterSetId = get(options, 'parameter_set.value')
      const parameterSet = find(parameterSets, { id: parameterSetId })

      const matFileRequired = get(
        parameterSet,
        'options.TS_SPM_mat_file_exists.value',
      )
      const matFilePath = get(options, 'TS_SPM_mat_file_path.value')

      if (matFileRequired && !matFilePath) {
        return 'SPM mat file is required'
      }
    }

    return false
  }

  const pareparingData =
    analysesStatus === listAnalysisType.pending().type ||
    analysesStatus === getAnalysisType.pending().type ||
    dataFilesStatus === listParameterSet.pending().type

  const renderContent = () => {
    if (pareparingData) {
      return <Loader />
    }

    if (!analysis) {
      const noFileSelected = !get(currentFiles, '0.id')
      const dismissFileSelect = ANALYSIS_DISMISS_FILE_SELECT.includes(
        analysisType.label,
      )

      if (noFileSelected && !dismissFileSelect) {
        return (
          <Alert message="No file selected" type="warning" showIcon banner />
        )
      }
    }

    const error = validateAnalysis()

    return (
      <>
        <AnalysisConfiguration />
        {error && (
          <Alert
            message={error}
            type="warning"
            className="mb-2"
            showIcon
            banner
          />
        )}
        <Button
          type="primary"
          disabled={initingAnalysis || error}
          loading={initingAnalysis}
          onClick={handleRunAnalysis}
        >
          {groupRun ? 'Run Group Analysis' : 'Run Analysis'}
        </Button>
      </>
    )
  }

  return (
    <div
      className="analysis-start-widget"
      data-testid="analysis-prediction-section"
    >
      <div className="analysis-start-widget-heading">
        {groupRun ? 'Analysis' : 'Analysis & Prediction'}
      </div>
      {renderContent()}
    </div>
  )
}

AnalysisPredictionSection.propTypes = {
  groupRun: PropTypes.bool,
}

AnalysisPredictionSection.defaultProps = {
  groupRun: false,
}

export default AnalysisPredictionSection
