import { EditOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import { ParameterSetForm } from 'components'
import { updateParameterSet } from 'store/modules/datafiles'
import { getAnalysisLabel } from 'utils/analyses'

const ParameterSetInfo = ({
  analysisTypes,
  editable,
  parameterSet,
  status,
  user,
}) => {
  const [readOnly, setReadOnly] = useState(true)

  useEffect(() => {
    if (status === updateParameterSet.fulfilled().type) {
      setReadOnly(true)
    }
  }, [status])

  const toggleEdit = () => setReadOnly(prevState => !prevState)

  return (
    <div>
      <h2 className="d-flex align-items-center justify-content-center">
        {getAnalysisLabel(analysisTypes, parameterSet)} Parameter Set
        {readOnly && editable && (
          <Button
            className="ml-05"
            icon={<EditOutlined />}
            shape="circle"
            size="small"
            onClick={toggleEdit}
          />
        )}
      </h2>
      <ParameterSetForm
        user={user}
        readOnly={readOnly}
        analysisTypes={analysisTypes}
        parameterSet={parameterSet}
        onCancel={toggleEdit}
      />
    </div>
  )
}

ParameterSetInfo.propTypes = {
  analysisTypes: PropTypes.array,
  editable: PropTypes.bool,
  parameterSet: PropTypes.object,
  status: PropTypes.string,
  user: PropTypes.object,
}

export default ParameterSetInfo
