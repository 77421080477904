import { LANGUAGE } from 'config/base'

export const validators = {
  email: [
    { required: true, message: LANGUAGE.required },
    { type: 'email', message: LANGUAGE.email.invalid },
  ],
  password: [
    { required: true, message: LANGUAGE.required },
    { min: 8, message: LANGUAGE.password.minLength },
  ],
  passwordUpdate: [{ min: 8, message: LANGUAGE.password.minLength }],
  genericRequired: [{ required: true, message: LANGUAGE.required }],
}
