import {
  ClusterOutlined,
  DeleteOutlined,
  DownloadOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  EyeOutlined,
  RedoOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Modal } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { deleteAnalyses, prepareDownloadResult } from 'utils/analyses'

export const ActionDropDown = ({
  record,
  shared,
  onDeleteAnalysis,
  onToggleModal,
}) => {
  const navigate = useNavigate()

  const [downloadingAnalysis, setDownloadingAnalysis] = useState(null)

  const handleDeleteResult = id => {
    Modal.confirm({
      title: 'Are you sure want to delete this analysis result?',
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => deleteAnalyses([id], onDeleteAnalysis),
    })
  }

  const handleDownloadResult = async id => {
    setDownloadingAnalysis(id)

    prepareDownloadResult(id)
  }

  const completed = record.status === 'Complete'
  const failed = record.status === 'Error'
  const downloading = record.id === downloadingAnalysis

  const items = [
    {
      key: 'inputs',
      label: 'Inputs',
      icon: <ExperimentOutlined />,
      onClick: () => onToggleModal('inputsModal', record.id),
    },
    {
      key: 'parameters',
      label: 'Parameters',
      icon: <SettingOutlined />,
      onClick: () => onToggleModal('paramsModal', record.id),
    },
    completed && {
      key: 'results',
      label: 'Results',
      icon: <EyeOutlined />,
      onClick: () => navigate(`/analysis/${record.id}/result`),
    },
    completed && {
      key: 'download',
      label: downloading ? 'Preparing Download' : 'Prepare Download',
      icon: <DownloadOutlined />,
      onClick: () => handleDownloadResult(record.id),
    },
    failed && {
      key: 'errors',
      label: 'Errors',
      icon: <ExclamationCircleOutlined />,
      onClick: () => onToggleModal('errorModal', record.id),
    },
    {
      key: 'provenance',
      label: 'Provenance',
      icon: <ClusterOutlined />,
      onClick: () => onToggleModal('provenanceModal', record.id),
    },
    {
      key: 'redo',
      label: 'Redo',
      icon: <RedoOutlined />,
      onClick: () =>
        navigate(
          `/analysis-start/${record.analysis_type}?analysisId=${record.id}`,
        ),
    },
    (completed || failed) &&
      !shared && {
        key: 'delete',
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: () => handleDeleteResult(record.id),
      },
  ].filter(Boolean)

  return (
    <Dropdown menu={{ items }} trigger={['click']}>
      <Button size="small" onClick={e => e.preventDefault()}>
        Action <DownOutlined />
      </Button>
    </Dropdown>
  )
}

ActionDropDown.propTypes = {
  record: PropTypes.object,
  shared: PropTypes.bool,
  onDeleteAnalysis: PropTypes.func,
  onGetAnalysis: PropTypes.func,
}

export default ActionDropDown
