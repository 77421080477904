import { Checkbox, Col, Form, InputNumber, Row } from 'antd'
import update from 'immutability-helper'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const RealignForm = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
  setOption,
}) => {
  const updateArrayIndex = (sourceArr, arrayName, index, value) => {
    const destArr = sourceArr.map((elem, eIdx) =>
      eIdx === index ? parseFloat(value) : elem,
    )

    setOption(arrayName, 'value', destArr)
  }

  const renderArrayForm = (fieldLabel, fieldName, inputProps) => {
    const fieldValue = get(analysisOptions, [fieldName, 'value'])

    return (
      <Form.Item {...formLayout} label={fieldLabel}>
        {fieldValue.map((_item, id) => (
          <InputNumber
            disabled={readOnly}
            style={{ marginLeft: id ? 5 : 0 }}
            key={`${fieldName}_${id}`}
            id={`${fieldName}_${id}`}
            value={fieldValue[id]}
            {...inputProps}
            onChange={value =>
              updateArrayIndex(fieldValue, fieldName, id, value)
            }
          />
        ))}
      </Form.Item>
    )
  }

  const renderInputNumber = (fieldLabel, fieldName, inputProps) => (
    <Form.Item {...formLayout} label={fieldLabel}>
      <InputNumber
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        {...inputProps}
        onChange={value => setOption(fieldName, 'value', value)}
      />
    </Form.Item>
  )

  const {
    realign_register_to_sbref,
    realign_register_to_mean,
    realign_register_to_first,
    realign_write_mask,
  } = analysisOptions

  return (
    <Row>
      <Col span={12}>
        {renderInputNumber('Realign Smoothing FWHM', 'realign_fwhm', {
          min: 1,
        })}
        {renderInputNumber('Interpolation', 'realign_interp', { min: 1 })}
        {renderInputNumber('Realign Quality', 'realign_quality', { min: 0 })}
      </Col>
      <Col span={12}>
        <Form.Item {...formLayout} label="Register to SBRef" {...formLayout}>
          <Checkbox
            disabled={readOnly || realign_register_to_mean.value}
            onChange={event => {
              const value = event.target.checked
              let newAnalysisOptions = update(analysisOptions, {
                $merge: {
                  realign_register_to_sbref: { value },
                },
              })
              if (value) {
                newAnalysisOptions = update(newAnalysisOptions, {
                  $merge: {
                    realign_register_to_first: { value },
                    realign_register_to_mean: { value: !value },
                  },
                })
              }
              setAnalysisOption({ options: newAnalysisOptions })
            }}
            checked={get(realign_register_to_sbref, 'value')}
          />
        </Form.Item>
        <Form.Item {...formLayout} label="Register to Mean" {...formLayout}>
          <Checkbox
            disabled={readOnly}
            onChange={event => {
              const value = event.target.checked
              let newAnalysisOptions = update(analysisOptions, {
                $merge: {
                  realign_register_to_mean: { value },
                },
              })
              if (value) {
                newAnalysisOptions = update(newAnalysisOptions, {
                  $merge: {
                    realign_register_to_first: { value },
                    realign_register_to_sbref: { value: !value },
                  },
                })
              }
              setAnalysisOption({ options: newAnalysisOptions })
            }}
            checked={realign_register_to_mean.value}
          />
        </Form.Item>
        <Form.Item {...formLayout} label="Register to First" {...formLayout}>
          <Checkbox
            disabled={readOnly || realign_register_to_mean.value}
            onChange={event =>
              setOption(
                'realign_register_to_first',
                'value',
                event.target.checked,
              )
            }
            checked={get(realign_register_to_first, 'value')}
          />
        </Form.Item>
      </Col>
      <Col span={12}>
        {renderInputNumber('Realign Separation', 'realign_separation', {
          min: 0,
        })}
        {renderInputNumber('Write Interpolation', 'realign_write_interp', {
          min: 0,
        })}
        {renderArrayForm('Images to Reslice', 'realign_write_which', {
          min: 0,
          step: 0.1,
        })}
      </Col>
      <Col span={12}>
        {renderArrayForm('Wrapping', 'realign_wrap', { min: 0, step: 0.1 })}
        {renderArrayForm('Write Wrapping', 'realign_write_wrap', {
          min: 0,
          step: 0.1,
        })}

        <Form.Item {...formLayout} label="Write Mask?">
          <Checkbox
            disabled={readOnly}
            onChange={event =>
              setOption('realign_write_mask', 'value', event.target.checked)
            }
            checked={realign_write_mask.value}
          />
        </Form.Item>
      </Col>
    </Row>
  )
}
RealignForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
  setOption: PropTypes.func,
}

RealignForm.defaultProps = {
  readOnly: false,
}

export default RealignForm
