import { Card, Col, Row, Typography } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'

import { ForgotPasswordForm, LogInForm, Logo } from 'components'
import { selectVersion } from 'store/modules/auth'

const LogInPage = () => {
  const version = useSelector(selectVersion)

  const [currentForm, setCurrentForm] = useState('login')

  const handleChangeForm = () =>
    setCurrentForm(prevState =>
      prevState === 'login' ? 'forgot-password' : 'login',
    )

  return (
    <div className="auth-page">
      <Card className="auth-widget">
        <Logo className="auth-logo" />

        <Row>
          <Col span={24} className="auth-widget__heading">
            {currentForm === 'login' ? 'Log In' : 'Forgot Password'}
          </Col>
        </Row>

        {currentForm === 'login' ? (
          <LogInForm onChangeForm={handleChangeForm} />
        ) : (
          <ForgotPasswordForm onChangeForm={handleChangeForm} />
        )}

        {version && (
          <Typography.Title level={5} strong className="text-center mt-2">
            {version}
          </Typography.Title>
        )}
      </Card>
    </div>
  )
}

export default LogInPage
