import { Empty, Modal } from 'antd'
import { find, get } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from 'components'
import * as sitesRedux from 'store/modules/sites'

const SiteAdmin = ({ site, status, setAdmin }) => {
  const handleChange = userId => {
    const { id, members } = site
    const member = find(members, mem => mem.id === userId)

    Modal.confirm({
      title: `Are you going to promote ${member.username} as site admin?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        setAdmin({ siteId: id, userId: member.id })
      },
    })
  }

  const { members } = site
  const admin = get(find(members, { site_role: 'Admin' }), 'id')

  return (
    <div className="text-center" data-testid="site-admin">
      <h2 className="mb-2">Admin</h2>
      {members.length > 0 ? (
        <Select
          style={{ width: 150 }}
          value={admin}
          loading={status === sitesRedux.setAdmin.pending().type}
          options={members.map(({ id, username }) => ({
            label: username,
            value: id,
          }))}
          onChange={handleChange}
        />
      ) : (
        <Empty description="Please Add Members" />
      )}
    </div>
  )
}

SiteAdmin.propTypes = {
  site: PropTypes.object,
  status: PropTypes.string,
  setAdmin: PropTypes.func,
}

export default SiteAdmin
