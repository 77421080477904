import { Card, Col, Row, Typography } from 'antd'
import { useSelector } from 'react-redux'

import { Logo, RegisterForm } from 'components'
import { selectVersion } from 'store/modules/auth'

const RegisterPage = () => {
  const version = useSelector(selectVersion)

  return (
    <div className="auth-page">
      <Card className="auth-widget">
        <Logo className="auth-logo--register" />

        <Row>
          <Col span={24} className="auth-widget__heading">
            Register
          </Col>
        </Row>

        <RegisterForm />

        {version && (
          <Typography.Title level={5} strong className="text-center mt-2">
            {version}
          </Typography.Title>
        )}
      </Card>
    </div>
  )
}

export default RegisterPage
