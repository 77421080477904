import {
  createAsyncThunk,
  createSlice,
  isPending,
  isRejected,
} from '@reduxjs/toolkit'
import { notification } from 'antd'
import { find, keys, sortBy } from 'lodash'

import { sitesApi } from 'apis'
import * as datafilesRedux from 'store/modules/datafiles'
import { parseError } from 'utils/error-parser'

export const listSite = createAsyncThunk(
  'sites/listSite',
  async (_, { rejectWithValue }) => {
    try {
      return await sitesApi.listSite()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createSite = createAsyncThunk(
  'sites/createSite',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createSite(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const getSite = createAsyncThunk(
  'sites/getSite',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.getSite(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteSite = createAsyncThunk(
  'sites/deleteSite',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.deleteSite(payload)
      return payload
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const sendInvite = createAsyncThunk(
  'sites/sendInvite',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.sendInvite(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteInvite = createAsyncThunk(
  'sites/deleteInvite',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.deleteInvite(payload)
      return payload.inviteId
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const setAdmin = createAsyncThunk(
  'sites/setAdmin',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.setAdmin(payload)
      return payload.userId
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const removeMember = createAsyncThunk(
  'sites/removeMember',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.removeMember(payload)
      return payload.membershipId
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listStudy = createAsyncThunk(
  'sites/listStudy',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await sitesApi.listStudy(payload)
      return sortBy(data, 'label')
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createStudy = createAsyncThunk(
  'sites/createStudy',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createStudy(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const getStudy = createAsyncThunk(
  'sites/getStudy',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.getStudy(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateStudy = createAsyncThunk(
  'sites/updateStudy',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.updateStudy(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteStudy = createAsyncThunk(
  'sites/deleteStudy',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.deleteStudy(payload)
      return payload
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listUploadableStudy = createAsyncThunk(
  'sites/listUploadableStudy',
  async (_, { rejectWithValue }) => {
    try {
      return await sitesApi.listUploadableStudy()
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listAllScanner = createAsyncThunk(
  'sites/listAllScanner',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listAllScanner(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listScanner = createAsyncThunk(
  'sites/listScanner',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listScanner(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createScanner = createAsyncThunk(
  'sites/createScanner',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createScanner(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getScanner = createAsyncThunk(
  'sites/getScanner',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.getScanner(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateScanner = createAsyncThunk(
  'sites/updateScanner',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.updateScanner(payload)
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const deleteScanner = createAsyncThunk(
  'sites/deleteScanner',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.deleteScanner(payload)
      return payload
    } catch (error) {
      const err = parseError(error)
      notification.error({ message: err.message })
      return rejectWithValue(err)
    }
  },
)

export const listAllSubject = createAsyncThunk(
  'sites/listAllSubject',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listAllSubject(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createSubject = createAsyncThunk(
  'sites/createSubject',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createSubject(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listAllSession = createAsyncThunk(
  'sites/listAllSession',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listAllSession(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listSession = createAsyncThunk(
  'sites/listSession',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listSession(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const getSession = createAsyncThunk(
  'sites/getSession',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.getSession(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createSession = createAsyncThunk(
  'sites/createSession',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createSession(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listAllSeries = createAsyncThunk(
  'sites/listAllSeries',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listAllSeries(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listSeries = createAsyncThunk(
  'sites/listSeries',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listSeries(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createSeries = createAsyncThunk(
  'sites/createSeries',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createSeries(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const listTag = createAsyncThunk(
  'sites/listTag',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.listTag(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const createTag = createAsyncThunk(
  'sites/createTag',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.createTag(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const updateTag = createAsyncThunk(
  'sites/updateTag',
  async (payload, { rejectWithValue }) => {
    try {
      return await sitesApi.updateTag(payload)
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const deleteTag = createAsyncThunk(
  'sites/deleteTag',
  async (payload, { rejectWithValue }) => {
    try {
      await sitesApi.deleteTag(payload)
      return payload
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

export const assignTags = createAsyncThunk(
  'sites/assignTags',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const data = await sitesApi.assignTags(payload)
      dispatch(datafilesRedux.assignTags(payload))
      return data
    } catch (error) {
      return rejectWithValue(parseError(error))
    }
  },
)

const initialState = {
  sites: [],
  site: null,
  studies: [],
  study: null,
  allScanners: [],
  allSubjects: [],
  allSessions: [],
  allSeries: [],
  series: [],
  scanners: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  scanner: null,
  sessions: {
    pageSize: 10,
    currentPage: 1,
    totalCount: 0,
    results: [],
  },
  tags: [],
  uploadableStudies: [],
  status: 'INIT',
  error: null,
}

export const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  extraReducers: builder => {
    builder.addCase(getSite.pending, state => {
      state.site = null
    })
    builder.addCase(getStudy.pending, state => {
      state.study = null
    })
    builder.addCase(listSite.fulfilled, (state, { payload, type }) => {
      state.sites = payload
      state.status = type
    })
    builder.addCase(createSite.fulfilled, (state, { payload, type }) => {
      state.sites = [payload, ...state.sites]
      state.status = type
    })
    builder.addCase(getSite.fulfilled, (state, { payload, type }) => {
      state.site = payload
      state.status = type
    })
    builder.addCase(deleteSite.fulfilled, (state, { payload, type }) => {
      state.sites = state.sites.filter(site => site.id !== payload)
      state.status = type
    })
    builder.addCase(sendInvite.fulfilled, (state, { payload, type }) => {
      state.site.invites = find(state.site.invites, { id: payload.id })
        ? state.site.invites.map(invite =>
            invite.id === payload.id ? { ...invite, ...payload } : invite,
          )
        : [payload, ...state.site.invites]
      state.status = type
    })
    builder.addCase(deleteInvite.fulfilled, (state, { payload, type }) => {
      state.site.invites = state.site.invites.filter(
        invite => invite.id !== payload,
      )
      state.status = type
    })
    builder.addCase(setAdmin.fulfilled, (state, { payload, type }) => {
      state.site.members = state.site.members.map(member => ({
        ...member,
        site_role: member.id === payload ? 'Admin' : 'Member',
      }))
      state.status = type
    })
    builder.addCase(removeMember.fulfilled, (state, { payload, type }) => {
      state.site.members = state.site.members.filter(
        member => member.id !== payload,
      )
      state.status = type
    })
    builder.addCase(listStudy.fulfilled, (state, { payload, type }) => {
      state.studies = payload
      state.status = type
    })
    builder.addCase(createStudy.fulfilled, (state, { payload, type }) => {
      state.studies = [...state.studies, payload]
      state.status = type
    })
    builder.addCase(getStudy.fulfilled, (state, { payload, type }) => {
      state.studies = state.studies.map(study =>
        study.id === payload.id ? payload : study,
      )
      state.study = payload
      state.status = type
    })
    builder.addCase(updateStudy.fulfilled, (state, { payload, type }) => {
      state.studies = state.studies.map(study =>
        study.id === payload.id ? payload : study,
      )
      state.study = payload
      state.status = type
    })
    builder.addCase(deleteStudy.fulfilled, (state, { payload, type }) => {
      state.studies = state.studies.filter(study => study.id !== payload)
      state.status = type
    })
    builder.addCase(
      listUploadableStudy.fulfilled,
      (state, { payload, type }) => {
        state.uploadableStudies = payload
        state.status = type
      },
    )
    builder.addCase(listAllScanner.fulfilled, (state, { payload, type }) => {
      state.allScanners = payload
      state.status = type
    })
    builder.addCase(listScanner.fulfilled, (state, { payload, type }) => {
      state.scanners = payload
      state.status = type
    })
    builder.addCase(createScanner.fulfilled, (state, { payload, type }) => {
      state.allScanners = [...state.allScanners, payload]
      state.scanners.results = [...state.scanners.results, payload]
      state.status = type
    })
    builder.addCase(getScanner.fulfilled, (state, { payload, type }) => {
      state.scanner = payload
      state.status = type
    })
    builder.addCase(updateScanner.fulfilled, (state, { payload, type }) => {
      state.scanners.results = state.scanners.results.map(scanner =>
        scanner.id === payload.id ? payload : scanner,
      )
      state.status = type
    })
    builder.addCase(deleteScanner.fulfilled, (state, { payload, type }) => {
      state.scanners.results = state.scanners.results.filter(
        scanner => scanner.id !== payload,
      )
      state.status = type
    })
    builder.addCase(listAllSubject.fulfilled, (state, { payload, type }) => {
      state.allSubjects = payload
      state.status = type
    })
    builder.addCase(createSubject.fulfilled, (state, { payload, type }) => {
      state.allSubjects = [...state.allSubjects, payload]
      state.status = type
    })
    builder.addCase(listAllSession.fulfilled, (state, { payload, type }) => {
      state.allSessions = payload
      state.status = type
    })
    builder.addCase(getSession.fulfilled, (state, { payload, type }) => {
      state.sessions.results = state.sessions.results.map(session =>
        session.id === payload.id ? payload : session,
      )
      state.status = type
    })
    builder.addCase(listSession.fulfilled, (state, { payload, type }) => {
      state.sessions = payload
      state.status = type
    })
    builder.addCase(createSession.fulfilled, (state, { payload, type }) => {
      state.allSessions = [...state.allSessions, payload]
      state.status = type
    })
    builder.addCase(listAllSeries.fulfilled, (state, { payload, type }) => {
      state.allSeries = payload
      state.status = type
    })
    builder.addCase(listSeries.fulfilled, (state, { payload, type }) => {
      state.series = payload
      state.status = type
    })
    builder.addCase(createSeries.fulfilled, (state, { payload, type }) => {
      state.allSeries = [...state.allSeries, payload]
      state.status = type
    })
    builder.addCase(listTag.fulfilled, (state, { payload, type }) => {
      state.tags = payload
      state.status = type
    })
    builder.addCase(createTag.fulfilled, (state, { payload, type }) => {
      state.tags = [...state.tags, payload]
      state.status = type
    })
    builder.addCase(updateTag.fulfilled, (state, { payload, type }) => {
      state.tags = state.tags.map(tag =>
        tag.id === payload.id ? payload : tag,
      )
      state.status = type
    })
    builder.addCase(deleteTag.fulfilled, (state, { payload, type }) => {
      state.tags = state.tags.filter(tag => tag.id !== payload)
      state.status = type
    })
    builder.addCase(assignTags.fulfilled, (state, { payload, type }) => {
      if (keys(payload).includes('study')) {
        state.studies = state.studies.map(study =>
          study.id === payload.study ? { ...study, tags: payload.tags } : study,
        )
        state.status = type
      }
    })
    builder.addMatcher(isPending, (state, { type }) => {
      state.error = null
      state.status = type
    })
    builder.addMatcher(isRejected, (state, { payload, type }) => {
      state.error = payload.message
      state.status = type
    })
  },
})

export const { reducer } = sitesSlice
