import {
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
} from 'antd'
import { get, noop } from 'lodash'
import PropTypes from 'prop-types'

import { MiscFileSelect, Select } from 'components'
import { DTI_CONFIG_OPTIONS } from 'config/base'
import { getParameterLayouts } from 'utils/common'

const { Item: FormItem } = Form
const { Panel } = Collapse
const { formLayout } = getParameterLayouts()

const DTIOptionEditor = ({ analysisOptions, readOnly, setAnalysisOption }) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const renderInput = (fieldName, placeholder) => (
    <FormItem {...formLayout} label={fieldName}>
      <Input
        placeholder={placeholder}
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      />
    </FormItem>
  )

  const renderSelect = (fieldName, fieldOptions) => (
    <FormItem {...formLayout} label={fieldName}>
      <Select
        className="w-50"
        name={fieldName}
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        options={fieldOptions.map(option => ({ label: option, value: option }))}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  const renderInputNumber = (fieldName, precision) => (
    <FormItem {...formLayout} label={fieldName}>
      <InputNumber
        className="w-100"
        value={get(analysisOptions, [fieldName, 'value'])}
        disabled={readOnly}
        precision={precision}
        onChange={value => handleSetOption(fieldName, 'value', value)}
      />
    </FormItem>
  )

  const renderSwitch = fieldName => (
    <FormItem {...formLayout} label={fieldName}>
      <Radio.Group
        disabled={readOnly}
        value={get(analysisOptions, [fieldName, 'value'])}
        onChange={evt => handleSetOption(fieldName, 'value', evt.target.value)}
      >
        <Radio value={false}>false</Radio>
        <Radio value={true}>true</Radio>
      </Radio.Group>
    </FormItem>
  )

  const renderUseCuda = () => (
    <Panel header="CUDA Parameters" key="panel">
      <Row>
        <Col md={10}>
          {renderInputNumber('Mporder', 6)}
          {renderInputNumber('Niter', 0)}
          {renderSelect('Config', DTI_CONFIG_OPTIONS)}
          <FormItem {...formLayout} label="Slice Order Acquisition">
            <MiscFileSelect
              disabled={readOnly}
              initialValue={get(analysisOptions, ['Slice_File', 'value'])}
              onChange={file => handleSetOption('Slice_File', 'value', file.id)}
            />
          </FormItem>
        </Col>
        <Col md={10} offset={2}>
          {renderInputNumber('Slice2Vol_Lambda', 0)}
          {renderInputNumber('Slice2Vol_Niter', 0)}
          {renderInput('Slice2Vol_Interp', 'trilinear')}
          {renderInput('gres', 'gpu:1')}
        </Col>
      </Row>
    </Panel>
  )

  const renderUseRegular = () => (
    <Panel header="Regular Parameters" key="panel">
      <Row>
        <Col md={10}>{renderSwitch('Estimate_Skeleton')}</Col>
      </Row>
    </Panel>
  )

  const useCuda = get(analysisOptions, ['Use_Cuda', 'value'])

  return (
    <Row data-testid="dti-option-editor">
      <Col span={24}>
        <Row gutter={24}>
          <Col md={10}>
            {renderSwitch('Mask')}
            {renderSwitch('Repol')}
            {renderSwitch('Use_Cuda')}
          </Col>
          <Col md={10} offset={2}>
            {renderInputNumber('Frac', 2)}
            {renderInputNumber('Sigma', 2)}
            {renderInput('Tag', '32ch')}
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col md={24}>
            <Collapse defaultActiveKey={'panel'}>
              {useCuda ? renderUseCuda() : renderUseRegular()}
            </Collapse>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

DTIOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

DTIOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default DTIOptionEditor
