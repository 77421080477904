import { Col, Collapse, Row } from 'antd'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

import DistortionForm from './DistortionForm'
import NormalizeForm from './NormalizeForm'
import RealignForm from './RealignForm'
import ReorientForm from './ReorientForm'
import SBRefForm from './SBRefForm'
import ScansForm from './ScansForm'
import SliceTimingCorrectionForm from './SliceTimingCorrectionForm'
import SmoothingForm from './SmoothingForm'

const { Panel } = Collapse

const FunctionalMRIPreprocOptionEditor = ({
  analysisOptions,
  readOnly,
  setAnalysisOption,
}) => {
  const handleSetOption = (optionName, parameterName, value) => {
    setAnalysisOption({
      name: optionName,
      option: { [parameterName]: value },
    })
  }

  const childProps = {
    analysisOptions,
    readOnly,
    setAnalysisOption,
    setOption: handleSetOption,
  }

  return (
    <Row data-testid="fmri-option-editor">
      <Col md={24}>
        <Collapse>
          <Panel header="Discard Dummy Scans Options">
            <ScansForm {...childProps} />
          </Panel>
          <Panel header="SBRef Options">
            <SBRefForm {...childProps} />
          </Panel>
          <Panel header="Distortion Correction Options">
            <DistortionForm {...childProps} />
          </Panel>
          <Panel header="Reorient Options">
            <ReorientForm {...childProps} />
          </Panel>
          <Panel header="Slice Timing Correction Options">
            <SliceTimingCorrectionForm {...childProps} />
          </Panel>
          <Panel header="Realign Options">
            <RealignForm {...childProps} />
          </Panel>
          <Panel header="Normalize Options">
            <NormalizeForm {...childProps} />
          </Panel>
          <Panel header="Smoothing Options">
            <SmoothingForm {...childProps} />
          </Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

FunctionalMRIPreprocOptionEditor.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setAnalysisOption: PropTypes.func,
}

FunctionalMRIPreprocOptionEditor.defaultProps = {
  readOnly: false,
  setAnalysisOption: noop,
}

export default FunctionalMRIPreprocOptionEditor
