import { Button, Col, Form, Input, InputNumber, Row } from 'antd'
import { get, pick } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Select } from 'components'
import { validators } from 'validators'

const ScannerForm = ({
  isSuper,
  scanner,
  sites,
  submitting,
  onCancel,
  onSubmit,
}) => {
  const initialValues = useMemo(() => {
    if (!scanner) {
      return null
    }

    const values = pick(
      scanner,
      'full_name',
      'label',
      'model',
      'manufacturer',
      'station',
      'field_strength',
    )

    if (isSuper) {
      values.site = get(scanner, ['site', 'id'])
    }

    return values
  }, [scanner, isSuper])

  const handleSubmit = values => {
    onSubmit({ id: get(scanner, 'id'), data: values })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      initialValues={initialValues}
      data-testid="scanner-form"
      disabled={submitting}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          {isSuper && (
            <Form.Item
              name="site"
              label="Site"
              rules={validators.genericRequired}
            >
              <Select
                options={sites.map(({ id, label }) => ({
                  label,
                  value: id,
                }))}
              />
            </Form.Item>
          )}

          <Form.Item
            name="full_name"
            label="Name"
            extra="Full name of the scanner"
            rules={validators.genericRequired}
          >
            <Input placeholder="Name" />
          </Form.Item>

          <Form.Item
            name="label"
            label="Label"
            extra="Abbreviation for scanner, no spaces"
            rules={validators.genericRequired}
          >
            <Input placeholder="Label" />
          </Form.Item>

          <Form.Item name="model" label="Model">
            <Input placeholder="Model" />
          </Form.Item>

          <Form.Item name="manufacturer" label="Manufacturer">
            <Input placeholder="Manufacturer" />
          </Form.Item>

          <Form.Item name="station" label="Station">
            <Input placeholder="Station" />
          </Form.Item>

          <Form.Item name="field_strength" label="Field Strength">
            <InputNumber
              placeholder="Field Strength"
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

ScannerForm.propTypes = {
  isSuper: PropTypes.bool,
  scanner: PropTypes.object,
  sites: PropTypes.array,
  submitting: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ScannerForm
