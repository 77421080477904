import { UploadOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, Row } from 'antd'
import { get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { DataFileTree, Loader } from 'components'
import { selectAnalysis, selectAnalysisType } from 'store/modules/analyses'
import {
  initializeCurrentFiles,
  setAllFiles,
  setCurrentFiles,
  updateCurrentFileFields,
} from 'store/modules/datafiles'
import {
  listUploadableStudy,
  selectSitesStatus,
  selectUploadableStudies,
} from 'store/modules/sites'

export const DefaultUploadSection = ({ showSelector }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisType = useSelector(selectAnalysisType)
  const sitesStatus = useSelector(selectSitesStatus)
  const studies = useSelector(selectUploadableStudies)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listUploadableStudy())

    return () => dispatch(initializeCurrentFiles())
  }, [dispatch])

  const handleTreeChange = files => {
    dispatch(setAllFiles(files))
    dispatch(setCurrentFiles(files))
  }

  const inputFile = get(analysis, 'parameters.analysis.options.files.value')
  const loading = sitesStatus === listUploadableStudy.pending().type

  if (loading) return <Loader />

  return (
    <Form layout="vertical">
      <Row>
        <Col span={24}>
          <Form.Item label="Upload a new data:">
            {studies && !isEmpty(studies) ? (
              <Link to="/data/new/">
                <Button>
                  <UploadOutlined /> Click to upload
                </Button>
              </Link>
            ) : (
              <Alert
                className="mb-1"
                message={
                  'You do not have any studies. To upload data, please create your own study or get permission to add data to other studies.'
                }
                type="warning"
                showIcon
                banner
              />
            )}
          </Form.Item>
        </Col>
        {showSelector && (
          <Col span={24}>
            <Form.Item label="Or select a previously uploaded/output dataset:">
              <DataFileTree
                multiple
                analysisType={analysisType}
                analysis={analysis}
                initialValue={inputFile}
                onChange={handleTreeChange}
                onUpdateFields={values =>
                  dispatch(updateCurrentFileFields(values))
                }
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  )
}

DefaultUploadSection.propTypes = {
  showSelector: PropTypes.bool,
}

DefaultUploadSection.defaultProps = {
  showSelector: true,
}

export default DefaultUploadSection
