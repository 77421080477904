import { Button, Col, Form, Input, Row } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'

import { validators } from 'validators'

const TagForm = ({ submitting, tag, onCancel, onSubmit }) => {
  const handleSubmit = values => {
    onSubmit({ id: get(tag, 'id'), data: values })
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  }

  return (
    <Form
      {...formItemLayout}
      initialValues={tag}
      disabled={submitting}
      onFinish={handleSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="label"
            label="Label"
            extra="Label for Tag"
            rules={validators.genericRequired}
          >
            <Input placeholder="Label" />
          </Form.Item>
        </Col>

        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={submitting}>
            Save
          </Button>
          <Button className="ml-1" onClick={onCancel}>
            Cancel
          </Button>
        </Col>
      </Row>
    </Form>
  )
}

TagForm.propTypes = {
  submitting: PropTypes.bool,
  tag: PropTypes.object,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default TagForm
