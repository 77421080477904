import { DownloadOutlined } from '@ant-design/icons'
import { Alert, Button } from 'antd'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'

import {
  ASLResult,
  BrainvizResult,
  DTIResult,
  FMRI32Result,
  FMRIPhantomQAResult,
  FMRIResult,
  FreeSurfer7Result,
  FreeSurferRegressionResult,
  FreeSurferResult,
  GiftResult,
  PolyssifierResult,
  RegressionResult,
  SPMGLMLevel1Result,
  SPMGLMLevel2Result,
  VBMResult,
  WMHResult,
} from 'components'
import { prepareDownloadResult } from 'utils/analyses'
import { getAuthData } from 'utils/storage'

const AnalysisResultSummary = ({ data, dataFile, id }) => {
  const [downloading, setDownloading] = useState(false)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const authData = getAuthData()
    setToken(get(authData, 'token'))
  }, [])

  const handleDownload = () => {
    setDownloading(true)
    prepareDownloadResult(id)
  }

  const renderSubResult = () => {
    switch (data.name) {
      case 'ASL':
        return <ASLResult data={data} dataFile={dataFile} />
      case 'DTI Preprocessing':
        return <DTIResult dataFile={dataFile} data={data} token={token} />
      case 'regression':
        return <RegressionResult dataFile={dataFile} data={data} />
      case 'BrainViZ':
        return <BrainvizResult datafile={dataFile} data={data} token={token} />
      case 'polyssifier':
        return (
          <PolyssifierResult dataFile={dataFile} data={data} token={token} />
        )
      case 'groupica':
      case 'dfnc':
      case 'MANCOVA':
        return <GiftResult data={data} id={id} token={token} />
      case 'vbm':
        return <VBMResult data={data} dataFile={dataFile} token={token} />
      case 'fMRIPreproc':
        return <FMRIResult data={data} dataFile={dataFile} token={token} />
      case 'fMRI 32-channel':
        return <FMRI32Result data={data} dataFile={dataFile} token={token} />
      case 'fMRI Phantom QA':
        return (
          <FMRIPhantomQAResult data={data} dataFile={dataFile} token={token} />
        )
      case 'Freesurfer':
        return <FreeSurferResult data={data} dataFile={dataFile} />
      case 'FreeSurfer7':
        return <FreeSurfer7Result data={data} dataFile={dataFile} />
      case 'Freesurfer Regression':
        return <FreeSurferRegressionResult data={data} dataFile={dataFile} />
      case 'SPM-GLM Level 1':
        return (
          <SPMGLMLevel1Result data={data} token={token} dataFile={dataFile} />
        )
      case 'SPM-GLM Group Level':
        return (
          <SPMGLMLevel2Result data={data} token={token} dataFile={dataFile} />
        )
      case 'WMH':
        return <WMHResult dataFile={dataFile} data={data} token={token} />
      default:
        return (
          <Alert
            message="Your analysis is running, please check back later."
            type="info"
            showIcon
            banner
          />
        )
    }
  }

  if (!data) return null

  const { name, description, has_figures, all_files } = data

  return (
    <div data-testid="analysis-result">
      <div className="analysis-result">
        {(has_figures || all_files) && (
          <div>
            <Button
              type="default"
              icon={<DownloadOutlined />}
              size="large"
              style={{ marginLeft: 10 }}
              loading={downloading}
              disabled={downloading}
              data-testid="download-result-button"
              onClick={handleDownload}
            >
              {downloading
                ? "Preparing download. You will be notified when it's ready."
                : 'Prepare download results'}
            </Button>
          </div>
        )}
        {name && (
          <div className="analysis-result__heading">
            <div className="text-uppercase">{name}</div>
            {description && (
              <div className="analysis-result__subheading">{description}</div>
            )}
          </div>
        )}
      </div>
      {renderSubResult()}
    </div>
  )
}

AnalysisResultSummary.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
    description: PropTypes.string,
    has_figures: PropTypes.bool,
    name: PropTypes.string,
    out_dir: PropTypes.string,
    save_path: PropTypes.string,
  }),
  dataFile: PropTypes.object,
  id: PropTypes.number,
}

export default AnalysisResultSummary
