import { Col, Form, InputNumber, Row } from 'antd'
import { cloneDeep, get, isArray, set } from 'lodash'
import PropTypes from 'prop-types'

import { Select } from 'components'
import { getParameterFormLayouts } from 'utils/common'

const formLayout = getParameterFormLayouts('fMRI')

const NormalizeForm = ({ analysisOptions, readOnly, setOption }) => {
  const updateArrayIndex = (sourceArr, arrayName, index, value) => {
    const destArr = sourceArr.map((elem, eIdx) =>
      eIdx === index ? parseFloat(value) : elem,
    )

    if (arrayName.includes(':')) {
      const [fieldName, fieldIndex] = arrayName.split(':')
      const nextFieldValue = cloneDeep(
        get(analysisOptions, [fieldName, 'value']),
      )
      set(nextFieldValue, fieldIndex, destArr)
      setOption(fieldName, 'value', nextFieldValue)
    } else {
      setOption(arrayName, 'value', destArr)
    }
  }

  const renderArrayForm = (arrayValue, fieldName, inputProps) =>
    arrayValue.map((item, id) =>
      isArray(item) ? (
        <Row key={id}>
          <Col span={24}>
            {renderArrayForm(item, `${fieldName}:${id}`, inputProps)}
          </Col>
        </Row>
      ) : (
        <InputNumber
          key={`${fieldName}_${id}`}
          style={{ marginLeft: id ? 5 : 0 }}
          disabled={readOnly}
          value={item}
          {...inputProps}
          onChange={value => updateArrayIndex(arrayValue, fieldName, id, value)}
        />
      ),
    )

  const {
    normalize_affine_regularization_type,
    normalize_write_bounding_box,
    normalize_write_interp,
    normalize_write_voxel_sizes,
    normalize_template,
  } = analysisOptions

  return (
    <Row>
      <Col span={16}>
        <Form.Item label="Template" {...formLayout}>
          <Select
            disabled={readOnly}
            value={get(normalize_template, 'value')}
            options={[
              { label: 'EPI', value: 'EPI' },
              { label: 'TPM', value: 'TPM' },
            ]}
            onChange={value => setOption('normalize_template', 'value', value)}
          />
        </Form.Item>
        <Form.Item label="Affine Regularization" {...formLayout}>
          <Select
            disabled={readOnly}
            value={get(normalize_affine_regularization_type, 'value')}
            options={[
              { label: 'MNI', value: 'mni' },
              { label: 'Eastern', value: 'eastern' },
              { label: 'Subject', value: 'subj' },
              { label: 'None', value: 'none' },
            ]}
            onChange={value =>
              setOption('normalize_affine_regularization_type', 'value', value)
            }
          />
        </Form.Item>
        <Form.Item label="Write Bounding Box" {...formLayout}>
          {renderArrayForm(
            normalize_write_bounding_box.value,
            'normalize_write_bounding_box',
            { min: -300, max: 300, step: 1 },
          )}
        </Form.Item>
        <Form.Item label="Write Interpolation" {...formLayout}>
          <InputNumber
            value={get(normalize_write_interp, 'value')}
            min={1}
            max={162}
            disabled={readOnly}
            onChange={value =>
              setOption('normalize_write_interp', 'value', value)
            }
          />
        </Form.Item>
        <Form.Item label="Write Voxel Sizes" {...formLayout}>
          {renderArrayForm(
            normalize_write_voxel_sizes.value,
            'normalize_write_voxel_sizes',
            { min: -300, max: 300, step: 0.1 },
          )}
        </Form.Item>
      </Col>
    </Row>
  )
}

NormalizeForm.propTypes = {
  analysisOptions: PropTypes.object,
  readOnly: PropTypes.bool,
  setOption: PropTypes.func,
}

NormalizeForm.defaultProps = {
  readOnly: false,
}

export default NormalizeForm
