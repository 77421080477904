import { Form } from 'antd'
import { concat, filter, first, get } from 'lodash'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { DataFileTree } from 'components'
import {
  initAnalysisOptions,
  selectAnalysis,
  selectAnalysisType,
  setAnalysisOption,
} from 'store/modules/analyses'
import { setAllFiles, setCurrentFiles } from 'store/modules/datafiles'

const { Item: FormItem } = Form

export const WMHUploadSection = ({ readOnly }) => {
  const analysis = useSelector(selectAnalysis)
  const analysisType = useSelector(selectAnalysisType)

  const dispatch = useDispatch()

  const [masterFile, setMasterFile] = useState([])
  const [baseImage, setBaseImage] = useState([])
  const [test, setTest] = useState([])
  const [training, setTraining] = useState([])

  useEffect(() => {
    if (analysis) {
      const analysisOptions = analysis.parameters.analysis.options

      dispatch(initAnalysisOptions(analysisOptions))
      dispatch(setCurrentFiles([analysis.input_file]))
    }
  }, [dispatch, analysis])

  useEffect(() => {
    const allFiles = filter(concat(masterFile, baseImage, test, training))

    if (allFiles) {
      dispatch(setAllFiles(allFiles))
      dispatch(setCurrentFiles(allFiles))
    }
  }, [masterFile, baseImage, test, training, dispatch])

  const handleSetOption = (optionName, parameterName, value) => {
    dispatch(
      setAnalysisOption({
        name: optionName,
        option: { [parameterName]: value },
      }),
    )
  }

  const handleMasterFileChange = files => {
    handleSetOption('Master_File', 'value', first(files))
    setMasterFile(first(files))
  }

  const handleBaseImageChange = files => {
    handleSetOption('Base_Image', 'value', first(files))
    setBaseImage(first(files))
  }

  const handleSelectTestFiles = files => {
    handleSetOption('test', 'value', files)
    setTest(files)
  }

  const handleSelectTrainingFiles = files => {
    handleSetOption('training', 'value', files)
    setTraining(files)
  }

  const initialOptions = get(analysis, 'parameters.analysis.options')

  return (
    <Form layout="vertical">
      <FormItem label="Please select Base Image">
        <DataFileTree
          multiple
          analysisType={analysisType}
          analysis={analysis}
          name={'base-image'}
          disabled={readOnly}
          initialValue={get(initialOptions, 'Base_Image.value')}
          onChange={handleBaseImageChange}
        />
      </FormItem>
      <FormItem label="Please select Master File">
        <DataFileTree
          multiple
          analysisType={analysisType}
          name={'master-file'}
          disabled={readOnly}
          analysis={analysis}
          initialValue={get(initialOptions, 'Master_File.value')}
          onChange={handleMasterFileChange}
        />
      </FormItem>
      <FormItem label="Please select Test Files">
        <DataFileTree
          multiple
          analysisType={analysisType}
          analysis={analysis}
          name={'test-files'}
          disabled={readOnly}
          initialValue={get(initialOptions, 'test.value')}
          onChange={handleSelectTestFiles}
        />
      </FormItem>
      <FormItem label="Please select Training Files">
        <DataFileTree
          multiple
          analysisType={analysisType}
          name={'training-files'}
          disabled={readOnly}
          analysis={analysis}
          initialValue={get(initialOptions, 'training.value')}
          onChange={handleSelectTrainingFiles}
        />
      </FormItem>
    </Form>
  )
}

WMHUploadSection.propTypes = {
  readOnly: PropTypes.bool,
}

WMHUploadSection.defaultProps = {
  readOnly: false,
}

export default WMHUploadSection
