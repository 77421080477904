import { get } from 'lodash'
import { useSelector } from 'react-redux'

import { selectAnalysisType } from 'store/modules/analyses'

import ASLUploadSection from './ASL'
import BrainvizUploadSection from './BrainViz'
import DefaultUploadSection from './DefaultUpload'
import DFNCUploadSection from './dFNC'
import FMRI32UploadSection from './fMRI32'
import FreesurferRegressionUploadSection from './FreesurferRegression'
import GroupICAUploadSection from './GICA'
import GroupSPMGLMUploadSection from './GroupSPMGLM'
import MancovaUploadSection from './Mancova'
import SPMGLMUploadSection from './SPMGLM'
import WMHUploadSection from './WMH'

const UploadSection = () => {
  const analysisType = useSelector(selectAnalysisType)

  const getUploadSection = () => {
    const analysisLabel = get(analysisType, 'label')
    window.analysisLabel = analysisLabel
    switch (analysisLabel) {
      case 'ASL':
        return <ASLUploadSection />
      case 'dFNC':
        return <DFNCUploadSection />
      case 'fMRI_32ch':
        return <FMRI32UploadSection />
      case 'FSR':
        return <FreesurferRegressionUploadSection />
      case 'GICA':
        return <GroupICAUploadSection />
      case 'GroupSPMGLM':
        return <GroupSPMGLMUploadSection />
      case 'MANCOVA':
        return <MancovaUploadSection />
      case 'SPMGLM':
        return <SPMGLMUploadSection />
      case 'WMH':
        return <WMHUploadSection />
      case 'BViZ':
        return <BrainvizUploadSection />
      default:
        return <DefaultUploadSection />
    }
  }

  return (
    <div className="analysis-start-widget" data-testid="upload-section">
      <div className="analysis-start-widget-heading">Data Sets</div>
      {getUploadSection()}
    </div>
  )
}

export default UploadSection
