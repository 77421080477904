import { Card, Col, Empty, Row, Table } from 'antd'
import { first, get, last, split } from 'lodash'
import PropTypes from 'prop-types'

import { FileInfo, PapayaViewer, Tabs } from 'components'
import {
  ARCHIVE_PATH,
  PAPAYA_ALLOWED_FILE_TYPES,
  UPLOAD_PATH,
} from 'config/base'

const DataFileTab = ({ dataFile }) => {
  const renderSharedUsers = () => {
    const colums = [
      {
        title: 'Username',
        dataIndex: 'username',
        key: 'username',
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
    ]

    return (
      <div>
        <h2 className="text-center mb-2">Shared Users</h2>
        {dataFile.shared_users.length > 0 ? (
          <Table
            dataSource={dataFile.shared_users}
            columns={colums}
            size="small"
            rowKey="id"
            pagination={false}
            bordered
          />
        ) : (
          <Empty description="No Shared Users" />
        )}
      </div>
    )
  }

  if (!dataFile) return null

  const isManaged = get(dataFile, 'series.is_managed')
  const mediaPath = isManaged ? ARCHIVE_PATH : UPLOAD_PATH
  const dataFilePath = `/${mediaPath}/${dataFile.path}/${first(dataFile.files)}`
  const fileExt = last(split(dataFile.name, '.')).toLowerCase()
  const isPapaya = PAPAYA_ALLOWED_FILE_TYPES.includes(fileExt)

  return (
    <Row>
      <Col>
        <div className="app-page__subheading">{dataFile.name}</div>
        <Card>
          <Tabs
            items={[
              {
                key: 'data-info',
                label: 'Data Info',
                children: <FileInfo dataFile={dataFile} />,
              },
              {
                key: 'shared-users',
                label: 'Shared Users',
                children: <>{renderSharedUsers()}</>,
              },
              isPapaya && {
                key: 'papaya',
                label: 'Papaya',
                children: <PapayaViewer file={dataFilePath} />,
              },
            ].filter(Boolean)}
          />
        </Card>
      </Col>
    </Row>
  )
}

DataFileTab.propTypes = {
  dataFile: PropTypes.object,
}

export default DataFileTab
