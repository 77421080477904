import { Tabs } from 'antd'
import PropTypes from 'prop-types'

import { FileInfo } from 'components'

import OutputFileTree from './OutputFileTree'
import VolumeViewer from './VolumeViewer'

const FreeSurfer7Result = ({ data, dataFile }) => {
  const { all_files } = data

  return (
    <div className="analysis-result" data-testid="freesurfer7-result">
      <Tabs
        animated={false}
        centered
        items={[
          {
            key: 'meta-data',
            label: 'Input',
            children: (
              <div className="w-50">
                <FileInfo dataFile={dataFile} />
              </div>
            ),
          },
          {
            key: 'files',
            label: 'Output Files',
            children: (
              <div className="w-33 mx-auto text-left">
                <OutputFileTree files={all_files} />
              </div>
            ),
          },
          {
            key: 'volume-viewer',
            label: 'Volume Viewer',
            children: <VolumeViewer data={data} />,
          },
        ]}
      />
    </div>
  )
}

FreeSurfer7Result.propTypes = {
  data: PropTypes.shape({
    all_files: PropTypes.array,
  }),
  dataFile: PropTypes.object,
  pi: PropTypes.string,
}

export default FreeSurfer7Result
