import { Navigate, Route, Routes } from 'react-router-dom'

import {
  AboutPage,
  AnalysisDetailPage,
  AnalysisPlanPage,
  AnalysisResultPage,
  AnalysisRunPage,
  AnalysisStartPage,
  AnalysisTypes,
  CLIDownloadPage,
  DataDirectoryPage,
  DataFilePage,
  EmailVerifyPage,
  LogInPage,
  MiscFilePage,
  MyInfoPage,
  MySitePage,
  Page404,
  ParameterSetDetailPage,
  ParameterSetListPage,
  PasswordResetPage,
  PreprocessingSummaryPage,
  RegisterPage,
  ScannerDetailPage,
  ScannerListPage,
  SiteDetailPage,
  SiteListPage,
  StatusPage,
  StudyDetailPage,
  StudyListPage,
  TagDetailPage,
  TagListPage,
  UserPage,
  UserProfilePage,
} from 'containers/Pages'
import {
  AdminRoute,
  NonAdminRoute,
  ProtectedRoute,
  UnprotectedRoute,
} from 'containers/RouteWrappers'

const authenticatedRoutes = (
  <>
    <Route path="/" element={<Navigate replace to="/study" />} />
    <Route path="/status" element={<StatusPage />} />
    <Route path="/me/:page" element={<MyInfoPage />} />
    <Route
      path="/my-site"
      element={
        <NonAdminRoute>
          <MySitePage />
        </NonAdminRoute>
      }
    />
    <Route exact path="/data-directory" element={<DataDirectoryPage />} />

    <Route exact path="/analysis-plans" element={<AnalysisPlanPage />} />

    <Route exact path="/analysis-types" element={<AnalysisTypes />} />

    <Route exact path="/analysis-run" element={<AnalysisRunPage />} />

    <Route
      path="/analysis-start/:analysisType"
      element={<AnalysisStartPage />}
    />

    <Route path="/analysis/:analysisId" element={<AnalysisDetailPage />} />
    <Route
      path="/analysis/:analysisId/result"
      element={<AnalysisResultPage />}
    />

    <Route path="/parameter-set" element={<ParameterSetListPage />} />
    <Route
      path="/parameter-set/:parameterSetId"
      element={<ParameterSetDetailPage />}
    />

    <Route
      path="/preprocessing-summary/:studyLabel"
      element={<PreprocessingSummaryPage />}
    />

    <Route
      path="/site"
      element={
        <AdminRoute>
          <SiteListPage />
        </AdminRoute>
      }
    />
    <Route
      path="/site/:siteId"
      element={
        <AdminRoute>
          <SiteDetailPage />
        </AdminRoute>
      }
    />

    <Route path="/study" element={<StudyListPage />} />
    <Route path="/study/:studyId" element={<StudyDetailPage />} />

    <Route path="/scanner" element={<ScannerListPage />} />
    <Route path="/scanner/:scannerId" element={<ScannerDetailPage />} />

    <Route path="/tag" element={<TagListPage />} />
    <Route path="/tag/:tagId" element={<TagDetailPage />} />

    <Route path="/about" element={<AboutPage />} />

    <Route path="/users" element={<UserPage />} />
    <Route path="/users/profile/:userId" element={<UserProfilePage />} />

    <Route path="/cli-download" element={<CLIDownloadPage />} />

    <Route path="/data/new" element={<DataFilePage />} />

    <Route path="/misc" element={<MiscFilePage />} />

    <Route element={Page404} />
  </>
)

const BFRoutes = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <UnprotectedRoute>
          <LogInPage />
        </UnprotectedRoute>
      }
    />
    <Route
      path="/register"
      element={
        <UnprotectedRoute>
          <RegisterPage />
        </UnprotectedRoute>
      }
    />
    <Route path="/email-verify" element={<EmailVerifyPage />} />
    <Route path="/password-reset" element={<PasswordResetPage />} />

    <Route element={<ProtectedRoute />}>{authenticatedRoutes}</Route>
  </Routes>
)

export default BFRoutes
