import { Card } from 'antd'
import { useSelector } from 'react-redux'

import { PageLayout } from 'containers/Layouts'
import { selectVersion } from 'store/modules/auth'

const About = () => {
  const version = useSelector(selectVersion)

  return (
    <PageLayout heading="Version">
      <Card>
        <h5 className="app-page__subheading">{version}</h5>
      </Card>
    </PageLayout>
  )
}

export default About
